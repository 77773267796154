<div class="container-fluid company-page">
  <div class="row content-page">
      <div class="content-watertest">
        <div class="container-title has-sub">
          <h1 class="white">Watertest {{title}} Company</h1>
        </div>
        <br>
        <div class="navigator-watertest-table">
          <button class="btn background-adey-red color-white btn-result" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
          &nbsp;
          <button *ngIf="(companyId==0)" class="btn background-adey-red color-white" (click)="watertestListCompany()">List Companies</button>
          &nbsp;
          <button *ngIf="(companyId>0)" class="btn background-adey-red color-white" (click)="watertestBackResultsCompany()">Back to company results</button>
        </div>
          <div *ngIf="(errors||success)" class="message">
              <div *ngIf="errors" class="alert alert-warning text-center">
                {{message}}
              </div>
              <div *ngIf="success" class="alert alert-success text-center">
                {{message}}
              </div>
          </div>
        <div class="company-container background-white col-xs-12">
          <app-waiting-spinner kind="bar" class="text-center" *ngIf="(loadingData||regCompany)">
            {{spinnerMessage}}
          </app-waiting-spinner>
          <form *ngIf="!(loadingData||regCompany)" [formGroup]="companyForm" (ngSubmit)="onFormSubmit()">

            <div class="company-section col-xs-12 col-sm-6">
                <div [class]="formGroupClass('name','col-xs-12')">
                  <label class="control-label">Name <span class="color-adey-red">*</span></label>
                  <input type="text" class="form-control" formControlName="name" />
                </div>
                <div [class]="formGroupClass('address_line_1','col-xs-12')">
                  <label class="control-label">Address line 1 <span class="color-adey-red">*</span></label>
                  <input type="text" class="form-control" formControlName="address_line_1" />
                </div>
                <div [class]="formGroupClass('address_line_2','col-xs-12')">
                  <label class="control-label">Address line 2</label>
                  <input type="text" class="form-control" formControlName="address_line_2" />
                </div>
                <div [class]="formGroupClass('address_line_3','col-xs-12')">
                  <label class="control-label">Address line 3</label>
                  <input type="text" class="form-control" formControlName="address_line_3" />
                </div>
                <div [class]="formGroupClass('town','col-xs-12')">
                  <label class="control-label">Town <span class="color-adey-red">*</span></label>
                  <input type="text" class="form-control" formControlName="town" />
                </div>
                <div [class]="formGroupClass('county','col-xs-12')">
                  <label class="control-label">County</label>
                  <input type="text" class="form-control" formControlName="county" />
                </div>
            </div>

            <div class="company-section col-xs-12 col-sm-6">
                <div [class]="formGroupClass('country','col-xs-12')">
                  <label class="control-label">Country <span class="color-adey-red">*</span></label>
                  <input type="text" class="form-control" formControlName="country" />
                </div>
                <div [class]="formGroupClass('postcode','col-xs-12')">
                  <label class="control-label">Postcode <span class="color-adey-red">*</span></label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="postcode" />
                      <!--<span class="input-group-btn">-->
                        <!--<button-->
                                <!--class="btn btn-default"-->
                                <!--type="button"-->
                                <!--[disabled]="!enablePostcodeLookup"-->
                                <!--(click)="postcodeLookup()"-->
                        <!--&gt;-->
                          <!--<i class="glyphicon glyphicon-search"></i>-->
                        <!--</button>-->
                      <!--</span>-->
                  </div>
                </div>
                <div [class]="formGroupClass('contact_email','col-xs-12')">
                  <label class="control-label">Email</label>
                  <input type="email" class="form-control" formControlName="contact_email" />

                </div>
                <div [class]="formGroupClass('contact_tel','col-xs-12')">
                  <label class="control-label">Contact telephone</label>
                  <input type="text" class="form-control" formControlName="contact_tel" />
                </div>
                <div class="btn-group col-xs-12">
                  <button class="btn background-green color-white pull-right no-radius submit-button"
                          style="float: none!important;"  >Submit</button>
                </div>
            </div>

          </form>
        </div>
      </div>
  </div>
</div>
