/**
 * Modal component for registering sense warranty
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm
import {Component, HostListener, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {State as AdminPro3ListState} from '../../../../../state-management/reducers/admin-pro3';
import {Action, Store} from '@ngrx/store';
import {StoreState} from '../../../../../state-management/store';
import {AdminDevice, AdminPro3RegisterWarrantyRequest} from '../../../../../models/admin-pro3';
import {
    AdminPro3RegisterWarrantyDialogData
} from '../../../../../models/admin-pro3-dialog-data';
import {MAT_DIALOG_DATA, MatDialogRef, MatHorizontalStepper, MatSnackBar} from '@angular/material';
import * as DeviceActions from '../../../../../state-management/actions/admin-pro3';
import {DFSPRequest} from '../../../../../models/df-proclub';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {Form, NgForm} from '@angular/forms';
import {Actions} from '@ngrx/effects';
import {take, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {UserProfile} from '../../../../../models/user-profile';
import {AdminGetInstallerFromPartialRequest} from '../../../../../state-management/actions/admin-pro3';
import {Subject} from 'rxjs/Subject';
import {AdminInstallerService} from '../../../../../services/admin-installer.service';

/**
 * The component
 */
@Component({
    selector: 'app-admin-pro3-register-warranty',
    templateUrl: './admin-pro3-register-warranty.component.html',
    styleUrls: ['./admin-pro3-register-warranty.component.scss']
})
export class AdminPro3RegisterWarrantyComponent implements OnInit {
    // Store state
    private store: Store<StoreState>;
    public filterList$: Observable<AdminPro3ListState>;

    //Installer service
    private adminInstallerService: AdminInstallerService;

    //The device
    public device: AdminDevice;
    public selfRef: any;

    //If we should be editing an existing warranty (default is to create)
    editMode = false;

    //The warranty data
    warranty: AdminPro3RegisterWarrantyRequest;
    @ViewChild('warrantyForm') warrantyForm: NgForm;
    installer: Partial<UserProfile>;

    //If there is an existing warranty, has it been loaded?
    existingLoaded = false;

    //The stepper
    @ViewChild('warrantyWizard') stepper: MatHorizontalStepper;

    //Are we on the first page? Or the last? Start on the first, not the last
    stepperFirst = true;
    stepperLast = false;
    stepperPageCount: number;
    actions$: any;

    //Email search and result:
    emailNotFound = false;
    emailFound = false;
    snackBar: MatSnackBar;

  /**
     * Handle stepper page changes
     *
     * @param event
     */
    stepperWatch(event: StepperSelectionEvent) {
        //Do we know how many pages there are?
        if (this.stepperPageCount === undefined) {
            this.stepperPageCount = this.stepper._steps.length;
        }

        //Is this the first page?
        this.stepperFirst = (event.selectedIndex <= 0);

        //Is it the last (0-based index == 1-based count, )?
        this.stepperLast = (event.selectedIndex >= this.stepperPageCount - 1);
    }

    /**
     * Inject dependencies and register the NGRX store
     *
     * @param injector
     */
    constructor(injector: Injector) {
        //Connect to the store
        this.store = <Store<StoreState>>injector.get(Store);
        this.filterList$ = this.store.select('adminPro3');

        //Installer lookup
      this.adminInstallerService = injector.get(AdminInstallerService);

        //Get the device out of the incoming data
        const data = <AdminPro3RegisterWarrantyDialogData>injector.get(MAT_DIALOG_DATA);
        this.device = data.device;

        //Handle for the dialog
        this.selfRef = <MatDialogRef<AdminPro3RegisterWarrantyComponent>>injector.get(MatDialogRef);

        //Switch off the close on background to avoid losing unsaved changes
        this.selfRef.disableClose = true;

        //Listen to the actions stream
        this.actions$ = injector.get(Actions);

        this.snackBar = injector.get(MatSnackBar);
    }

    /**
     * Trigger the save action on the warranty
     */
    saveWarranty(warrantyForm) {
        //If the form is invalid, go no further
        if (!warrantyForm.valid) {
          const controls = [];

          // Run through all of the controls in the form,
          // find any that are invalid.
          Object.keys(warrantyForm.controls).forEach(key => {
            if (!warrantyForm.controls[key].valid) {
              controls.push(this.toTitleCase(key.replace(/_/g, ' ')));
            }
          }, this);

          this.snackBar.open('Warranty information incomplete - ' + controls[0], '', {duration: 10000});
            return;
        }

        //Ready to send
        const payload = new DFSPRequest<AdminPro3RegisterWarrantyRequest>(this.warranty);

      //Submit edits using a PATCH request, including the device ID:
      if (this.editMode) {
            //Include the ID:
            payload.params.device_id = this.device.id;
      } else {
            //If not, remove the field trial flag (this can't currently be set on create)
            payload.params.device_isTestDevice = undefined;
      }

        //Send the request to the server
        this.store.dispatch(new DeviceActions.AdminRegisterMagsenseDeviceRequestAction(payload));

        //Listen out for response
        this.actions$.ofType(
            DeviceActions.ADMIN_REGISTER_MAGSENSE_DEVICE_FAILURE,
            DeviceActions.ADMIN_REGISTER_MAGSENSE_DEVICE_RESPONSE
        ).pipe(take(1))
            .subscribe((action: Action) => {
                if (action.type === DeviceActions.ADMIN_REGISTER_MAGSENSE_DEVICE_RESPONSE) {
                  //Get the warranty info
                  this.getWarrantyInfo();

                    //We've succeeded, so close the dialog
                    this.selfRef.close();
                  this.snackBar.open('Warranty information successfully updated', '', {duration: 3000});
                    return;
                }

                //Otherwise, it was an eeerror :( ...
              this.snackBar.open('Server Error - please check all fields (especially user ID).\nIf this problem persists, please contact support.', '', {duration: 10000});
            })
        ;

    }

    /**
     * Check for existing warranty info
     */
    ngOnInit() {
        //If we have an existing warranty, use that and go to edit mode
        if (this.device.warrantyID) {
            this.editMode = true;

          this.getWarrantyInfo();
        } else {
            //Otherwise create a new warranty for entry
            this.warranty = {
                ...new AdminPro3RegisterWarrantyRequest(), ...{
                    user_id: this.device.installUID || undefined,
                    device_filterNo: this.device.filterNo,
                    device_isWarrantyReplacement: this.device.isWarrantyReplacement,
                    device_serialNo: this.device.serialNo,
                    job_cleaned: false,
                    job_magnacleanse: false,
                    job_new_filter: false,
                    job_new_system: false,
                    job_protect: false,
                    job_smart_thermostat_installed: false,
                    job_test: false,
                    warranty_new_boiler: false,
                device_isTestDevice: this.device.fieldTrialFlag,
                }
            };
        }
    }

  private getWarrantyInfo() {
//Get the warranty info
    const attributes = {device_id: this.device.id, hosted_company_id: this.device.hosted_company_id};
    const payload = new DFSPRequest<{ device_id: number, hosted_company_id }>(attributes);
    this.store.dispatch(new DeviceActions.AdminGetMagsenseWarrantyInfoAction(payload));

    this.actions$.ofType(
      DeviceActions.ADMIN_GET_MAGSENSE_WARRANTY_INFO_SUCCESS,
      DeviceActions.ADMIN_GET_MAGSENSE_WARRANTY_INFO_FAILED
    ).pipe(take(1)).subscribe((action: Action) => {
      const successAction = <DeviceActions.AdminGetMagsenseWarrantyInfoActionSuccess>action;
      if (successAction && successAction.payload) {
        this.warranty = {
          ...successAction.payload.data[0], ...{
            //Fix broken dates:
            // //FIXME: This is not very robust
            servicerecord_service_date: this.fixDate(successAction.payload.data[0].servicerecord_service_date),
            servicerecord_last_service_date: this.fixDate(successAction.payload.data[0].servicerecord_last_service_date),
            warranty_install_date: this.fixDate(successAction.payload.data[0].warranty_install_date),
            job_filter_expiry_date: this.fixDate(successAction.payload.data[0].job_filter_expiry_date),
          }
        };

        this.device.warrantyID = this.device.warrantyID || this.warranty.id;

        this.existingLoaded = true;
      }
    });
  }

  /**
     * Format the date understandable by the material datepicker
     *
     * @param inputDate
     */
  fixDate(inputDate: any) {
    if (<Date>inputDate || <string>inputDate) {
      return new Date(inputDate);
    }
  }

  /**
   * Reset the status of anything watching the email field
   */
  resetEmailNotFound() {
    this.emailNotFound = false;
    this.emailFound = false;
  }

  /**
   * Get the details of the installer
   *
   * @param partialInstaller
   * @param event The event coming in if it needs cancelling
   */
  findAndUpdateInstaller(partialInstaller: Partial<UserProfile>) {
        this.adminInstallerService.findUserByPartial(partialInstaller).pipe(take(1))
          .subscribe((result: UserProfile) => {
            //If one is found...
            if (result && result.id) {
              //...Update the user ID and let the user know
              this.warranty.installer_id = parseInt(result.id, 10);
              this.snackBar.open('User found - ID: ' + result.id, '', {duration: 5000});

              //Mark that the amil was found so a tick can be shown next to the user ID
              this.emailFound = true;
            } else {
              //Mark that the current e-mail is not found, so
              this.emailNotFound = true;
            }
          });
  }

  /**
   * Convert a field name into a user friendly string
   *
   * @param key
   */
  toTitleCase(key: string): string {
    //Split the string into words.
    const keySections = key.toLowerCase().split(' ');

    //Run through the words.
    for (let i = 0; i < keySections.length; i++) {
      //Convert the first letter of each word to upper case.
      keySections[i] = keySections[i].charAt(0).toUpperCase() + keySections[i].slice(1);
    }

    //Return the user friendly field name.
    return keySections.join(' ');
  }
}
