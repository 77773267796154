<div class="container-fluid voucher-page">
  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Redeem voucher</h1>
    </div>

    <!--<app-page-content page="REWARDS" field="intro" class="color-white intro-above-content"></app-page-content>-->

    <div class="voucher-container background-white col-xs-12">
      <div class="voucher-content col-xs-12" *ngIf="loginDetails$ | async; let loginDetails">

        <app-waiting-spinner kind="bar" *ngIf="(emailDetails$ | async).pending">Sending email, please wait...
        </app-waiting-spinner>
        <app-message-panel kind="ERROR" *ngIf="(emailDetails$ | async).error">
          {{(emailDetails$ | async).error}}
        </app-message-panel>

        <app-waiting-spinner kind="bar" *ngIf="loginDetails.pending">Loading form, please wait...</app-waiting-spinner>
        <div class="contact-form-container align-center" *ngIf="!loginDetails.pending">
          <h1 class="color-adey-red medium">Want to redeem your points?</h1>
          <h2 class="color-dark-gray">Simply fill out the form below and we will aim to respond as soon as possible.
          </h2>

          <form [formGroup]="fg" class="contact-form align-left" novalidate (ngSubmit)="submitForm()">
            <div [class]="formGroupClass('name', 'col-sm-6 col-xs-12')">
              <label class="control-label">Name <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="name" />
            </div>
            <div [class]="formGroupClass('email', 'col-sm-6 col-xs-12')">
              <label class="control-label">Email <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="email" />
            </div>
            <div [class]="formGroupClass('company', 'col-sm-6 col-xs-12')">
              <label class="control-label">Company <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="company" />
            </div>
            <div [class]="formGroupClass('addressLine1', 'col-sm-6 col-xs-12')">
              <label class="control-label">Address line 1 <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="addressLine1" />
            </div>
            <div [class]="formGroupClass('addressLine2', 'col-sm-6 col-xs-12')">
              <label class="control-label">Address line 2</label>
              <input type="text" class="form-control" formControlName="addressLine2" />
            </div>
            <div [class]="formGroupClass('town', 'col-sm-6 col-xs-12')">
              <label class="control-label">Town</label>
              <input type="text" class="form-control" formControlName="town" />
            </div>
            <div [class]="formGroupClass('county', 'col-sm-6 col-xs-12')">
              <label class="control-label">County</label>
              <input type="text" class="form-control" formControlName="county" />
            </div>
            <div [class]="formGroupClass('postcode', 'col-sm-6 col-xs-12')">
              <label class="control-label">Postcode <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="postcode" />
            </div>
            <div *ngIf="fgChange" class="col-xs-12 color-adey-red">
              <p>The details you have entered here do not match your account, would you like to <a [routerLink]="" (click)="updateProfile()">update your
                  account details</a>?</p>
            </div>
            <hr class="col-xs-12" />
            <div class="col-xs-12">
              <h3 class="color-adey-red">
                Points available: {{loginDetails.currentProfile.pointsCurrent}}
              </h3>
              <div [class]="formGroupClass('pointsToConvert')">
                <label class="control-label">Available vouchers (1 point = <span [translate]="'CURRENCY'"></span>1)
                  <span class="color-adey-red">*</span></label>
                <select class="form-control" formControlName="pointsToConvert">
                  <option value="">Please select a voucher</option>
                  <option [value]="voucher" *ngFor="let voucher of availableVouchers"
                    [disabled]="loginDetails.currentProfile.pointsCurrent < voucher"><span
                      [translate]="'CURRENCY'"></span>{{voucher}}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12">
              <input class="btn full-width background-adey-red color-white" type="submit" value="Submit"
                [disabled]="pointsSpent" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
