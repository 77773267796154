import { Component, OnInit}      from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {CompanyService} from '../../services/company.service';
import {ResultCompanyListService} from '../../services/resultcompanylist.service';

@Component({
  selector: 'app-resultcompanylist',
  templateUrl: './resultcompanylist.component.html',
  styleUrls: ['./resultcompanylist.component.scss']
})
export class ResultCompanyListComponent implements OnInit {

  type = 'member';
  query = '';
  companyName = null;
  companyId = 0;
  memberName = '';
  postCode = '';
  search = '';

  constructor(
      private resultcompanylistService: ResultCompanyListService,
      private companyService: CompanyService,
      private router: Router,
      private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.GetResultsCompany();

  }

  /**
   * Get the list of results Customer Service
   */
  GetResultsCompany() {

    this.route.params.subscribe(params => {
      this.companyId = (params['id'] > 0) ? params['id'] : 0;
      this.type = params['type'];
      this.query = (params['query'] > 0) ? params['query'] : 0;
    });

    if (this.companyId > 0) {
      this.companyService.getCompanyById(this.companyId).subscribe(
          resp => {
            this.companyName = resp['data'][0]['name'];
          }
      );
    }

    if (this.type === 'member') {
      this.resultcompanylistService.getUserById(this.query).subscribe(
          resp => {
            //console.log(resp);
            this.memberName = resp['data'][0]['tester_name'] ? resp['data'][0]['tester_name'] : '';
          }
      );
    }

    if (this.type === 'address') {
      this.resultcompanylistService.getLocationById(this.query).subscribe(
          resp => {
            //console.log(resp);
            this.postCode = resp['data'][0]['postcode'];

          }
      );
    }

    this.search = this.resultcompanylistService.getResultsCustomerService(this.query, this.type, this.companyId);
  }

  /**
   * Navigates to the /watertest/company/:id route
   */
  watertestBackResultsCompany() {
    this.router.navigate(['/watertest/company/' + this.companyId]);
  }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }
}
