import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';

import * as ActionTypes from '../actions/admin-rewards';

import {
  DeleteAdminPromotionResponse,
  FetchAdminPromotionsResponse,
  TransferAdminUserPointsResponse,
  UpdateAdminUserPointsResponse,
  AddAdminPromotionResponse,
  FetchUserPointsHistoryResponse,
} from '../../models/admin-rewards';

@Injectable()
export class AdminRewardEffects {
  constructor(
    private actions$: Actions,
    private service: AdminService,
  ) { }

  @Effect() addAdminPromotion$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_PROMOTION_REQUEST)
    .switchMap((req: ActionTypes.AddAdminPromotionRequestAction): Observable<ActionTypes.AddAdminPromotionResponseAction> =>
      this.service.addPromotion(req.payload)
        .map((res: AddAdminPromotionResponse): ActionTypes.AddAdminPromotionResponseAction =>
          new ActionTypes.AddAdminPromotionResponseAction(res)
        )
    );

  @Effect() deleteAdminPromotion$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_PROMOTION_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminPromotionRequestAction): Observable<ActionTypes.DeleteAdminPromotionResponseAction> =>
      this.service.deletePromotion(req.payload)
        .map((res: DeleteAdminPromotionResponse): ActionTypes.DeleteAdminPromotionResponseAction =>
          new ActionTypes.DeleteAdminPromotionResponseAction(res)
        )
    );

  @Effect() fetchAdminPromotions$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_PROMOTIONS_REQUEST)
    .switchMap((): Observable<ActionTypes.FetchAdminPromotionsResponseAction> =>
      this.service.fetchPromotions()
        .map((res: FetchAdminPromotionsResponse): ActionTypes.FetchAdminPromotionsResponseAction =>
          new ActionTypes.FetchAdminPromotionsResponseAction(res)
        )
    );

  @Effect() fetchUserPointsHistory$: Observable<Action> = this.actions$
  .ofType(ActionTypes.FETCH_USER_POINTS_HISTORY_REQUEST)
  .switchMap((req: ActionTypes.FetchUserPointsHistoryRequestAction): Observable<ActionTypes.FetchUserPointsHistoryResponseAction> =>
    this.service.fetchUserPointsHistory(req.payload)
      .map((res: FetchUserPointsHistoryResponse): ActionTypes.FetchUserPointsHistoryResponseAction =>
        new ActionTypes.FetchUserPointsHistoryResponseAction(res)
      )
  );

  /**
   * For an TransferAdminUserPointsRequestAction, call
   * AdminService::updateUserPoints() and dispatch a new
   * TransferAdminUserPointsResponseAction with the response.
   */
  @Effect() transferUserPoints$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TRANSFER_ADMIN_USER_POINTS_REQUEST)
    .switchMap((req: ActionTypes.TransferAdminUserPointsRequestAction): Observable<ActionTypes.TransferAdminUserPointsResponseAction> =>
      this.service.transferUserPoints(req.payload)
        .map((res: TransferAdminUserPointsResponse): ActionTypes.TransferAdminUserPointsResponseAction =>
          new ActionTypes.TransferAdminUserPointsResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminUserPointsRequestAction, call
   * AdminService::updateUserPoints() and dispatch a new
   * UpdateAdminUserPointsResponseAction with the response.
   */
  @Effect() updateUserPoints$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_USER_POINTS_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminUserPointsRequestAction): Observable<ActionTypes.UpdateAdminUserPointsResponseAction> =>
      this.service.updateUserPoints(req.payload)
        .map((res: UpdateAdminUserPointsResponse): ActionTypes.UpdateAdminUserPointsResponseAction =>
          new ActionTypes.UpdateAdminUserPointsResponseAction(res)
        )
    );
}
