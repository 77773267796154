import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {StoreState} from '../../state-management/store';
import {State as ReviewInvitationState} from '../../state-management/reducers/invitation';
import * as invitationAction from '../../state-management/actions/invitation';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {filter, take, takeUntil} from 'rxjs/operators';
import { AdditionalFieldSpecifications } from '../../models/invitation';
import 'rxjs/add/operator/filter';
import { AcceptInvitationQueryRequestAction } from '../../state-management/actions/invitation';

@Component({
  selector: 'app-review-invitation',
  templateUrl: './review-invitation.component.html',
  styleUrls: ['./review-invitation.component.scss']
})
export class ReviewInvitationComponent implements OnDestroy, OnInit {

  // Store state
  public reviewInvitation$: Observable<ReviewInvitationState>;

  //For unsubscribing...
  destroying$ = new Subject<void>();

  public pageTitle: string = 'Review invitation';


  // Form errors
  public form_errors: string = null;

  // term & conditions error
  public error_terms_portal: string = null;
  public error_policy: string = null;
  public error_terms_app: string = null;

  // Flag to show if form has been submitted
  private submitted: boolean = false;


  // Instance to store the login form data
  reviewInvitationForm: FormGroup;

  // Activation token provided by the querystring parameter "token"
  public token: string = null;

  //when you post accept invitation ignore this field
  public except_fields = ['termsAndConditionsConfirm'];

  //fields to post accept invitation
  public post_fields: any = {};

  constructor(
      private _store: Store<StoreState>,
      private _route: ActivatedRoute,
      private _router: Router,
  ) {
    // Set reference to login state
    this.reviewInvitation$ = this._store.select('ReviewInvitation');
  }

  ngOnInit() {

    // subscribe
    this._route.paramMap
        .pipe(takeUntil(this.destroying$))
        .subscribe((params: ParamMap) => {
          this.token = params.get('token');

          //Dispatch an InvitationQueryRequestAction call to the store in order to get the info from invitation
          if (this.token) {
            this._store.dispatch(new invitationAction.ReviewInvitationQueryRequestAction({token: this.token}));
          }

        });

    this.buildForm();
  }

  /**
   * Unload resources when the page is destroyed to free up space
   */
  ngOnDestroy() {
    //Fire the destroying signal for anything listening
    this.destroying$.next();
  }

  /**
   * Apply a class to the form field dependant on the form's state
   *
   * @param {string} fieldName    The name of the field to check
   * @param {string} extraClasses Extra classes to add to the html
   *
   * @return {string} Return a string of classes to the form field object
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.reviewInvitationForm.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Called when the user submits the form
   */
  onSubmitForm() {

    this.submitted = true;
    this.error_terms_portal = null;
    this.error_terms_app = null;
    this.error_policy = null;
    this.form_errors = null;
    if (this.reviewInvitationForm.valid)
    {
      // If the form is valid dispatch AcceptInvitationQueryRequestAction to pass the form data
      this._store.dispatch(new AcceptInvitationQueryRequestAction({
        token: this.token,
        activationCode: this.reviewInvitationForm.controls['activationCode'].value
      }));

    }else {
      this.form_errors = 'The form contains errors. Please correct the highlighted fields and try again.';
      if ( ! this.reviewInvitationForm.controls['termsAndConditionsConfirm'].value ) {
        this.error_terms_portal = 'Accept our terms & conditions.';
      }

      if ( ! this.reviewInvitationForm.controls['dataProtectionConfirm'].value ) {
        this.error_policy = 'Accept our privacy policy.';
      }

      if ( ! this.reviewInvitationForm.controls['termsAndConditionsConfirmApp'].value ) {
        this.error_terms_app = 'Accept our terms & conditions - App.';
      }

    }
  }

  /**
   * Build Review invitation form
   */
  buildForm() {
    //Build the default form group
    this.reviewInvitationForm = new FormGroup({
      'termsAndConditionsConfirm': new FormControl(false, Validators.pattern('true')),
      'dataProtectionConfirm': new FormControl(false, Validators.pattern('true')),
      'termsAndConditionsConfirmApp': new FormControl(false, Validators.pattern('true')),
      'activationCode': new FormControl('')
    });

    //Subscribe to the state to get the invitation data
    // this.reviewInvitation$
    //     .pipe(
    //       //Listen out for the destroying signal to free up resources
    //       takeUntil(this.destroying$),
    //       //First check to see we have results
    //       filter((invitation: ReviewInvitationState) => invitation.results),
    //       //Only need to run this once
    //       take(1)
    //     )
    //     //Do the subscription
    //     .subscribe((invitation: ReviewInvitationState) => {
    //         //Go through any additional fields required and add them, with any options, into the FormGroup
    //         //invitation.results['additional_fields'].forEach((field: AdditionalFieldSpecifications) => this.buildField(field));
    //     });
  }

  /**
   * Add a given AdditionalFieldSpecifications to the FormGroup
   *
   * @param additionalField The field to add
   */
  private buildField(additionalField: AdditionalFieldSpecifications) {
    //Set up an array for options
    const options = [];

    //Check for a pattern requirement
    if (additionalField.pattern) {
      options.push(Validators.pattern(additionalField.pattern));
    }

    //Check if the field is required
    if (additionalField.required) {
      options.push(Validators.required);
    }

    //Add (or update) the field in the FormGroup
    this.reviewInvitationForm.setControl(additionalField.name, new FormControl('', options));
  }

  /**
   *  Called when the user clicks the button within the registration success message. Will navigate the user to the
   *  login page
   */
  onConfirmSuccess() {
    this._router.navigate(['/login']);
  }
}
