/**
 * ServiceRecordService: provides all functionality related to service records
 */

import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


import { ApiService } from './api.service';
import { ServiceRecordService as MockService } from './service-record.service.mock';

import { Job } from '../models/job';

import {
  ServiceRecordStats,

  AddServiceRequestResponse,
  FetchServiceRecordStatsResponse,
  FetchServiceRecordsRequest,
  FetchServiceRecordsResponse,
  ServiceRecord,
  ServiceRecordFlags,
  ServiceRequest,
  SetServiceRecordFlagRequest,
  SetServiceRecordFlagResponse,
  UpdateServiceRecordRequest,
  UpdateServiceRecordResponse,
} from '../models/service-record';


@Injectable()
export class ServiceRecordService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService;


  constructor(
    private apiService: ApiService
  ) {
    this.mockService = new MockService();
  }

  /**
   * Adds a new ServiceRequest
   *
   * @param {ServiceRequest} sr
   * @return {Observable<AddServiceRequestResponse>}
   */
  addServiceRequest(sr: ServiceRequest): Observable<AddServiceRequestResponse> {
    return this.mockService.addServiceRequest(sr);
  }

  /**
   * Fetches ServiceRecord models
   *
   * @param {FetchServiceRecordsRequest} req
   * @return {Observable<FetchServiceRecordsResponse>}
   */
  fetchRecords(req: FetchServiceRecordsRequest): Observable<FetchServiceRecordsResponse> {
    return this.apiService.apiGet('/servicerecord?offset=' + encodeURIComponent((req.perPage * (req.pageNo - 1)).toString()) + `&upcoming=${req.upcoming}`)
      .map((res: any): FetchServiceRecordsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error:      valid ? null : 'Invalid response from server',
          records:    valid ? res.resource.map(ServiceRecord.fromApiData) : null,
          totalPages: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 1,
        };
      })
      .catch((err: any): Observable<FetchServiceRecordsResponse> => {
        return Observable.of({
          error:      err && err.error && err.error.message
            ? `Unable to fetch job records: ${err.error.message}`
            : 'Unable to fetch job records',
          records:    null,
          totalPages: 0,
        });
      });
  }

  /**
   * Fetches service records statistics
   *
   * @return {Observable<FetchServiceRecordStatsResponse>}
   */
  fetchStats(): Observable<FetchServiceRecordStatsResponse> {
    return this.apiService.apiPost('/servicerecord-stats', null)
      .map((res: any): FetchServiceRecordStatsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0;
        return {
          error: valid ? null : 'Invalid response from server',
          stats: ServiceRecordStats.fromAPI(res.resource[0]),
        };
      })
      .catch((err: any): Observable<FetchServiceRecordStatsResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to fetch statistics: ${err.error.message}`
            : 'Unable to fetch statistics',
          stats: null,
        })
      );
  }

  /**
   * Sets one or more flags for a specified ServiceRecord. Also updates related
   * model data as necessary.
   *
   * @param {SetServiceRecordFlagRequest} req
   * @return {Observable<SetServiceRecordFlagResponse>}
   */
  setFlags(req: SetServiceRecordFlagRequest): Observable<SetServiceRecordFlagResponse> {

    // 1. Update the flags in the ServiceRecord
    const reqUpdateSRFlags = this.apiService.apiPatch('/servicerecord', ServiceRecordFlags.toAPIPatchServiceRecord(req.record, req.flags))
      .map((res: any): boolean => true)
      .catch((err: any): Observable<boolean> => Observable.of(false));

    // 2. Update the fields associated with the flags that are set in the
    // associated Job (if applicable)
    const reqUpdateJob = req.record.job
      ? this.apiService.apiPatch(`/job/${req.record.jobId}`, ServiceRecordFlags.toAPIPatchJob(req.record, req.flags))
        .map((res: any): boolean => true)
        .catch((err: any): Observable<boolean> => Observable.of(false))
      : Observable.of(true);

    // 3. Update the fields associated with the flags that are set in the
    // associated Warranty (if applicable)
    const reqUpdateWarranty = req.record.warranty
      ? this.apiService.apiPatch('/warranty', ServiceRecordFlags.toAPIPatchWarranty(req.record, req.flags))
        .map((res: any): boolean => true)
        .catch((err: any): Observable<boolean> => Observable.of(false))
      : Observable.of(true);

    // 4. Join requests and return a SetServiceRecordFlagResponse
    return Observable.forkJoin([reqUpdateSRFlags, reqUpdateJob, reqUpdateWarranty])
      .map((res: boolean[]): SetServiceRecordFlagResponse => {
        const valid: boolean = res.filter((v: boolean) => v).length === 3;
        return {
          record: req.record,
          error:  valid ? null : 'Error updating flag',
          flags:  req.flags,
        };
      })
      .catch((err: any): Observable<SetServiceRecordFlagResponse> =>
        Observable.of({
          record: req.record,
          error:  'Unable to update flag',
          flags:  req.flags,
        })
      );
  }

  /**
   * Updates an existing ServiceRecord
   *
   * @param {UpdateServiceRecordRequest} req
   * @return {Observable<UpdateServiceRecordResponse>}
   */
  update(req: UpdateServiceRecordRequest): Observable<UpdateServiceRecordResponse> {
    // Updating a service record now updates the underlying Job, so the service
    // record must be related to a Job
    if (req.record.type.toLowerCase() !== 'job' || !req.record.jobId || !req.record.job) {
      return Observable.of({
        error:  'Only service records for jobs can be updated',
        record: req.record,
      });
    }

    return this.apiService.apiPatch(`/job/${req.record.jobId}`, Job.toApiJobNewCustomer(req.record.job))
      .map((res: any): UpdateServiceRecordResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error:  valid ? null : 'Invalid response from server',
          record: req.record,
        };
      })
      .catch((err: any): Observable<UpdateServiceRecordResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to update job record: ${err.error.message}`
            : 'Unable to update job record',
          record: req.record,
        })
      );
  }

}
