import 'rxjs/add/operator/debounceTime';

import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Store }        from '@ngrx/store';
import { Observable }   from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { MatDialog, MatExpansionPanel } from '@angular/material';


import { StoreState }              from '../../../state-management/store';
import { State as AdminJobsState } from '../../../state-management/reducers/admin-jobs';

import {
  AddAdminJobRequestAction,
  AddAdminPromoCodeRequestAction,
  AddAdminWarrantyRequestAction,
  DeleteAdminJobRequestAction,
  DeleteAdminPromoCodeRequestAction,
  DeleteAdminWarrantyRequestAction,
  FetchAdminJobsRequestAction,
  FetchAdminPromoCodesRequestAction,
  FetchAdminWarrantiesRequestAction,
  UpdateAdminJobRequestAction,
  UpdateAdminPromoCodeRequestAction,
  UpdateAdminWarrantyRequestAction,
  UpdateAdminCustomerRequestAction,
  UpdateRegisteredProductRequestAction
} from '../../../state-management/actions/admin-jobs';

import{ FetchAdminWarrantiesRequest } from '../../../models/admin-jobs';
import { FetchUserSuggestionRequestAction } from '../../../state-management/actions/user-suggestion';
import { FetchCustomerSuggestionRequestAction } from '../../../state-management/actions/customer-suggestion';
import { WarrantyService } from '../../../services/warranty.service';

import { Customer }            from '../../../models/customer';
import { Job, RegisterJobRequest }                 from '../../../models/job';

import { PromoCode, Warranty, FetchProductTypesResponse } from '../../../models/warranty';

import { UserSuggestion }      from '../../../models/user-suggestion';
import { CustomerSuggestion } from '../../../models/customer-suggestion';

import { JobEditModalComponent }      from '../../job/job-edit-modal/job-edit-modal.component';
import { WarrantyEditModalComponent } from '../../warranty/warranty-edit-modal/warranty-edit-modal.component';

import * as moment from 'moment';

/**
 * Summary
 *    Provides a page to manage user Jobs, Warranties and promotional codes
 *
 * Description
 *    Jobs and Warranties can be loaded for a selected user or by a customer
 *    name search and can then be managed. Promotional codes can also be
 *    managed.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-installer-jobs',
  templateUrl: './admin-installer-jobs.component.html',
  styleUrls: ['./admin-installer-jobs.component.scss']
})
export class AdminInstallerJobsComponent implements OnDestroy, OnInit {

  // "adminJobs" from the store
  public adminJobs$: Observable<AdminJobsState>;

  // Subscription to above Observable
  private adminJobsSub$: Subscription = null;

  // Form for adding and editing promotional codes
  public fgPromoCode: FormGroup;

  // Form for selecting the seach type
  public fgSearchType: FormGroup;

  // Form for entering a customer query
  public fgCustomerSelect: FormGroup;

  // Form for entering a customer query
  public fgWarrantyFilter: FormGroup;

  // Form for seleting a user
  public fgUserSelect: FormGroup;

  // Subscription to fgUserSelect.userId.valueChanged from fgUserSelect form
  private userSelectSub$: Subscription = null;

  // Subscription to fgCompanySelect.userId.valueChanged from fgCompanySelect form
  private companySelectSub$: Subscription = null;

  // Subscription to fgPostcodeSelect.userId.valueChanged from fgPostcodeSelect form
  private postcodeSelectSub$: Subscription = null;

  // Subscription to fgCustomerSelect.userId.valueChanged from fgCustomerSelect form
  private customerSelectSub$: Subscription = null;

  // References to edit dialogs
  private dialogRef_job;
  private dialogRef_warranty;

  // The form group responsible for any filters for the paginated view
  public fgFilters: FormGroup;

  // Paginated page numbers
  public pagination: number[];
  public paginationJobs: number[];

  // Local pagination variables (from store)
  public pageNumJobs:          number = 1;
  public totalPagesJobs:       number = 0;
  public pageNumWarranties:    number = 1;
  public totalPagesWarranties: number = 0;

  // Errors relating to the promotional code form
  public promoCodeErrors: string = null;

  // Used for calculating the expiry on warranty cards.
  public  productTypes$: Observable<FetchProductTypesResponse>;
  private productTypes: any[];

  constructor(
    private dialog:   MatDialog,
    private fb:       FormBuilder,
    private store:    Store<StoreState>,
    private service:  WarrantyService,

  ) {
    this.adminJobs$ = this.store.select('adminJobs');

    this.fgPromoCode = this.fb.group({
      updateCode:  null,
      id:          null,
      code:        ['',    Validators.required],
      name:        ['',    Validators.required],
      enabled:     [true,  Validators.required],
      usesPerUser: [0,     Validators.required],
      calcType:    ['MUL', Validators.required],
      calcValue:   [1,     Validators.required],
    });

    this.fgSearchType = this.fb.group({
      type: 'USER',
    });

    this.fgCustomerSelect = this.fb.group({
      customerName: '',
    });

    this.fgUserSelect = this.fb.group({
      userId: '',
      postCodeUserId: '',
      companyUserId: ''
    });

    this.fgWarrantyFilter = this.fb.group({
      nameFilter: '',
      postcodeFilter: '',
      serialFilter: '',
      addressFilter: '',
    });

  }

  ngOnInit() {
    this.productTypes$ = this.service.FetchProductTypes(false);

    this.productTypes$.subscribe(val => {
      this.productTypes = val.products;
    });

    // Update local state when store changes
    this.adminJobsSub$ = this.adminJobs$.subscribe((state: AdminJobsState) => {
      this.pageNumJobs          = state.jobs.pageNum;
      this.totalPagesJobs       = state.jobs.totalPages;
      this.pageNumWarranties    = state.warranties.pageNum;
      this.totalPagesWarranties = state.warranties.totalPages;
      this.paginate();
    });

    // Fetch user suggections when the user select form changes
    this.customerSelectSub$ = this.fgCustomerSelect.get('customerName')
      .valueChanges
      .debounceTime(500)
      .subscribe((v: string) => {
          this.store.dispatch(new FetchCustomerSuggestionRequestAction({ query: v }));
      });

    // Fetch user suggections when the user select form changes
    this.userSelectSub$ = this.fgUserSelect.get('userId')
      .valueChanges
      .debounceTime(500)
      .subscribe((v: string) => {
        this.store.dispatch(new FetchUserSuggestionRequestAction({query: v}));
      });

    // Fetch user suggections when the user select form changes
    this.companySelectSub$ = this.fgUserSelect.get('companyUserId')
      .valueChanges
      .debounceTime(500)
      .subscribe((v: string) => {
        this.store.dispatch(new FetchUserSuggestionRequestAction({query: v, field: 'COMPANY'}));
      });

    // Fetch user suggections when the user select form changes
    this.postcodeSelectSub$ = this.fgUserSelect.get('postCodeUserId')
      .valueChanges
      .debounceTime(500)
      .subscribe((v: string) => {
        this.store.dispatch(new FetchUserSuggestionRequestAction({query: v, field: 'POSTCODE'}));
      });

    this.store.dispatch(new FetchAdminPromoCodesRequestAction());

    // OrderBy form
    this.fgFilters = this.fb.group({ orderBy: [false] });
    this.fgFilters.get('orderBy').valueChanges.subscribe(() => this.fetchAllWarranties());
  }

  ngOnDestroy() {
    if (this.adminJobsSub$)
      this.adminJobsSub$.unsubscribe();
    if (this.userSelectSub$)
      this.userSelectSub$.unsubscribe();

    // Clear warranty store
    this.store.dispatch(new FetchAdminWarrantiesRequestAction( {type: 'WARRANTY', query: '(user_id=0)', pageNum: 0, perPage: 0, upcoming: true }));

  }


  /**
   * Sets the pagination variable based on the current and total pages
   */
  paginate() {
    const pagination = [];
    const paginationJobs = [];

    let maxNumbersToPaginate = 9;
    let maxNumbersToPaginateJobs = 9;

    // Paginate the Warranties
    if (this.pageNumWarranties > 1) {
        let timesNumbersAdded = 0;

        for (let i = this.pageNumWarranties; i > 1; i--) {
          if (timesNumbersAdded >= 5) break;

          timesNumbersAdded++;
          maxNumbersToPaginate--;
          pagination.unshift(this.pageNumWarranties - timesNumbersAdded);
        }
    }

    pagination.push(this.pageNumWarranties);

    if (this.totalPagesWarranties > this.pageNumWarranties) {
      for (let i = this.pageNumWarranties + 1; i < this.totalPagesWarranties + 1; i++) {
        if (maxNumbersToPaginate === 0) break;

        maxNumbersToPaginate--;
        pagination.push(i);

      }
    }

    // Paginate the Jobs
    if (this.pageNumJobs > 1) {
        let timesNumbersAdded = 0;

        for (let i = this.pageNumJobs; i > 1; i--) {
          if (timesNumbersAdded >= 5) break;

          timesNumbersAdded++;
          maxNumbersToPaginateJobs--;
          paginationJobs.unshift(this.pageNumJobs - timesNumbersAdded);
        }
    }

    paginationJobs.push(this.pageNumJobs);

    if (this.totalPagesJobs > this.pageNumJobs) {
      for (let i = this.pageNumJobs + 1; i < this.totalPagesJobs + 1; i++) {
        if (maxNumbersToPaginateJobs === 0) break;

        maxNumbersToPaginateJobs--;
        paginationJobs.push(i);

      }
    }

    this.pagination = pagination;
    this.paginationJobs = paginationJobs;
  }

  /**
   * Sets up the form for adding a new PromoCode
   */
  addPromoCode() {
    this.fgPromoCode.reset();
    this.fgPromoCode.get('updateCode').setValue(null);
    this.fgPromoCode.get('id').setValue(null);
    this.fgPromoCode.get('enabled').setValue(true);
    this.fgPromoCode.get('usesPerUser').setValue(0);
    this.fgPromoCode.get('calcValue').setValue(1);
    this.fgPromoCode.markAsUntouched();
  }

  /**
   * Asks for confirmation and then deletes the specified PromoCode
   *
   * @param {PromoCode} p PromoCode to delete
   */
  deletePromoCode(p: PromoCode) {
    if (confirm(`Are you sure you want to delete the "${p.code}" (${p.name}) promotional code?`))
      this.store.dispatch(new DeleteAdminPromoCodeRequestAction({promoCode: p}));
  }

  /**
   * Sets up the form for editing the specified PromoCode
   *
   * @param {PromoCode} p PromoCode to edit
   */
  editPromoCode(p: PromoCode) {
    this.fgPromoCode.get('updateCode').setValue(p.code);
    this.fgPromoCode.get('id').setValue(p.id);
    this.fgPromoCode.get('code').setValue(p.code);
    this.fgPromoCode.get('name').setValue(p.name);
    this.fgPromoCode.get('enabled').setValue(p.enabled);
    this.fgPromoCode.get('usesPerUser').setValue(p.usesPerUser);
    this.fgPromoCode.get('calcType').setValue(p.calcType);
    this.fgPromoCode.get('calcValue').setValue(p.calcValue);
  }

  /**
   * Adds or updates a PromoCode if the edit form is valid
   */
  updatePromoCode() {
    if (this.fgPromoCode.valid)
    {
      this.promoCodeErrors = null;
      if (this.fgPromoCode.get('updateCode').value)
        this.store.dispatch(new UpdateAdminPromoCodeRequestAction({promoCode: this.fgPromoCode.value}));
      else
        this.store.dispatch(new AddAdminPromoCodeRequestAction({promoCode: this.fgPromoCode.value}));
    }
    else
      this.promoCodeErrors = 'The form contains errors. Please correct them and try again.';
  }

  /**
   * Formats a UserSuggestion for display in the autocomplete panel
   *
   * @param {UserSuggestion} u
   * @return {string}
   */
  displayUserSuggestion(u: UserSuggestion): string {
    return u ? `${u.title} ${u.firstName} ${u.lastName}` : '';
  }

  /**
   * Formats a CustomerSuggestion for display in the auto-complete panel
   *
   * @param {CustomerSuggestion} c
   * @return {string}
   */
  displayCustomerSuggestion(c: CustomerSuggestion): string {
      return c ? `${c.firstName} ${c.lastName}` : '';
  }

   /**
   * Formats a Company Suggestion for display in the autocomplete panel
   *
   * @param {UserSuggestion} u
   * @return {string}
   */
  displayCompanySuggestion(u: UserSuggestion): string {
    return u ? `${u.companyName}` : '';
  }

  /**
   * Formats a Postcode Suggestion for display in the autocomplete panel
   *
   * @param {UserSuggestion} u
   * @return {string}
   */
  displayPostcodeSuggestion(u: UserSuggestion): string {
    return u ? `${u.postCode}` : '';
  }

  /**
   * Fetches all warranties
   *
   * @param {number}        pageNum     Page number of results to fetch
   * @param {MatExpansionPanel} expandPanel If set, the specified accordion panel will be expanded automatically
   */
  fetchAllWarranties(pageNum: number = 1, expandPanel: MatExpansionPanel = null) {
    let query = '';
    let postcodeFormData, serialFormData, addressFormData, nameFormData = '';
    const queryPartial = [];

    postcodeFormData = this.fgWarrantyFilter.value.postcodeFilter;
    if (postcodeFormData.length !== 0) {

      let postCodeNoSpace, postCode = postcodeFormData;

      // Check if the postcode query has a space
      const isSpace: Boolean = (postcodeFormData.indexOf(' ') >= 0);

      // Create a space-less and spaced postcode to search with
      if (isSpace) {
         postCodeNoSpace = postcodeFormData.replace(' ', '');
         queryPartial.push(`((legacy_customer_postcode like %${postCode}%) or (legacy_customer_postcode like %${postCodeNoSpace}%))`);
      } else {
        const length = postcodeFormData.length;
        // Check if the query is a partial post code
        if (length < 5) {
          queryPartial.push(`(legacy_customer_postcode like %${postCode}%)`);
        } else {
          postCode = postcodeFormData.substr(0, length - 3) + ' ' + postcodeFormData.substr(-3);
          queryPartial.push(`((legacy_customer_postcode like %${postCode}%) or (legacy_customer_postcode like %${postCodeNoSpace}%))`);
        }
      }
    }

    serialFormData = this.fgWarrantyFilter.value.serialFilter;
    if (serialFormData.length !== 0) {
      queryPartial.push(`(product_serial_number like %${serialFormData}%)`);
    }

    addressFormData = this.fgWarrantyFilter.value.addressFilter;
    if (addressFormData.length !== 0) {
      queryPartial.push(`((legacy_customer_address_1 like %${addressFormData}%) or (legacy_customer_address_2 like %${addressFormData}%) or (legacy_customer_town_city like %${addressFormData}%))`);
    }

    nameFormData = this.fgWarrantyFilter.value.nameFilter;
    if (nameFormData.length !== 0) {
      queryPartial.push(`((legacy_customer_first_name like %${nameFormData}%) or (legacy_customer_surname like %${nameFormData}%))`);
    }

    query += queryPartial.join(' and ');

    const perPage = 12;
    this.store.dispatch(new FetchAdminWarrantiesRequestAction( {type: 'WARRANTY', query, perPage, pageNum, upcoming: this.fgFilters.get('orderBy').value }));
    if (expandPanel)
      expandPanel.open();
  }

  /**
   * Fetches items related to the selected customer (name query)
   *
   * @param {boolean} jobs       If set, customer Jobs are fetched
   * @param {boolean} warranties If set, customer Warranties are fetched
   * @param {number}  pageNum    Page number of results to fetch
   */
  fetchCustomerItems(jobs: boolean = true, warranties: boolean = true, pageNum: number = 1, expandPanel: MatExpansionPanel = null) {
    if (this.fgCustomerSelect.valid)
    {
      const perPage = 12;
      const query = this.fgCustomerSelect.value.customerName.firstName + ' ' + this.fgCustomerSelect.value.customerName.lastName;

      if (jobs) {
        this.store.dispatch(new FetchAdminJobsRequestAction({type: 'CUSTOMER', query, perPage, pageNum}));

        if (expandPanel) expandPanel.open();
      }

      if (warranties) {
        this.store.dispatch(new FetchAdminWarrantiesRequestAction( {type: 'CUSTOMER', query, perPage, pageNum, upcoming: this.fgFilters.get('orderBy').value }));

        if (expandPanel) expandPanel.open();
      }
    }
  }

  /**
   * Fetches items related to the selected user
   *
   * @param {boolean}       jobs        If set, customer Jobs are fetched
   * @param {boolean}       warranties  If set, customer Warranties are fetched
   * @param {number}        pageNum     Page number of results to fetch
   * @param {MatExpansionPanel} expandPanel If set, the specified accordion panel will be expanded automatically
   */
  fetchUserItems(jobs: boolean = true, warranties: boolean = true, pageNum: number = 1, expandPanel: MatExpansionPanel = null) {
    if (this.fgUserSelect.valid)
    {
       let query = this.fgUserSelect.value.userId.id;

      if (this.fgUserSelect.value.companyUserId)
        query = this.fgUserSelect.value.companyUserId.id;

      if (this.fgUserSelect.value.postCodeUserId)
        query = this.fgUserSelect.value.postCodeUserId.id;

      const perPage = 12;

      if (jobs)
        this.store.dispatch(new FetchAdminJobsRequestAction({type: 'USER', query, perPage, pageNum}));
      if (warranties)
        this.store.dispatch(new FetchAdminWarrantiesRequestAction({type: 'USER', query, perPage, pageNum, upcoming: this.fgFilters.get('orderBy').value }));
      if (expandPanel)
        expandPanel.open();
    }
  }

  /**
   * Fetches the next page of Jobs, or a set amount of pages forward of the current position
   */
  pageNextJobs(pages: number = 1) {
    this.pageNumJobs = Math.min(this.totalPagesJobs, this.pageNumJobs + pages);
    if (this.fgSearchType.value.type === 'CUSTOMER')
      this.fetchCustomerItems(true, false, this.pageNumJobs);
    else
      this.fetchUserItems(true, false, this.pageNumJobs);
  }

  /**
   * Fetches the previous page of Jobs, or a set amount of pages backward of the current position
   */
  pagePrevJobs(pages: number = 1) {
    this.pageNumJobs = Math.max(1, this.pageNumJobs - pages);
    if (this.fgSearchType.value.type === 'CUSTOMER')
      this.fetchCustomerItems(true, false, this.pageNumJobs);
    else
      this.fetchUserItems(true, false, this.pageNumJobs);
  }

  /**
   * Fetches the next page of Warranties, or a set amount of pages forward of the current position
   */
  pageNextWarranties(pages: number = 1) {
    this.pageNumWarranties = Math.min(this.totalPagesWarranties, this.pageNumWarranties + pages);
    if (this.fgSearchType.value.type === 'CUSTOMER') {
      this.fetchCustomerItems(false, true, this.pageNumWarranties);
    } else if (this.fgSearchType.value.type === 'USER') {
      this.fetchUserItems(false, true, this.pageNumWarranties);
    } else if (this.fgSearchType.value.type === 'WARRANTY') {
      this.fetchAllWarranties(this.pageNumWarranties);
    }
  }

  /**
   * Fetches the previous page of Warranties, or a set amount of pages backward of the current position
   */
  pagePrevWarranties(pages: number = 1) {
    this.pageNumWarranties = Math.max(1, this.pageNumWarranties - pages);
    if (this.fgSearchType.value.type === 'CUSTOMER') {
      this.fetchCustomerItems(false, true, this.pageNumWarranties);
    } else if (this.fgSearchType.value.type === 'USER') {
      this.fetchUserItems(false, true, this.pageNumWarranties);
    } else if (this.fgSearchType.value.type === 'WARRANTY') {
      this.fetchAllWarranties(this.pageNumWarranties);
    }
  }

  /**
   * Fetches the specific page of Warranties
   */
  goToPageWarranties(page: number) {
    if (this.fgSearchType.value.type === 'CUSTOMER') {
      this.fetchCustomerItems(false, true, page);
    } else if (this.fgSearchType.value.type === 'USER') {
      this.fetchUserItems(false, true, page);
    } else if (this.fgSearchType.value.type === 'WARRANTY') {
      this.fetchAllWarranties(page);
    }
  }

  /**
   * Fetches the specific page of Jobs
   */
  goToPageJobs(page: number) {
    if (this.fgSearchType.value.type === 'CUSTOMER') {
      this.fetchCustomerItems(true, false, page);
    } else {
      this.fetchUserItems(true, false, page);
    }
  }

  /**
   * Opens JobEditModalComponent to add a new Job for the current user
   */
  addJob() {
    this.dialogRef_job = this.dialog.open(JobEditModalComponent, {
      data: {
        adminMode: true,
        edit:      true,
        userId:    this.fgUserSelect.value.userId.id,
        job:       null,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_job.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new AddAdminJobRequestAction({job: result as Job}));
    });
  }

  /**
   * Asks for confirmation and then deletes the specified Job
   *
   * @param {Job} j Job to delete
   */
  deleteJob(j: Job) {
    if (confirm(`Are you sure you want to delete job ID ${j.id}?`))
      this.store.dispatch(new DeleteAdminJobRequestAction({job: j}));
  }

  /**
   * Opens JobEditModalComponent to edit an existing Job
   *
   * @param {Job} j Job to edit
   */
  editJob(j: Job) {
    this.dialogRef_job = this.dialog.open(JobEditModalComponent, {
      data: {
        adminMode: true,
        edit:      true,
        jobId:     j.id,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_job.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new UpdateAdminJobRequestAction({job: result as Job}));
    });
  }


  /**
   * Opens WarrantyEditModalComponent to add a new Warranty for the current
   * user
   */
  addWarranty() {
    this.dialogRef_warranty = this.dialog.open(WarrantyEditModalComponent, {
      data: {
        adminMode: true,
        edit:      true,
        userId:    this.fgUserSelect.value.userId.id,
        warranty:  null,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_warranty.afterClosed().subscribe(result => {
      if (result)
      this.store.dispatch(new AddAdminJobRequestAction(RegisterJobRequest.fromJob(result)));
    });
  }

  /**
   * Asks for confirmation and then deletes the specified Warranty
   *
   * @param {Warranty} w Warranty to delete
   */
  deleteWarranty(w: Warranty) {
    if (confirm(`Are you sure you want to delete warranty ID ${w.id}?`))
      this.store.dispatch(new DeleteAdminWarrantyRequestAction({warranty: w}));
  }

  /**
   * Opens WarrantyEditModalComponent to edit an existing Warranty
   *
   * @param {Warranty} w Warranty to edit
   */
  editWarranty(w: Warranty) {
    this.dialogRef_warranty = this.dialog.open(WarrantyEditModalComponent, {
      data: {
        adminMode: true,
        edit:      true,
        warrantyId:  w.id,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_warranty.afterClosed().subscribe(result => {
      if (result) {
        result.warranty_id = result.id;
        result.id = w.job.id;
        this.store.dispatch(new UpdateAdminWarrantyRequestAction({warranty: result as Warranty}));

        const customer: Customer = result.customer as Customer;
        customer.customerId = result.customer.customerId;
        customer.save_on_behalf_of = result.userId;
        this.store.dispatch(new UpdateAdminCustomerRequestAction({customer: customer}));

        if (w.serialNumber !== result.serialNumber) {
          this.store.dispatch(new UpdateRegisteredProductRequestAction({ userId:  w.userId, oldSerialNumber: w.serialNumber , newSerialNumber: result.serialNumber, productId: result.productId }));
        }
      }
    });
  }

  /**
   * Calculates the expiry date of a warranty based on registration and product type
   *
   * @param {Warranty} w Warranty to check
   */
   calculateExpiry(w: Warranty ) {
     let warrantyLength = 0;
     const expiryDate = moment(w.dateOfInstallation, 'YYYY-MM-DD');

     for (let i = 0; i < this.productTypes.length ; ++i) {
       if (this.productTypes[i].id === w.productId) {
         warrantyLength = this.productTypes[i].warrantyLength;
       }
     }

     expiryDate.add(warrantyLength, 'months');
     const now = moment();

     const result = {
       date: expiryDate.format('YYYY-MM-DD'),
       valid: now < expiryDate,
     };

     return result;
   }
}
