import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { DiaryService } from '../../services/diary.service';
import * as ActionTypes from '../actions/diary';
import { CommitEventResponse, FetchEventsResponse, FetchEventTypesResponse, } from '../../models/diary';
var DiaryEffects = /** @class */ (function () {
    function DiaryEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a FetchEventsRequestAction, call DiaryService::fetchEvents() and
         * dispatch a new FetchEventsResponseAction with the response.
         */
        this.fetchEventsRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_EVENTS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchEvents()
                .map(function (res) {
                return new ActionTypes.FetchEventsResponseAction(res);
            });
        });
        /**
         * For an AddEventAction, call DiaryService::addEvent() and dispatch a new
         * CommitEventAction for the DiaryEvent.
         */
        this.addEvent$ = this.actions$
            .ofType(ActionTypes.ADD_EVENT)
            .switchMap(function (req) {
            return _this.service.addEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.CommitEventAction(res);
            });
        });
        /**
         * For a BookEventAction, call DiaryService::bookEvent() and dispatch a new
         * CommitEventAction for the DiaryEvent.
         */
        this.bookEvent$ = this.actions$
            .ofType(ActionTypes.BOOK_EVENT)
            .switchMap(function (req) {
            return _this.service.bookEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.CommitEventAction(res);
            });
        });
        /**
         * For a DeleteEventAction, call DiaryService::deleteEvent() and dispatch a
         * new CommitEventAction for the DiaryEvent.
         */
        this.deleteEvent$ = this.actions$
            .ofType(ActionTypes.DELETE_EVENT)
            .switchMap(function (req) {
            return _this.service.deleteEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.CommitEventAction(res);
            });
        });
        /**
         * For a FetchEventTypesRequestAction, call DiaryService::fetchEventTypes()
         * and dispatch a new FetchEventTypesResponseAction with the response.
         */
        this.fetchEventTypes$ = this.actions$
            .ofType(ActionTypes.FETCH_EVENT_TYPES_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchEventTypes()
                .map(function (res) {
                return new ActionTypes.FetchEventTypesResponseAction(res);
            });
        });
        /**
         * For an UpdateEventAction, call DiaryService::updateEvent() and dispatch a
         * new CommitEventAction for the DiaryEvent.
         */
        this.updateEvent$ = this.actions$
            .ofType(ActionTypes.UPDATE_EVENT)
            .switchMap(function (req) {
            return _this.service.updateEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.CommitEventAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], DiaryEffects.prototype, "fetchEventsRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], DiaryEffects.prototype, "addEvent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], DiaryEffects.prototype, "bookEvent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], DiaryEffects.prototype, "deleteEvent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], DiaryEffects.prototype, "fetchEventTypes$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], DiaryEffects.prototype, "updateEvent$", void 0);
    return DiaryEffects;
}());
export { DiaryEffects };
