import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';

@Injectable()
export class DashboardService {

  constructor(
      private apiService: ApiService
  ) { }


  /**
   * return x months ago date
   */
  public getDate(month: number) {
    return moment().subtract(month, 'months').format('Y-M-D');
  }

  public notification(checked, email) {
    const input = {
      'email': email,
      'checked': checked
    };
    //console.log(input);
    return this.apiService.apiPost('/watertest/notification', input);
  }
}
