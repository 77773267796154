<button mat-button [matDialogClose]="(noteDirty) ? returnNote(note) : {}" style="float: right; font-size: small;"><i class="fa fa-times"></i></button>
<h2 matDialogTitle>
    {{ device.filterNo }} - {{ device.serialNo }}
</h2>

<mat-dialog-content *ngIf="filterList$ | async; let filterList">

    <!--<app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>-->

    <p *ngIf="device.name && device.name !== ''"><em>Named "{{ device.name }}", device ID: {{ device.id }}</em></p>

    <div *ngIf="filterList.deviceDetailPending; else dataReady">
        <app-waiting-spinner kind="bar">Fetching Detail for device ({{ device.serialNo }})...</app-waiting-spinner>
    </div>

    <div *ngIf="filterList.deviceDetail" #dataReady>
        <div *ngIf="filterList.deviceDetail; let detail">
                <app-message-panel
                        kind="ERROR"
                        *ngIf="detail.actionText !== 'No action required.'"
                >
                    {{ detail.actionText}}
                </app-message-panel>
                <app-message-panel
                        kind="WARNING"
                        *ngIf="detail.informationText !== 'No information available.'
                        && detail.informationText !== detail.actionText"
                >
                    {{ detail.informationText}}
                </app-message-panel>
                <magnetite-bar [magnetite]="detail.currentReading"></magnetite-bar>
                <h3>Current Status</h3>


                Last Reading Received:
                <span *ngIf="detail.lastReadingDateTime">
                    {{ detail.lastReadingDateTime | dateTimeFormat }}
                </span><br/>
                Last Contact from Device:
                <span *ngIf="detail.lastContactDateTime">
                    {{ detail.lastContactDateTime | dateTimeFormat }}
                </span><br/>
                Last Cleaned: {{ detail.deviceLastCleaned | dateFormat }}<br/>
                Battery Level: <battery-icon [battLevel]="detail.batteryLevel"></battery-icon><br />

                <h3>Service Information</h3>
                <p *ngIf="device.installer">
                    Installer: {{ device.installer }} ({{ device.installUID }})
                </p>
                <p *ngIf="device.installDate">
                    Device Installed: {{ device.installDate | dateFormat }}
                </p>
                <p *ngIf="detail.nextServiceDate">
                    Service next Due: {{ detail.nextServiceDate | dateFormat }}
                </p>
                <p *ngIf="detail.warrantyExpiryDate">
                    Warranty Expiry: {{ detail.warrantyExpiryDate | dateFormat }}
                </p>
                <p *ngIf="device.firmwareVer">
                    Firmware Version: {{ device.firmwareVer }}
                </p>
                <p *ngIf="detail.deviceLastUpdated">
                    (Last Update: {{ detail.deviceLastUpdated | dateTimeFormat }})
                </p>

                <h3>
                  Customer Information <a href="javascript:void(0)" (click)="createOrEditWarranty()"><i class="fa fa-pencil"></i></a>
                </h3>
                Name:
                <span *ngIf="detail.customerTitle && detail.customerTitle !== ''">
                    {{ detail.customerTitle }}
                </span>
                <span *ngIf="detail.customerFirstname && detail.customerFirstname !== ''">
                    {{ detail.customerFirstname }}
                </span>
                <span *ngIf="detail.customerSurname && detail.customerSurname !== ''">
                    {{ detail.customerSurname }}
                </span><br />
                <p *ngIf="detail.customerPhone">Phone: {{ detail.customerPhone }}</p>
                <p *ngIf="detail.customerEmail">E-mail: {{ detail.customerEmail }}</p>
                Address:
                <span *ngIf="detail.customerAddress1">
                    {{ detail.customerAddress1 }},
                </span>
                <span *ngIf="detail.customerAddress2">
                    {{ detail.customerAddress2 }},
                </span>
                <span *ngIf="detail.customerAddress3">
                    {{ detail.customerAddress3 }},
                </span>
                <span *ngIf="detail.customerTown_city">
                    {{ detail.customerTown_city }},
                </span>
                <span *ngIf="detail.customerCountyState">
                    {{ detail.customerCountyState }},
                </span>
                <span *ngIf="detail.customerPostcode">
                    {{ detail.customerPostcode }}
                </span>
            <h3>
              Notes: <a href="javascript:void(0)" (click)="saveNote(note.value)" *ngIf="noteDirty"><i class="fa fa-save"></i></a>
            </h3>
<!--          [ngModel]="filterList.deviceNote.content" (change)="console.log(note, $event)"-->
          <div>

            <textarea matInput #note (input)="noteDirty = true">{{ theNote(filterList) }}</textarea>
          </div>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <!--<button mat-button [matDialogClose]="false">Close</button>-->
    <!--<button mat-button (click)="onSave()">Save</button>-->
</mat-dialog-actions>
