/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/waiting-spinner/waiting-spinner.component.ngfactory";
import * as i3 from "../../shared/waiting-spinner/waiting-spinner.component";
import * as i4 from "../common/message-panel/message-panel.component.ngfactory";
import * as i5 from "../common/message-panel/message-panel.component";
import * as i6 from "./job-details/job-details.component.ngfactory";
import * as i7 from "./job-details/job-details.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/forms";
import * as i10 from "../../services/warranty.service";
import * as i11 from "@angular/common";
import * as i12 from "../common/page-content/page-content.component.ngfactory";
import * as i13 from "../common/page-content/page-content.component";
import * as i14 from "@angular/platform-browser";
import * as i15 from "@ngrx/store";
import * as i16 from "./job.component";
import * as i17 from "@angular/router";
var styles_JobComponent = [i0.styles];
var RenderType_JobComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_JobComponent, data: {} });
export { RenderType_JobComponent as RenderType_JobComponent };
function View_JobComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-waiting-spinner", [["class", "form-header"], ["kind", "bar"]], null, null, null, i2.View_WaitingSpinnerComponent_0, i2.RenderType_WaitingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.WaitingSpinnerComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n          Registering job, please wait...\n          "])), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n        "]))], function (_ck, _v) { var currVal_0 = "bar"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_JobComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-message-panel", [["class", "form-header"], ["kind", "WARNING"]], null, null, null, i4.View_MessagePanelComponent_0, i4.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(2, 0, ["\n          ", "\n        "]))], function (_ck, _v) { var currVal_0 = "WARNING"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.message; _ck(_v, 2, 0, currVal_1); }); }
function View_JobComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-message-panel", [["class", "form-header"], ["kind", "SUCCESS"]], null, null, null, i4.View_MessagePanelComponent_0, i4.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(2, 0, ["\n          ", "\n        "]))], function (_ck, _v) { var currVal_0 = "SUCCESS"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.message; _ck(_v, 2, 0, currVal_1); }); }
function View_JobComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "product-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading products..."]))], null, null); }
function View_JobComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "job-container background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 16, "app-job-details", [["submitLabel", "Register"]], null, [[null, "onPreSubmit"], [null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onPreSubmit" === en)) {
        var pd_0 = (_co.handleFormPreSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSubmit" === en)) {
        var pd_1 = (_co.handleFormSubmit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_JobDetailsComponent_0, i6.RenderType_JobDetailsComponent)), i1.ɵdid(3, 770048, null, 0, i7.JobDetailsComponent, [i8.MatDialog, i9.FormBuilder, i10.WarrantyService], { submitLabel: [0, "submitLabel"], productTypes: [1, "productTypes"] }, { onPreSubmit: "onPreSubmit", onSubmit: "onSubmit" }), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_JobComponent_2)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_JobComponent_3)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_JobComponent_4)), i1.ɵdid(13, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, 1, 2, null, View_JobComponent_5)), i1.ɵdid(16, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Register"; var currVal_1 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform(_co.productState$)).products; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.pending; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.error; _ck(_v, 10, 0, currVal_3); var currVal_4 = _v.context.$implicit.success; _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.productState$)).productsPending; _ck(_v, 16, 0, currVal_5); }, null); }
export function View_JobComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "container-fluid job-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-title has-sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register a job"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-page-content", [["class", "color-white intro-above-content"], ["field", "intro"], ["page", "JOB_SUBMIT"]], null, null, null, i12.View_PageContentComponent_0, i12.RenderType_PageContentComponent)), i1.ɵdid(11, 245760, null, 0, i13.PageContentComponent, [i14.DomSanitizer, i15.Store], { page: [0, "page"], field: [1, "field"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_JobComponent_1)), i1.ɵdid(14, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "JOB_SUBMIT"; var currVal_1 = "intro"; _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.state$)); _ck(_v, 14, 0, currVal_2); }, null); }
export function View_JobComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-job", [], null, null, null, View_JobComponent_0, RenderType_JobComponent)), i1.ɵdid(1, 114688, null, 0, i16.JobComponent, [i17.Router, i15.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobComponentNgFactory = i1.ɵccf("app-job", i16.JobComponent, View_JobComponent_Host_0, {}, {}, []);
export { JobComponentNgFactory as JobComponentNgFactory };
