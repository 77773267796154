import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNow',
  pure: true
})
export class FromNowPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const parsed = Date.parse(value);
      switch (isNaN(parsed)) {
        case true:
          return moment.unix(value).fromNow();
        default:
          return moment(parsed).fromNow();
      }
    }
    return value;
  }

}
