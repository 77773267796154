/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roi-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./roi-widget.component";
import * as i3 from "@angular/router";
var styles_RoiWidgetComponent = [i0.styles];
var RenderType_RoiWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoiWidgetComponent, data: {} });
export { RenderType_RoiWidgetComponent as RenderType_RoiWidgetComponent };
export function View_RoiWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "widget-body border-box border-lighter-gray roi-widget background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "feature-image-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [["class", "color-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show your customers how much they could save"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn background-green no-radius color-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/roi-calculator") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Savings calculator"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_RoiWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-roi-widget", [], null, null, null, View_RoiWidgetComponent_0, RenderType_RoiWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i2.RoiWidgetComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoiWidgetComponentNgFactory = i1.ɵccf("app-roi-widget", i2.RoiWidgetComponent, View_RoiWidgetComponent_Host_0, {}, {}, []);
export { RoiWidgetComponentNgFactory as RoiWidgetComponentNgFactory };
