/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message-panel.component";
var styles_MessagePanelComponent = [i0.styles];
var RenderType_MessagePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagePanelComponent, data: {} });
export { RenderType_MessagePanelComponent as RenderType_MessagePanelComponent };
export function View_MessagePanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClass(); _ck(_v, 0, 0, currVal_0); }); }
export function View_MessagePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-panel", [], null, null, null, View_MessagePanelComponent_0, RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i2.MessagePanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagePanelComponentNgFactory = i1.ɵccf("app-message-panel", i2.MessagePanelComponent, View_MessagePanelComponent_Host_0, { kind: "kind" }, {}, ["*"]);
export { MessagePanelComponentNgFactory as MessagePanelComponentNgFactory };
