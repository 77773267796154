import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { SystemMessageService } from '../../services/system-message.service';

import * as ActionTypes from '../actions/system-message';

import { SystemMessageResponse } from '../../models/system-message';

@Injectable()
export class SystemMessageEffects {
  constructor(
    private systemMessageService: SystemMessageService,
    private actions$: Actions
  ) {}

  /**
   * For a SystemMessageRequestAction, call SystemMessage::getSystemMessage() and
   * dispatch a new SystemMessageResponseAction with the response.
   */
  @Effect() systemMessageRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.SYSTEM_MESSAGE_REQUEST)
    .switchMap((): Observable<Action> => {
      //console.log('effects');
      return this.systemMessageService.getSystemMessage()
        .map((res: SystemMessageResponse): ActionTypes.SystemMessageResponseAction => new ActionTypes.SystemMessageResponseAction(res));
    });

}
