import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { InvitationService } from '../../services/invitation.service';

import * as ActionTypes from '../actions/invitation';

import {
  AcceptInvitationQueryResponse,
  ReviewInvitationQueryResponse,
  SendInvitationQueryResponse,
} from '../../models/invitation';

@Injectable()
export class InvitationEffects {
  constructor(
    private service:  InvitationService,
    private actions$: Actions
  ) {}

  /**
   * For a ReviewInvitationQueryResponseAction, call service.reviewInvitation() and
   * dispatch a new ReviewInvitationQueryResponseAction with the response.
   */
  @Effect() reviewInvitation$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REVIEW_INVITATION_QUERY_REQUEST)
    .switchMap((req: ActionTypes.ReviewInvitationQueryRequestAction): Observable<ActionTypes.ReviewInvitationQueryResponseAction> =>
      this.service.reviewInvitation(req.payload)
        .map((res: ReviewInvitationQueryResponse): ActionTypes.ReviewInvitationQueryResponseAction =>
          new ActionTypes.ReviewInvitationQueryResponseAction(res))
    );

  /**
   * For a SendInvitationQueryResponseAction, call service.sendInvitation() and
   * dispatch a new SendInvitationQueryResponseAction with the response.
   */
  @Effect() sendInvitation$: Observable<Action> = this.actions$
    .ofType(ActionTypes.SEND_INVITATION_QUERY_REQUEST)
    .switchMap((req: ActionTypes.SendInvitationQueryRequestAction): Observable<ActionTypes.SendInvitationQueryResponseAction> =>
      this.service.sendInvitation(req.payload)
        .map((res: SendInvitationQueryResponse): ActionTypes.SendInvitationQueryResponseAction =>
          new ActionTypes.SendInvitationQueryResponseAction(res))
    );

  /**
   * For a AcceptInvitationQueryResponseAction, call service.acceptInvitation() and
   * dispatch a new AcceptInvitationQueryResponseAction with the response.
   */
  @Effect() acceptInvitation$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ACCEPT_INVITATION_QUERY_REQUEST)
    .switchMap((req: ActionTypes.AcceptInvitationQueryRequestAction): Observable<ActionTypes.AcceptInvitationQueryResponseAction> =>
      this.service.acceptInvitation(req.payload)
        .map((res: AcceptInvitationQueryResponse): ActionTypes.AcceptInvitationQueryResponseAction =>
          new ActionTypes.AcceptInvitationQueryResponseAction(res))
    );
}
