/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./training.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./training.component";
import * as i3 from "@angular/router";
var styles_TrainingComponent = [i0.styles];
var RenderType_TrainingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrainingComponent, data: {} });
export { RenderType_TrainingComponent as RenderType_TrainingComponent };
export function View_TrainingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Training"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "training-container background-white col-xs-12 align-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h2", [["class", "color-adey-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Watch this space for more information coming soon on ADEY training courses around the UK."])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn background-adey-red hover-background-adey-blue color-white no-radius"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/home") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        Return home\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_TrainingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-training", [], null, null, null, View_TrainingComponent_0, RenderType_TrainingComponent)), i1.ɵdid(1, 114688, null, 0, i2.TrainingComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrainingComponentNgFactory = i1.ɵccf("app-training", i2.TrainingComponent, View_TrainingComponent_Host_0, {}, {}, []);
export { TrainingComponentNgFactory as TrainingComponentNgFactory };
