import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { UserProfile } from '../../models/user-profile';
import {
  CreatePromoShopTokenRequestAction,
  FetchUserProfileRequestAction,
} from '../../state-management/actions/login-details';
import { FetchWarrantiesRequestAction } from '../../state-management/actions/warranty-list';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { State as EmailState } from '../../state-management/reducers/email';
import { State as RewardsState } from '../../state-management/reducers/rewards';
import { State as WarrantyListState } from '../../state-management/reducers/warranty-list';
import { StoreState } from '../../state-management/store';
import { Router } from '@angular/router';
import { InitEmailStateAction } from '../../state-management/actions/email';
import { InitRewardsStateAction } from '../../state-management/actions/rewards';

/**
 * Summary
 *    Tell the user how they can redeem their points and show the value of each point.
 *
 *
 * Description
 *    Display the user's current points along with a series of instructions and information on how to redeem points.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnDestroy, OnInit {

  // Store state and Subscription
  public loginDetails$: Observable<LoginDetailsState>;
  public emailDetails$: Observable<EmailState>;
  public rewards$: Observable<RewardsState>;
  public warrantyList$: Observable<WarrantyListState>;
  private loginDetailsSub: Subscription;
  private warrantyListSub: Subscription;

  // Set when a user does not have a shop token and one is being created. If
  // set when a token is created, the shop will be opened automatically.
  public pendingToken: boolean = false;

  // Current shop token from the user's profile
  public currentPromoShopToken: string = null;

  /**
   * Constructor for page
   *
   * @param {Store<StoreState>} store Initialises Store object
   */
  constructor(
    private router: Router,
    private store: Store<StoreState>,
    private translate: TranslateService,
  ) {
    // Set reference to login, email and rewards state
    this.loginDetails$ = this.store.select('loginDetails');
    this.emailDetails$ = this.store.select('email');
    this.rewards$ = this.store.select('rewards');
  }

  /**
   * Retrieve the user's profile and subscribe to the state in order to fetch important profile information
   */
  ngOnInit() {
    // Dispatch FetchUserProfileRequestAction in order to retrieve the user's profile information
    this.store.dispatch(new FetchUserProfileRequestAction());

    // Dispatch FetchWarrantiesRequestAction to check the user has
    // serial numbers registered to enable spending points
    this.warrantyList$ = this.store.select('warrantyList');

    // Subscribe to the state in order to check if a promoShopToken is present
    this.loginDetailsSub = this.loginDetails$.subscribe((v: LoginDetailsState) => {
      if (
        !v.promoShopPending &&
        !v.promoShopError &&
        v.currentProfile &&
        v.currentProfile.promoShopToken &&
        v.currentProfile.promoShopToken !== this.currentPromoShopToken
      ) {
        this.currentPromoShopToken = v.currentProfile.promoShopToken;
        if (this.currentPromoShopToken && this.pendingToken)
          this.promoShopAccess(v.currentProfile);
        this.store.dispatch(new FetchWarrantiesRequestAction({ id: v.currentProfile.id, pageNum: null, perPage: null, upcoming: true }));
      }
    });

  }

  /**
   * Unsubscribe to login state when the page is destroyed to free up memory
   */
  ngOnDestroy() {
    this.store.dispatch(new InitEmailStateAction());
    this.store.dispatch(new InitRewardsStateAction());

    if (this.loginDetailsSub) {
      this.loginDetailsSub.unsubscribe();
    }
    if (this.warrantyListSub) {
      this.warrantyListSub.unsubscribe();
    }
  }

  /**
   * Navigates to the /home route
   */
  goHome() {
    this.router.navigate(['/home']);
  }

  openStore() {
    window.open('https://adeyresourcehub.myprintdesk.net/DSF/SmartStore.aspx?6xni2of2cF0O2F39KZhPqKiq3uogp6u+fvUv3hs8JLQ4DnM/5aa7uAlveFYI9bT6#!/Storefront', '_blank');
  }

  /**
   * Unsubscribe to login state when the page is destroyed to free up memory
   *
   * @param {any} createdAt The date the account was created
   *
   * @return {string}   User friendly time stamp displaying how long the user has been a member
   */
  getMembershipLength(createdAt: any): string {
    return moment(createdAt).fromNow(true);
  }

  /**
   * Open the promo shop if the user's promoShopToken exists
   *
   * @param {UserProfile} profile The user's profile data
   */
  promoShopAccess(profile: UserProfile) {
    this.pendingToken = false;

    // Open a new window with the promo shop if the user's promoShopToken exists
    if (profile.promoShopToken)
      window.open(
        `http://www.e-workcloud.co.uk/${this.translate.instant('E_WORKCLOUD_URL')}/?fastlogin=` + encodeURIComponent(profile.promoShopToken) +
        '&currentPoints=' + encodeURIComponent(profile.pointsCurrent) +
        '&uid=' + encodeURIComponent(profile.id),
        '_blank'
      );
    else {
      // If the token doesn't exist, dispatch CreatePromoShopTokenRequestAction to retrieve a token
      this.pendingToken = true;
      this.store.dispatch(new CreatePromoShopTokenRequestAction());
    }
  }
}
