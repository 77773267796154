import { Injectable } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { DatatableService } from './datatable.service';

@Injectable()
export class CompanyMemberService {

  constructor(
      private apiService: ApiService,
      private dataTable: DatatableService
  ) {

  }

  public getResultsByMember(dataTablesParameters, id_company) {
    const param = this.dataTable.joinParameters(dataTablesParameters, '(hosted_company_id = ' + id_company + ')');
    return this.apiService.apiGet('/watertest/user-result' + param);
  }

  public getResultsByAddress(dataTablesParameters, id_company) {
    const param = this.dataTable.joinParameters(dataTablesParameters, '(hosted_company_id=' + id_company + ')');
    return this.apiService.apiGet('/watertest/location-result' + param);
  }

}
