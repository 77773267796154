import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { ProCheckSite } from '../../models/procheck-site';
import { RequestResetPasswordRequestAction } from '../../state-management/actions/login-details';
import { StoreState } from '../../state-management/store';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';

/**
 * Summary
 *    Display the reset password request form within a modal.
 *
 *
 * Description
 *    Allow the user to enter their email address in order to reset their password.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {

  public loginDetails$: Observable<LoginDetailsState>;
  public proCheckView: boolean = false;
  public resetForm: any;
  /**
  * Constructor for page, creates the reset password form
  */
  constructor(
    private store: Store<StoreState>,
  ) {
    this.loginDetails$ = this.store.select('loginDetails');

    this.resetForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {
    this.proCheckView = ProCheckSite.getCheck();
    this.resetForm.get('email').markAsTouched();
  }

  public getErrorMessage(): string {
    return this.resetForm.get('email').hasError('required') ? 'You must enter a value' :
      this.resetForm.get('email').hasError('email') ? 'Not a valid email' :
        '';
  }

  public submit(): void {
    this.store.dispatch(new RequestResetPasswordRequestAction({ email: this.resetForm.get('email').value }));
  }
}
