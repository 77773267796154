import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
var PopupService = /** @class */ (function () {
    function PopupService(apiService) {
        this.apiService = apiService;
    }
    PopupService.prototype.getUserPopup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.apiGet('/popup').toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PopupService.prototype.setUserPopupDisplayCount = function (name, count) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.apiPost("/popup/" + name + "/" + count, {}).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return PopupService;
}());
export { PopupService };
