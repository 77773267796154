import { Component, OnInit } from '@angular/core';

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { Router } from '@angular/router';

/**
 * Summary
 *    Calculate a user's potential savings based on annual costs.
 *    
 *
 * Description
 *    Allow the user to input either their annual gas cost or their annual LPG cost and 
 *    calculate how much they could save.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-roi-calculator',
  templateUrl: './roi-calculator.component.html',
  styleUrls: ['./roi-calculator.component.scss']
})
export class RoiCalculatorComponent implements OnInit {

  // Form for cost entry
  public fg: FormGroup;

  // Flag to indicate if form has been submitted
  public submitted: boolean = false;

  // List of selectable products (only one currently)
  public productSelections: any[] = [
    {
      name:       'MagnaClean',
      url:        '/assets/i/pages/roi/magnacleanse.jpg',
      label:      'magnacleanse',
      valueLabel: '6'
    },
    /*
    {
      name:       'MagnaClean & MC1+ Inhibitor',
      url:        '/assets/i/pages/roi/MagnaCleanMc1.jpg',
      label:      'magnaCleanseInhibitorSaving',
      valueLabel: '6'
    }
    */
  ];

  // Calculated total savings
  public totalSavings: number = null;


  /**
   * Constructor for page
   *
   * @param {Router} router Initialises router object providing navigation between pages
   */
  constructor(
    private router: Router,
  ) {
     // Create the form for the savings calculator
     this.fg = new FormGroup({
      annualGasCost: new FormControl({value: '', disabled: false}, [Validators.required]),
      annualLpgCost: new FormControl({value: '', disabled: false}, [Validators.required]),
    });
  }

  ngOnInit() {
  }

  /**
   * Calculate how much the user could save based on the values within the form
   */
  calculateROI() {
    if (this.fg.valid) {
      const annualCost = parseInt((this.fg.get('annualGasCost').value ? this.fg.get('annualGasCost').value : this.fg.get('annualLpgCost').value), 10);
      const saving = 6;

      this.totalSavings = Math.ceil(annualCost * (saving / 100));
      if (isNaN(this.totalSavings))
        this.totalSavings = null;
    }
  }

  /**
   * If the user enters a value into a field, disable the opposite field in order to prevent issues with the calculations
   *
   * @param {Event} e               The form field event
   * @param {string} ownField       The field this function is attached to
   * @param {string} oppositeField  The field to disable if this field is changed
   */
  detectFieldChanges(e: Event, ownField: string, oppositeField: string) {
    if (this.fg.controls[ownField].value !== '' && !this.fg.controls[oppositeField].disabled)
      this.fg.controls[oppositeField].disable();
    else if (!this.fg.controls[ownField].value && this.fg.controls[oppositeField].disabled)
      this.fg.controls[oppositeField].enable();
  }

  /**
   * Apply a class to the form field dependant on the form's state
   *
   * @param {string} fieldName    The name of the field to check
   * @param {string} extraClasses Extra classes to add to the html
   *
   * @return {string} Return a string of classes to the form field object
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.fg.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && !ff.disabled && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Navigate the user to a given page
   *
   * @param {string} page The page to navigate to
   */
  goToPage(page: string) {
    this.router.navigate([page]);
  }

  /**
   *  Called when the form is submitted, will call calculateROI if the form is valid
   */
  submitRoiForm() {
    this.submitted = true;

    if (this.fg.valid) {
      this.calculateROI();
    }
  }
}
