/**
 * JobService: provides all functionality related to handling Jobs
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from './api.service';
import { JobService as MockService } from './job.service.mock';

import {
  ProductType,
  RegisterJobRequest,
  RegisterJobResponse,
  FetchJobRequest,
  FetchJobResponse,
  FetchJobPdfRequest,
  FetchJobPdfResponse,
  Job,
  UpdateJobResponse,
  UpdateJobRequest,
} from '../models/job';


@Injectable()
export class JobService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService;


  constructor(
    private apiService: ApiService,
  ) {
    this.mockService = new MockService();
  }


  /**
   * Fetches all available ProductType models
   *
   * @return {Observable<ProductType[]>}
   */
  getProductTypes(): Observable<ProductType[]> {
    return this.apiService.apiGet('/productgroup')
      .map((res: any): ProductType[] => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return valid
          ? res.resource.map(ProductType.fromAPI)
          : [];
      })
      .catch((err: any): Observable<ProductType[]> =>
        Observable.of([])
      );
  }

  /**
   * Fetches a specified Job model
   *
   * @param {FetchJobRequest} req
   * @return {Observable<FetchJobResponse>}
   */
  getJobData(req: FetchJobRequest): Observable<FetchJobResponse> {
    return this.apiService.apiGet('/job/' + encodeURIComponent(req.id.toString()))
      .map((res: any): FetchJobResponse => {
        const valid: boolean = res && res.id;
        return {
          job: valid ? Job.fromAPI(res) : null,
          message: null,
          error: valid ? null : 'Invalid response from server',
        };
      })
      .catch((err: any): Observable<FetchJobResponse> =>
        Observable.of({
          job: null,
          message: null,
          error: err && err.error && err.error.message
            ? `Unable to fetch job: ${err.error.message}`
            : 'Unable to fetch job',
        })
      );
  }

  /**
   * Requests the specified Job as a PDF. PDF will be returned as
   * base64-encoded file content.
   *
   * @param {FetchJobPdfRequest} req
   * @return {Observable<FetchJobPdfResponse>}
   */
  getJobPDF(req: FetchJobPdfRequest): Observable<FetchJobPdfResponse> {
    return this.apiService.apiGet(`/job-pdf-download?id=${encodeURIComponent(req.id)}`)
      .map((res: any): FetchJobPdfResponse => {
        const valid: boolean = res && res.pdf;
        return {
          error: valid ? null : 'Invalid response from server',
          pdfData: valid ? res.pdf : null,
        };
      })
      .catch((err: any): Observable<FetchJobPdfResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to get Job PDF: ${err.error.message}`
            : 'Unable to get Job PDF',
          pdfData: null,
        })
      );
  }

  /**
   * Submits Job data to the API.
   *
   * The data submitted will be dependant on whether or not the customer already exists
   *
   * @param {RegisterJobRequest} req
   * @return {Observable<RegisterJobResponse>}
   */
  submitJob(req: RegisterJobRequest): Observable<RegisterJobResponse> {
    const apiJobData = (req.job.customer.customerId ? Job.toApiJobExistingCustomer(req.job) : Job.toApiJobNewCustomer(req.job));

    return this.apiService.apiPost('/job', apiJobData)
      .map((res: any): RegisterJobResponse => {
        return {
          error: false,
          message: res && res.response ? res.response : 'Job submitted successfully',
        };
      })
      .catch((err: any): Observable<RegisterJobResponse> => {
        return Observable.of({
          error: true,
          message: err && err.error && err.error.message
            ? `Unable to submit job: ${err.error.message}`
            : 'Unable to submit job',
        });
      });
  }

  /**
   * Submits Job update data to the API.
   *
   * The data submitted will be dependant on whether or not the customer already exists
   *
   * @param {UpdateJobRequest} req
   * @return {Observable<RegisterJobResponse>}
   */
  updateJob(req: UpdateJobRequest): Observable<UpdateJobResponse> {
    return this.apiService.apiPatch(`/job/${req.job.id}`, Job.toApiJobExistingCustomer(req.job))
      .map((res: any): UpdateJobResponse => {
        return {
          error: false,
          job: null,
          message: res && res.response ? res.response : 'Job update successfully',
        };
      })
      .catch((err: any): Observable<UpdateJobResponse> => {
        return Observable.of({
          error: true,
          job: null,
          message: err && err.error && err.error.message
            ? `Unable to update job: ${err.error.message}`
            : 'Unable to update job',
        });
      });
  }
}
