<div class="container-fluid company-page">
  <div class="row content-page">
    <div class="content-watertest">
      <div class="container-title has-sub">
        <h1 class="white">Water testers</h1>
      </div>
      <br>
      <div *ngIf="(isWaterTesterAdmin || isCustomerService)" class="navigator-watertest-table">
        <button class="btn background-adey-red color-white btn-result" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
        &nbsp;
        <button *ngIf="companyId>0" class="btn background-green color-white btn-result" (click)="sendInviteModal()">Invite member</button>
      </div>
      <div *ngIf="(isWaterTesterAdmin || isCustomerService)"  class="panel-table col-xs-12">
        <table id="table-watertesters" datatable [dtOptions]="dtOptions" class="row-border colCenter hover">
          <thead>
            <tr>
              <th class="colCenter">First Name</th>
              <th class="colCenter">Last Name</th>
              <th class="colCenter">Email Address</th>
              <th class="colCenter">Phone</th>
              <th class="colCenter">Results</th>
              <th *ngIf="companyId>0" class="colCenter">Admin Access</th>
              <th *ngIf="companyId>0" class="colCenter">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row-data"  *ngFor="let result of results">
              <td (click)="goToPage('/watertest/admin/result/watertester/' + result.user_id + companyFilter)">{{ result.first_name }}</td>
              <td (click)="goToPage('/watertest/admin/result/watertester/' + result.user_id + companyFilter)">{{ result.last_name }}</td>
              <td (click)="goToPage('/watertest/admin/result/watertester/' + result.user_id + companyFilter)">{{ result.email }}</td>
              <td (click)="goToPage('/watertest/admin/result/watertester/' + result.user_id + companyFilter)">{{ result.phone }}</td>
              <td (click)="goToPage('/watertest/admin/result/watertester/' + result.user_id + companyFilter)">{{ result.total_result }}</td>
              <td *ngIf="companyId>0">
                  <mat-checkbox  (click)="adminTesterModal(result.user_id, result.user_role_id, switchRole(result.role_id))" md-no-ink class="md-primary vertical-align-box-tester" checked="{{ checked(result.role_id) }}">
                  </mat-checkbox>
              </td>
              <td *ngIf="companyId>0">
                <button class="btn background-adey-red color-white"  (click)="removeUserFromCompany(result.user_id,result.user_role_id)">Remove</button>
              </td>
            </tr>
            <tr *ngIf="results?.length == 0">
              <td colspan="7" class="no-data-available">No data!</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

