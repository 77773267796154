<form class="service-request-form" id="fg" [formGroup]="fg" novalidate (ngSubmit)="onSubmitForm()">

  <app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>

  <div class="row">

    <div class="col-sm-6 col-xs-12">
      <h4>Main details</h4>
      <div class="form-group">
        <mat-form-field>
          <input matInput formControlName="serviceDate" min="2017-01-01" [matDatepicker]="dpServiceDate" placeholder="Service date" />
          <mat-datepicker #dpServiceDate></mat-datepicker>
          <mat-error>Required (YYYY-MM-DD)</mat-error>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpServiceDate"></mat-datepicker-toggle>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="serviceTime" placeholder="Service time" />
          <mat-error>Time is required (HH:MM:SS)</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="typeOfService" placeholder="Type of service" />
          <mat-error>Type of service required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="col-sm-6 col-xs-12">
      <h4>Customer details</h4>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerTitle" placeholder="Title" />
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerForename" placeholder="First name" />
          <mat-error>First name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerSurname" placeholder="Surname" />
          <mat-error>Surname is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerAddress1" placeholder="Address 1" />
          <mat-error>Address line 1 is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerAddress2" placeholder="Address 2" />
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerTown" placeholder="Town" />
          <mat-error>Town is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerCounty" placeholder="County" />
          <mat-error>County is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerPostcode" placeholder="Postcode" />
          <mat-error>Postcode is required</mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="row" *ngIf="showSubmit">
    <div class="btn-group">
      <input class="btn background-green color-white full-width" type="submit" [value]="submitLabel" />
    </div>
  </div>

</form>
