/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./diary-event.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "./diary-event.component";
var styles_DiaryEventComponent = [i0.styles];
var RenderType_DiaryEventComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DiaryEventComponent, data: {} });
export { RenderType_DiaryEventComponent as RenderType_DiaryEventComponent };
export function View_DiaryEventComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "a", [["class", "no-pad-item-content mat-list-item"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 2, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), (_l()(), i1.ɵted(-1, 2, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, 2, 0, "i", [["mat-icon", ""]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, 2, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, 2, 9, "div", [["class", "event-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "p", [["class", "event-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " | "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "p", [["class", "event-details"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 2, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1)._avatar; var currVal_1 = i1.ɵnov(_v, 1)._avatar; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ("fa event-icon color-white " + _co.getEventDateClass()); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getEventDate(); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.getEventTime(); _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.event.details; _ck(_v, 15, 0, currVal_5); }); }
export function View_DiaryEventComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-diary-event", [], null, null, null, View_DiaryEventComponent_0, RenderType_DiaryEventComponent)), i1.ɵdid(1, 114688, null, 0, i4.DiaryEventComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DiaryEventComponentNgFactory = i1.ɵccf("app-diary-event", i4.DiaryEventComponent, View_DiaryEventComponent_Host_0, { event: "event", colorScheme: "colorScheme" }, { onEventClick: "onEventClick" }, []);
export { DiaryEventComponentNgFactory as DiaryEventComponentNgFactory };
