import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  ActivatedRoute,
  ParamMap
} from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


import { StoreState } from '../../state-management/store';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';

import { ActivateUserRequestAction } from '../../state-management/actions/login-details';

/**
 * Summary
 *    Activate a user's profile.
 *
 *
 * Description
 *    User's will be directed to this page when they activate their profile from anchor
 *    automatically generated email. The link provided within the email will include
 *    an activation token to ensure the correct profile is activated.
 *
 * @copyright 2017 ReallyB2B Limited
 */

@Component({
  selector: 'app-activate-profile',
  templateUrl: './activate-profile.component.html',
  styleUrls: ['./activate-profile.component.scss']
})
export class ActivateProfileComponent implements OnDestroy, OnInit {

  // "loginDetails" from store
  public loginDetails$: Observable<LoginDetailsState>;

  // Subscription to the querystring parameters
  private paramSub: Subscription;

  // Activation e-mail address provided by the querystring parameter "email"
  public email: string = null;

  // Activation token provided by the querystring parameter "token"
  public token: string = null;

  public magSenseOwned: string = null;

  public homeowner: boolean = false;


  /**
   * Constructor for page, initialises store and router values
   *
   * @param {ActivatedRoute} route    Contains information about the associated route
   * @param {Router} router           Initialises router object providing navigation between pages
   * @param {Store<StoreState>} store Initialises Store object
   */
  constructor(
    private route: ActivatedRoute,
    private store: Store<StoreState>,
  ) {
    // Set reference to login state
    this.loginDetails$ = this.store.select('loginDetails');
  }

  /**
   * Retrieve the user's email and activation token from the given url
   * in order to activate the user's profile.
   */
  ngOnInit() {
    // Subscribe to and map the values returned from the ActivatedRoute.paramMap instance
    this.paramSub = this.route.paramMap
      .subscribe((params: ParamMap) => {
        this.email = params.get('email');
        this.token = params.get('token');
        this.magSenseOwned = params.get('token');
        this.homeowner = JSON.parse(params.get('homeowner'));

        //Dispatch an ActivateUserRequestAction call to the store in order to activate the user's profile
        if (this.token && this.email)
          this.store.dispatch(new ActivateUserRequestAction({ token: this.token, email: this.email, magSenseOwned: this.magSenseOwned, homeowner: this.homeowner }));
      });
  }

  /**
   * Unsubscribe from the ActivatedRoute.paramMap instance when the view is destroyed
   * in order to free up memory
   */
  ngOnDestroy() {
    if (this.paramSub)
      this.paramSub.unsubscribe();
  }
}
