import {
  Component,
  Inject,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
} from '@angular/material';

@Component({
  selector: 'app-admin-promotions-view-modal',
  templateUrl: './admin-promotions-view-modal.component.html',
  styleUrls: ['./admin-promotions-view-modal.component.scss']
})
export class AdminPromotionsViewModalComponent {

  public fg: FormGroup;
  public errors: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminPromotionsViewModalComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.fg = this.fb.group({
      id: [{ value: this.data.promotion.id, disabled: true }, Validators.required],
      productId: [{ value: this.data.promotion.productId, disabled: true }, Validators.required],
      name: [{ value: this.data.promotion.name, disabled: true }, [Validators.required, Validators.maxLength(255)]],
      description: [{ value: this.data.promotion.description, disabled: true }, Validators.maxLength(65535)],
      reference: [{ value: this.data.promotion.reference, disabled: true }, [Validators.required, Validators.maxLength(255)]],
      points: [{ value: this.data.promotion.points, disabled: true }, [Validators.required, Validators.maxLength(11), Validators.min(0)]],
      startDate: [{ value: this.data.promotion.startDate, disabled: true }, Validators.required],
      endDate: [{ value: this.data.promotion.endDate, disabled: true }, Validators.required],
    });

    dialogRef.afterClosed().subscribe(() => {
      this.snackBar.dismiss();
    });
  }

  public openConfirmDelete(): void {
    const snackBarRef = this.snackBar.open('Are you sure?', 'Delete');

    snackBarRef.onAction().subscribe(() => {
      this.dialogRef.close(this.fg.value);
    });
  }
}
