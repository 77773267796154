import {Component, OnInit} from '@angular/core';
import {WatertestersService} from '../../services/watertesters.service';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {DashboardService} from '../../services/dashboard.service';
import {State as LoginDetailsState} from '../../../state-management/reducers/login-details';
import {CompanyAddMemberService} from '../../services/companyaddmember.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InviteModalComponent} from '../../../components/sendemail/invite-modal/invite-modal.component';
import {MatDialog} from '@angular/material';
import {RemoveTesterModalComponent} from '../remove-tester-modal/remove-tester-modal.component';
import {AdminTesterModalComponent} from '../admin-tester-modal/admin-tester-modal.component';
import {CurrentUser} from '../../../models/login';

@Component({
    selector: 'app-watertesters',
    templateUrl: './watertesters.component.html',
    styleUrls: ['./watertesters.component.scss']
})

export class WatertestersComponent implements OnInit {


    // Reference to user state
    public loginDetailsState$: Observable<LoginDetailsState>;

    //user roles
    public isCustomerService: boolean = false;
    public isWaterTesterAdmin: boolean = false;


    //removing members
    public message: string = '';
    public formSubmitted: boolean = false;
    public errors: boolean = false;
    public success: boolean = false;

    public companyId: number = 0;

    //tester role id
    public testerRoleId: any = null;

    //tester id
    public testerId: number = null;

    //my id
    public myId: number = null;

    //company query
    public companyQuery: boolean = false;

    //company filter
    public companyFilter = '';

    //table options
    ColumnDefs = [{'searchable': true, 'targets': [0, 1, 2, 3, 4]},
        {'orderable': true, 'targets': [0, 1, 2, 3, 4]}];
    //ColumnDefs = [];
    Columns = [{data: 'first_name'}, {data: 'last_name'}, {data: 'email'}, {data: 'phone'}, {data: 'total_result'}];

    dtOptions: DataTables.Settings = {};

    search = null;
    results = [];
    pageLength = 25;

    constructor(
        private _store: Store<any>,
        private waterTestersService: WatertestersService,
        private dashboardService: DashboardService,
        private companyAddMemberService: CompanyAddMemberService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
    ) {

        this.loginDetailsState$ = _store.select('loginDetails');
        // Subscribe to the login state in order to set a reference to the available user roles
        this.loginDetailsState$.subscribe((state: LoginDetailsState) => {

            if (state.currentProfile) {

                //Customer Service
                if (CurrentUser.userHasRole(state.user, 1)) {
                  //when customer service i need to check if i have company_id parameter as a filter to show
                  this.route.params.subscribe(params => {
                    this.companyId = (params['company_id'] > 0) ? params['company_id'] : 0;
                  });

                  if ( this.companyId > 0 ) {
                    this.search = '(company_id=' + this.companyId + ')';
                    this.companyQuery = true;
                    this.companyFilter = '/company/' + this.companyId;
                  }

                 this.isCustomerService = true;

                } else if (CurrentUser.userHasRole(state.user, 5)) {
                    //if is admin then there is a company info to get
                    const companyInfo = CurrentUser.getInfoCompanyForUser(state.user);

                    if (companyInfo) {
                      //get the first company
                      this.companyId = companyInfo[0].company_id;
                      this.companyFilter = '/company/' + this.companyId;
                    }
                    this.companyQuery = true;
                    this.isWaterTesterAdmin = true;
                }

            }
        });
    }

    ngOnInit(): void {
        this.GetWatertesters();
    }

    /**
     * Get the list of Water testers
     */
    GetWatertesters() {

        const that = this;
        //console.log('search : ' + this.search);
        const company = (this.companyQuery) ? 'company' : '';
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: this.pageLength,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {

                that.waterTestersService.getWatertesters(dataTablesParameters, this.search, company).subscribe(resp => {
                    //console.log(resp['data']);
                    that.results = resp['data'];

                    callback({
                        recordsTotal: resp['paging']['results'],
                        recordsFiltered: resp['paging']['results'],
                        data: []
                    });
                });
            },
            columns: this.Columns,
            columnDefs: this.ColumnDefs,
            language: {
                'zeroRecords': ''
            }
        };
    }

    /**
     * Returns if is checked or not
     *
     * @param result
     * @return checked
     */
    checked(result) {
        if (result === 5) {
            return 'checked';

        }
        return '';
    }

  /**
   * Returns role to change
   *
   * @param result
   * @return role
   */
  switchRole(result) {
    if (result === 5) {
      return 4;

    }
    return 5;
  }

    /**
     * Remove member from a company
     *
     * @param user_id
     * @param user_role_id
     */
    removeUserFromCompany(user_id, user_role_id) {


        if (window)
            window.scrollTo(0, 0);
        this.formSubmitted = true;
        this.errors = false;
        this.success = false;
        this.message = 'Error: Something went wrong.';

        this.testerRoleId = user_role_id;
        this.myId =
        this.testerId = user_id;

        this.removeTesterModal();

    }

    /**
     * Navigate the user to a given page
     *
     * @param {string} pageRef The page chosen to navigate to
     */
    goToPage(pageRef: string)
    {
        this.router.navigate([pageRef]);
    }


  /**
   * Opens InviteModalComponent to invite members
   *
   */
  sendInviteModal() {
    const dialogRef = this.dialog.open(InviteModalComponent, {
      data: {
        companyId: this.companyId,
      }
    });
  }


  /**
   * Opens RemoveTesterModalComponent to remove a member from the company
   *
   */
  removeTesterModal() {
    const dialogRef = this.dialog.open(RemoveTesterModalComponent, {
      data: {
          testerId: this.testerId,
          myId: this.myId,
          testerRoleId: this.testerRoleId,
      }
      ,
      width: '50%',
      panelClass: 'feature-modal-dialog',
    });
  }

  /**
   * Opens AdminTesterModalComponent to update role
   * @param user_id
   * @param role_id
   * @param user_role_id
   */
  adminTesterModal(user_id: number, user_role_id: number, role_id) {

    //console.log('roles 5 id role', Object.keys(roles_object[5])[0]);

    if (window)
      window.scrollTo(0, 0);

      const dialogRef = this.dialog.open(AdminTesterModalComponent, {
        data: {
          userRoleId: user_role_id,
          testerId: user_id,
          roleId: role_id,
          companyId: this.companyId
        }
      });

    //$('#table-watertesters').DataTable().ajax.reload();
  }
}


