import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { CheckValidPostcode, FullAddressItem } from '../../../models/postcode-lookup';
import { ProCheckSite } from '../../../models/procheck-site';
import { UserRole } from '../../../models/registration';
import { AccreditationItem, SimpleUserProfile, UserProfile } from '../../../models/user-profile';
import { CountryRequestAction } from '../../../state-management/actions/country';
import { State as BusinessToolsState } from '../../../state-management/reducers/business-tools';
import { State as CountryState } from '../../../state-management/reducers/country';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { State as ProductsState } from '../../../state-management/reducers/products';
import { State as RegistrationState } from '../../../state-management/reducers/registration';
import { GenericModalComponent } from '../../common/generic-modal/generic-modal.component';
import { PostcodeLookupModalComponent } from '../../common/postcode-lookup-modal/postcode-lookup-modal.component';

/**
 * Summary
 *    User profile edit form
 *
 * Description
 *    For used to view and edit a user's profile as well as for new user
 *    registration and imported user confirmation.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDetailsComponent implements OnChanges, OnInit, OnDestroy {

  // Hide some inputs if the signup is in simple mode
  @Input() simple: boolean = false;
  // Existing user profile for populating fields with initial values
  @Input() profileData: UserProfile = null;
  // If the role type should currently be considered Homeowner (AdminUser page)
  @Input() isHomeowner = false;
  // Array of accreditations for the user to select
  @Input() accreditations: AccreditationItem[] = [];
  // Array of business tool registrations (admin view only)
  @Input() businessToolRegistrations: any = [];
  // Show customer type select?
  @Input() showCustomerTypeSelect = true;
  // List of available roles (User type)
  @Input() roles: UserRole[] = [];
  // Flag to determine if terms checkboxes are shown and need to be ticked
  // (i.e. for initial registration)
  @Input() showTerms: boolean = false;
  // Flag to determine if photo input is shown
  // (i.e. for initial registration)
  @Input() showPhoto: boolean = true;
  // Flag to determine if the submit button is visible
  @Input() showSubmit: boolean = true;
  // Label to show on the submit button
  @Input() submitLabel: string = 'Save';
  // When set, the profile form will be tailored to an admin user
  @Input() adminUser: boolean = false;
  @Input() allowPasswordField: boolean = false;
  @Output() onFormChange = new EventEmitter<boolean>();
  // Called when the form is valid and submitted
  @Output() onSubmit = new EventEmitter<UserProfile>();
  @Output() onSimpleSubmit = new EventEmitter<SimpleUserProfile>();

  // Flag to show if form has been submitted
  public submitted: boolean = false;

  // Validation error to display
  public errors: string = null;

  // Arrays of days, months and years for DOB selects
  public days: string[] = [];
  public months: string[] = [];
  public years: string[] = [];

  // FormGroup for the profile form
  public fg: FormGroup = null;

  // Array of types of memberships (e.g. Corgi), defined in buildForm()
  public membershipTypes: Array<any> = null;

  // New profile photo data as set from ProfilePhotoUploadComponent
  public newProfilePhoto: string = null;

  // Store state for products and Subscription
  public productState$: Observable<ProductsState>;
  private productSub: any;

  // Flag to indicate if serial number is valid
  public validSerial: boolean = null;

  // Subscription to valueChanges Observable for "postCode" form field
  private postcodeValueSub: Subscription = null;

  // Reference to the PostcodeLookupModalComponent
  private dialogRef_postcode: any = null;

  // If set, the postcode lookup button will be enabled
  public enablePostcodeLookup: boolean = false;

  // Store states
  public businessTools$: Observable<BusinessToolsState>;
  public countryState$: Observable<CountryState>;
  public registrationState$: Observable<RegistrationState>;
  public loginDetails$: Observable<LoginDetailsState>;

  public skipRequiredValidation = false;

  public proCheckView: boolean = false;

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    private _fb: FormBuilder,
    private ref: ChangeDetectorRef,
  ) {
    this.businessTools$ = this.store.select('businessTools');
    this.countryState$ = this.store.select('country');
    this.loginDetails$ = this.store.select('loginDetails');
    this.productState$ = this.store.select('products');
    this.registrationState$ = this.store.select('registration');

    this.store.dispatch(new CountryRequestAction());
    // Update this.validSerial when the "products" store state changes
    this.productSub = this.productState$.subscribe((res) => {
      if (res && res.serialCheckResult !== null) {
        this.validSerial = res.serialCheckResult;
      }
    });
  }

  ngOnInit() {
    this.proCheckView = ProCheckSite.getCheck();
    this.buildForm();

    this.fg.valueChanges.subscribe(() => {
      this.onFormChange.emit(true);
    });
  }

  ngOnDestroy() {
    if (this.productSub) {
      this.productSub.unsubscribe();
    }

    if (this.postcodeValueSub) {
      this.postcodeValueSub.unsubscribe();
    }
  }

  ngOnChanges() {
    this.buildForm();
  }


  /**
   * Builds the main FormGroup used for the user profile
   */
  buildForm() {

    // Unsubscribe from valueChanges Observable if necessary as subscription
    // will be recreated once the form is built
    if (this.postcodeValueSub) {
      this.postcodeValueSub.unsubscribe();
      this.postcodeValueSub = null;
    }

    // Array of possible membership options to display in the form
    this.membershipTypes = [];
    if (this.accreditations) {
      for (let i = 0; i < this.accreditations.length; i++) {
        this.membershipTypes.push(
          {
            id: this.accreditations[i].id,
            formControlName: this.accreditations[i].title,
            label: this.accreditations[i].title,
            value: this.getProfileMembership(parseInt(this.accreditations[i].id, 10)),
          }
        );
      }
    }

    // Build values for DOB options
    const d = new Date();
    this.days = [];
    this.months = [];
    this.years = [];
    for (let i = 1; i <= 31; i++)
      this.days.push(i.toString().length === 1 ? '0' + i.toString() : i.toString());
    for (let i = 1; i <= 12; i++)
      this.months.push(i.toString().length === 1 ? '0' + i.toString() : i.toString());
    for (let i = d.getFullYear(); i >= d.getFullYear() - 80; i--)
      this.years.push(i.toString());

    // Custom validator for serial number: only required if the current user is
    // not an admin
    const serialRequired = (control: AbstractControl): { [key: string]: boolean } => {
      if (this.adminUser || this.skipRequiredValidation || !control.value) {
        return null;
      } else {
        return ((this.validSerial === false || control.value === '') ? { error: true } : null);
      }
    };

    // Build DOB values for fields (moment logic moved into form as DOB is now optional)
    const dob: string = this.getProfileStr('dob');

    if (this.simple) {
      this.fg = this._fb.group({
        id: this.getProfileStr('id'),

        registerAs: ['', this.requiredValidators()],
        contractSupport: [false],

        emails: this._fb.group({
          email: [this.getProfileStr('email'), Validators.email],
          confirmEmail: [this.getProfileStr('email'), Validators.email],
        }, { validator: [Validators.required, this.checkEmails] }),
        password: [this.getProfileStr('password'), this.requiredPasswordValidators()],
        country: [this.getProfileStr('country', '2300'), [Validators.required]],
        companyName: this.getProfileStr('companyName'),
        firstName: [this.getProfileStr('firstName'), [Validators.required]],
        lastName: [this.getProfileStr('lastName'), [Validators.required]],
        telephone: [this.getProfileStr('telephone'), [Validators.required]],
        address1: [this.getProfileStr('address1'), this.requiredValidators()],
        address2: this.getProfileStr('address2'),
        town: this.getProfileStr('town'),
        county: this.getProfileStr('county'),
        postCode: [this.getProfileStr('postCode'), this.requiredValidators()],

        // Build a "memberships" group and create fields for each membershipTypes
        memberships: this._fb.group(
          this.membershipTypes.reduce((a, v) => {
            a[v.formControlName] = v.value;
            return a;
          }, {})
        ),

        dataProtectionConfirm: [!this.showTerms, this.adminUser ? [] : [Validators.requiredTrue]],
        termsAndConditionsConfirm: [!this.showTerms, this.adminUser ? [] : [Validators.requiredTrue]],
      });
    } else {
      if (this.profileData) {
        if (this.profileData.optIns.length === 0) {
          this.profileData.optIns.push({
            offers_by_email: 0,
            offers_by_sms: 0,
            offers_by_telephone: 0,
            information_by_email: 0,
            information_by_sms: 0,
            information_by_telephone: 0,
            add_to_find_an_installer: 0,
          });
        }
      }

      this.fg = this._fb.group({
        id: this.getProfileStr('id'),

        offers_by_email: [this.profileData ? this.profileData.optIns[0].offers_by_email : null, []],
        offers_by_sms: [this.profileData ? this.profileData.optIns[0].offers_by_sms : null, []],
        offers_by_telephone: [this.profileData ? this.profileData.optIns[0].offers_by_telephone : null, []],
        information_by_email: [this.profileData ? this.profileData.optIns[0].information_by_email : null, []],
        information_by_sms: [this.profileData ? this.profileData.optIns[0].information_by_sms : null, []],
        information_by_telephone: [this.profileData ? this.profileData.optIns[0].information_by_telephone : null, []],
        add_to_find_an_installer: [this.profileData && this.profileData.optIns[0].add_to_find_an_installer != null ? 'yes' : 'no', []],

        registerAs: [this.getProfileNum('userType', 2), this.requiredValidators()],
        title: [this.getProfileStr('title', 'Mr'), this.requiredValidators()],
        firstName: [this.getProfileStr('firstName'), [Validators.required]],
        lastName: [this.getProfileStr('lastName'), [Validators.required]],

        dob_dd: [dob ? moment(dob).format('DD') : ''],
        dob_mm: [dob ? moment(dob).format('MM') : ''],
        dob_yyyy: [dob ? moment(dob).format('YYYY') : ''],

        mobile: [this.getProfileStr('mobile'), this.requiredValidators()],
        emails: this._fb.group({
          email: [this.getProfileStr('email'), Validators.email],
          confirmEmail: [this.getProfileStr('email'), Validators.email],
        }, { validator: [Validators.required, this.checkEmails] }),
        password: [this.getProfileStr('password'), this.requiredPasswordValidators()],
        telephone: this.getProfileStr('telephone'),

        companyName: [this.getProfileStr('companyName'), this.adminOrHomeownerValidator()],
        companySize: [this.getProfileStr('companySize'), this.adminOrHomeownerValidator()],
        address1: [this.getProfileStr('address1'), this.requiredValidators()],
        address2: this.getProfileStr('address2'),
        town: this.getProfileStr('town'),
        county: this.getProfileStr('county'),
        postCode: [this.getProfileStr('postCode'), this.requiredValidators()],
        country: [this.getProfileStr('country_id', '2300'), [Validators.required]],

        photoUrl: this.getProfileStr('photoUrl'),

        howDidYouHear: this.getProfileNum('howDidYouHear', 1),
        preferredMerchant: this.getProfileStr('preferredMerchant', ''),
        projects: this.getProfileStr('projects', 'Commercial'),
        gasSafeRegistered: this.getProfileBool('gasSafeRegistered', false),
        gasSafeNumber: [{ value: this.getProfileStr('gasSafeNumber'), disabled: true }, []],
        oftecRegistered: this.getProfileBool('oftecRegistered', false),
        oftecNumber: [{ value: this.getProfileStr('oftecNumber'), disabled: true }, []],

        // User must own a MagnaCleanse unless needsMagnaCleanse is set
        magnaCleanseOwned: true,
        magnaCleanseSerial: [this.getProfileStr('magnaCleanseSerial'), !this.skipRequiredValidation ? [serialRequired] : []],

        contract: this.getProfileBool('contract'),
        preferredBoiler: this.getProfileStr('preferredBoiler'),

        // Build a "memberships" group and create fields for each membershipTypes
        memberships: this._fb.group(
          this.membershipTypes.reduce((a, v) => {
            a[v.formControlName] = v.value;
            return a;
          }, {})
        ),

        contactByPost: this.getProfileBool('contactByPost', false),
        contactByPhone: this.getProfileBool('contactByPhone', false),
        contactByEmail: this.getProfileBool('contactByEmail', false),

        showDetailsInInstallersClub: this.getProfileBool('showDetailsInInstallersClub', false),

        dataProtectionConfirm: [!this.showTerms, this.adminUser ? [] : [Validators.requiredTrue]],
        termsAndConditionsConfirm: [!this.showTerms, this.adminUser ? [] : [Validators.requiredTrue]],
      });
    }

    // Enable/disable fields based on checkbox values
    this.checkFieldEnabled('gasSafeRegistered');
    this.checkFieldEnabled('oftecRegistered');

    // Enable the postcode lookup button when the postCode field value changes
    this.postcodeValueSub = this.fg.get('postCode')
      .valueChanges
      .subscribe((v: string) => {
        this.enablePostcodeLookup = CheckValidPostcode(v);
      });

  }

  /**
   * Returns Validators required if the user is not an Admin or a Homeowner
   */
  adminOrHomeownerValidator() {
    return (this.adminUser || this.profileData ? this.profileData.userType === 3 : false) ? [] : [Validators.required];
  }

  /**
   * Given a FullAddressItem from an address lookup, populate relevant fields
   * with the values from the address
   *
   * @param {FullAddressItem} address
   */
  autofillAddress(address: FullAddressItem) {
    this.countryState$.subscribe((res: CountryState) => {
      // Autofill country by comparing country codes
      if (res.countries) {
        const address_country = res.countries.filter(
          country => country.country_code === address.country_code
        );

        this.fg.get('address1').setValue(address.address1);
        this.fg.get('address2').setValue(address.address2);
        this.fg.get('town').setValue(address.town);
        this.fg.get('county').setValue(address.county);
        this.fg.get('postCode').setValue(address.postcode);
        this.fg.get('country').setValue(address_country[0].id);
      }
    });

  }

  /**
   * Opens PostcodeLookupModalComponent with the current postcode value. When
   * closed with a valid address, this.autofillAddress() is called to update
   * the form.
   */
  postcodeLookup() {
    this.dialogRef_postcode = this.dialog.open(PostcodeLookupModalComponent, {
      data: {
        postcode: this.fg.value.postCode,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_postcode.afterClosed().subscribe((result: any) => {
      if (result) {
        this.autofillAddress(result);
      }
    });
  }

  /**
   * Filters available roles to remove the "admin" role
   *
   * @return {UserRole[]}
   */
  filterRoles(): UserRole[] {
    return this.roles
      ? this.roles.filter((r: UserRole): boolean => r.name.toLowerCase() !== 'admin')
      : [];
  }

  /**
   * Generates the correct Bootstrap classes for a "form-group" based on the
   * field's validity
   *
   * @param {string} fieldName    Name of the field in the FormGroup
   * @param {string} extraClasses Optional extra classes to append
   * @return {string}
   */
  public formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses) {
      classes += ` ${extraClasses}`;
    }

    const ff = this.fg.controls[fieldName];
    if (!ff) {
      return classes;
    }

    return `${classes}${!ff.valid && ff.enabled && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Gets a field value as a Boolean or returns the default
   *
   * @param {string}  field
   * @param {boolean} defaultValue Default value if field does not exist
   * @return {boolean}
   */
  getProfileBool(field: string, defaultValue: boolean = false): boolean {
    if (!this.profileData)
      return defaultValue;
    return this.profileData[field];
  }

  /**
   * Returns a Boolean to indicate if the user is a member of the specified
   * accredited body
   *
   * @param {number} id ID of the accredited body
   * @return {boolean}
   */
  getProfileMembership(id: number): boolean {
    if (!this.profileData || !this.profileData.accreditations)
      return false;
    return this.profileData.accreditations
      .filter(v =>
        v.id.toString().toLowerCase() === id.toString().toLowerCase() && v.active
      )
      .length > 0;
  }

  /**
   * Gets a field value as a string or returns the default
   *
   * @param {string} field
   * @param {string} defaultValue Default value if field does not exist
   * @return {string}
   */
  getProfileStr(field: string, defaultValue: string = ''): string {
    if (this.fg && this.fg.value[field] && this.fg.value[field].length > 0)
      return this.fg.value[field];
    if (!this.profileData || !this.profileData[field])
      return defaultValue;
    return this.profileData[field];
  }

  /**
   * Gets a field value as a number or returns the default
   *
   * @param {string} field
   * @param {number} defaultValue Default value if field does not exist
   * @return {number}
   */
  getProfileNum(field: string, defaultValue: number = 0): number {
    if (this.fg && this.fg.value[field] && this.fg.value[field].length > 0) {
      return this.fg.value[field];
    }

    if (!this.profileData || !this.profileData[field]) {
      return defaultValue;
    }

    return this.profileData[field];
  }

  /**
   * Gets the profile photo URL if possible
   *
   * @return {string|null}
   */
  getProfilePhotoUrl(): string {
    if (!this.profileData)
      return null;
    return this.profileData.photoUrl;
  }

  /**
   * Called from ProfilePhotoUploadComponent when a new image has been loaded
   *
   * @param {string} data Base64-encoded file data
   */
  handleProfilePhotoData(data: string) {
    this.newProfilePhoto = data;
  }

  /**
   * Emits the updated profile data via onSubmit() if the form is valid,
   * otherwise sets form errors
   */
  onSubmitForm() {
    this.submitted = true;
    this.errors = null;

    if (window) {
      window.scrollTo(0, 0);
    }

    if (this.fg.valid) {
      this.fg.value.ignoreSerial = this.skipRequiredValidation;
      let registerAs = this.fg.value.registerAs;
      if (this.showCustomerTypeSelect) {
        if (registerAs === '7' && !this.fg.value.contractSupport) {
          // If the installer does not receive ADEY contract support they are still entitled to earn points
          registerAs = '2';
        }
      }

      if (this.simple) {
        this.onSimpleSubmit.emit(
          SimpleUserProfile.fromFormData(
            Object.assign({}, this.fg.value, { profilePhotoData: this.newProfilePhoto, registerAs }),
          )
        );
      } else {

        const optIns = [];
        const optInsObject = {};

        if (this.profileData) {
          if (this.fg.value.offers_by_email !== this.profileData.optIns[0].offers_by_email) {
            Object.assign(optInsObject, { offers_by_email: this.fg.value.offers_by_email ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { offers_by_email: this.profileData.optIns[0].offers_by_email });
          }

          if (this.fg.value.offers_by_sms !== this.profileData.optIns[0].offers_by_sms) {
            Object.assign(optInsObject, { offers_by_sms: this.fg.value.offers_by_sms ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { offers_by_sms: this.profileData.optIns[0].offers_by_sms });
          }

          if (this.fg.value.offers_by_telephone !== this.profileData.optIns[0].offers_by_telephone) {
            Object.assign(optInsObject, { offers_by_telephone: this.fg.value.offers_by_telephone ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { offers_by_telephone: this.profileData.optIns[0].offers_by_telephone });
          }

          if (this.fg.value.information_by_email !== this.profileData.optIns[0].information_by_email) {
            Object.assign(optInsObject, { information_by_email: this.fg.value.information_by_email ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { information_by_email: this.profileData.optIns[0].information_by_email });
          }

          if (this.fg.value.information_by_sms !== this.profileData.optIns[0].information_by_sms) {
            Object.assign(optInsObject, { information_by_sms: this.fg.value.information_by_sms ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { information_by_sms: this.profileData.optIns[0].information_by_sms });
          }

          if (this.fg.value.information_by_telephone !== this.profileData.optIns[0].information_by_telephone) {
            Object.assign(optInsObject, { information_by_telephone: this.fg.value.information_by_telephone ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { information_by_telephone: this.profileData.optIns[0].information_by_telephone });
          }

          if (this.fg.value.add_to_find_an_installer !== this.profileData.optIns[0].add_to_find_an_installer) {
            Object.assign(optInsObject, { add_to_find_an_installer: this.fg.value.add_to_find_an_installer === 'yes' ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null });
          } else {
            Object.assign(optInsObject, { add_to_find_an_installer: this.profileData.optIns[0].add_to_find_an_installer });
          }

          optIns.push(optInsObject);
        } else {
          Object.assign(optInsObject, {
            offers_by_email: this.fg.value.offers_by_email ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            offers_by_sms: this.fg.value.offers_by_sms ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            offers_by_telephone: this.fg.value.offers_by_telephone ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            information_by_email: this.fg.value.information_by_email ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            information_by_sms: this.fg.value.information_by_sms ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            information_by_telephone: this.fg.value.information_by_telephone ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            add_to_find_an_installer: this.fg.value.add_to_find_an_installer === 'yes' ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null
          });

          optIns.push(optInsObject);
        }

        this.onSubmit.emit(
          UserProfile.fromFormData(
            Object.assign({}, this.fg.value, { profilePhotoData: this.newProfilePhoto, registerAs }),
            this.accreditations,
            optIns
          )
        );
      }

    }
    else {
      this.fg.setErrors({ errors: true });
      this.fg.markAsTouched();

      this.errors = 'The form contains errors. Please correct the highlighted fields and try again.';
      this.ref.detectChanges();
    }
  }

  /**
   * Returns required Validators if the current user is not an admin
   *
   * @return {Validators[]}
   */
  requiredValidators(): Validators[] {
    return this.adminUser ? [] : [Validators.required];
  }

  /**
   * Returns required Validators only if the form is being used for a new user
   *
   * @return {Validators[]}
   */
  requiredPasswordValidators(): Validators[] {
    return this.profileData ? [] : [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$')];
  }

  /**
   * Skips the serial number validation, used in the template for
   * toggling the serial number field validation for admin user.
   * @returns {boolean}
   */
  skipSerialValidation(): boolean {
    // true -> false, false ->true.
    this.skipRequiredValidation = !this.skipRequiredValidation;

    // Rebuild the form.
    this.buildForm();

    // Return true/false depending on the toggle status.
    return this.skipRequiredValidation;
  }

  /**
   * Enables/disables associated fields based on a specified checkbox value
   *
   * @param {string} fieldValue Name of the field being checked
   */
  checkFieldEnabled(fieldValue: string) {
    if (this.fg.controls[fieldValue]) {
      switch (fieldValue) {
        case 'gasSafeRegistered':
          (this.fg.controls[fieldValue].value === true ? this.fg.controls['gasSafeNumber'].enable() : this.fg.controls['gasSafeNumber'].disable());
          break;
        case 'oftecRegistered':
          (this.fg.controls[fieldValue].value === true ? this.fg.controls['oftecNumber'].enable() : this.fg.controls['oftecNumber'].disable());
          break;
      }
    }
  }

  /**
   * Called on customer type selection, if homeowner is selected display the warning message
   *
   * @param {string} roleId
   */
  selectCustomerType(roleId: string): void {
    if (roleId === '3') {
      const dialogRef_customerType = this.dialog.open(GenericModalComponent, {
        data: {
          title: 'Homeowner',
          content: `This is a trade-only platform designed for heating engineers. If you are looking to register your MagnaClean filter warranty please visit <a href="https://www.adey.com/">www.adey.com</a> or contact our Customer Service team on <a href="tel:01242546700">01242546700</a>.`,
          dismissLabel: 'OK',
        },
      });

      dialogRef_customerType.afterClosed().subscribe(() => {
        this.fg.get('registerAs').setValue('');
      });
    }
  }

  /**
   * Compare both inputs to ensure emails match
   * @param group Form group to validate
   */
  private checkEmails(group: FormGroup): null | object {
    const pass = group.get('email').value;
    const confirmPass = group.get('confirmEmail').value;
    return pass === confirmPass ? null : { error: 'Email addresses do not match.' };
  }
}
