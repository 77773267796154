import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {
  AcceptInvitationQueryRequest, AcceptInvitationQueryResponse,
  Invitation,
  ReviewInvitationQueryRequest,
  ReviewInvitationQueryResponse,
  SendInvitationQueryRequest,
  SendInvitationQueryResponse
} from '../models/invitation';
import {ApiService} from './api.service';

@Injectable()
export class InvitationService {

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Checks the token and get the review invitation
   *
   * @param {ReviewInvitationQueryRequest} req
   * @return {Observable<ReviewInvitationQueryResponse>}
   */
  reviewInvitation(req: ReviewInvitationQueryRequest): Observable<ReviewInvitationQueryResponse> {
    return this.apiService.apiGet('/invite/accept/' + req.token)
      .map((res: any): ReviewInvitationQueryResponse => {
        return {
          message: null,
          results: res,
          valid: true,
        };
      })
      .catch((err: any): Observable<ReviewInvitationQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request.',
          results: null,
          valid: false
        });
      });
  }


  /**
   * Send invitation
   *
   * @param {SendInvitationQueryRequest} req
   * @return {Observable<SendInvitationQueryResponse>}
   */
  sendInvitation(req: SendInvitationQueryRequest): Observable<SendInvitationQueryResponse> {
    return this.apiService.apiPost('/watertest/invite', Invitation.sendInviteToAPI(req))
      .map((res: any): SendInvitationQueryResponse => {
        //console.log(res);
        return {
          message: 'Invitation has been sent successfully.',
          valid: true,
        };
      })
      .catch((err: any): Observable<SendInvitationQueryResponse> => {
        //console.log(err);
        return Observable.of({
          message: err.error.message ? err.error.message : 'Invalid Request.',
          valid: false
        });
      });
  }

  /**
   * Accept invitation
   *
   * @param {AcceptInvitationQueryRequest} req
   * @return {Observable<AcceptInvitationQueryResponse>}
   */
  acceptInvitation(req: AcceptInvitationQueryRequest): Observable<AcceptInvitationQueryResponse> {
    //console.log('accept invitation ', req);
    return this.apiService.apiPost('/watertest/invite/accept', Invitation.acceptInviteToAPI(req))
      .map((res: any): AcceptInvitationQueryResponse => {
        return {
          message: 'Invitation has been accepted successfully.',
          valid: true,
        };
      })
      .catch((err: any): Observable<AcceptInvitationQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request.',
          valid: false
        });
      });
  }
}
