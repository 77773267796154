import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableResultsService} from '../../services/tableresults.service';
import {Router} from '@angular/router';
import {CheckBoxListService} from '../../services/checkboxlist.service';
import {TableTypes} from './TableTypes.enum';

import {DatatableService} from '../../services/datatable.service';

import * as moment from 'moment';

@Component({
    selector: 'app-tableresults',
    templateUrl: './tableresults.component.html',
    styleUrls: ['./tableresults.component.scss']
})
export class TableResultsComponent implements OnInit {

    @Input() search:    string;
    @Input() tableType: TableTypes;
    @Input() admin:     boolean;
    @Input() lastTest:  boolean;

    @Output() resultIds = new EventEmitter<any>();
    @Output() tableSearch = new EventEmitter<any>();

    public tableTypes = TableTypes;

    //table options
    public columnDefs = [
        {'searchable': false, 'targets': [2, 3]},
        {'orderable': false, 'targets': [2, 3]},
        {'width': '100px', 'targets': [2, 3]},
        {'width': '200px', 'targets': [1]}
    ];
    public columns: any[];
    public pageLength = 50;
    public dtOptions: DataTables.Settings = {};

    //results called
    public results: any[];

    //checkbox
    public selectAll: boolean;
    public selectedIds: any[];
    public allIds: any[];

    //order field index
    public orderByIndex = 0;

    constructor(
        private tableResultsService: TableResultsService,
        private checkBoxListService: CheckBoxListService,
        private dataTableService: DatatableService,
        private router: Router) {
    }

    ngOnInit() {
        const that = this;
        //console.log('this.tableType ', this.tableType);
        if (this.tableType === TableTypes.normal || this.tableType === TableTypes.due ) {
            this.orderByIndex = 1;
            this.columns = [{data: 'address'}, {data: 'last_test'}, {data: 'pass'}, {data: ''}];
        }

        if (this.tableType === TableTypes.byAddress || this.tableType === TableTypes.waterTester) {
          this.columnDefs = [
            {'searchable': false, 'targets': [2, 3, 4]},
            {'orderable': false, 'targets': [3, 4]},
            {'width': '100px', 'targets': [3, 4]},
            {'width': '200px', 'targets': [1, 2]}
          ];
          this.orderByIndex = 2;
          this.columns = [{data: 'tester_name'}, {data: 'company_name'}, {data: 'test_timestamp'}, {data: 'pass'}, {data: ''}];
        }

        if (this.tableType === TableTypes.all) {
          this.orderByIndex = 2;
          this.columnDefs = [
            {'searchable': false, 'targets': [3, 4]},
            {'orderable': false, 'targets': [3, 4]},
            {'width': '100px', 'targets': [3, 4]},
            {'width': '200px', 'targets': [1, 2]}
          ];
          this.columns = [{data: 'address'}, {data: 'tester_name'}, {data: 'test_timestamp'}, {data: 'pass'}, {data: ''}];
        }

        // console.log('this.lastest : ' + this.lastTest);
        //console.log('this.type : ' + TableTypes[this.tableType]);
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: this.pageLength,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                //console.log('dataTablesParameters : ', dataTablesParameters);
                this.sendOutPutSearch(dataTablesParameters);
                that.tableResultsService.getResults(dataTablesParameters, this.search, this.tableType).subscribe(resp => {
                    //console.log('table results ', resp['data']);
                    that.results = (resp['paging']['results'] > 0) ? resp['data'] : [];
                    this.allIds = this.checkBoxListService.collectIds(resp['data'], this.lastTest, this.tableType);
                    this.selectAll = false;
                    this.selectedIds = [];
                    callback({
                        recordsTotal: resp['paging']['results'],
                        recordsFiltered: resp['paging']['results'],
                        data: []
                    });
                });
            },
            columns: this.columns,
            columnDefs: this.columnDefs,
            language: {
                'zeroRecords': ''
            },
          'order': [[ this.orderByIndex, 'desc' ]]
        };

    }

    /**
     * Check events from the checkbox
     */
    checkIndividual(e) {
        const updateIds = true;
        if (e.source.value > 0) {
            if (e.checked) {
               // tslint:disable-next-line:radix
               this.selectedIds.push( parseInt(e.source.value));
            } else {
               this.selectedIds = this.removeValue(this.selectedIds, e.source.value);
            }

            if (updateIds) {
              this.resultIds.emit(this.selectedIds);
            }
        }
    }

    /**
     * Check events from the main checkbox
     */
    checkCollective(e) {
        this.selectAll = e.source.checked;
        if (e.source.checked) {
            this.selectedIds = this.allIds;
        } else {
            this.selectedIds = [];
        }
        this.resultIds.emit(this.selectedIds);
    }

  /**
   * Returns the current table query
   *
   * @param dataTablesParameters
   */
    //send query from table output
    sendOutPutSearch(dataTablesParameters) {
       const param = this.dataTableService.joinParameters(dataTablesParameters, this.search);
       this.tableSearch.emit(param);
    }

    /**
     * Returns icon
     *
     * @param result
     * @return icon
     */
    iconResult(result) {
        if (result) {
          return 'tick.png';
            //return 'glyphicon glyphicon-ok color-green';
        }
        return 'recommended.png';
       // return 'glyphicon glyphicon-remove color-adey-red';
    }

    /**
     * Navigates to the /watertest/result
     */
    goToResult(id) {
        this.router.navigate(['/watertest/result/' + id]);
    }

    /**
     * Navigates to the /watertest/admin/result/address/
     */
    goToLocalResults(local_id: number) {
        this.router.navigate(['/watertest/admin/result/address/' + local_id]);
    }

    checkTableType(typeToCheck: TableTypes) {
      return this.tableType === typeToCheck;
    }

  /**
   * Remove value from array
   */
   removeValue(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == value) {
        arr.splice(i, 1);
        break;
      }
    }
    return arr;
  }

  /**
   * Date format
   */
  dateFormat(date: string) {
    return moment(date).format('DD-MM-YYYY');
  }
}
