import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { PostcodeLookupService } from '../../services/postcode-lookup.service';

import * as ActionTypes from '../actions/postcode-lookup';

import {
  GetPostcodeAddressResponse,
  PostcodeLookupResponse,
} from '../../models/postcode-lookup';

@Injectable()
export class PostcodeLookupEffects {
  constructor(
    private service:  PostcodeLookupService,
    private actions$: Actions
  ) {}

  /**
   * For a PostcodeLookupRequestAction, call
   * PostcodeLookupService::queryPostcode() and dispatch a new
   * PostcodeLookupResponseAction with the response.
   */
  @Effect() postcodeLookupRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.POSTCODE_LOOKUP_REQUEST)
    .switchMap((req: ActionTypes.PostcodeLookupRequestAction): Observable<ActionTypes.PostcodeLookupResponseAction> =>
      this.service.queryPostcode(req.payload)
        .map((res: PostcodeLookupResponse): ActionTypes.PostcodeLookupResponseAction =>
          new ActionTypes.PostcodeLookupResponseAction(res)
        )
    );

  /**
   * For a PostcodeLookupResponseAction, call the response's "callback"
   * function if it exists. Do not dispatch any further actions.
   */
  @Effect({dispatch: false}) postcodeLookupResponse$: Observable<void> = this.actions$
    .ofType(ActionTypes.POSTCODE_LOOKUP_RESPONSE)
    .map((res: ActionTypes.PostcodeLookupResponseAction): void => {
      if (res.payload.callback && !res.payload.error)
        res.payload.callback(res.payload.results);
    });

  /**
   * For a GetPostcodeAddressRequestAction, call
   * PostcodeLookupService::getAddressByID() and dispatch a new
   * GetPostcodeAddressResponseAction with the response.
   */
  @Effect() getPostcodeAddressRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.GET_POSTCODE_ADDRESS_REQUEST)
    .switchMap((req: ActionTypes.GetPostcodeAddressRequestAction): Observable<ActionTypes.GetPostcodeAddressResponseAction> =>
      this.service.getAddressByID(req.payload)
        .map((res: GetPostcodeAddressResponse): ActionTypes.GetPostcodeAddressResponseAction =>
          new ActionTypes.GetPostcodeAddressResponseAction(res)
        )
    );

  /**
   * For a GetPostcodeAddressResponseAction, call the response's "callback"
   * function if it exists. Do not dispatch any further actions.
   */
  @Effect({dispatch: false}) getPostcodeAddressResponse$: Observable<void> = this.actions$
    .ofType(ActionTypes.GET_POSTCODE_ADDRESS_RESPONSE)
    .map((res: ActionTypes.GetPostcodeAddressResponseAction): void => {
      if (res.payload.callback && !res.payload.error)
        res.payload.callback(res.payload.address);
    });
}
