import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/customer-suggestion';
var CustomerSuggestionEffects = /** @class */ (function () {
    function CustomerSuggestionEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * Fetch the customer suggestions from the API.
         *
         * @type {Observable<any>}
         */
        this.fetchCustomerSuggestions$ = this.actions$
            .ofType(ActionTypes.FETCH_CUSTOMER_SUGGESTION_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchCustomerSuggestions(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchCustomerSuggestionResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CustomerSuggestionEffects.prototype, "fetchCustomerSuggestions$", void 0);
    return CustomerSuggestionEffects;
}());
export { CustomerSuggestionEffects };
