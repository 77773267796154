import {
  Component,
  Inject,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


/**
 * Summary
 *    Provides a modal dialog used for adding and editing resources
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-content-resource-edit-modal',
  templateUrl: './admin-content-resource-edit-modal.component.html',
  styleUrls: ['./admin-content-resource-edit-modal.component.scss']
})
export class AdminContentResourceEditModalComponent {

  // Edit form
  public fg: FormGroup;

  // Errors for display
  public errors: string = null;

  // Available resource pages for select options
  public availablePages: string[] = [
    'Business tools',
    'Dashboard',
    'Homeowner videos',
    'Infographics',
    'Installation guides',
    'Other resources',
    'Product brochures',
    'Resources',
    'Sales tools',
    'Videos',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminContentResourceEditModalComponent>,
    private fb: FormBuilder
  ) {
    this.fg = this.fb.group({
      addToPage: null,

      title: [this.data.resource ? this.data.resource.title : null, Validators.required],
      description: [this.data.resource ? this.data.resource.description : null],
      visible: [this.data.resource ? this.data.resource.visible : true],
      order: [this.data.resource ? this.data.resource.order : 0],

      pages: [this.data.resource ? this.data.resource.pages.join(',') : null, Validators.required],
      categories: [this.data.resource && this.data.resource.categories ? this.data.resource.categories.join(',') : null],
      keywords: [this.data.resource && this.data.resource.keywords ? this.data.resource.keywords.join(',') : null],

      imageData: null,

      attachment: this.fb.group({
        itemType: [
          this.data.resource && this.data.resource.attachment ? this.data.resource.attachment.itemType : 'DOWNLOAD',
          Validators.required
        ],
        url: [
          this.data.resource && this.data.resource.attachment ? this.data.resource.attachment.url : null,
        ],
        downloadName: [
          this.data.resource && this.data.resource.attachment ? this.data.resource.attachment.downloadName : null,
        ],
        data: null,
      }),
    });
  }

  /**
   * Adds the selected page name to the list of pages to which the ResourceItem
   * is attached
   */
  addPageToList() {
    let pages: string = this.fg.value.pages ? this.fg.value.pages : '';
    pages += (pages.length === 0 ? '' : ',') + this.fg.value.addToPage;
    this.fg.get('pages').setValue(pages);
  }

  /**
   * Handler for the resource attachment FileUploadComponent::onData()
   * EventEmitter: sets the form control value to the encoded file data
   *
   * @param {string} data Base64-encoded file data from FileUploadComponent
   */
  handleAttachmentFileData(data: string) {
    this.fg.get('attachment').get('data').setValue(data);
  }

  /**
   * Handler for the resource thumbnail FileUploadComponent::onData()
   * EventEmitter: sets the form control value to the encoded file data
   *
   * @param {string} data Base64-encoded file data from FileUploadComponent
   */
  handleThumbnailData(data: string) {
    this.fg.get('imageData').setValue(data);
  }

  /**
   * Called when the resource is to be saved and the dialog closed
   */
  onSave() {
    // Cards can only be used on the Business tools page
    if (this.fg.get('attachment.itemType').value === 'CARD') {
      this.fg.get('pages').setValue('Business tools');
    }
    if (this.fg.valid) {
      this.errors = null;
      this.dialogRef.close(
        Object.assign(
          {},
          this.data.resource ? this.data.resource : { id: null, visible: true },
          Object.assign({}, this.fg.value, {
            pages: this.fg.value.pages.split(','),
            categories: this.fg.value.categories ? this.fg.value.categories.split(',') : null,
            keywords: this.fg.value.keywords ? this.fg.value.keywords.split(',') : null,
          })
        )
      );
    }
    else {
      this.errors = 'The form contains errors. Please correct them and try again.';
    }

  }
}
