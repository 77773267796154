import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {State as LoginDetailsState} from '../../../state-management/reducers/login-details';

import {DashboardService} from '../../services/dashboard.service';

import {FormBuilder} from '@angular/forms';
import {
  CompanyTotalQueryRequestAction,
  TesterTotalQueryRequestAction, TestOverDueNextMonthTotalQueryRequestAction, TestOverDueTotalQueryRequestAction,
  TestStatusPropertyTotalFailQueryRequestAction,
  TestStatusPropertyTotalPassQueryRequestAction,
  TestTotalQueryRequestAction
} from '../../state-management/actions/watertesters';
import {State as WaterTesterState} from '../../state-management/reducers/watertester';
import {CurrentUser} from '../../../models/login';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    // Reference to user state
    public loginDetailsState$: Observable<LoginDetailsState>;

    public isCustomerService: boolean = false;
    public isWaterTesterAdmin: boolean = false;
    public title: string = 'Dashboard';
    public companyName: string = null;
    public companyId: number = null;

    public month1: number = 12;
    public month2: number = 11;

    public saving: boolean = false;
    public message: string = '';
    public errors: boolean = false;
    public success: boolean = false;

  // "dashboard" from store
  public dashboard$: Observable<WaterTesterState>;

    constructor(
        private formBuilder: FormBuilder,
        private _store: Store<any>,
        private dashboardService: DashboardService,
        private router: Router
    ) {
        this.dashboard$ = _store.select('WaterTester');
        this.loginDetailsState$ = _store.select('loginDetails');
        // Subscribe to the login state in order to set a reference to the available user roles
        this.loginDetailsState$.subscribe((state: LoginDetailsState) => {
            if (state.currentProfile) {
                //Customer Service
                if (CurrentUser.userHasRole(state.user, 1)) {
                    this.isCustomerService = true;
                    this.title = 'ADEY Admin Dashboard';
                } else
                    // Water tester company
                    if (CurrentUser.userHasRole(state.user, 5)) {
                      this.title = 'Dashboard for ';
                      this.isWaterTesterAdmin = true;
                      //if is admin then there is a company info to get
                      const companyInfo = CurrentUser.getInfoCompanyForUser(state.user);

                      if (companyInfo) {
                        //get the first company
                        this.companyName = companyInfo[0].company_name;
                        this.companyId = companyInfo[0].company_id;
                      }
                    }
            }
        });
    }

    ngOnInit() {

      if (this.isCustomerService) {
        //get the total company
        this._store.dispatch(new CompanyTotalQueryRequestAction());
      }

      if ( this.isCustomerService || this.isWaterTesterAdmin) {
        this._store.dispatch(new TesterTotalQueryRequestAction());
      }

      //get the total test
      this._store.dispatch(new TestTotalQueryRequestAction());

      //get the latest test status property total pass
      this._store.dispatch(new TestStatusPropertyTotalPassQueryRequestAction());

      //get the latest test status property total recommended
      this._store.dispatch(new TestStatusPropertyTotalFailQueryRequestAction());

      //get the test's over due total
      this._store.dispatch(new TestOverDueTotalQueryRequestAction({monthStart: this.month1}));

      //get the test's over due next month total
      this._store.dispatch(new TestOverDueNextMonthTotalQueryRequestAction({monthStart: this.month1, monthEnd: this.month2}));

    }

    /**
     * Navigates to the /watertest/company route
     */
    waterTestListCompany() {
        this.router.navigate(['/watertest/company']);
    }

    /**
     * Navigates to the /watertest/results/:filter
     */
    waterTests(filter: string) {
        this.router.navigate(['/watertest/admin/result/' + filter]);
    }

    /**
     * Navigates to the /watertesters/company route
     */
    testerListCompany() {
        this.router.navigate(['/watertest/admin/watertesters']);
    }

    //

    /**
     * Navigates to the /watertesters/admin/results/due/date/due/date route
     */
    waterTestsDueNextMonth(month1: number, month2: number) {
        this.router.navigate(['/watertest/admin/result/due-next-month/' + month1 + '/' + month2]);
    }

    /**
     * Navigates to the /watertesters/admin/results/due route
     */
    waterTestsDue(month: number) {
        this.router.navigate(['/watertest/admin/result/due/' + month]);
    }



}
