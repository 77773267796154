import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { JobService } from '../../services/job.service';
import * as ActionTypes from '../actions/register-job';
var RegisterJobEffects = /** @class */ (function () {
    function RegisterJobEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a RegisterJobRequestAction, call
         * JobService::submit() and dispatch a new
         * RegisterJobResponseAction with the response.
         */
        this.registerJobRequest$ = this.actions$
            .ofType(ActionTypes.REGISTER_JOB_REQUEST)
            .switchMap(function (req) {
            return _this.service.submitJob(req.payload)
                .map(function (res) {
                return new ActionTypes.RegisterJobResponseAction(res);
            });
        });
        /**
         * For a FetchJobRequestAction, call
         * JobService::getJobData() and dispatch a new
         * FetchJobResponseAction with the response.
         */
        this.fetchJobRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_JOB_REQUEST)
            .switchMap(function (req) {
            return _this.service.getJobData(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchJobResponseAction(res);
            });
        });
        /**
         * For a FetchJobPdfRequestAction, call JobService::getJobPDF() and dispatch
         * a new FetchJobPdfResponseAction with the response.
         */
        this.fetchJobPdfRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_JOB_PDF_REQUEST)
            .switchMap(function (req) {
            return _this.service.getJobPDF(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchJobPdfResponseAction(res);
            });
        });
        /**
         * For a UpdateJobRequestAction, call
         * JobService::submit() and dispatch a new
         * UpdateJobResponseAction with the response.
         */
        this.updateJobRequest$ = this.actions$
            .ofType(ActionTypes.UPDATE_JOB_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateJob(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateJobResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterJobEffects.prototype, "registerJobRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterJobEffects.prototype, "fetchJobRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterJobEffects.prototype, "fetchJobPdfRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterJobEffects.prototype, "updateJobRequest$", void 0);
    return RegisterJobEffects;
}());
export { RegisterJobEffects };
