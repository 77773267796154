import { Component, OnInit, Input } from '@angular/core';
import { MatDialog }                from '@angular/material';
import { DomSanitizer, SafeUrl }    from '@angular/platform-browser';


import { YoutubeModalComponent }  from '../youtube-modal/youtube-modal.component';


/**
 * Summary
 *    Simple component providing a button to open a YoutubeModalComponent with
 *    a specified video.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  // URL of the video
  @Input() videoUrl: string;

  // Button label
  @Input() buttonText: string;

  // Additional CSS classes to add to button
  @Input() buttonClass: string;


  constructor(
    private dialog:    MatDialog,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
  }


  /**
   * Returns a sanitized videoUrl
   *
   * @return {SafeUrl}
   */
  getSafeURL(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  /**
   * Opens the YoutubeModalComponent dialog and passes the sanitized videoUrl
   */
  openVideoPlayer() {
    const dialogRef = this.dialog.open(YoutubeModalComponent, {
      data: {
        videoUrl: this.getSafeURL(),
      },
      panelClass: 'feature-modal-dialog',
    });
  }
}
