import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule } from 'angular-calendar';
import { DataTablesModule } from 'angular-datatables';
import { ChartsModule } from 'ng2-charts';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFaviconModule } from 'ngx-favicon';

import { environment } from '../environments/environment';
import { AvailableCustomFavicons, customFavicons } from '../favicon.config';
import { AppComponent } from './app.component';
import { appRouting } from './app.router';
import { ActivateProfileComponent } from './components/activate-profile/activate-profile.component';
import {
  AdminContentPageEditModalComponent,
} from './components/admin/admin-content/admin-content-pages/admin-content-page-edit-modal/admin-content-page-edit-modal.component';
import {
  AdminContentPagesComponent,
} from './components/admin/admin-content/admin-content-pages/admin-content-pages.component';
import {
  AdminContentResourceEditModalComponent,
} from './components/admin/admin-content/admin-content-resources/admin-content-resource-edit-modal/admin-content-resource-edit-modal.component';
import {
  AdminContentResourcesComponent,
} from './components/admin/admin-content/admin-content-resources/admin-content-resources.component';
import { AdminContentComponent } from './components/admin/admin-content/admin-content.component';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { AdminEventsComponent } from './components/admin/admin-events/admin-events.component';
import { AdminInstallerJobsComponent } from './components/admin/admin-installer-jobs/admin-installer-jobs.component';
import {
  AdminPro3AlertDetailModalComponent,
} from './components/admin/admin-pro3/admin-pro3-alert-detail/admin-pro3-alert-detail-modal.component';
import {
  AdminPro3AlertListComponent,
} from './components/admin/admin-pro3/admin-pro3-alert-list/admin-pro3-alert-list.component';
import {
  AdminPro3AlertRowComponent,
} from './components/admin/admin-pro3/admin-pro3-alert-list/admin-pro3-alert-row/admin-pro3-alert-row.component';
import {
  AdminPro3DeviceDetailModalComponent,
} from './components/admin/admin-pro3/admin-pro3-device-detail/admin-pro3-device-detail-modal.component';
import {
  AdminPro3RegisterWarrantyComponent,
} from './components/admin/admin-pro3/admin-pro3-device-detail/admin-pro3-register-warranty/admin-pro3-register-warranty.component';
import {
  AdminPro3DeviceFilterPanelComponent,
} from './components/admin/admin-pro3/admin-pro3-device-filter-panel/admin-pro3-device-filter-panel.component';
import {
  AdminPro3DeviceListComponent,
} from './components/admin/admin-pro3/admin-pro3-device-list/admin-pro3-device-list.component';
import {
  AdminPro3DeviceRowComponent,
} from './components/admin/admin-pro3/admin-pro3-device-list/admin-pro3-device-row/admin-pro3-device-row.component';
import {
  AdminPro3DeviceStatusComponent,
} from './components/admin/admin-pro3/admin-pro3-device-status/admin-pro3-device-status.component';
import {
  AdminPro3NoteAlertComponent,
} from './components/admin/admin-pro3/admin-pro3-note-alert/admin-pro3-note-alert.component';
import { AdminPro3Component } from './components/admin/admin-pro3/admin-pro3.component';
import {
  AdminPromotionsCreateModalComponent,
} from './components/admin/admin-rewards/admin-promotions-create-modal/admin-promotions-create-modal.component';
import {
  AdminPromotionsViewModalComponent,
} from './components/admin/admin-rewards/admin-promotions-view-modal/admin-promotions-view-modal.component';
import {
  AdminRewardsEditModalComponent,
} from './components/admin/admin-rewards/admin-rewards-edit-modal/admin-rewards-edit-modal.component';
import { AdminRewardsComponent } from './components/admin/admin-rewards/admin-rewards.component';
import {
  AdminUserPointsReportModalComponent,
} from './components/admin/admin-rewards/admin-user-points-report-modal/admin-user-points-report-modal.component';
import {
  AdminUserEditModalComponent,
} from './components/admin/admin-users/admin-user-edit-modal/admin-user-edit-modal.component';
import { AdminUsersComponent } from './components/admin/admin-users/admin-users.component';
import { BusinessToolsComponent } from './components/business-tools/business-tools.component';
import { TradehelpComponent } from './components/business-tools/tradehelp/tradehelp.component';
import { TruequoteComponent } from './components/business-tools/truequote/truequote.component';
import { BatteryIconComponent } from './components/common/battery-icon/battery-icon.component';
import { ConfirmPromptComponent } from './components/common/confirm-prompt/confirm-prompt.component';
import { FileDownloadComponent } from './components/common/file-download/file-download.component';
import { FileUploadComponent } from './components/common/file-upload/file-upload.component';
import { GenericModalComponent } from './components/common/generic-modal/generic-modal.component';
import { MagnetiteBarComponent } from './components/common/magnetite-bar/magnetite-bar.component';
import { MessagePanelComponent } from './components/common/message-panel/message-panel.component';
import { PageContentComponent } from './components/common/page-content/page-content.component';
import { PostcodeLookupModalComponent } from './components/common/postcode-lookup-modal/postcode-lookup-modal.component';
import { PromotionModalComponent } from './components/common/promotion-modal/promotion-modal.component';
import { RichEditorComponent } from './components/common/rich-editor/rich-editor.component';
import { VideoComponent } from './components/common/video-component/video.component';
import { YoutubeModalComponent } from './components/common/youtube-modal/youtube-modal.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContentArchiveComponent } from './components/content-archive/content-archive.component';
import { ContentItemComponent } from './components/content-archive/content-item/content-item.component';
import { ContentListComponent } from './components/content-archive/content-list/content-list.component';
import { DiaryCalendarComponent } from './components/diary/diary-calendar/diary-calendar.component';
import { DiaryEventComponent } from './components/diary/diary-event/diary-event.component';
import { DiaryListEventsModalComponent } from './components/diary/diary-list-events-modal/diary-list-events-modal.component';
import { DiaryListEventsComponent } from './components/diary/diary-list-events/diary-list-events.component';
import { DiarySingleEventComponent } from './components/diary/diary-single-event/diary-single-event.component';
import { DiaryComponent } from './components/diary/diary.component';
import { FilterSliderComponent } from './components/filter-slider/filter-slider.component';
import {
  BusinessToolsWidgetComponent,
} from './components/homepage-widgets/business-tools-widget/business-tools-widget.component';
import { DiaryWidgetComponent } from './components/homepage-widgets/diary-widget/diary-widget.component';
import { ProductWidgetComponent } from './components/homepage-widgets/product-widget/product-widget.component';
import { ProfileWidgetComponent } from './components/homepage-widgets/profile-widget/profile-widget.component';
import { ResourceWidgetComponent } from './components/homepage-widgets/resource-widget/resource-widget.component';
import { RewardsWidgetComponent } from './components/homepage-widgets/rewards-widget/rewards-widget.component';
import { RoiWidgetComponent } from './components/homepage-widgets/roi-widget/roi-widget.component';
import { ServiceWidgetComponent } from './components/homepage-widgets/service-widget/service-widget.component';
import { SocialWidgetComponent } from './components/homepage-widgets/social-widget/social-widget.component';
import { WarrantyWidgetComponent } from './components/homepage-widgets/warranty-widget/warranty-widget.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { JobDetailsComponent } from './components/job/job-details/job-details.component';
import { JobEditModalComponent } from './components/job/job-edit-modal/job-edit-modal.component';
import { JobComponent } from './components/job/job.component';
import { PrintableJobComponent } from './components/job/printable-job/printable-job.component';
import { LoginExistingComponent } from './components/login-existing/login-existing.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { Pro3ListComponent } from './components/pro3-list/pro3-list.component';
import {
  ProductSelectorContainerComponent,
} from './components/product-selector-container/product-selector-container.component';
import {
  ProductSelectorComponent,
} from './components/product-selector-container/product-selector/product-selector.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordModalComponent } from './components/reset-password-modal/reset-password-modal.component';
import {
  ResetPasswordResponseModalComponent,
} from './components/reset-password-response-modal/reset-password-response-modal.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ReviewInvitationComponent } from './components/review-invitation/review-invitation.component';
import { DemokitRedeemComponent } from './components/rewards/demokit-redeem/demokit-redeem.component';
import { RewardsComponent } from './components/rewards/rewards.component';
import { SparesRedeemComponent } from './components/rewards/spares-redeem/spares-redeem.component';
import { VoucherRedeemComponent } from './components/rewards/voucher-redeem/voucher-redeem.component';
import { RoiCalculatorComponent } from './components/roi-calculator/roi-calculator.component';
import { InviteModalComponent } from './components/sendemail/invite-modal/invite-modal.component';
import {
  AddServiceRequestModalComponent,
} from './components/service-dashboard/add-service-request-modal/add-service-request-modal.component';
import {
  AddServiceRequestComponent,
} from './components/service-dashboard/add-service-request/add-service-request.component';
import { FlagUpdateModalComponent } from './components/service-dashboard/flag-update-modal/flag-update-modal.component';
import { ServiceDashboardComponent } from './components/service-dashboard/service-dashboard.component';
import {
  ServiceRecordModalComponent,
} from './components/service-dashboard/service-record-modal/service-record-modal.component';
import { ServiceRecordComponent } from './components/service-dashboard/service-record/service-record.component';
import { SocialHubComponent } from './components/social-hub/social-hub.component';
import { SubmissionSelectionComponent } from './components/submission-selection/submission-selection.component';
import { SystemDownComponent } from './components/system-down/system-down.component';
import { SystemMessageModalComponent } from './components/system-message-modal/system-message-modal.component';
import { TcAppLoginComponent } from './components/tc-app-login/tc-app-login.component';
import { TcPortalLoginComponent } from './components/tc-portal-login/tc-portal-login.component';
import { TrainingComponent } from './components/training/training.component';
import { OptinComponent } from './components/user-optin/optin/optin.component';
import { UserOptinComponent } from './components/user-optin/user-optin.component';
import { ProfileDetailsComponent } from './components/user-profile/profile-details/profile-details.component';
import { ProfileEditModalComponent } from './components/user-profile/profile-edit-modal/profile-edit-modal.component';
import { ProfileHeaderComponent } from './components/user-profile/profile-header/profile-header.component';
import { ProfilePercentageComponent } from './components/user-profile/profile-percentage/profile-percentage.component';
import { ProfilePhotoUploadComponent } from './components/user-profile/profile-photo-upload/profile-photo-upload.component';
import {
  UserEditPasswordModalComponent,
} from './components/user-profile/user-edit-password-modal/user-edit-password-modal.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { WarrantyListComponent } from './components/warranty-list/warranty-list.component';
import { WarrantyStatisticsComponent } from './components/warranty-statistics/warranty-statistics.component';
import { PrintableWarrantyComponent } from './components/warranty/printable-warranty/printable-warranty.component';
import { WarrantyDetailsComponent } from './components/warranty/warranty-details/warranty-details.component';
import { WarrantyEditModalComponent } from './components/warranty/warranty-edit-modal/warranty-edit-modal.component';
import { WarrantyComponent } from './components/warranty/warranty.component';
import { ExternalLinks, SocialItems } from './models/main';
import { NavItems } from './models/nav-items';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FromNowPipe } from './pipes/from-now.pipe';
import { WarrantyPointsPipe } from './pipes/warranty-points.pipe';
import { AdminCompanyService } from './services/admin-company.service';
import { AdminInstallerService } from './services/admin-installer.service';
import { AdminPro3Service } from './services/admin-pro3.service';
import { AdminService } from './services/admin.service';
import { ApiSenseService } from './services/api-sense.service';
import { ApiService } from './services/api.service';
import { BusinessToolsService } from './services/business-tools.service';
import { ContentService } from './services/content.service';
import { CountryService } from './services/country.service';
import { DiaryService } from './services/diary.service';
import { EmailService } from './services/email.service';
import { FeatureService } from './services/feature.service';
import { InvitationService } from './services/invitation.service';
import { JobService } from './services/job.service';
import { LoginService } from './services/login.service';
import { PopupService } from './services/popup.service';
import { PostcodeLookupService } from './services/postcode-lookup.service';
import { Pro3Service } from './services/pro3.service';
import { ProductSelectorService } from './services/product-selector.service';
import { RegisterService } from './services/register.service';
import { ResourceService } from './services/resource.service';
import { RewardsService } from './services/rewards.service';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';
import { ServiceRecordService } from './services/service-record.service';
import { SocialService } from './services/social.service';
import { SystemMessageService } from './services/system-message.service';
import { WarrantyService } from './services/warranty.service';
import { SharedModule } from './shared/shared.module';
import { AdminCompanyEffects } from './state-management/effects/admin-companies.effects';
import { AdminEventsEffects } from './state-management/effects/admin-events.effects';
import { AdminJobsEffects } from './state-management/effects/admin-jobs.effects';
import { AdminPro3Effects } from './state-management/effects/admin-pro3.effects';
import { AdminResourcesEffects } from './state-management/effects/admin-resources.effects';
import { AdminRewardEffects } from './state-management/effects/admin-rewards.effects';
import { AdminStatsEffects } from './state-management/effects/admin-stats.effects';
import { AdminUserEffects } from './state-management/effects/admin-users.effects';
import { BusinessToolsEffects } from './state-management/effects/business-tools.effects';
import { ContentPagesEffects } from './state-management/effects/content-pages.effects';
import { CountryEffects } from './state-management/effects/country.effects';
import { CustomerSuggestionEffects } from './state-management/effects/customer-suggestion.effects';
import { DiaryEffects } from './state-management/effects/diary.effects';
import { EmailEffects } from './state-management/effects/email.effects';
import { FeatureEffects } from './state-management/effects/feature.effects';
import { InvitationEffects } from './state-management/effects/invitation.effects';
import { LoginEffects } from './state-management/effects/login.effects';
import { PopupEffects } from './state-management/effects/popup.effects';
import { PostcodeLookupEffects } from './state-management/effects/postcode-lookup.effects';
import { Pro3Effects } from './state-management/effects/pro3.effects';
import { ProductsEffects } from './state-management/effects/products.effects';
import { RegisterJobEffects } from './state-management/effects/register-job.effects';
import { RegisterWarrantyEffects } from './state-management/effects/register-warranty.effects';
import { RegistrationEffects } from './state-management/effects/registration.effects';
import { ResourceEffects } from './state-management/effects/resource.effects';
import { RewardsEffects } from './state-management/effects/rewards.effects';
import { ServiceRecordEffects } from './state-management/effects/service-record.effects';
import { SocialEffects } from './state-management/effects/social.effects';
import { SystemMessageEffects } from './state-management/effects/system-message.effects';
import { UserSuggestionEffects } from './state-management/effects/user-suggestion.effects';
import { WarrantiesEffects } from './state-management/effects/warranties.effects';
import { WarrantyStatsEffects } from './state-management/effects/warranty-stats.effects';
import { store } from './state-management/store';
import { WatertesterEffects } from './watertest/state-management/effects/watertester.effects';
import { WatertestModule } from './watertest/watertest.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Navigate to an external site
export function navigateExternal(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
  return window.location.href = (route.data as any).externalUrl;
}

// Only use Sentry in production mode
export function errorHandlerFactory(): SentryErrorHandlerService | ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandlerService();
  }
  return new ErrorHandler();
}

// Main app module definition
// @ts-ignore
@NgModule({
  declarations: [

    // Top-level component
    AppComponent,

    // App components
    ActivateProfileComponent,
    AddServiceRequestComponent,
    AddServiceRequestModalComponent,
    BatteryIconComponent,
    BusinessToolsComponent,
    ConfirmPromptComponent,
    ContactUsComponent,
    ContentArchiveComponent,
    ContentItemComponent,
    ContentListComponent,
    DemokitRedeemComponent,
    DiaryCalendarComponent,
    DiaryComponent,
    DiaryEventComponent,
    DiaryListEventsComponent,
    DiaryListEventsModalComponent,
    DiarySingleEventComponent,
    FileDownloadComponent,
    FileUploadComponent,
    FlagUpdateModalComponent,
    GenericModalComponent,
    HomepageComponent,
    JobComponent,
    JobDetailsComponent,
    JobEditModalComponent,
    LoginComponent,
    LoginExistingComponent,
    MagnetiteBarComponent,
    MessagePanelComponent,
    PageContentComponent,
    PageNotFoundComponent,
    Pro3ListComponent,
    PostcodeLookupModalComponent,
    PrintableJobComponent,
    PrintableWarrantyComponent,
    ProductSelectorComponent,
    ProductSelectorContainerComponent,
    OptinComponent,
    ProfileDetailsComponent,
    ProfileEditModalComponent,
    ProfileHeaderComponent,
    ProfilePercentageComponent,
    ProfilePhotoUploadComponent,
    PromotionModalComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ResetPasswordModalComponent,
    ResetPasswordResponseModalComponent,
    RewardsComponent,
    RichEditorComponent,
    RoiCalculatorComponent,
    ServiceDashboardComponent,
    ServiceRecordComponent,
    ServiceRecordModalComponent,
    SocialHubComponent,
    SparesRedeemComponent,
    SubmissionSelectionComponent,
    SystemDownComponent,
    TrainingComponent,
    TruequoteComponent,
    TradehelpComponent,
    UserEditPasswordModalComponent,
    UserOptinComponent,
    UserProfileComponent,
    VideoComponent,
    VoucherRedeemComponent,
    WarrantyComponent,
    WarrantyDetailsComponent,
    WarrantyEditModalComponent,
    WarrantyListComponent,
    WarrantyStatisticsComponent,
    YoutubeModalComponent,

    // App widgets
    BusinessToolsWidgetComponent,
    DiaryWidgetComponent,
    ProductWidgetComponent,
    ProfileWidgetComponent,
    ResourceWidgetComponent,
    RewardsWidgetComponent,
    RoiWidgetComponent,
    ServiceWidgetComponent,
    SocialWidgetComponent,
    WarrantyWidgetComponent,

    // Admin components
    AdminContentComponent,
    AdminContentPageEditModalComponent,
    AdminContentPagesComponent,
    AdminContentResourceEditModalComponent,
    AdminContentResourcesComponent,
    AdminDashboardComponent,
    AdminEventsComponent,
    AdminInstallerJobsComponent,
    AdminPro3AlertDetailModalComponent,
    AdminPro3AlertListComponent,
    AdminPro3AlertRowComponent,
    AdminPro3Component,
    AdminPro3DeviceDetailModalComponent,
    AdminPro3DeviceFilterPanelComponent,
    AdminPro3DeviceListComponent,
    AdminPro3DeviceRowComponent,
    AdminPro3DeviceStatusComponent,
    AdminPro3RegisterWarrantyComponent,
    AdminPro3NoteAlertComponent,
    AdminPromotionsCreateModalComponent,
    AdminPromotionsViewModalComponent,
    AdminRewardsComponent,
    AdminRewardsEditModalComponent,
    AdminUserEditModalComponent,
    AdminUsersComponent,
    AdminUserPointsReportModalComponent,

    //Pipes and useful bits:
    DateFormatPipe,
    DateTimeFormatPipe,
    FilterPipe,
    FromNowPipe,
    WarrantyPointsPipe,

    FilterSliderComponent,

    ReviewInvitationComponent,

    InviteModalComponent,

    TcPortalLoginComponent,

    TcAppLoginComponent,

    SystemMessageModalComponent
  ],

  entryComponents: [
    // All modal components
    AddServiceRequestModalComponent,
    AdminContentPageEditModalComponent,
    AdminContentResourceEditModalComponent,
    AdminPro3AlertDetailModalComponent,
    AdminPro3DeviceDetailModalComponent,
    AdminPro3RegisterWarrantyComponent,
    AdminPro3NoteAlertComponent,
    AdminPromotionsCreateModalComponent,
    AdminPromotionsViewModalComponent,
    AdminRewardsEditModalComponent,
    AdminUserEditModalComponent,
    AdminUserPointsReportModalComponent,
    ConfirmPromptComponent,
    DiaryListEventsModalComponent,
    DiarySingleEventComponent,
    FlagUpdateModalComponent,
    GenericModalComponent,
    JobEditModalComponent,
    PostcodeLookupModalComponent,
    ProfileEditModalComponent,
    PromotionModalComponent,
    ResetPasswordModalComponent,
    ResetPasswordResponseModalComponent,
    ServiceRecordModalComponent,
    UserEditPasswordModalComponent,
    WarrantyEditModalComponent,
    YoutubeModalComponent,
    InviteModalComponent,
    SystemMessageModalComponent
  ],

  imports: [
    //google maps
    AgmCoreModule.forRoot({
      apiKey: environment.api.agm_key
    }),

    // Base Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    SharedModule,

    // Third-party modules
    CalendarModule.forRoot(),
    ChartsModule,
    ClipboardModule,
    DataTablesModule,
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons,
    }),

    // Angular Material modules
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule,

    // Routing
    appRouting,

    // Store module
    StoreModule.forRoot(store),

    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name: 'ADEY ProClub Devtools'
    }),

    // Translation module (ngx-translate)
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    // Effects module (containing all app effects)
    EffectsModule.forRoot([
      AdminCompanyEffects,
      AdminEventsEffects,
      AdminPro3Effects,
      AdminJobsEffects,
      AdminResourcesEffects,
      AdminRewardEffects,
      AdminStatsEffects,
      AdminUserEffects,
      BusinessToolsEffects,
      ContentPagesEffects,
      CountryEffects,
      DiaryEffects,
      EmailEffects,
      FeatureEffects,
      LoginEffects,
      PopupEffects,
      PostcodeLookupEffects,
      Pro3Effects,
      ProductsEffects,
      RegisterJobEffects,
      RegisterWarrantyEffects,
      RegistrationEffects,
      ResourceEffects,
      RewardsEffects,
      ServiceRecordEffects,
      SocialEffects,
      UserSuggestionEffects,
      CustomerSuggestionEffects,
      WarrantiesEffects,
      WarrantyStatsEffects,
      InvitationEffects,
      WatertesterEffects,
      SystemMessageEffects
    ]),

    WatertestModule,
  ],

  providers: [
    // App services
    AdminCompanyService,
    AdminInstallerService,
    AdminPro3Service,
    AdminService,
    ApiService,
    BusinessToolsService,
    ContentService,
    CountryService,
    DiaryService,
    EmailService,
    FeatureService,
    ExternalLinks,
    JobService,
    LoginService,
    NavItems,
    PopupService,
    PostcodeLookupService,
    Pro3Service,
    ProductSelectorService,
    RegisterService,
    ResourceService,
    RewardsService,
    ServiceRecordService,
    SocialItems,
    SocialService,
    WarrantyService,
    InvitationService,
    SystemMessageService,
    ApiSenseService,

    // Sentry error monitoring
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },

    {
      provide: 'externalUrlRedirectResolver',
      useValue: navigateExternal,
    }

  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
