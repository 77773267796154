import {
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { FetchUserPointsHistoryRequestAction } from '../../../../state-management/actions/admin-rewards';
import { StoreState } from '../../../../state-management/store';
import { State as AdminRewardsState } from '../../../../state-management/reducers/admin-rewards';
import { PointsHistory } from '../../../../models/admin-rewards';
import { ExportToCsv } from 'export-to-csv';
import { State as RegisterWarrantyState } from '../../../../state-management/reducers/register-warranty';
import { ProductType } from '../../../../models/warranty';

@Component({
  selector: 'app-admin-promotions-create-modal',
  templateUrl: './admin-user-points-report-modal.component.html',
  styleUrls: ['./admin-user-points-report-modal.component.scss']
})
export class AdminUserPointsReportModalComponent implements OnDestroy {
  private adminRewards$: Observable<AdminRewardsState>;
  private adminRewardsSub$: Subscription = null;
  public errors: string = null;
  private csvExporter: any;
  public history: PointsHistory[] = [];
  public products: any = [];
  private products$: Observable<RegisterWarrantyState>;
  private productsSub$: Subscription = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AdminUserPointsReportModalComponent>,
    private store: Store<StoreState>,
  ) {
    this.adminRewards$ = this.store.select('adminRewards');
    this.adminRewardsSub$ = this.adminRewards$.subscribe((state: AdminRewardsState) => {
      if (state.type === 'FETCH_USER_POINTS_HISTORY_RESPONSE') {
        this.errors = state.error;
        this.history = state.pointsHistory;
        this.history.map(h => {
          const prod = this.products.products.find((p: ProductType) => {
            return p.id === h.productId;
          });
          h.productId = prod ? prod.name : null;
        });
      }
    });
    this.products$ = this.store.select('registerWarranty');
    this.productsSub$ = this.products$.subscribe((state: RegisterWarrantyState) => {
      this.products = state.products;
    });
    this.store.dispatch(new FetchUserPointsHistoryRequestAction({ id: data }));
  }

  ngOnDestroy() {
    if (this.adminRewardsSub$) {
      this.adminRewardsSub$.unsubscribe();
    }
    if (this.productsSub$) {
      this.productsSub$.unsubscribe();
    }
  }

  public getCSV(): void {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers: ['Date', 'Product', 'Serial number', 'Points awarded', 'Points promotion', 'Promotion code'],
    };
    this.csvExporter = new ExportToCsv(options);
    this.csvExporter.generateCsv(this.history);
  }
}
