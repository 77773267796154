import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Injectable()
export class CompanyInviteMemberService {

  constructor(
      private apiService: ApiService
  ) { }

  SendInvite(email) {
    return this.apiService.apiGet('/watertest/send-invite?email=' + email);
  }
}
