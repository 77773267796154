<h2 matDialogTitle>Add service request</h2>

<mat-dialog-content>
  <app-add-service-request
    [showSubmit]="false"
    (onSave)="handleSubmitForm($event)"
  >
  </app-add-service-request>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button mat-button (click)="onSaveClick()">Add service request</button>
</mat-dialog-actions>
