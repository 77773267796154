/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-record-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/common";
import * as i8 from "../service-record/service-record.component.ngfactory";
import * as i9 from "../service-record/service-record.component";
import * as i10 from "@angular/forms";
import * as i11 from "./service-record-modal.component";
var styles_ServiceRecordModalComponent = [i0.styles];
var RenderType_ServiceRecordModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceRecordModalComponent, data: {} });
export { RenderType_ServiceRecordModalComponent as RenderType_ServiceRecordModalComponent };
function View_ServiceRecordModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Edit job record"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_ServiceRecordModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["View job record"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_ServiceRecordModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSaveClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, 0, ["Save changes"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
export function View_ServiceRecordModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { compDetails: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceRecordModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceRecordModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-service-record", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.handleSubmitForm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ServiceRecordComponent_0, i8.RenderType_ServiceRecordComponent)), i1.ɵdid(11, 638976, [[1, 4]], 0, i9.ServiceRecordComponent, [i10.FormBuilder], { serviceRecord: [0, "serviceRecord"], readOnly: [1, "readOnly"], showSubmit: [2, "showSubmit"] }, { onSave: "onSave" }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(15, 0, null, null, 10, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).dialogRef.close(i1.ɵnov(_v, 20).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), i1.ɵdid(20, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceRecordModalComponent_3)), i1.ɵdid(24, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.viewMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.viewMode; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data.serviceRecord; var currVal_3 = _co.viewMode; var currVal_4 = false; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4); var currVal_7 = false; _ck(_v, 20, 0, currVal_7); var currVal_8 = !_co.viewMode; _ck(_v, 24, 0, currVal_8); }, function (_ck, _v) { var currVal_5 = (i1.ɵnov(_v, 19).disabled || null); var currVal_6 = i1.ɵnov(_v, 20).ariaLabel; _ck(_v, 18, 0, currVal_5, currVal_6); }); }
export function View_ServiceRecordModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-record-modal", [], null, null, null, View_ServiceRecordModalComponent_0, RenderType_ServiceRecordModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.ServiceRecordModalComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServiceRecordModalComponentNgFactory = i1.ɵccf("app-service-record-modal", i11.ServiceRecordModalComponent, View_ServiceRecordModalComponent_Host_0, {}, {}, []);
export { ServiceRecordModalComponentNgFactory as ServiceRecordModalComponentNgFactory };
