import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-rewards';
var AdminRewardEffects = /** @class */ (function () {
    function AdminRewardEffects(actions$, service) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.addAdminPromotion$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_PROMOTION_REQUEST)
            .switchMap(function (req) {
            return _this.service.addPromotion(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminPromotionResponseAction(res);
            });
        });
        this.deleteAdminPromotion$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_PROMOTION_REQUEST)
            .switchMap(function (req) {
            return _this.service.deletePromotion(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminPromotionResponseAction(res);
            });
        });
        this.fetchAdminPromotions$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_PROMOTIONS_REQUEST)
            .switchMap(function () {
            return _this.service.fetchPromotions()
                .map(function (res) {
                return new ActionTypes.FetchAdminPromotionsResponseAction(res);
            });
        });
        this.fetchUserPointsHistory$ = this.actions$
            .ofType(ActionTypes.FETCH_USER_POINTS_HISTORY_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchUserPointsHistory(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchUserPointsHistoryResponseAction(res);
            });
        });
        /**
         * For an TransferAdminUserPointsRequestAction, call
         * AdminService::updateUserPoints() and dispatch a new
         * TransferAdminUserPointsResponseAction with the response.
         */
        this.transferUserPoints$ = this.actions$
            .ofType(ActionTypes.TRANSFER_ADMIN_USER_POINTS_REQUEST)
            .switchMap(function (req) {
            return _this.service.transferUserPoints(req.payload)
                .map(function (res) {
                return new ActionTypes.TransferAdminUserPointsResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminUserPointsRequestAction, call
         * AdminService::updateUserPoints() and dispatch a new
         * UpdateAdminUserPointsResponseAction with the response.
         */
        this.updateUserPoints$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_USER_POINTS_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateUserPoints(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminUserPointsResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminRewardEffects.prototype, "addAdminPromotion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminRewardEffects.prototype, "deleteAdminPromotion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminRewardEffects.prototype, "fetchAdminPromotions$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminRewardEffects.prototype, "fetchUserPointsHistory$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminRewardEffects.prototype, "transferUserPoints$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminRewardEffects.prototype, "updateUserPoints$", void 0);
    return AdminRewardEffects;
}());
export { AdminRewardEffects };
