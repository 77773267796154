import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { InvitationService } from '../../services/invitation.service';
import * as ActionTypes from '../actions/invitation';
var InvitationEffects = /** @class */ (function () {
    function InvitationEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a ReviewInvitationQueryResponseAction, call service.reviewInvitation() and
         * dispatch a new ReviewInvitationQueryResponseAction with the response.
         */
        this.reviewInvitation$ = this.actions$
            .ofType(ActionTypes.REVIEW_INVITATION_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.reviewInvitation(req.payload)
                .map(function (res) {
                return new ActionTypes.ReviewInvitationQueryResponseAction(res);
            });
        });
        /**
         * For a SendInvitationQueryResponseAction, call service.sendInvitation() and
         * dispatch a new SendInvitationQueryResponseAction with the response.
         */
        this.sendInvitation$ = this.actions$
            .ofType(ActionTypes.SEND_INVITATION_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.sendInvitation(req.payload)
                .map(function (res) {
                return new ActionTypes.SendInvitationQueryResponseAction(res);
            });
        });
        /**
         * For a AcceptInvitationQueryResponseAction, call service.acceptInvitation() and
         * dispatch a new AcceptInvitationQueryResponseAction with the response.
         */
        this.acceptInvitation$ = this.actions$
            .ofType(ActionTypes.ACCEPT_INVITATION_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.acceptInvitation(req.payload)
                .map(function (res) {
                return new ActionTypes.AcceptInvitationQueryResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], InvitationEffects.prototype, "reviewInvitation$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], InvitationEffects.prototype, "sendInvitation$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], InvitationEffects.prototype, "acceptInvitation$", void 0);
    return InvitationEffects;
}());
export { InvitationEffects };
