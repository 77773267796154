import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import {UserProfile, OptInItem, AccreditationItem} from '../../../models/user-profile';
import { State as LoginDetailsState }   from '../../../state-management/reducers/login-details';
import { Observable } from 'rxjs/Observable';
import { StoreState } from '../../../state-management/store';
import {
    CheckLoginTokenRequestAction, LoadLoginTokenRequestAction,
    UpdateUserProfileRequestAction
} from '../../../state-management/actions/login-details';
import {CurrentUser} from "../../../models/login";

@Component({
    selector: 'app-optin',
    templateUrl: './optin.component.html',
    styleUrls: ['./optin.component.scss']
})
export class OptinComponent implements OnInit {

    // Store states and Subscriptions
    public loginDetails$:   Observable<LoginDetailsState>;

    // Flag to show if form has been submitted
    public submitted: boolean = false;

    // Validation error to display
    public errors: string = null;

    // FormGroup for the profile form
    public fg: FormGroup = null;

    public error: any;

    // User profile.
    @Input() userProfile: any;

    public token: any;

    constructor(
        private store:  Store<StoreState>,
        private router: Router,
        private _fb:    FormBuilder
    ) {
        this.loginDetails$   = this.store.select('loginDetails');
    }

    ngOnInit() {
        this.buildForm();
    }

    buildForm() {
        if (this.userProfile.optIns.length === 0) {
            this.userProfile.optIns.push({
                offers_by_email: 0,
                offers_by_sms: 0,
                offers_by_telephone: 0,
                information_by_email: 0,
                information_by_sms: 0,
                information_by_telephone: 0,
                add_to_find_an_installer: 0,
            });
        } else {
           this.router.navigate(['/home']);
        }


        this.fg = this._fb.group({
            offers_by_email: [ this.userProfile.optIns[0].offers_by_email, [] ],
            offers_by_sms: [ this.userProfile.optIns[0].offers_by_sms, [] ],
            offers_by_telephone: [ this.userProfile.optIns[0].offers_by_telephone, [] ],
            information_by_email: [ this.userProfile.optIns[0].information_by_email, [] ],
            information_by_sms: [ this.userProfile.optIns[0].information_by_sms, [] ],
            information_by_telephone: [ this.userProfile.optIns[0].information_by_telephone, [] ],
            add_to_find_an_installer: [ this.userProfile.optIns[0].add_to_find_an_installer, [ ] ]
        });
    }

    onSubmitForm() {

        let newUser = false;
        this.submitted = true;
        this.errors = null;

        // if (!this.fg.controls.add_to_find_an_installer.value) {
        //     this.errors = 'This field is required.';
        // } else {
            if (window)
                window.scrollTo(0, 0);

            const optIns = [];

            let optInObject = {
                offers_by_email: this.fg.value.offers_by_email ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                offers_by_sms: this.fg.value.offers_by_sms ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                offers_by_telephone: this.fg.value.offers_by_telephone ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                information_by_email: this.fg.value.information_by_email ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                information_by_sms: this.fg.value.information_by_sms ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                information_by_telephone: this.fg.value.information_by_telephone ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                add_to_find_an_installer: this.fg.value.add_to_find_an_installer === 'yes' ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            }

            optIns.push(optInObject);

            // Attempt to determine if this is from a simple sign on registration.
            if (this.userProfile.firstName == " " && this.userProfile.lastName == " ") {
                newUser = true;
            }

            if (this.fg.valid) {
                const profile = UserProfile.fromFormData(
                    this.userProfile,
                    this.userProfile.accreditations,
                    optIns,
                    true,
                    true
                );

                this.store.dispatch(new UpdateUserProfileRequestAction({profile: profile}));

                if (!newUser)
                    this.router.navigate(['/home']);
                else
                    this.router.navigate(['/register/update']);

            }
        // }
    }
}
