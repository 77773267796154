import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { DiaryService } from '../../services/diary.service';

import * as ActionTypes from '../actions/diary';

import {
  CommitEventResponse,
  DiaryEvent,
  FetchEventsResponse,
  FetchEventTypesResponse,
} from '../../models/diary';

@Injectable()
export class DiaryEffects {
  constructor(
    private service:  DiaryService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchEventsRequestAction, call DiaryService::fetchEvents() and
   * dispatch a new FetchEventsResponseAction with the response.
   */
  @Effect() fetchEventsRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_EVENTS_REQUEST)
    .switchMap((req: ActionTypes.FetchEventsRequestAction): Observable<Action> => {
      return this.service.fetchEvents()
        .map((res: FetchEventsResponse): ActionTypes.FetchEventsResponseAction =>
          new ActionTypes.FetchEventsResponseAction(res)
        );
    });

  /**
   * For an AddEventAction, call DiaryService::addEvent() and dispatch a new
   * CommitEventAction for the DiaryEvent.
   */
  @Effect() addEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_EVENT)
    .switchMap((req: ActionTypes.AddEventAction): Observable<Action> => {
      return this.service.addEvent(req.payload)
        .map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        );
    });

  /**
   * For a BookEventAction, call DiaryService::bookEvent() and dispatch a new
   * CommitEventAction for the DiaryEvent.
   */
  @Effect() bookEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.BOOK_EVENT)
    .switchMap((req: ActionTypes.BookEventAction): Observable<Action> => {
      return this.service.bookEvent(req.payload)
        .map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        );
    });

  /**
   * For a DeleteEventAction, call DiaryService::deleteEvent() and dispatch a
   * new CommitEventAction for the DiaryEvent.
   */
  @Effect() deleteEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_EVENT)
    .switchMap((req: ActionTypes.DeleteEventAction): Observable<Action> => {
      return this.service.deleteEvent(req.payload)
        .map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        );
    });

  /**
   * For a FetchEventTypesRequestAction, call DiaryService::fetchEventTypes()
   * and dispatch a new FetchEventTypesResponseAction with the response.
   */
  @Effect() fetchEventTypes$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_EVENT_TYPES_REQUEST)
    .switchMap((req: ActionTypes.FetchEventTypesRequestAction): Observable<ActionTypes.FetchEventTypesResponseAction> =>
      this.service.fetchEventTypes()
        .map((res: FetchEventTypesResponse): ActionTypes.FetchEventTypesResponseAction =>
          new ActionTypes.FetchEventTypesResponseAction(res)
        )
    );

  /**
   * For an UpdateEventAction, call DiaryService::updateEvent() and dispatch a
   * new CommitEventAction for the DiaryEvent.
   */
  @Effect() updateEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_EVENT)
    .switchMap((req: ActionTypes.UpdateEventAction): Observable<Action> => {
      return this.service.updateEvent(req.payload)
        .map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        );
    });
}
