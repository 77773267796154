/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resultcompanylist.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../tableresults/tableresults.component.ngfactory";
import * as i4 from "../tableresults/tableresults.component";
import * as i5 from "../../services/tableresults.service";
import * as i6 from "../../services/checkboxlist.service";
import * as i7 from "../../services/datatable.service";
import * as i8 from "@angular/router";
import * as i9 from "./resultcompanylist.component";
import * as i10 from "../../services/resultcompanylist.service";
import * as i11 from "../../services/company.service";
var styles_ResultCompanyListComponent = [i0.styles];
var RenderType_ResultCompanyListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResultCompanyListComponent, data: {} });
export { RenderType_ResultCompanyListComponent as RenderType_ResultCompanyListComponent };
function View_ResultCompanyListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Member ", " results"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.memberName; _ck(_v, 1, 0, currVal_0); }); }
function View_ResultCompanyListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Postcode ", " results"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postCode; _ck(_v, 1, 0, currVal_0); }); }
export function View_ResultCompanyListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "div", [["class", "container-fluid company-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 34, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 31, "div", [["class", "content-watertest"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "container-title has-sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", "navigator-watertest-table"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn background-adey-red color-white btn-result"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/watertest/dashboard") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back to dashboard"])), (_l()(), i1.ɵted(-1, null, ["\n        \u00A0\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn background-adey-red color-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.watertestBackResultsCompany() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back to previous page"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(23, 0, null, null, 7, "div", [["class", "container-title has-sub adjust-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultCompanyListComponent_1)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultCompanyListComponent_2)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "app-tableresults", [], null, null, null, i3.View_TableResultsComponent_0, i3.RenderType_TableResultsComponent)), i1.ɵdid(33, 114688, null, 0, i4.TableResultsComponent, [i5.TableResultsService, i6.CheckBoxListService, i7.DatatableService, i8.Router], { search: [0, "search"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        Loading table...\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.type == "member"); _ck(_v, 26, 0, currVal_1); var currVal_2 = (_co.type == "address"); _ck(_v, 29, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.search, ""); _ck(_v, 33, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyName; _ck(_v, 9, 0, currVal_0); }); }
export function View_ResultCompanyListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resultcompanylist", [], null, null, null, View_ResultCompanyListComponent_0, RenderType_ResultCompanyListComponent)), i1.ɵdid(1, 114688, null, 0, i9.ResultCompanyListComponent, [i10.ResultCompanyListService, i11.CompanyService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResultCompanyListComponentNgFactory = i1.ɵccf("app-resultcompanylist", i9.ResultCompanyListComponent, View_ResultCompanyListComponent_Host_0, {}, {}, []);
export { ResultCompanyListComponentNgFactory as ResultCompanyListComponentNgFactory };
