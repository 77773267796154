import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { SocialService } from '../../services/social.service';

import * as ActionTypes from '../actions/social';

import { SocialFeedResponse } from '../../models/social';

@Injectable()
export class SocialEffects {
  constructor(
    private socialService: SocialService,
    private actions$: Actions
  ) {}

  /**
   * For a SocialFeedRequestAction, call SocialService::getSocialFeeds() and
   * dispatch a new SocialFeedResponseAction with the response.
   */
  @Effect() socialFeedRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.SOCIAL_FEED_REQUEST)
    .switchMap((): Observable<Action> => {
      return this.socialService.getSocialFeeds()
        .map((res: SocialFeedResponse): ActionTypes.SocialFeedResponseAction => new ActionTypes.SocialFeedResponseAction(res));
    });

}
