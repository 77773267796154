/**
 * Provides AdminCompanyEffects
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import * as tslib_1 from "tslib";
import * as ActionTypes from '../actions/admin-companies';
import { AdminCompanyService } from '../../services/admin-company.service';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
/**
 * NGRX Actions for the Companies
 */
var AdminCompanyEffects = /** @class */ (function () {
    /**
     * Set up required injected thingies
     *
     * @param adminCompanyService
     * @param actions$
     */
    function AdminCompanyEffects(adminCompanyService, actions$) {
        var _this = this;
        this.adminCompanyService = adminCompanyService;
        this.actions$ = actions$;
        this.getListOfCompanies = this.actions$
            .ofType(ActionTypes.LOAD_COMPANIES)
            .switchMap(function (action) {
            return _this.adminCompanyService.getCompanyList(action.payload)
                .map(function (result) {
                return new ActionTypes.AdminCompaniesLoadCompanyActionSuccess(result);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminCompanyEffects.prototype, "getListOfCompanies", void 0);
    return AdminCompanyEffects;
}());
export { AdminCompanyEffects };
