/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./page-content.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@ngrx/store";
var styles_PageContentComponent = [i0.styles];
var RenderType_PageContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageContentComponent, data: {} });
export { RenderType_PageContentComponent as RenderType_PageContentComponent };
function View_PageContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("page-content-field " + _co.field); var currVal_1 = _co.fieldValue; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PageContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageContentComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pageModel && _co.fieldValue); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-content", [], null, null, null, View_PageContentComponent_0, RenderType_PageContentComponent)), i1.ɵdid(1, 245760, null, 0, i3.PageContentComponent, [i4.DomSanitizer, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageContentComponentNgFactory = i1.ɵccf("app-page-content", i3.PageContentComponent, View_PageContentComponent_Host_0, { page: "page", field: "field" }, {}, []);
export { PageContentComponentNgFactory as PageContentComponentNgFactory };
