import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngrx/store';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { StoreState } from '../../../state-management/store';
import { State as AdminRewardsState } from '../../../state-management/reducers/admin-rewards';

import {
  AddAdminPromotionRequestAction,
  DeleteAdminPromotionRequestAction,
  FetchAdminPromotionsRequestAction,
  TransferAdminUserPointsRequestAction,
  UpdateAdminUserPointsRequestAction,
} from '../../../state-management/actions/admin-rewards';

import {
  FetchAdminUsersRequestAction,
} from '../../../state-management/actions/admin-users';

import { AdminUser, UserPoints } from '../../../models/admin-users';

import { AdminRewardsEditModalComponent } from './admin-rewards-edit-modal/admin-rewards-edit-modal.component';
import { Promotion } from '../../../models/admin-rewards';
import { AdminPromotionsViewModalComponent } from './admin-promotions-view-modal/admin-promotions-view-modal.component';
import { AdminPromotionsCreateModalComponent } from './admin-promotions-create-modal/admin-promotions-create-modal.component';
import { FetchProductTypesRequestAction } from '../../../state-management/actions/register-warranty';
import { State as RegisterWarrantyState } from '../../../state-management/reducers/register-warranty';
import { ProductType } from '../../../models/warranty';
import { AdminUserPointsReportModalComponent } from './admin-user-points-report-modal/admin-user-points-report-modal.component';

/**
 * Summary
 *    Displays a list of users (searchable) and allows editing and transerring of points
 *
 * Description
 *    Users can be searched for by name and address and results are displayed
 *    in a paginated list. Each user's reward points can be edited and points
 *    can be transferred from one user to another.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-rewards',
  templateUrl: './admin-rewards.component.html',
  styleUrls: ['./admin-rewards.component.scss']
})
export class AdminRewardsComponent implements OnDestroy, OnInit {
  // "adminRewards" state from store
  public adminRewards$: Observable<AdminRewardsState>;
  public products$: Observable<RegisterWarrantyState>;

  // Subscription to above Observable
  private adminRewardsSub$: Subscription = null;
  private productsSub$: Subscription = null;

  // Form groups
  public fgSearch: FormGroup;
  public fgTransfer: FormGroup;

  // Reference to rewards edit dialog
  private dialogRef_edit;

  // Local pagination variables (from store)
  public pageNum: number = 1;
  public totalPages: number = 0;
  // Paginated page numbers
  public pagination: number[] = [];

  // Users selected for point transfer
  public transferFrom: AdminUser = null;
  public transferTo: AdminUser = null;

  // Errors to be displayed in transfer form
  public transferErrors: string = null;

  public promotions: Promotion[] = [];
  private products: ProductType[] = [];

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private store: Store<StoreState>,
  ) {
    this.adminRewards$ = this.store.select('adminRewards');
    this.products$ = this.store.select('registerWarranty');

    this.fgSearch = this.fb.group({
      userName: null,
      userAddress: null,
    });

    this.fgTransfer = this.fb.group({
      transferAmount: [0, [Validators.required]],
    });
  }

  ngOnInit() {
    // Update local pagination variables when the state changes
    this.adminRewardsSub$ = this.adminRewards$.subscribe((state: AdminRewardsState) => {
      this.pageNum = state.pageNum;
      this.totalPages = state.totalPages;
      this.promotions = state.promotions;
      this.paginate();
      if (state.type === 'ADD_ADMIN_PROMOTION_RESPONSE' && !state.error) {
        this.store.dispatch(new FetchAdminPromotionsRequestAction());
      }
      if (state.error) {
        this.snackBar.open(state.error, 'Close');
      }
    });

    this.productsSub$ = this.products$.subscribe((state: RegisterWarrantyState) => {
      this.products = state.products;
    });

    this.store.dispatch(new FetchAdminPromotionsRequestAction());
    this.store.dispatch(new FetchProductTypesRequestAction());
    this.performSearch();
  }

  ngOnDestroy() {
    if (this.adminRewardsSub$) {
      this.adminRewardsSub$.unsubscribe();
    }
    if (this.productsSub$) {
      this.productsSub$.unsubscribe();
    }
  }

  /**
   * Sets the pagination variable based on the current and total pages
   */
  paginate() {
    const pagination = [];

    let maxNumbersToPaginate = 11;

    if (this.pageNum > 1) {
      let timesNumbersAdded = 0;

      for (let i = this.pageNum; i > 1; i--) {
        if (timesNumbersAdded >= 6) break;

        timesNumbersAdded++;
        maxNumbersToPaginate--;
        pagination.unshift(this.pageNum - timesNumbersAdded);
      }
    }

    pagination.push(this.pageNum);

    if (this.totalPages > this.pageNum) {
      for (let i = this.pageNum + 1; i < this.totalPages + 1; i++) {
        if (maxNumbersToPaginate === 0) break;

        maxNumbersToPaginate--;
        pagination.push(i);

      }
    }

    this.pagination = pagination;
  }


  /**
   * Opens AdminRewardsEditModalComponent to edit the specified user's reward
   * points
   *
   * @param {AdminUser} user
   */
  editUser(user: AdminUser) {
    this.dialogRef_edit = this.dialog.open(AdminRewardsEditModalComponent, {
      data: {
        user,
      },
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new UpdateAdminUserPointsRequestAction({ user, points: result as UserPoints }));
    });
  }

  /**
   * Fetches a specific page
   */
  goToPage(page: number) {
    this.pageNum = page;
    this.performSearch();
  }

  /**
   * Loads the next page of results or a set amount of pages forward
   */
  pageNext(pages: number = 1) {
    this.pageNum = Math.min(this.totalPages, this.pageNum + pages);
    this.performSearch();
  }

  /**
   * Loads the previous page of results or a set amount of pages backward
   */
  pagePrev(pages: number = 1) {
    this.pageNum = Math.max(1, this.pageNum - pages);
    this.performSearch();
  }

  /**
   * Performs a search for users by name and/or address
   */
  performSearch() {
    this.store.dispatch(
      new FetchAdminUsersRequestAction({
        userType: 0,
        userName: this.fgSearch.value.userName,
        userAddress: this.fgSearch.value.userAddress,
        perPage: 12,
        page: this.pageNum,
      })
    );
  }

  /**
   * Initiates a transfer of points from one user to another
   */
  performTransfer() {
    if (!this.transferFrom) {
      this.transferErrors = 'Please select a user from which to transfer points';
      return;
    }
    if (!this.transferTo) {
      this.transferErrors = 'Please select a user to which to transfer points';
      return;
    }

    const amount: number = parseInt(this.fgTransfer.value.transferAmount, 10);
    if (!this.fgTransfer.valid || amount < 0 || amount > this.transferFrom.points.earned - this.transferFrom.points.spent) {
      this.transferErrors = 'Please enter a valid number of points to transfer';
      return;
    }

    this.transferErrors = null;

    this.store.dispatch(new TransferAdminUserPointsRequestAction({
      userFrom: this.transferFrom,
      userTo: this.transferTo,
      amount,
    }));

    this.transferFrom = null;
    this.transferTo = null;
    this.fgTransfer.controls.transferAmount.setValue(0);
  }

  /**
   * Sets the specified user as the sender of reward points
   *
   * @param {AdminUser} user
   */
  setTransferFrom(user: AdminUser) {
    this.transferFrom = user;
  }

  /**
   * Sets the specified user as the recipient of reward points
   *
   * @param {AdminUser} user
   */
  setTransferTo(user: AdminUser) {
    this.transferTo = user;
  }

  public createPromotion(): void {
    this.dialogRef_edit = this.dialog.open(AdminPromotionsCreateModalComponent, {
      data: this.products,
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe((promotion: Promotion) => {
      if (promotion) {
        this.store.dispatch(new AddAdminPromotionRequestAction({ promotion }));
      }
    });
  }

  /**
   * 
   * @param promotion 
   */
  public viewPromotion(selectedPromotion: Promotion): void {
    this.dialogRef_edit = this.dialog.open(AdminPromotionsViewModalComponent, {
      data: {
        ...this.products,
        promotion: selectedPromotion,
      },
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe((promotion: Promotion) => {
      if (promotion) {
        this.store.dispatch(new DeleteAdminPromotionRequestAction({ id: promotion.id }));
      }
    });
  }

  /**
   * 
   * @param user 
   */
  public viewUserPointsReport(user: AdminUser): void {
    this.dialogRef_edit = this.dialog.open(AdminUserPointsReportModalComponent, {
      data: user.id,
      panelClass: 'feature-modal-dialog',
    });

    // this.dialogRef_edit.afterClosed().subscribe((promotion: Promotion) => {
    //   if (promotion) {
    //     this.store.dispatch(new AddAdminPromotionRequestAction({ promotion }));
    //   }
    // });
  }
}
