import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';

import { MatDialog }    from '@angular/material';
import { Store }        from '@ngrx/store';
import { Observable }   from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


import { StoreState }                   from '../../../../state-management/store';
import { State as AdminResourcesState } from '../../../../state-management/reducers/admin-resources';

import {
  AddAdminResourceRequestAction,
  DeleteAdminResourceRequestAction,
  FetchAdminResourcesRequestAction,
  UpdateAdminResourceRequestAction,
} from '../../../../state-management/actions/admin-resources';

import { ResourceItem } from '../../../../models/resources';

import { AdminContentResourceEditModalComponent } from './admin-content-resource-edit-modal/admin-content-resource-edit-modal.component';


/**
 * Summary
 *    Displays a list of resources to be managed
 *
 * Description
 *    Allows resources to be listed in a paginated list for a specified page.
 *    Each resource can be edited and deleted and new resources can be added.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-content-resources',
  templateUrl: './admin-content-resources.component.html',
  styleUrls: ['./admin-content-resources.component.scss']
})
export class AdminContentResourcesComponent implements OnDestroy, OnInit {

  // "adminResources" state from store
  public adminResources$: Observable<AdminResourcesState>;

  // Subscription to above Observable
  private adminResourcesSub$: Subscription = null;

  // Form for selecting page
  public fgPageSelect: FormGroup;

  // Paginated page numbers
  public pagination: number[] = [];

  // Reference to the edit dialog
  private dialogRef_edit;

  // Local pagination variables (from store)
  public pageNum:    number = 1;
  public totalPages: number = 0;


  constructor(
    private dialog: MatDialog,
    private fb:     FormBuilder,
    private store:  Store<StoreState>,
  ) {
    this.adminResources$ = this.store.select('adminResources');

    this.fgPageSelect = this.fb.group({
      pageName: 'Resources',
    });

    // Whenever the page select changes, fetch the resources for that page
    this.fgPageSelect.get('pageName').valueChanges.subscribe((value: string) => {
      this.fetchResources(value);
    });
  }

  ngOnInit() {

    // Update pagination variables when the store state changes
    this.adminResourcesSub$ = this.adminResources$.subscribe((state: AdminResourcesState) => {
      this.pageNum    = state.pageNum;
      this.totalPages = state.totalPages;
      this.paginate();
    });

    this.fetchResources(this.fgPageSelect.value.pageName);
  }

  ngOnDestroy() {
    if (this.adminResourcesSub$)
      this.adminResourcesSub$.unsubscribe();
  }


  /**
   * Sets the pagination variable based on the current and total pages
   */
  paginate() {
    const pagination = [];

    let maxNumbersToPaginate = 11;

    if (this.pageNum > 1) {
        let timesNumbersAdded = 0;

        for (let i = this.pageNum; i > 1; i--) {
          if (timesNumbersAdded >= 6) break;

          timesNumbersAdded++;
          maxNumbersToPaginate--;
          pagination.unshift(this.pageNum - timesNumbersAdded);
        }
    }

    pagination.push(this.pageNum);

    if (this.totalPages > this.pageNum) {
      for (let i = this.pageNum + 1; i < this.totalPages + 1; i++) {
        if (maxNumbersToPaginate === 0) break;

        maxNumbersToPaginate--;
        pagination.push(i);

      }
    }

    this.pagination = pagination;
  }


  /**
   * Opens the AdminContentResourceEditModalComponent to add a new resource
   */
  addResource() {
    this.dialogRef_edit = this.dialog.open(AdminContentResourceEditModalComponent, {
      data: {},
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new AddAdminResourceRequestAction({resource: result}));
    });
  }

  /**
   * Asks for confirmation and then deletes the specified ResourceItem
   *
   * @param {ResourceItem} r ResourceItem to delete
   */
  deleteResource(r: ResourceItem) {
    if (confirm(`Are you sure you want to delete the "${r.title}" resource?`))
      this.store.dispatch(new DeleteAdminResourceRequestAction({resource: r}));
  }

  /**
   * Opens the AdminContentResourceEditModalComponent to edit the specified
   * ResourceItem
   *
   * @param {ResourceItem} r ResourceItem to edit
   */
  editResource(r: ResourceItem) {
    this.dialogRef_edit = this.dialog.open(AdminContentResourceEditModalComponent, {
      data: {
        resource: r,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new UpdateAdminResourceRequestAction({resource: result}));
    });
  }

  /**
   * Fetches a page of resources for the specified page name
   *
   * @param {string} page Name of the resource page (e.g. "Infographics")
   */
  fetchResources(page: string) {
    this.store.dispatch(new FetchAdminResourcesRequestAction({
      pageName: page,
      perPage:  12,
      pageNum:  this.pageNum,
    }));
  }

  /**
   * Fetches a specific page
   */
  goToPage(page: number) {
    this.pageNum = page;
    this.fetchResources(this.fgPageSelect.value.pageName);
  }

  /**
   * Loads the next page of resources
   */
  pageNext(page: number = 1) {
    this.pageNum = Math.min(this.totalPages, this.pageNum + page);
    this.fetchResources(this.fgPageSelect.value.pageName);
  }

  /**
   * Loads the previous page of resources
   */
  pagePrev(page: number = 1) {
    this.pageNum = Math.max(1, this.pageNum - page);
    this.fetchResources(this.fgPageSelect.value.pageName);
  }
}
