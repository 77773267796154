/**
 * Provides AdminPro3Effects
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import * as tslib_1 from "tslib";
// Created using PhpStorm
//required classes
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminPro3Service } from '../../services/admin-pro3.service';
import * as ActionTypes from '../actions/admin-pro3';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { AdminInstallerService } from '../../services/admin-installer.service';
/**
 * NGRX Actions for the Pro3 Admin Portal
 */
var AdminPro3Effects = /** @class */ (function () {
    /**
     * Set up required injected thingies
     *
     * @param adminPro3Service
     * @param actions$
     */
    function AdminPro3Effects(adminPro3Service, actions$, adminInstallerService) {
        var _this = this;
        this.adminPro3Service = adminPro3Service;
        this.actions$ = actions$;
        this.adminInstallerService = adminInstallerService;
        this.adminDeviceAlertRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_ALERT_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getDeviceAlerts(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminDeviceAlertResponseAction(res);
            });
        });
        this.adminDeviceAlertExportRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_ALERT_EXPORT_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getDeviceAlerts(req.payload, true)
                .map(function (res) {
                return new ActionTypes.AdminDeviceAlertExportResponseAction(res);
            });
        });
        this.adminDeviceListRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getDeviceList(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminDeviceResponseAction(res);
            });
        });
        this.adminDeviceListExportRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_EXPORT_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getDeviceList(req.payload, true)
                .map(function (res) {
                return new ActionTypes.AdminDeviceExportResponseAction(res);
            });
        });
        this.adminDeviceStatusRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_STATUS_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getPro3Status(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminDeviceStatusResponseAction(res);
            });
        });
        this.adminDeviceDetailRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_DETAIL_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getDeviceDetail(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminDeviceDetailResponseAction(res);
            });
        });
        this.adminDeviceNoteRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DEVICE_NOTE_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.getDeviceNote(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminDeviceNoteResponseAction(res);
            });
        });
        this.adminGetExistingWarranty = this.actions$
            .ofType(ActionTypes.ADMIN_GET_MAGSENSE_WARRANTY_INFO)
            .switchMap(function (req) {
            return _this.adminPro3Service.getWarrantyInfo(req.payload)
                .pipe(map(function (result) { return new ActionTypes.AdminGetMagsenseWarrantyInfoActionSuccess(result); }), catchError(function (err) { return of(new ActionTypes.AdminGetMagsenseWarrantyInfoActionFailed(err)); }));
        });
        this.adminGetInstallerForWarranty = this.actions$
            .ofType(ActionTypes.ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST)
            .switchMap(function (req) {
            return _this.adminInstallerService.findUserByPartial(req.payload)
                .pipe(map(function (result) {
                return new ActionTypes.AdminGetInstallerFromPartialResponse(result.data.pop());
            }));
        });
        this.adminDismissAlertRequest = this.actions$
            .ofType(ActionTypes.ADMIN_DISMISS_ALERT_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.dismissAlert(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminDismissAlertResponseAction(res);
            });
        });
        this.adminRegisterWarrantyRequest = this.actions$
            .ofType(ActionTypes.ADMIN_REGISTER_MAGSENSE_DEVICE_REQUEST)
            .switchMap(function (req) {
            return _this.adminPro3Service.registerWarranty(req.payload).pipe(map(function (res) {
                return new ActionTypes.AdminRegisterMagsenseDeviceResponseAction();
            }), catchError(function (err) { return of(new ActionTypes.AdminRegisterMagsenseDeviceFailureAction(err)); }));
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceAlertRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceAlertExportRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceListRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceListExportRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceStatusRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceDetailRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDeviceNoteRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminGetExistingWarranty", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminGetInstallerForWarranty", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminDismissAlertRequest", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminPro3Effects.prototype, "adminRegisterWarrantyRequest", void 0);
    return AdminPro3Effects;
}());
export { AdminPro3Effects };
