/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * Provides AdminPro3DeviceDetailModalComponent
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".fa.fa-times[_ngcontent-%COMP%], .icon-fitted[_ngcontent-%COMP%]{font-size:36px;padding:5px}.tile[_ngcontent-%COMP%]{font-family:WeissenhofGrotesk-Light,\"Helvetica Neue\",Sans-serif;color:#fff;font-size:large;text-align:center;cursor:pointer}.tile[_ngcontent-%COMP%]   .deviceCount[_ngcontent-%COMP%]{font-size:36px}.blueTile[_ngcontent-%COMP%]{background-color:#280071}.redTile[_ngcontent-%COMP%]{background-color:red}.amberTile[_ngcontent-%COMP%]{background-color:#ffbf00}.greenTile[_ngcontent-%COMP%]{background-color:green}p[_ngcontent-%COMP%]{-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:0;margin-block-end:0}magnetite-bar[_ngcontent-%COMP%]{margin-bottom:10px}textarea[_ngcontent-%COMP%]{background-color:#e4e8eb}"];
export { styles as styles };
