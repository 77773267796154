/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./magnetite-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./magnetite-bar.component";
var styles_MagnetiteBarComponent = [i0.styles];
var RenderType_MagnetiteBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MagnetiteBarComponent, data: {} });
export { RenderType_MagnetiteBarComponent as RenderType_MagnetiteBarComponent };
export function View_MagnetiteBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "magnetite-outer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "bar active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "bar active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "magnetite-inner ", _co.magnetiteLevelClass(_co.magnetite), ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_MagnetiteBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "magnetite-bar", [], null, null, null, View_MagnetiteBarComponent_0, RenderType_MagnetiteBarComponent)), i1.ɵdid(1, 114688, null, 0, i2.MagnetiteBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MagnetiteBarComponentNgFactory = i1.ɵccf("magnetite-bar", i2.MagnetiteBarComponent, View_MagnetiteBarComponent_Host_0, { magnetite: "magnetite" }, {}, []);
export { MagnetiteBarComponentNgFactory as MagnetiteBarComponentNgFactory };
