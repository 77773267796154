import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { ProductSelectorService } from '../../services/product-selector.service';

import * as ActionTypes from '../actions/products';

import { Product } from '../../models/product';

import {
  ProductSelectQueryRequest,
  ProductSelectQueryResponse,
} from '../../models/product-selector';

import {
  ProductCheckSerialRequest,
  ProductCheckSerialResponse,
} from '../../models/product';


@Injectable()
export class ProductsEffects {
  constructor(
    private service:  ProductSelectorService,
    private actions$: Actions
  ) {}

  /**
   * For a ProductSelectQueryRequestAction, call
   * ProductSelectorService::query() and dispatch a new
   * ProductSelectQueryResponseAction with the response.
   */
  @Effect() query$: Observable<Action> = this.actions$
    .ofType(ActionTypes.PRODUCT_SELECT_QUERY_REQUEST)
    .switchMap((req: ActionTypes.ProductSelectQueryRequestAction): Observable<ActionTypes.ProductSelectQueryResponseAction> =>
      this.service.query(req.payload)
        .map((res: ProductSelectQueryResponse): ActionTypes.ProductSelectQueryResponseAction =>
          new ActionTypes.ProductSelectQueryResponseAction(res)
        )
    );
    
  /**
   * For a ProductCheckSerialRequestAction, call
   * ProductSelectorService::checkSerial() and dispatch a new
   * ProductCheckSerialResponseAction with the response.
   */
  @Effect() checkSerial$: Observable<Action> = this.actions$
    .ofType(ActionTypes.PRODUCT_CHECK_SERIAL_REQUEST)
    .switchMap((req: ActionTypes.ProductCheckSerialRequestAction): Observable<ActionTypes.ProductCheckSerialResponseAction> =>
      this.service.checkSerial(req.payload)
        .map((res: ProductCheckSerialResponse): ActionTypes.ProductCheckSerialResponseAction =>
          new ActionTypes.ProductCheckSerialResponseAction(res)
        )
    );
}
