/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./battery-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./battery-icon.component";
var styles_BatteryIconComponent = [i0.styles];
var RenderType_BatteryIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatteryIconComponent, data: {} });
export { RenderType_BatteryIconComponent as RenderType_BatteryIconComponent };
export function View_BatteryIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "", _co.extraClasses, " fa fa-battery-", _co.battLevelClass(_co.battLevel), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_BatteryIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "battery-icon", [], null, null, null, View_BatteryIconComponent_0, RenderType_BatteryIconComponent)), i1.ɵdid(1, 49152, null, 0, i2.BatteryIconComponent, [], null, null)], null, null); }
var BatteryIconComponentNgFactory = i1.ɵccf("battery-icon", i2.BatteryIconComponent, View_BatteryIconComponent_Host_0, { extraClasses: "extraClasses", battLevel: "battLevel" }, {}, []);
export { BatteryIconComponentNgFactory as BatteryIconComponentNgFactory };
