import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/withLatestFrom';

import { Injectable }      from '@angular/core';
import { Action, Store }   from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { StoreState } from '../store';

import { ServiceRecordService } from '../../services/service-record.service';

import * as ActionTypes from '../actions/service-record';

import {
  AddServiceRequestResponse,
  FetchServiceRecordStatsResponse,
  FetchServiceRecordsRequest,
  FetchServiceRecordsResponse,
  SetServiceRecordFlagResponse,
  UpdateServiceRecordResponse,
} from '../../models/service-record';

@Injectable()
export class ServiceRecordEffects {
  constructor(
    private actions$: Actions,
    private service:  ServiceRecordService,
    private store:    Store<StoreState>
  ) {}

  /**
   * For an AddServiceRequestRequestAction, call
   * ServiceRecordsService::addServiceRequest() and dispatch a new
   * AddServiceRequestResponseAction with the response.
   */
  @Effect() addServiceRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_SERVICE_REQUEST_REQUEST)
    .switchMap((req: ActionTypes.AddServiceRequestRequestAction): Observable<ActionTypes.AddServiceRequestResponseAction> =>
      this.service.addServiceRequest(req.payload)
        .map((res: AddServiceRequestResponse): ActionTypes.AddServiceRequestResponseAction =>
          new ActionTypes.AddServiceRequestResponseAction(res)
        )
    );

  /**
   * For a FetchServiceRecordsRequestAction, call
   * ServiceRecordsService::fetchRecords() and dispatch a new
   * FetchServiceRecordsResponseAction with the response.
   */
  @Effect() fetchServiceRecords$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_SERVICE_RECORDS_REQUEST)
    .switchMap((req: ActionTypes.FetchServiceRecordsRequestAction): Observable<ActionTypes.FetchServiceRecordsResponseAction> =>
      this.service.fetchRecords(req.payload)
        .map((res: FetchServiceRecordsResponse): ActionTypes.FetchServiceRecordsResponseAction =>
          new ActionTypes.FetchServiceRecordsResponseAction(res)
        )
    );

  /**
   * For a FetchServiceRecordStatsRequestAction, call
   * ServiceRecordsService::fetchStats() and dispatch a new
   * FetchServiceRecordStatsResponseAction with the response.
   */
  @Effect() fetchServiceRecordStats$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_SERVICE_RECORD_STATS_REQUEST)
    .switchMap((req: ActionTypes.FetchServiceRecordStatsRequestAction): Observable<ActionTypes.FetchServiceRecordStatsResponseAction> =>
      this.service.fetchStats()
        .map((res: FetchServiceRecordStatsResponse): ActionTypes.FetchServiceRecordStatsResponseAction =>
          new ActionTypes.FetchServiceRecordStatsResponseAction(res)
        )
    );

  /**
   * For a NextServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
  @Effect() nextPage$: Observable<Action> = this.actions$
    .ofType(ActionTypes.NEXT_SERVICE_RECORD_PAGE)
    .withLatestFrom(this.store.select('serviceRecord'))
    .map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage:       s.perPage,
        pageNo:        s.currentPage,
        upcoming:      s.upcoming,
        customerQuery: s.customerQuery,
      });
    });

  /**
   * For a NextFiveServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
  @Effect() nextFivePages$: Observable<Action> = this.actions$
    .ofType(ActionTypes.NEXT_FIVE_SERVICE_RECORD_PAGE)
    .withLatestFrom(this.store.select('serviceRecord'))
    .map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage:       s.perPage,
        pageNo:        s.currentPage,
        upcoming:      s.upcoming,
        customerQuery: s.customerQuery,
      });
    });

  /**
   * For a PrevFiveServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
  @Effect() prevFivePages$: Observable<Action> = this.actions$
    .ofType(ActionTypes.PREV_FIVE_SERVICE_RECORD_PAGE)
    .withLatestFrom(this.store.select('serviceRecord'))
    .map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage:       s.perPage,
        pageNo:        s.currentPage,
        upcoming:      s.upcoming,
        customerQuery: s.customerQuery,
      });
    });

  /**
   * For a PrevServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
  @Effect() prevPage$: Observable<Action> = this.actions$
    .ofType(ActionTypes.PREV_SERVICE_RECORD_PAGE)
    .withLatestFrom(this.store.select('serviceRecord'))
    .map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage:       s.perPage,
        pageNo:        s.currentPage,
        upcoming:      s.upcoming,
        customerQuery: s.customerQuery,
      });
    });

  /**
   * For a SetServiceRecordFlagRequestAction, call
   * ServiceRecordService::setFlags() and dispatch a new
   * SetServiceRecordFlagResponseAction with the response.
   *
   * Uses concatMap() so that multiple actions will be processed serially
   * rather than being merged as with switchMap().
   */
  @Effect() setFlags$: Observable<Action> = this.actions$
    .ofType(ActionTypes.SET_SERVICE_RECORD_FLAG_REQUEST)
    .concatMap((req: ActionTypes.SetServiceRecordFlagRequestAction): Observable<ActionTypes.SetServiceRecordFlagResponseAction> =>
      this.service.setFlags(req.payload)
        .map((res: SetServiceRecordFlagResponse): ActionTypes.SetServiceRecordFlagResponseAction =>
          new ActionTypes.SetServiceRecordFlagResponseAction(res)
        )
    );

  /**
   * For an UpdateServiceRecordRequestAction, call
   * ServiceRecordService::update() and dispatch a new
   * UpdateServiceRecordResponseAction with the response.
   */
  @Effect() update$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_SERVICE_RECORD_REQUEST)
    .switchMap((req: ActionTypes.UpdateServiceRecordRequestAction): Observable<ActionTypes.UpdateServiceRecordResponseAction> =>
      this.service.update(req.payload)
        .map((res: UpdateServiceRecordResponse): ActionTypes.UpdateServiceRecordResponseAction =>
          new ActionTypes.UpdateServiceRecordResponseAction(res)
        )
    );
}
