import 'rxjs/add/operator/map';
import 'rxjs/add/observable/empty';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.service';
import { CountryResponse } from '../models/country';

@Injectable()
export class CountryService {

  constructor(
    private apiService: ApiService,
  ) { }

  getCountries(): Observable<CountryResponse> {
    return this.apiService.apiGet('/countries')
      .map((res: any): CountryResponse => {
        return {
          countries: res.resource.map(CountryResponse.fromApiData),
          error: null,
        };
      })
      .catch((err: any): Observable<CountryResponse> => {
        return Observable.of({
          countries: null,
          error: err.error.message,
        });
      });
  }
}
