import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { environment } from '../../../../environments/environment';
import { FetchAdminStatsRequestAction } from '../../../state-management/actions/admin-stats';
import { SystemMessageRequestAction } from '../../../state-management/actions/system-message';
import { State as AdminStatsState } from '../../../state-management/reducers/admin-stats';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { StoreState } from '../../../state-management/store';

/**
* Summary
*    Main admin dashboard component which displays current statistics
*
* @copyright 2017 ReallyB2B Limited
*/
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnDestroy, OnInit {

  @ViewChild('downloadForm') downloadForm: ElementRef;

  public filterForm: FormGroup;

  // Flag to indicate if the form has been submitted
  public submitted: boolean = false;

  // Current date for display
  public currentDate: Date = moment().toDate();

  // Store state and Subscription
  public adminStats$: Observable<AdminStatsState>;
  public loginDetails$: Observable<LoginDetailsState>;
  public adminStatsSub: Subscription;
  public loginDetailsSub: Subscription;

  private loginSub: Subscription = null;

  private Uid = null;

  public regions: any[] = [
    { field: 'unknown', label: 'Unknown' },
    { field: 'scotland', label: 'Scotland' },
    { field: 'north-east', label: 'North East' },
    { field: 'north-west', label: 'North West' },
    { field: 'midlands', label: 'Midlands' },
    { field: 'east-anglia', label: 'East Anglia' },
    { field: 'south-east', label: 'South East' },
    { field: 'south-central', label: 'South Central' },
    { field: 'south-west', label: 'South West' },
  ];

  // Overview fields to display in dashboard
  public overviewStatsFields: any[] = [
    { field: 'totalMembers', label: 'Total potential users', download: 'allmembers', directDownload: false },
    { field: 'validMembers', label: 'Total valid members', download: 'validmembers', directDownload: false },
    { field: 'loyalMembers', label: 'Total loyal members', download: 'loyal_members', directDownload: false },
    { field: 'newMembers', label: 'New members this month', download: 'membersthismonth', directDownload: false },
    { field: 'activeMembers', label: 'Active members', download: 'activemembers', directDownload: false },
    { field: 'activeMembersPercent', label: 'Active members percent', download: null, directDownload: false },
    { field: 'lapsedMembers', label: 'Lapsed members', download: 'lapsed_members', directDownload: false },
    { field: 'invalidMembersOld', label: 'Migrated users with inactive T&Cs', download: 'invalidmembers_old', directDownload: false },
    { field: 'newMembersNoSn', label: 'New members with no MagnaCleanse serial number', download: 'newmembers_noserialno', directDownload: false },
    { field: 'totalWarranties', label: 'Total warranties registered', download: 'total_warranties', directDownload: false },
    { field: 'totalWarrantiesOld', label: 'Warranties registered by previous AIC members', download: 'total_warranties_old', directDownload: false },
    { field: 'totalWarrantiesNew', label: 'Warranties registered by new users', download: 'total_warranties_new', directDownload: false },
    { field: 'totalWarrantiesTimeouts', label: 'Warranties registered by potential timeouts', download: 'total_warranties_timeouts', directDownload: false },
    { field: 'totalRewardPointsSpentResult', label: 'Total points spent on rewards across all accounts', download: 'reward_points_spent', directDownload: false },
    { field: 'totalPromotionalPointsAccrued', label: 'Total promotional points accrued across all accounts', download: 'promotional_points_accrued', directDownload: false },
    { field: 'totalRetrofit', label: 'Retrofit', download: 'total_retrofit', directDownload: false },
    { field: 'businessToolTradeHelp', label: 'TradeHelp', download: 'business_tools', directDownload: false, toolId: 2 },
    { field: 'businessToolTrueQuote', label: 'TrueQuote', download: 'business_tools', directDownload: false, toolId: 1 },
  ];

  // Reference to current login JWT
  private loginToken: string = null;

  // Data URL for downloadable stats (generated via API)
  public downloadUrlStr: string = null;
  public downloadUrl: SafeUrl = null;

  // Values used for stats download form hidden fields
  public formDownloadType: string = '';
  public formDownloadProductId: string = '';
  public formDownloadToolId: number | null;
  public formDownloadRegion: string = '';
  public formDownloadMinDate: string = '';
  public formDownloadMaxDate: string = '';

  public region: string = null;
  private minDate: string = null;
  private maxDate: string = null;
  private regionDownload: string = null;
  private minDateDownload: string = null;
  private maxDateDownload: string = null;

  constructor(
    private sanitizer: DomSanitizer,
    private store: Store<StoreState>
  ) {
    this.adminStats$ = this.store.select('adminStats');
    this.loginDetails$ = this.store.select('loginDetails');
  }

  ngOnInit() {
    this.loginSub = this.loginDetails$.subscribe((res) => {
      if (res && res.currentProfile) {
        this.Uid = res.currentProfile.id;
      }
    });

    this.adminStatsSub = this.adminStats$.subscribe((s: AdminStatsState) => {

      // Update downloadUrlStr and downloadUrl when the state changes
      if (s.downloadData) {
        // downloadData is a base64 string containing CSV data, so encode it
        // and create a data URL
        this.downloadUrlStr = 'data:text/csv;base64,' + encodeURIComponent(s.downloadData);

        // Sanitize the URL string so that it can be applied to the template
        // attribute
        this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(this.downloadUrlStr);
      }
    });

    // Update current JWT when loginDetails state changes
    this.loginDetailsSub = this.loginDetails$.subscribe((s: LoginDetailsState) => {
      this.loginToken = s.token;
    });

    this.store.dispatch(new FetchAdminStatsRequestAction({
      region: null,
      minDate: null,
      maxDate: null
    }));

    this.filterForm = new FormGroup({
      'region': new FormControl(),
      'dates': new FormGroup({
        'minDate': new FormControl(),
        'maxDate': new FormControl()
      })
    });

    this.store.dispatch(
      new SystemMessageRequestAction({type: ''})
    );
  }

  ngOnDestroy() {
    if (this.adminStatsSub) {
      this.adminStatsSub.unsubscribe();
    }
    if (this.loginDetailsSub) {
      this.loginDetailsSub.unsubscribe();
    }
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
  }

  /**
  * Sets the values for the hidden fields in the download form and then calls
  * submit() on the form initiate the download in a new browser tab.
  *
  * @param {string} downloadType  Type of statistics file to download
  * @param {string} productId     ID of the product, used in "warranties_by_product" type only
  * @param {boolean} productId    Is the report a direct download or generated on the fly
  * @param {string} region        Region to filter report by
  * @param {number | null} toolId Tool ID to filter report by
  */
  public downloadStats(downloadType: string, productId: string, directDownload: boolean, region: string, toolId: number | null = null) {
    if (this.region != null) region = this.region;

    if (directDownload) {
      window.open(`${environment.api.baseUrl}/admin/report/?report=${downloadType}&user_id=${this.Uid}&region=${encodeURIComponent(region)}&maxDate=${encodeURIComponent(this.maxDateDownload)}&token=${encodeURIComponent(this.loginToken)}`);
    } else {
      this.formDownloadType = downloadType;
      this.formDownloadProductId = productId;
      this.formDownloadToolId = toolId;
      setTimeout(() => this.downloadForm.nativeElement.submit(), 500);
    }
  }

  /**
  * Returns a form action for the statistics file download forms based on the
  * current API base path and current login JWT
  *
  * @return {SafeUrl} Sanitised URL which can be applied directly to the form "action" attribute
  */
  getFormAction(filterForm: NgForm): SafeUrl {
    const minDate = new Date(filterForm.value.dates.minDate);
    const maxDate = new Date(filterForm.value.dates.maxDate);

    this.regionDownload = filterForm.value.region;
    this.minDateDownload = filterForm.value.dates.minDate != null ? moment(minDate).format('YYYY-MM-DD') : null;
    this.maxDateDownload = filterForm.value.dates.maxDate != null ? moment(maxDate).format('YYYY-MM-DD') : null;

    return this.sanitizer.bypassSecurityTrustUrl(
      `${environment.api.baseUrl}/admin/stat_download?region=${encodeURIComponent(this.regionDownload)}&user_id=${this.Uid}&minDate=${encodeURIComponent(this.minDateDownload)}&maxDate=${encodeURIComponent(this.maxDateDownload)}&token=${encodeURIComponent(this.loginToken)}`
    );
  }

  filter(filterForm: NgForm) {
    const minDate = new Date(filterForm.value.dates.minDate);
    const maxDate = new Date(filterForm.value.dates.maxDate);

    this.region = filterForm.value.region;
    this.minDate = filterForm.value.dates.minDate != null ? moment(minDate).format('YYYY-MM-DD') : null;
    this.maxDate = filterForm.value.dates.maxDate != null ? moment(maxDate).format('YYYY-MM-DD') : null;
    this.adminStatsSub = this.adminStats$.subscribe(() => false);
    this.store.dispatch(new FetchAdminStatsRequestAction({
      region: this.region,
      minDate: this.minDate,
      maxDate: this.maxDate
    }));
  }

  /**
  * Automatically set the value of endDate to the value of startDate if changed
  *
  * @param {any} value The minDate value
  */
  onStartDateChange(value: any) {
    if (moment(this.maxDate).unix() < moment(value).unix()) {
      this.maxDate = value;
    }
  }

  /**
  * Automatically set the value of startDate if endDate is changed
  *
  * @param {any} value The maxDate value
  */
  onEndDateChange(value: any) {
    if (moment(this.minDate).unix() > moment(value).unix()) {
      this.minDate = value;
    }
  }

}
