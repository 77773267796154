import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';

import * as ActionTypes from '../actions/user-suggestion';

import {
  FetchUserSuggestionRequest,
  FetchUserSuggestionResponse,
} from '../../models/user-suggestion';

@Injectable()
export class UserSuggestionEffects {
  constructor(
    private service:  AdminService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchUserSuggestionRequestAction, call
   * AdminService::fetchUserSuggestions() and dispatch a new
   * FetchUserSuggestionResponseAction with the response.
   */
  @Effect() fetchUserSuggestions$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_USER_SUGGESTION_REQUEST)
    .switchMap((req: ActionTypes.FetchUserSuggestionRequestAction): Observable<ActionTypes.FetchUserSuggestionResponseAction> =>
      this.service.fetchUserSuggestions(req.payload)
        .map((res: FetchUserSuggestionResponse): ActionTypes.FetchUserSuggestionResponseAction =>
          new ActionTypes.FetchUserSuggestionResponseAction(res)
        )
    );
}
