/**
 * main models
 */

import {environment} from '../../environments/environment';

/**
 * Links for site footer
 */
export class ExternalLinks
{
  topLinks: any[] = [
    {
      label: 'ADEY®',
      url: 'http://www.adey.com/'
    },
    {
      label: 'Products',
      url: 'http://www.adey.com/categories'
    },
    {
      label: 'Best Practice',
      url: 'http://www.adey.com/best-practice'
    },
    {
      label: 'Support',
      url: 'http://www.adey.com/support'
    },
    {
      label: 'Contact Us',
      url: 'http://www.adey.com/contact'
    },
  ];
  siteLinks: any[] = [
    {
      label: 'About us',
      url: 'http://www.adey.com/aboutus'
    },
    {
      label:      'Warranty',
      url:        null,
      routerLink: '/warranty',
    },
    {
      label: 'Awards',
      url: 'http://www.adey.com/aboutus#awards'
    },
    {
      label: 'Terms',
      url: 'https://www.adey.com/adeyproclub-terms-and-conditions'
    },
    {
      label: 'Privacy Policy',
      url: environment.externalRoutes.policy_privacy
    },
    {
      label: 'Cookies policy',
      url: 'http://www.adey.com/terms#cookies'
    },
    {
      label: 'Sales team',
      url: 'http://www.adey.com/installers/contact#salesteam'
    },
  ];
}

/**
 * Social media links
 */
export class SocialItems
{
  items: any[] = [
    {
      icon: 'fa-facebook',
      url: 'https://www.facebook.com/ADEYInnovation/'
    },
    {
      icon: 'fa-twitter',
      url: 'https://twitter.com/ADEY_Pro'
    },
    {
      icon: 'fa-linkedin',
      url: 'https://www.linkedin.com/company/adeyinnovation/'
    },
    {
      icon: 'fa-instagram',
      url: 'https://www.instagram.com/adey_pro/'
    },
    {
      icon: 'fa-youtube-play',
      url: 'https://www.youtube.com/channel/UCFMCih4xajpOqSiHIorSuvg'
    }
  ];
}
