import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { LoginExistingRequestAction, RequestResetPasswordRequestAction } from '../../state-management/actions/login-details';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';

/**
 * Summary
 *    Allow the user to login to the site.
 *
 *
 * Description
 *    Provide the user with a way to log into the ADEY Pro website, displays the login view
 *    and prompts the user to reset their password or register an account if they haven't done so.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-login-existing',
  templateUrl: './login-existing.component.html',
  styleUrls: ['./login-existing.component.scss']
})
export class LoginExistingComponent implements OnInit, OnDestroy {
  // Reference to login state
  loginDetails$: Observable<LoginDetailsState>;
  // Flag to show if form has been submitted
  private submitted: boolean = false;

  // Instance to store the login form data
  loginForm: any;

  // URL Params subscription
  private paramsSub$: Subscription;

  // Store the utm params if they exist in the request URL.
  private utmParams = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: ''
  };

  private redirectTo: any;

  /**
  * Constructor for page
  *
  * @param {Store<StoreState>} _store  Initialises Store object
  * @param {Router} router             Initialises router object providing navigation between pages
  * @param {MatDialog} dialog          Initialises a dialog object so that a dialog can be opened from the page
  */
  constructor(
    private _store: Store<any>,
    private _route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    // Set a reference to the login store
    this.loginDetails$ = this._store.select('loginDetails');
  }

  /**
   * Create the login form on page load
   */
  ngOnInit() {
    // URL Params
    this.paramsSub$ = this._route.queryParams.subscribe(params => {
      this.utmParams.utm_medium = params['utm_medium'];
      this.utmParams.utm_source = params['utm_source'];
      this.utmParams.utm_campaign = params['utm_campaign'];
      this.redirectTo = params['redirectTo'];
    });

    // Create the form with a username and password control
    this.loginForm = new FormGroup({
      'username': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$')]),
      'termsAndConditionsConfirm': new FormControl(false, Validators.pattern('true')),
      'privacyPolicyConfirm': new FormControl(false, Validators.pattern('true')),
    });
  }

  ngOnDestroy() {
    if (this.paramsSub$) {
      this.paramsSub$.unsubscribe();
    }
  }

  /**
   * Apply a class to the form field dependant on the form's state
   *
   * @param {string} fieldName    The name of the field to check
   * @param {string} extraClasses Extra classes to add to the html
   *
   * @return {string} Return a string of classes to the form field object
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.loginForm.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Called when the user submits the form
   */
  onSubmitForm() {
    this.submitted = true;
    if (this.loginForm.valid) {
      // If the form is valid dispatch LoginRequestAction to pass the form data
      // to the API. The loginEffects will handle the redirection based on the
      // paths passed into the request.
      this._store.dispatch(new LoginExistingRequestAction({
        email: this.loginForm.controls.username.value,
        password: this.loginForm.controls.password.value,
        utm_source: this.utmParams.utm_source,
        utm_medium: this.utmParams.utm_medium,
        utm_campaign: this.utmParams.utm_campaign,
        redirectClientAdmin: '/admin-dashboard',
        redirectPage: this.redirectTo ? this.redirectTo : null,
      }));
    }
  }

  /**
   * Navigate the user to the register page when they click the "Create a profile" button
   */
  goToRegister() {
    this.router.navigate(['/register']);
  }

  /**
   * Open a new dialog with the ResetPasswordModalComponent when the user clicks the "Forgotten password" button
   */
  goToForgotten() {
    const dialogRef = this.dialog.open(ResetPasswordModalComponent, {
      panelClass: 'feature-modal-dialog',
    });

    // Dispatch RequestResetPasswordRequestAction if the modal is closed with a result
    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this._store.dispatch(new RequestResetPasswordRequestAction({ email: result }));
    });

  }
}
