import * as tslib_1 from "tslib";
import { CalendarDateFormatter } from 'angular-calendar';
import * as moment from 'moment';
/**
 * Summary
 *    Overwrite methods within angular-calendar
 *
 *
 * Description
 *    Overwrite angular-calendar functions in order to enhance or edit the way in which
 *    data is displayed
 *
 * @copyright 2017 ReallyB2B Limited
 */
var CustomDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateFormatter, _super);
    function CustomDateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Overwrite the column header for the month view so that it displays a short date
     *
     * @param {date, local}  pass in the given date and local for each header
     *
     * @return {string}  return a new localised string for each column header
     */
    CustomDateFormatter.prototype.monthViewColumnHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
    };
    /**
     * Overwrite the date string for the month view so that it displays in the correct format
     *
     * @param {date, local}  pass in the given date and local for the current month
     *
     * @return {string}  return a new localised string for the month
     */
    CustomDateFormatter.prototype.monthViewTitle = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new Intl.DateTimeFormat(locale, {
            month: 'long',
            year: 'numeric',
        }).format(date);
    };
    /**
     * Overwrite the date string for the week view so that it displays in the correct format
     *
     * @param {date, local}  pass in the given date and local for the current week
     *
     * @return {string}  return a new localised string the week
     */
    CustomDateFormatter.prototype.weekViewTitle = function (_a) {
        var date = _a.date, locale = _a.locale;
        var weekStart = moment(date).utc().startOf('week').format('Do MMM YY');
        var weekEnd = moment(date).utc().endOf('week').format('Do MMM YY');
        return weekStart + " - " + weekEnd;
    };
    /**
     * Overwrite the number string within each day for the month view
     *
     * @param {date, local}  pass in the given date and local for each day
     *
     * @return {string}  return a new localised string for the day
     */
    CustomDateFormatter.prototype.monthViewDayNumber = function (_a) {
        var date = _a.date, locale = _a.locale;
        if (moment(date).isSame(moment(date).startOf('month')))
            return moment(date).format('D MMM');
        else
            return moment(date).format('D');
    };
    return CustomDateFormatter;
}(CalendarDateFormatter));
export { CustomDateFormatter };
