import { AfterContentInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { RegistrationRequest, SimpleRegistrationRequest, UserRole } from '../../models/registration';
import { SimpleUserProfile, UserProfile } from '../../models/user-profile';
import { LoginService } from '../../services/login.service';
import {
  ConfirmProfileRequestAction,
  FetchAccreditationsRequestAction,
  FetchUserProfileRequestAction,
} from '../../state-management/actions/login-details';
import {
  FetchUserRolesRequestAction,
  RegistrationInit,
  RegistrationRequestAction,
  SimpleRegistrationRequestAction,
} from '../../state-management/actions/registration';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { State as RegistrationState } from '../../state-management/reducers/registration';
import { StoreState } from '../../state-management/store';

/**
 * Summary
 *    Allow the user to register a new account.
 *
 *
 * Description
 *    Display the user profile component in order to capture the data required
 *    to successfully register a new account for the user.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements AfterContentInit, OnDestroy, OnInit {

  // Store states and Subscriptions
  public loginDetailsState$: Observable<LoginDetailsState>;
  private loginDetailsSub$: Subscription;
  public registerState$: Observable<RegistrationState>;
  private modeSub: Subscription;
  private paramsSub$: Subscription;

  // Page mode: "new" (new user) or "update" (confirm user profile)
  public mode: string = 'new';

  // Title to display on page
  public pageTitle: string = 'Sign up to the ADEY ProClub!';

  // Label for the submit button
  public submitLabel: string = 'Create profile';

  // Array of available user roles
  public roles: UserRole[] = [];

  // Store the utm params if they exist in the request URL.
  private utmParams = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: ''
  };

  /**
   * Constructor for page
   *
   * @param {ActivatedRoute} _route     Contains information about the associated route
   * @param {Router} _router            Initialises router object providing navigation between pages
   * @param {Store<StoreState>} _store  Initialises Store object
   * @param dialog
   */
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<StoreState>,
    private loginService: LoginService,
    public dialog: MatDialog
  ) {
    // The user should not be logged in
    // Call the service directly as we do not want to redirect the user to login
    this.loginService.logout();

    // Set references to login, register and country states
    this.loginDetailsState$ = _store.select('loginDetails');
    this.registerState$ = _store.select('registration');

    // Dispatch FetchAccreditationsRequestAction in order to display the available accreditations on the page
    _store.dispatch(new FetchAccreditationsRequestAction());
    // Dispatch FetchUserRolesRequestAction in order to get the available user roles
    _store.dispatch(new FetchUserRolesRequestAction());
  }

  /**
   * Subscribe to login state and retrieve any params passed to the page on page load
   */
  ngOnInit() {

    // Subscribe to the login state in order to set a reference to the available user roles
    this.loginDetailsSub$ = this.loginDetailsState$.subscribe((state: LoginDetailsState) => {
      this.roles = state.roles;
    });

    // URL Params
    this.paramsSub$ = this._route.queryParams.subscribe(params => {
      this.utmParams.utm_medium = params['utm_medium'];
      this.utmParams.utm_source = params['utm_source'];
      this.utmParams.utm_campaign = params['utm_campaign'];
    });

    // Check to see if mode is a valid param within the url, if so, display content based on the mode
    this.modeSub = this._route.paramMap
      .map((params: ParamMap) => {
        return params.get('mode');
      })
      .subscribe((mode: string) => {
        if (!mode)
          mode = 'new';
        this.mode = mode;
        switch (mode.toLowerCase()) {
          case 'new':
            this.pageTitle = 'Sign up to the ADEY ProClub!';
            this.submitLabel = 'Create profile';
            break;
          case 'update':
            this._store.dispatch(new FetchUserProfileRequestAction());
            this.pageTitle = 'Confirm Profile';
            this.submitLabel = 'Update profile';
            break;
          case 'simple':
            this.pageTitle = 'Sign up to the ADEY ProClub!';
            this.submitLabel = 'Create profile';
            break;
        }
      });
  }

  /**
   * Unsubscribe from the login state and _route.paramMap when the page is destroyed to free up memory
   */
  ngOnDestroy() {
    if (this.loginDetailsSub$) {
      this.loginDetailsSub$.unsubscribe();
    }
    if (this.modeSub) {
      this.modeSub.unsubscribe();
    }
    if (this.paramsSub$) {
      this.paramsSub$.unsubscribe();
    }
  }

  ngAfterContentInit() {
    // Initialise the registration system
    this._store.dispatch(new RegistrationInit());
  }


  /**
   * Called when the submit button is pressed from the user-profile component, will dispatch the form data to
   * either ConfirmProfileRequestAction or RegistrationRequestAction based on the current mode.
   * Validation is handled within the user profile component
   *
   * @param {UserProfile} profile The profile data collected from the form
   */

  handleFormSubmit(profile: UserProfile) {
    switch (this.mode.toLowerCase()) {
      case 'update':
        this._store.dispatch(new ConfirmProfileRequestAction({ profile }));
        break;
      default: // 'new'
        this._store.dispatch(new RegistrationRequestAction(RegistrationRequest.fromUserProfile(profile)));
    }
  }

  handleSimpleFormSubmit(simpleProfile: SimpleUserProfile) {

    simpleProfile.utm_campaign = this.utmParams.utm_campaign;
    simpleProfile.utm_medium = this.utmParams.utm_medium;
    simpleProfile.utm_source = this.utmParams.utm_source;

    this._store.dispatch(new SimpleRegistrationRequestAction(SimpleRegistrationRequest.fromUserProfile(simpleProfile)));
  }

  /**
   *  Called when the user clicks the button within the registration success message. Will navigate the user to the
   *  login page
   */
  onConfirmSuccess() {
    this._router.navigate(['/']);
  }
}
