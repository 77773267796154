import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { UserPopup } from '../../models/popup';
import { PopupService } from '../../services/popup.service';
import * as ActionTypes from '../actions/popup';
import { GetUserPopup } from '../actions/popup';
import { StoreState } from '../store';

@Injectable()
export class PopupEffects {
  constructor(
    private actions$: Actions,
    private service: PopupService,
    private store: Store<StoreState>,
  ) { }

  @Effect() userPopupRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.GET_USER_POPUP)
    .switchMap(() => this.service.getUserPopup()
      .then((payload: UserPopup[]) => new ActionTypes.GetUserPopupSuccess(payload))
      .catch((error: any) => new ActionTypes.GetUserPopupFailure(error))
    );

  @Effect() userPopupDisplayCountRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.SET_USER_POPUP_DISPLAY_COUNT)
    .switchMap((action: ActionTypes.SetUserPopupDisplayCount) => this.service.setUserPopupDisplayCount(action.request.name, action.request.count)
      .then(() => new ActionTypes.SetUserPopupDisplayCountSuccess())
      .catch((error: any) => new ActionTypes.SetUserPopupDisplayCountFailure(error))
    );

  @Effect({ dispatch: false }) userPopupDisplayCountSuccess$: Observable<void> = this.actions$
    .ofType(ActionTypes.SET_USER_POPUP_DISPLAY_COUNT_SUCCESS)
    .map((): void => this.store.dispatch(new GetUserPopup()));
}
