import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {DFResponse, DFTableRequest} from '../models/df-proclub';
import {Observable} from 'rxjs/Observable';
import {HostedCompany} from '../models/hosted-companies';
import {UserProfile} from '../models/user-profile';
import {map} from 'rxjs/operators';

/**
 * Provides AdminInstallerService
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

@Injectable()
export class AdminInstallerService {
    /**
     * Inject the API service
     *
     * @param apiService Application's main API data connection
     */
    constructor(
        private apiService: ApiService,
    ) {
    }

    /**
     * Get a list of companies
     *
     * @param req Request object
     */
    getInstallerList(req: DFTableRequest): Observable<DFResponse<UserProfile>> {
        //Get the full filter string given a provided filter
        req.filter = req.buildFilter();

        //Build the URI to submit
        const uri = '/installer?' + req.getFullQuery();

        //Send the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri)
            .map((res: any): DFResponse<UserProfile> => {
                return res;
            })
            .catch((err: any): Observable<DFResponse<UserProfile>> =>
                Observable.of({
                    error: err && err.error && err.error.message
                        ? `Could not fetch installer data: ${err.error.message}`
                        : err,
                    data: null,
                    paging: null,
                    timing: null,
                })
            );
    }

  /**
   *
   */
  findUserByPartial(partialUser: Partial<UserProfile>): Observable<UserProfile> {
      const req = new DFTableRequest();

      Object.keys(partialUser).forEach((key) => {
          if (req.filter !== '') {
              req.filter += ' AND ';
          }
          req.filter += ('(' + key + '=' + partialUser[key] + ')');
      });

      return this.getInstallerList(req).pipe(map((result: DFResponse<UserProfile>) => result.data[0]));
  }
}
