/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-hub.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/waiting-spinner/waiting-spinner.component.ngfactory";
import * as i3 from "../../shared/waiting-spinner/waiting-spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "../../pipes/from-now.pipe";
import * as i6 from "./social-hub.component";
import * as i7 from "@ngrx/store";
var styles_SocialHubComponent = [i0.styles];
var RenderType_SocialHubComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SocialHubComponent, data: {} });
export { RenderType_SocialHubComponent as RenderType_SocialHubComponent };
function View_SocialHubComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-waiting-spinner", [["kind", "bar"]], null, null, null, i2.View_WaitingSpinnerComponent_0, i2.RenderType_WaitingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.WaitingSpinnerComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, 0, ["Loading social feed..."]))], function (_ck, _v) { var currVal_0 = "bar"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SocialHubComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ie-image-patch"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "]))], null, function (_ck, _v) { var currVal_0 = (("url(" + _v.parent.parent.context.$implicit.image) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.image; _ck(_v, 2, 0, currVal_1); }); }
function View_SocialHubComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_SocialHubComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "social-item border-box border-lighter-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-twitter-square"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", "\n              "])), i1.ɵppd(6, 1), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialHubComponent_6)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialHubComponent_7)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.image; _ck(_v, 9, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.description; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.date)); _ck(_v, 5, 0, currVal_0); }); }
function View_SocialHubComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink(_v.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialHubComponent_5)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n          "]))], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.description || _v.context.$implicit.image); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ("social-item-container" + (_v.context.$implicit.url ? " pointer" : "")); _ck(_v, 0, 0, currVal_0); }); }
function View_SocialHubComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "social-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialHubComponent_4)), i1.ɵdid(3, 802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.twitterFeed; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SocialHubComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "social-container background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialHubComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialHubComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n      "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.socialPending; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.twitterFeed; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_SocialHubComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.FromNowPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "row content-page no-pad"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "col-sm-12 padded-container overflow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "container-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Social hub"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SocialHubComponent_1)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.state$)); _ck(_v, 14, 0, currVal_0); }, null); }
export function View_SocialHubComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-hub", [], null, null, null, View_SocialHubComponent_0, RenderType_SocialHubComponent)), i1.ɵdid(1, 49152, null, 0, i6.SocialHubComponent, [i7.Store], null, null)], null, null); }
var SocialHubComponentNgFactory = i1.ɵccf("app-social-hub", i6.SocialHubComponent, View_SocialHubComponent_Host_0, {}, {}, []);
export { SocialHubComponentNgFactory as SocialHubComponentNgFactory };
