import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';

import * as ActionTypes from '../actions/admin-jobs';

import {
  AddAdminJobRequest,
  AddAdminJobResponse,
  DeleteAdminJobRequest,
  DeleteAdminJobResponse,
  FetchAdminJobsRequest,
  FetchAdminJobsResponse,
  UpdateAdminJobRequest,
  UpdateAdminJobResponse,

  AddAdminPromoCodeRequest,
  AddAdminPromoCodeResponse,
  DeleteAdminPromoCodeRequest,
  DeleteAdminPromoCodeResponse,
  FetchAdminPromoCodesResponse,
  UpdateAdminPromoCodeRequest,
  UpdateAdminPromoCodeResponse,

  AddAdminWarrantyRequest,
  AddAdminWarrantyResponse,
  DeleteAdminWarrantyRequest,
  DeleteAdminWarrantyResponse,
  FetchAdminWarrantiesRequest,
  FetchAdminWarrantiesResponse,
  UpdateAdminWarrantyRequest,
  UpdateAdminWarrantyResponse,

  UpdateAdminCustomerRequest,
  UpdateAdminCustomerResponse,

  UpdateRegisteredProductRequest,
  UpdateRegisteredProductResponse,
} from '../../models/admin-jobs';

import { Store } from '@ngrx/store';
import { StoreState } from '../../state-management/store';
import { FetchWarrantiesRequestAction } from '../../state-management/actions/warranty-list';
import { FetchAdminWarrantiesRequestAction } from '../../state-management/actions/admin-jobs';

@Injectable()
export class AdminJobsEffects {
  constructor(
    private service:  AdminService,
    private actions$: Actions,
    private store: Store<StoreState>,
  ) {}

  /**
   * For an AddAdminJobRequestAction, call AdminService::addJob() and dispatch
   * a new AddAdminJobResponseAction with the response.
   */
  @Effect() addJob$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_JOB_REQUEST)
    .switchMap((req: ActionTypes.AddAdminJobRequestAction): Observable<ActionTypes.AddAdminJobResponseAction> =>
      this.service.addJob(req.payload)
        .map((res: AddAdminJobResponse): ActionTypes.AddAdminJobResponseAction => {
          this.store.dispatch(new FetchAdminWarrantiesRequestAction({type: 'WARRANTY', query: 'job_id=' + res.job.id, perPage: 50, pageNum: 1, upcoming: false}));
          return new ActionTypes.AddAdminJobResponseAction(res);
        })
    );

  /**
   * For a DeleteAdminJobRequestAction, call AdminService::deleteJob() and
   * dispatch a new DeleteAdminJobResponseAction with the response.
   */
  @Effect() deleteJob$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_JOB_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminJobRequestAction): Observable<ActionTypes.DeleteAdminJobResponseAction> =>
      this.service.deleteJob(req.payload)
        .map((res: DeleteAdminJobResponse): ActionTypes.DeleteAdminJobResponseAction =>
          new ActionTypes.DeleteAdminJobResponseAction(res)
        )
    );

  /**
   * For a FetchAdminJobsRequestAction, call AdminService::fetchJobs() and
   * dispatch a new FetchAdminJobsResponseAction with the response.
   */
  @Effect() fetchJobs$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_JOBS_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminJobsRequestAction): Observable<ActionTypes.FetchAdminJobsResponseAction> =>
      this.service.fetchJobs(req.payload)
        .map((res: FetchAdminJobsResponse): ActionTypes.FetchAdminJobsResponseAction =>
          new ActionTypes.FetchAdminJobsResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminJobRequestAction, call AdminService::updateJob() and
   * dispatch a new UpdateAdminJobResponseAction with the response.
   */
  @Effect() updateJob$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_JOB_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminJobRequestAction): Observable<ActionTypes.UpdateAdminJobResponseAction> =>
      this.service.updateJob(req.payload)
        .map((res: UpdateAdminJobResponse): ActionTypes.UpdateAdminJobResponseAction =>
          new ActionTypes.UpdateAdminJobResponseAction(res)
        )
    );


  /**
   * For an AddAdminPromoCodeRequestAction, call AdminService::addPromoCode()
   * and dispatch a new AddAdminPromoCodeResponseAction with the response.
   */
  @Effect() addPromoCode$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_PROMO_CODE_REQUEST)
    .switchMap((req: ActionTypes.AddAdminPromoCodeRequestAction): Observable<ActionTypes.AddAdminPromoCodeResponseAction> =>
      this.service.addPromoCode(req.payload)
        .map((res: AddAdminPromoCodeResponse): ActionTypes.AddAdminPromoCodeResponseAction =>
          new ActionTypes.AddAdminPromoCodeResponseAction(res)
        )
    );

  /**
   * For a DeleteAdminPromoCodeRequestAction, call
   * AdminService::deletePromoCode() and dispatch a new
   * DeleteAdminPromoCodeResponseAction with the response.
   */
  @Effect() deletePromoCode$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_PROMO_CODE_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminPromoCodeRequestAction): Observable<ActionTypes.DeleteAdminPromoCodeResponseAction> =>
      this.service.deletePromoCode(req.payload)
        .map((res: DeleteAdminPromoCodeResponse): ActionTypes.DeleteAdminPromoCodeResponseAction =>
          new ActionTypes.DeleteAdminPromoCodeResponseAction(res)
        )
    );

  /**
   * For a FetchAdminPromoCodesRequestAction, call
   * AdminService::fetchPromoCodes() and dispatch a new
   * FetchAdminPromoCodesResponseAction with the response.
   */
  @Effect() fetchPromoCodes$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_PROMO_CODES_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminPromoCodesRequestAction): Observable<ActionTypes.FetchAdminPromoCodesResponseAction> =>
      this.service.fetchPromoCodes()
        .map((res: FetchAdminPromoCodesResponse): ActionTypes.FetchAdminPromoCodesResponseAction =>
          new ActionTypes.FetchAdminPromoCodesResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminPromoCodeRequestAction, call
   * AdminService::updatePromoCode() and dispatch a new
   * UpdateAdminPromoCodeResponseAction with the response.
   */
  @Effect() updatePromoCode$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_PROMO_CODE_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminPromoCodeRequestAction): Observable<ActionTypes.UpdateAdminPromoCodeResponseAction> =>
      this.service.updatePromoCode(req.payload)
        .map((res: UpdateAdminPromoCodeResponse): ActionTypes.UpdateAdminPromoCodeResponseAction =>
          new ActionTypes.UpdateAdminPromoCodeResponseAction(res)
        )
    );


  /**
   * For an AddAdminWarrantyRequestAction, call AdminService::addWarranty() and
   * dispatch a new AddAdminWarrantyResponseAction with the response.
   */
  @Effect() addWarranty$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_WARRANTY_REQUEST)
    .switchMap((req: ActionTypes.AddAdminWarrantyRequestAction): Observable<ActionTypes.AddAdminWarrantyResponseAction> =>
      this.service.addWarranty(req.payload)
        .map((res: AddAdminWarrantyResponse): ActionTypes.AddAdminWarrantyResponseAction =>
          new ActionTypes.AddAdminWarrantyResponseAction(res)
        )
    );

  /**
   * For a DeleteAdminWarrantyRequestAction, call
   * AdminService::deleteWarranty() and dispatch a new
   * DeleteAdminWarrantyResponseAction with the response.
   */
  @Effect() deleteWarranty$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_WARRANTY_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminWarrantyRequestAction): Observable<ActionTypes.DeleteAdminWarrantyResponseAction> =>
      this.service.deleteWarranty(req.payload)
        .map((res: DeleteAdminWarrantyResponse): ActionTypes.DeleteAdminWarrantyResponseAction =>
          new ActionTypes.DeleteAdminWarrantyResponseAction(res)
        )
    );

  /**
   * For a FetchAdminWarrantiesRequestAction, call
   * AdminService::fetchWarranties() and dispatch a new
   * FetchAdminWarrantiesResponseAction with the response.
   */
  @Effect() fetchWarranties$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_WARRANTIES_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminWarrantiesRequestAction): Observable<ActionTypes.FetchAdminWarrantiesResponseAction> =>
      this.service.fetchWarranties(req.payload)
        .map((res: FetchAdminWarrantiesResponse): ActionTypes.FetchAdminWarrantiesResponseAction => {
          return new ActionTypes.FetchAdminWarrantiesResponseAction(res);
        })
    );

  /**
   * For an UpdateAdminWarrantyRequestAction, call
   * AdminService::updateWarranty() and dispatch a new
   * UpdateAdminWarrantyResponseAction with the response.
   */
  @Effect() updateWarranty$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_WARRANTY_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminWarrantyRequestAction): Observable<ActionTypes.UpdateAdminWarrantyResponseAction> =>
      this.service.updateWarranty(req.payload)
        .map((res: UpdateAdminWarrantyResponse): ActionTypes.UpdateAdminWarrantyResponseAction =>
          new ActionTypes.UpdateAdminWarrantyResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminCustomerRequestAction, call
   * AdminService::updateCustomer() and dispatch a new
   * UpdateAdminCstomerResponseAction with the response.
   */
  @Effect() updateCutomer$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_CUSTOMER_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminCustomerRequestAction): Observable<ActionTypes.UpdateAdminCustomerResponseAction> =>
      this.service.updateCustomer(req.payload)
        .map((res: UpdateAdminCustomerResponse): ActionTypes.UpdateAdminCustomerResponseAction =>
          new ActionTypes.UpdateAdminCustomerResponseAction(res)
        )
    );

  /**
   * For an UpdateRegisteredProductRequestAction, call
   * AdminService::updateRegisteredProduct() and dispatch a new
   * UpdateRegisteredProductResponseAction with the response.
   */
  @Effect() updateRegisteredProduct$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_REGISTERED_PRODUCT_REQUEST)
    .switchMap((req: ActionTypes.UpdateRegisteredProductRequestAction): Observable<ActionTypes.UpdateRegisteredProductResponseAction> =>
      this.service.updateRegisteredProduct(req.payload)
        .map((res: UpdateRegisteredProductResponse): ActionTypes.UpdateRegisteredProductResponseAction =>
          new ActionTypes.UpdateRegisteredProductResponseAction(res)
        )
    );
}
