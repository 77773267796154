/**
 * Provides NGRX Actions for Companies
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import {Action} from '@ngrx/store';
import {DFResponse, DFTableRequest} from '../../models/df-proclub';
import {HostedCompany} from '../../models/hosted-companies';

// Created using PhpStorm

export const LOAD_COMPANIES = '[Admin.Companies] Load Companies';
export const LOAD_COMPANIES_SUCCESS = '[Admin.Companies] Load Companies - Success';
export const LOAD_COMPANIES_FAILED = '[Admin.Companies] Load Companies - Failed';

export const SELECT_COMPANY = '[Admin.Companies] Select Company';

export class AdminCompaniesLoadCompanyAction implements Action {
    readonly type = LOAD_COMPANIES;

    constructor(public payload: DFTableRequest) {
    }
}

export class AdminCompaniesLoadCompanyActionSuccess implements Action {
    readonly type = LOAD_COMPANIES_SUCCESS;

    constructor(public payload: DFResponse<HostedCompany>) {
    }
}

export class AdminCompaniesLoadCompanyActionFailed implements Action {
    readonly type = LOAD_COMPANIES_FAILED;

    constructor(public error: any) {
    }
}

export class AdminCompaniesSelectCompany {
    readonly type = SELECT_COMPANY;

    constructor(public payload: { id: number }) {
    }
}

export type Actions =
    AdminCompaniesLoadCompanyAction
    | AdminCompaniesLoadCompanyActionSuccess
    | AdminCompaniesLoadCompanyActionFailed

    | AdminCompaniesSelectCompany
    ;
