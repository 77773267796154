import { HttpClient } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// Navigate to an external site
export function navigateExternal(route, _state) {
    return window.location.href = route.data.externalUrl;
}
// Only use Sentry in production mode
export function errorHandlerFactory() {
    if (environment.production) {
        return new SentryErrorHandlerService();
    }
    return new ErrorHandler();
}
var ɵ0 = (createTranslateLoader);
// Main app module definition
// @ts-ignore
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
