/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/common";
import * as i7 from "./generic-modal.component";
var styles_GenericModalComponent = [];
var RenderType_GenericModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericModalComponent, data: {} });
export { RenderType_GenericModalComponent as RenderType_GenericModalComponent };
function View_GenericModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor], null, null), (_l()(), i0.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.confirmLabel; _ck(_v, 2, 0, currVal_1); }); }
function View_GenericModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor], null, null), (_l()(), i0.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.dismissLabel; _ck(_v, 2, 0, currVal_1); }); }
export function View_GenericModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i5.MatDialogTitle, [[2, i5.MatDialogRef], i0.ElementRef, i5.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(8, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i5.MatDialogActions, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericModalComponent_1)), i0.ɵdid(12, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericModalComponent_2)), i0.ɵdid(15, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = _co.data.confirmLabel; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.data.dismissLabel; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.content; _ck(_v, 4, 0, currVal_2); }); }
export function View_GenericModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-modal", [], null, null, null, View_GenericModalComponent_0, RenderType_GenericModalComponent)), i0.ɵdid(1, 49152, null, 0, i7.GenericModalComponent, [i5.MatDialogRef, i5.MAT_DIALOG_DATA], null, null)], null, null); }
var GenericModalComponentNgFactory = i0.ɵccf("app-generic-modal", i7.GenericModalComponent, View_GenericModalComponent_Host_0, {}, {}, []);
export { GenericModalComponentNgFactory as GenericModalComponentNgFactory };
