import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable }   from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Store }        from '@ngrx/store';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


import { b64ToBlob } from '../../../utility';

import { StoreState }                     from '../../../state-management/store';
import { State as RegisterJobState }      from '../../../state-management/reducers/register-job';
import { State as RegisterWarrantyState } from '../../../state-management/reducers/register-warranty';

import { Job } from '../../../models/job';

import {
  InitJobRequestAction,
  FetchJobRequestAction,
  FetchJobPdfRequestAction,
} from '../../../state-management/actions/register-job';

import {
  FetchProductTypesRequestAction,
} from '../../../state-management/actions/register-warranty';

import { JobDetailsComponent }   from '../job-details/job-details.component';
import { PrintableJobComponent } from '../printable-job/printable-job.component';


/**
 * Summary
 *    Provides a modal dialog which can be used to view, edit, print and
 *    download a Job
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-job-edit-modal',
  templateUrl: './job-edit-modal.component.html',
  styleUrls: ['./job-edit-modal.component.scss'],
})
export class JobEditModalComponent implements OnDestroy, OnInit {

  // References to child components
  @ViewChild(JobDetailsComponent)   compDetails:   JobDetailsComponent;
  @ViewChild(PrintableJobComponent) compPrintable: PrintableJobComponent;


  // Store states and Subscriptions
  public  state$:        Observable<RegisterJobState>;
  public  productState$: Observable<RegisterWarrantyState>;
  private stateSub:      Subscription = null;

  // If set, form is tailored for admin users
  public adminMode: boolean = false;

  // If set, form will be read-only
  public viewMode: boolean = true;

  // Set when form is being printed
  public printMode: boolean = false;

  // Set automatically if an IE-based browser is detected in order to provide
  // IE-specific file download functionality
  public ieDownloadMode: boolean = false;

  // Data URL for a downloadable PDF version (generated via API)
  public pdfUrlStr: string  = null;
  public pdfUrl:    SafeUrl = null;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<JobEditModalComponent>,
    private sanitizer: DomSanitizer,
    private store:     Store<StoreState>
  ) {
    this.adminMode = !!data.adminMode;
    this.viewMode  = !data.edit;

    this.state$        = this.store.select('registerJob');
    this.productState$ = this.store.select('registerWarranty');

    this.store.dispatch(new InitJobRequestAction());
    this.store.dispatch(new FetchProductTypesRequestAction());

    if (data.jobId)
      this.getJobData(data.jobId);
  }

  ngOnInit() {

    // Set ieDownloadMode based on IE browser detection
    this.ieDownloadMode = !!(navigator['msSaveBlob']) && !!(window['Blob']);

    this.stateSub = this.state$.subscribe((state: RegisterJobState) => {

      // Update pdfUrlStr and pdfUrl when the state changes
      if (state.pdfData)
      {
        // pdfData is a base64 string so encode it and create a data URL
        this.pdfUrlStr = 'data:application/pdf;base64,' + encodeURIComponent(state.pdfData);

        // Sanitize the URL string so that it can be applied to the template
        // attribute
        this.pdfUrl = this.sanitizer.bypassSecurityTrustUrl(this.pdfUrlStr);
      }
      else
      {
        this.pdfUrlStr = null;
        this.pdfUrl    = null;
      }

    });
  }

  ngOnDestroy() {
    if (this.stateSub)
      this.stateSub.unsubscribe();
  }


  /**
   * Returns a suitable PDF filename for the specified Job
   *
   * @param {Job} job
   * @return {string}
   */
  getDownloadFilename(job: Job): string {
    let name: string = 'Job record.pdf';

    if (job && job.customer)
      name = `${job.customer.customerTitle} ${job.customer.customerSurname}.pdf`;
    else if (job)
      name = `Job record ${job.id}.pdf`;

    return name;
  }

  /**
   * Fetches the specified Job
   *
   * @param {number} jobId
   */
  getJobData(jobId: number) {
    this.store.dispatch(new FetchJobRequestAction({id: jobId}));
  }

  /**
   * Returns the view to normal view/edit mode
   */
  hidePrintVersion() {
    this.printMode = false;
  }

  /**
   * Handles downloading of the PDF data (in pdfUrlStr) for IE browsers. Job is
   * required in order to obtain the correct filename.
   *
   * @param {Job} job Job model used for generating filename
   */
  ieDownload(job: Job) {
    if (!this.ieDownloadMode)
      return;

    const dataParts: string[] = this.pdfUrlStr.split(',');
    if (dataParts.length !== 2)
      return;

    const data: string = decodeURIComponent(dataParts[1]);

    const mimeParts: string[] = dataParts[0].match(/:(.*?);/);
    if (mimeParts.length < 2)
      return;

    navigator['msSaveBlob'](b64ToBlob(mimeParts[1], data), this.getDownloadFilename(job));
  }

  /**
   * Calls JobDetailsComponent::onSubmitForm() to save the Job
   */
  onSaveClick() {
    this.compDetails.onSubmitForm();
  }

  /**
   * Handler for JobDetailsComponent::onSubmit() EventEmitter; closes the
   * dialog with the updated Job model.
   *
   * @param {Job} j Updated Job model from JobDetailsComponent
   */
  onSubmitForm(j: Job) {
    this.dialogRef.close(j);
  }

  /**
   * Calls PrintableJobComponent::printView() (child component) to cause the
   * browser to print the Job
   */
  printView() {
    this.compPrintable.printView();
  }

  /**
   * Switches the view to print mode and requests a PDF version of the
   * specified Job
   *
   * @param {Job} job
   */
  showPrintVersion(job: Job) {
    this.printMode = true;

    // Fetch PDF data from API
    this.store.dispatch(new FetchJobPdfRequestAction({id: job.id}));
  }
}
