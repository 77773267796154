import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

/**
 * Summary
 *    Display a rich text editor on the page.
 *
 *
 * Description
 *    Allow the user to update content via the rich text editor handled within this component.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss']
})
export class RichEditorComponent implements AfterViewInit, OnDestroy {

  // The Id given to the textarea, to allow for multiple editors on a single page
  @Input() elementId: string = null;

  // The initial content of the text editor
  @Input() initialContent: string = null;

  // A callback to the parent component
  @Output() onChange = new EventEmitter<any>();

  // Reference to editor object
  private editor: any = null;

  constructor() { }

  /**
   * After the view has initialised, create the rich editor object
   */
  ngAfterViewInit() {
    // Initialise the editor and set a reference
    tinymce.init({
      selector: `#${this.elementId}`,
      plugins: ['hr', 'image', 'link', 'lists', 'media', 'paste', 'table'],
      height: '400',
      branding: false,
      setup: (editor: any) => {
        this.editor = editor;

        // Setup callbacks to emit content back to the parent component
        editor.on('keyup', () => {
          const content = editor.getContent();
          this.onChange.emit(content);
        });
        editor.on('blur', () => {
          const content = editor.getContent();
          this.onChange.emit(content);
        });
        editor.on('focus', () => {
          const content = editor.getContent();
          this.onChange.emit(content);
        });
        editor.on('init', () => {
          if (this.initialContent) {
            editor.setContent(this.initialContent, { format: 'html' });
          }
        });

      },
    });
  }

  /**
   *  Remove the editor on destruction of the page
   */
  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
}
