/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./submission-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../common/page-content/page-content.component.ngfactory";
import * as i3 from "../common/page-content/page-content.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@ngrx/store";
import * as i6 from "./submission-selection.component";
import * as i7 from "@angular/router";
var styles_SubmissionSelectionComponent = [i0.styles];
var RenderType_SubmissionSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubmissionSelectionComponent, data: {} });
export { RenderType_SubmissionSelectionComponent as RenderType_SubmissionSelectionComponent };
export function View_SubmissionSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 49, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 46, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register a job / warranty"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-page-content", [["field", "intro"], ["page", "SUBMISSION_SELECTION"]], null, null, null, i2.View_PageContentComponent_0, i2.RenderType_PageContentComponent)), i1.ɵdid(11, 245760, null, 0, i3.PageContentComponent, [i4.DomSanitizer, i5.Store], { page: [0, "page"], field: [1, "field"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 34, "div", [["class", "submission-container col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(15, 0, null, null, 31, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 17, "div", [["class", "submission-selection col-sm-6 col-xs-12 job-selection"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(19, 0, null, null, 14, "div", [["class", "selection-box background-white align-center hover-background-adey-red hover-color-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSubmission("/job") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "icon-mc3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "icon-flush"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(25, 0, null, null, 0, "i", [["class", "icon-mc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["class", "icon-water-test"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(29, 0, null, null, 0, "i", [["class", "icon-service-complete"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register a job"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(36, 0, null, null, 9, "div", [["class", "submission-selection col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(38, 0, null, null, 6, "div", [["class", "selection-box background-white align-center hover-background-adey-red hover-color-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSubmission("/warranty") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(40, 0, null, null, 0, "i", [["class", "icon-warranty-submit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(42, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register a warranty"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "SUBMISSION_SELECTION"; var currVal_1 = "intro"; _ck(_v, 11, 0, currVal_0, currVal_1); }, null); }
export function View_SubmissionSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-submission-selection", [], null, null, null, View_SubmissionSelectionComponent_0, RenderType_SubmissionSelectionComponent)), i1.ɵdid(1, 114688, null, 0, i6.SubmissionSelectionComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubmissionSelectionComponentNgFactory = i1.ɵccf("app-submission-selection", i6.SubmissionSelectionComponent, View_SubmissionSelectionComponent_Host_0, {}, {}, []);
export { SubmissionSelectionComponentNgFactory as SubmissionSelectionComponentNgFactory };
