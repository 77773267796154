import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { SocialItem } from '../../../models/social';

/**
 * Summary
 *    Display items from social media feeds.
 *    
 *
 * Description
 *    Display items from social media feeds and allow the user to open individual links, can be used in multiple places.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-social-widget',
  templateUrl: './social-widget.component.html',
  styleUrls: ['./social-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialWidgetComponent {

  // Array of SocialItems to show
  @Input() facebookItems: SocialItem[];
  @Input() twitterItems: SocialItem[];
  @Input() instagramItems: SocialItem[];


  // Form used for selecting feed to display
  public fg: FormGroup;

  /**
   * Constructor for page
   *
   * @param {Router} router   Initialises Router object providing navigation between pages
   * @param {FormBuilder} _fb Initialise FormBuilder instance to construct form
   */
  constructor(
    private router: Router,
    private _fb: FormBuilder,
  ) {
    // Create the form with the default value set to 'twitter' to ensure 
    // twitter posts will show by default.
    this.fg = _fb.group({
      social_view: ['twitter', []]
    });
  }

  /**
   * Clicked when the user selects a social media item, will open the link in a new page
   *
   * @param {string} url The url for the selected social media item
   */
  openLink(url: string) {
    if (url)
      window.open(url, '_blank');
  }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string) {
    this.router.navigate([pageRef]);
  }
}
