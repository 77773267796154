/**
 * WarrantyService: provides all functionality related to handling Warranties
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import { Injectable }                        from '@angular/core';
import { Observable }                        from 'rxjs/Observable';

import * as moment from 'moment';


import { ApiService } from './api.service';

import { WarrantyService as MockService } from './warranty.service.mock';

import {
  MonthlyWarrantyStats,
  PromoCode,
  Warranty,
  WarrantyFullStats,

  CheckPromoCodeResponse,
  CheckSerialNumberResponse,
  FetchProductTypesResponse,
  FetchMonthlyWarrantyStatsRequest,
  FetchMonthlyWarrantyStatsResponse,
  FetchWarrantyRequest,
  FetchWarrantyResponse,
  FetchWarrantiesRequest,
  FetchWarrantiesResponse,
  FetchWarrantyPdfRequest,
  FetchWarrantyPdfResponse,
  FetchWarrantyFullStatsResponse,
  RegisterWarrantyRequest,
  RegisterWarrantyResponse,
  UpdateWarrantyResponse,
} from '../models/warranty';

import { Job }     from '../models/job';
import { Product } from '../models/product';


@Injectable()
export class WarrantyService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService;


  constructor(
    private apiService: ApiService,
  ) {
    this.mockService = new MockService();
  }


  /**
   * Checks the validity of a promotional code to be used during Warranty
   * registration. If valid, the response contains details of the promotion.
   *
   * @param {string} code
   * @return {Observable<CheckPromoCodeResponse>}
   */
  checkPromoCode(code: string): Observable<CheckPromoCodeResponse> {
    return this.apiService.apiGet('/promocode/' + encodeURIComponent(`(code=${code})`))
      .map((res: any): CheckPromoCodeResponse => {
        const responseValid: boolean = res && res.resource && Array.isArray(res.resource);
        const codeValid:     boolean = responseValid && res.resource.length === 1 && res.resource[0].code;
        return {
          error:     responseValid ? null : 'Invalid response from server',
          valid:     codeValid && res.resource[0].code === code && res.resource[0].enabled,
          promoCode: codeValid ? PromoCode.fromAPI(res.resource[0]) : null,
        };
      })
      .catch((err: any): Observable<CheckPromoCodeResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to check promotional code: ${err.error.message}`
            : 'Unable to check promotional code',
          valid:     false,
          promoCode: null,
        })
      );
  }

  /**
   * Checks the validity of a serial number for a specified product
   *
   * @param {string} productId    ID of the product to which the serial number relates
   * @param {string} serialNumber Serial number to check
   * @return {Observable<CheckSerialNumberResponse>}
   */
  checkSerialNumber(productId: string, serialNumber: string): Observable<CheckSerialNumberResponse> {
    return this.apiService.apiPost('/check-serial-number', {product_id: parseInt(productId, 10), serial_number: serialNumber})
      .map((res: any): CheckSerialNumberResponse => {
        return {
          valid:   res.valid,
          message: res.valid ? null : (res.message ? res.message : 'Invalid serial number'),
          serial:  serialNumber,
        };
      })
      .catch((err: any): Observable<CheckSerialNumberResponse> =>
        Observable.of({
          valid:   false,
          serial:  serialNumber,
          message: err && err.error && err.error.message
            ? err.error.message
            : 'Invalid serial number',
        })
      );
  }

  /**
   * Fetches overall Warranty statistics for the current user
   *
   * @return {Observable<FetchWarrantyFullStatsResponse>}
   */
  fetchFullStats(): Observable<FetchWarrantyFullStatsResponse> {
    return this.apiService.apiPost('/warranty/stats', null)
      .map((res: any): FetchWarrantyFullStatsResponse => {
        const valid: boolean = res && res.resource && res.resource.summary && Array.isArray(res.resource.summary) && res.resource.summary.length > 0;
        return {
          error: valid ? null : 'Invalid response from server',
          stats: valid ? WarrantyFullStats.fromAPI(res.resource.summary[0]) : null,
        };
      })
      .catch((err: any): Observable<FetchWarrantyFullStatsResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to fetch warranty statistics: ${err.error.message}`
            : 'Unable to fetch warranty statistics',
          stats: null,
        })
      );
  }

  /**
   * Fetches month-based Warranty statistics between two dates
   *
   * @param {FetchMonthlyWarrantyStatsRequest} req
   * @return {Observable<FetchMonthlyWarrantyStatsResponse>}
   */
  fetchMonthlyWarrantyStats(req: FetchMonthlyWarrantyStatsRequest): Observable<FetchMonthlyWarrantyStatsResponse> {
    return this.apiService.apiPost(
      '/warranty/stats',
      {
        input_date_range_start: moment.utc(req.dateStart + ' ' + '00:00:00').format('YYYY-MM-DD HH:mm:ss'),
        input_date_range_end:   moment.utc(req.dateEnd   + ' ' + '23:59:59').format('YYYY-MM-DD HH:mm:ss'),
      }
    )
      .map((res: any): FetchMonthlyWarrantyStatsResponse => {
        const valid: boolean = res && res.resource && res.resource.byMonth && Array.isArray(res.resource.byMonth);
        return {
          error: valid ? null : 'Invalid response from server',
          data:  valid ? MonthlyWarrantyStats.fromAPI(res.resource.byMonth) : null,
        };
      })
      .catch((err: any): Observable<FetchMonthlyWarrantyStatsResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to fetch monthly warranty statistics: ${err.error.message}`
            : 'Unable to fetch monthly warranty statistics',
          data: null,
        })
      );
  }

  /**
   * Fetches a specified Warranty model
   *
   * @param {FetchWarrantyRequest} req
   * @return {Observable<FetchWarrantyResponse>}
   */
  fetchWarranty(req: FetchWarrantyRequest): Observable<FetchWarrantyResponse> {
    return this.apiService.apiGet('/warranty/' + encodeURIComponent(req.id))
      .map((res: any): FetchWarrantyResponse => {
        const valid: boolean = res && res.id;
        return {
          error:    valid ? null : 'Invalid response from server',
          warranty: valid ? Warranty.getFromApi(res) : null,
        };
      })
      .catch((err: any): Observable<FetchWarrantyResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Could not fetch warranty: ${err.error.message}`
            : 'Could not fetch warranty',
          warranty: null,
        })
      );
  }

  /**
   * Fetches Warranty models
   *
   * @return {Observable<FetchWarrantiesResponse>}
   */
  fetchWarranties(req: FetchWarrantiesRequest): Observable<FetchWarrantiesResponse> {
    const offset = req.perPage * (req.pageNum - 1);
    const limit  = req.perPage;
    const upcoming = req.upcoming;

    return this.apiService.apiGet(`/user-warranties?offset=${offset}&limit=${limit}&upcoming=${upcoming}`)
      .map((res: any): FetchWarrantiesResponse => {
        if (!res || !res.resource || !Array.isArray(res.resource))
          return {
            items: null,
            error: 'Invalid response from server',
            stats: null,
            totalPages: null,
          };

        return {
          items: res.resource.map(Warranty.getFromApi),
          error: null,
          stats: {
            count: res.meta.count,
            complete:   res.resource.filter((v: any): boolean => !!v.job_id).length,
            incomplete: res.resource.filter((v: any): boolean =>  !v.job_id).length,
          },
          totalPages: res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 0,
        };
      })
      .catch((err: any): Observable<FetchWarrantiesResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Could not fetch warranties: ${err.error.message}`
            : 'Could not fetch warranties',
          items: null,
          stats: null,
          totalPages: null,
        })
      );
  }

  /**
   * Fetches all available Product models
   *
   * @return {Observable<FetchProductTypesResponse>}
   */
  FetchProductTypes(forWarrantySubmissions: boolean): Observable<FetchProductTypesResponse> {
    let filterString: string = ''

    if(forWarrantySubmissions){
      filterString = '?filter=(for_warranty_submissions%3d1)';
    }

    return this.apiService.apiGet('/productgroup')
      .map((res: any): FetchProductTypesResponse => {
        const mappedProductArray: Array<any> = [];

        for (let i = 0; i < res.resource.length; i++){
          if(forWarrantySubmissions){
            mappedProductArray.push(Product.getProductForBasicSelection(res.resource[i]));
          }else{
            mappedProductArray.push(Product.getProductsFromApi(res.resource[i]));
          }
        }

        return {
          products: mappedProductArray,
        };
      })
      .catch((err: any): Observable<FetchProductTypesResponse> => {
        return Observable.of({
          products: []
        });
      });
  }

  /**
   * Requests the specified Warranty as a PDF. PDF will be returned as
   * base64-encoded file content.
   *
   * @param {FetchWarrantyPdfRequest} req
   * @return {Observable<FetchWarrantyPdfResponse>}
   */
  getWarrantyPDF(req: FetchWarrantyPdfRequest): Observable<FetchWarrantyPdfResponse> {
    return this.apiService.apiGet(`/warranty-pdf-download?id=${encodeURIComponent(req.id)}`)
      .map((res: any): FetchWarrantyPdfResponse => {
        const valid: boolean = res && res.pdf;
        return {
          error:   valid ? null : 'Invalid response from server',
          pdfData: valid ? res.pdf : null,
        };
      })
      .catch((err: any): Observable<FetchWarrantyPdfResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to get Warranty PDF: ${err.error.message}`
            : 'Unable to get Warranty PDF',
          pdfData: null,
        })
      );
  }

  /**
   * Submits Warranty data to the API.
   *
   * @param {RegisterWarrantyRequest} req
   * @return {Observable<RegisterWarrantyResponse>}
   */
  submit(req: RegisterWarrantyRequest): Observable<RegisterWarrantyResponse> {
    return this.apiService.apiPost('/job', Job.toApiJobNewCustomer(req.warranty))
      .map((res: any): RegisterWarrantyResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length === 1 && res.resource[0].id;
        return {
          error: !valid,

          // TODO: message should come from API
          message: valid ? 'Warranty registration successful' : null,

        };
      })
      .catch((err: any): Observable<RegisterWarrantyResponse> =>
        Observable.of({
          error: true,
          message: err && err.error && err.error.message
            ? `Unable to register warranty: ${err.error.message}`
            : 'Unable to register warranty',
        })
      );
  }

  /**
   * Updates an existing Warranty
   *
   * @param {Warranty} w
   * @return {Observable<UpdateWarrantyResponse>}
   */
  updateWarranty(w: Warranty): Observable<UpdateWarrantyResponse> {
    return this.apiService.apiPatch('/warranty', Warranty.toApiPost(w))
      .map((res: any): UpdateWarrantyResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error:    valid ? null : 'Invalid response from server',
          warranty: valid ? Warranty.getFromApi(res.resource[0]) : null,
        };
      })
      .catch((err: any): Observable<UpdateWarrantyResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to update warranty: ${err.error.message}`
            : 'Unable to update warranty',
          warranty: w,
        })
      );
  }
}
