import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { TrueQuoteSignupFormData } from '../../../models/business-tools';
import { FetchBusinessToolsVerificationTokenRequestAction } from '../../../state-management/actions/business-tools';
import { State as BusinessToolsState } from '../../../state-management/reducers/business-tools';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { StoreState } from '../../../state-management/store';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-truequote',
  templateUrl: './truequote.component.html',
  styleUrls: ['./truequote.component.scss'],
})
export class TruequoteComponent implements OnInit, OnDestroy {

  private businessTools$: Observable<BusinessToolsState>;
  private businessToolsSub$: Subscription = null;
  public formData: Partial<TrueQuoteSignupFormData> = {};
  public formUrl: string = environment.businessTools.truequote;
  private loginDetails$: Observable<LoginDetailsState>;
  private loginDetailsSub$: Subscription = null;

  constructor(
    private store: Store<StoreState>,
  ) {
    this.loginDetails$ = this.store.select('loginDetails');
    this.businessTools$ = this.store.select('businessTools');
    this.store.dispatch(new FetchBusinessToolsVerificationTokenRequestAction({ tool: 'truequote' }));
  }

  ngOnInit() {
    this.businessToolsSub$ = this.businessTools$.subscribe((state: BusinessToolsState) => {
      if (state.verificationToken) {
        this.formData.idExternal = state.verificationToken.token;
      }
    });

    this.loginDetailsSub$ = this.loginDetails$.subscribe((state: LoginDetailsState) => {
      if (state.currentProfile) {
        this.formData.companyName = state.currentProfile.companyName;
        this.formData.country = state.currentProfile.countries_by_country_id.country_code;
        this.formData.email = state.currentProfile.email;
        this.formData.firstName = state.currentProfile.firstName;
        this.formData.gasSafe = state.currentProfile.gasSafeNumber;
        this.formData.lastName = state.currentProfile.lastName;
        this.formData.oftec = state.currentProfile.oftecNumber;
        this.formData.source = 'adey';
        this.formData.telephone = state.currentProfile.telephone ? state.currentProfile.telephone : state.currentProfile.mobile;
      }
    });
  }

  ngOnDestroy() {
    if (this.businessToolsSub$) {
      this.businessToolsSub$.unsubscribe();
    }

    if (this.loginDetailsSub$) {
      this.loginDetailsSub$.unsubscribe();
    }
  }
}
