/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./youtube-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "./youtube-modal.component";
var styles_YoutubeModalComponent = [i0.styles];
var RenderType_YoutubeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YoutubeModalComponent, data: {} });
export { RenderType_YoutubeModalComponent as RenderType_YoutubeModalComponent };
export function View_YoutubeModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["frameborder", "0"], ["height", "315"], ["width", "560"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["mat-button", ""], ["matDialogClose", ""], ["type", "button"]], [[8, "disabled", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_3 = ""; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.videoUrl; _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 10).disabled || null); var currVal_2 = i1.ɵnov(_v, 11).ariaLabel; _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_YoutubeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-youtube-modal", [], null, null, null, View_YoutubeModalComponent_0, RenderType_YoutubeModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.YoutubeModalComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YoutubeModalComponentNgFactory = i1.ɵccf("app-youtube-modal", i7.YoutubeModalComponent, View_YoutubeModalComponent_Host_0, {}, {}, []);
export { YoutubeModalComponentNgFactory as YoutubeModalComponentNgFactory };
