import 'rxjs/add/operator/debounceTime';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { MatDialog } from '@angular/material';

import * as moment from 'moment';


import { WarrantyService } from '../../../services/warranty.service';

import { Job } from '../../../models/job';

import {
  FullAddressItem,
  CheckValidPostcode,
} from '../../../models/postcode-lookup';

import {
  CheckSerialNumberResponse,
} from '../../../models/warranty';

import { PostcodeLookupModalComponent } from '../../common/postcode-lookup-modal/postcode-lookup-modal.component';


/**
 * Encapsulates a filter serial number validation state
 */
interface FilterSerialValidationState {
  pending: boolean;
  valid: boolean;
  message: string;
  serial: string;
}


/**
 * Summary
 *    Creates a form for submitting, viewing and editing Jobs
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnChanges, OnDestroy, OnInit {

  // If set, admin fields will be displayed in the form
  @Input() adminMode: boolean = false;

  // The default User ID used in admin mode when adding a new Warranty.
  @Input() defaultUserId: string = null;

  // If set, form will not be editable
  @Input() readOnly: boolean = false;

  // If set, form submit button will be displayed
  @Input() showSubmit: boolean = true;

  // Label to display on the form submit button
  @Input() submitLabel: string = 'Save';

  // The Job model being viewed/edited. If NULL then the form will be in add
  // mode.
  @Input() job: Job = null;

  // List of product types to display in select
  @Input() productTypes: any = null;

  // Fired when the form is about to be submitted (validity flag emitted)
  @Output() onPreSubmit = new EventEmitter<boolean>();

  // Fired when the form is submitted with the Job model
  @Output() onSubmit = new EventEmitter<Job>();


  // Main Job form
  public fg: FormGroup = null;

  // Subscription to the filterSerial.valueChanges Observable
  private filterSerialSub: Subscription;

  // Current state of filter serial number validation
  public filterSerialValidation: FilterSerialValidationState = {
    pending: false,
    message: null,
    valid: false,
    serial: null,
  };

  // Page errors
  public errors: string = null;

  // Flag to indicate if form has been submitted
  public submitted: boolean = false;

  // List of years for warrantyLength options
  public expiryYears: number[] = [];

  // The current serial number prefix of the currently selected product
  public currentPrefix: string = null;

  // Subscription to valueChanges Observable for "customerPostcode" form field
  private postcodeValueSub: Subscription = null;

  // Reference to the PostcodeLookupModalComponent
  private dialogRef_postcode: any = null;

  // If set, the postcode lookup button will be enabled
  public enablePostcodeLookup: boolean = false;

  // Don't allow installers to set FilterInstalledDate after todays date
  public maxInstallDate = moment().format();

  // Don't allow installers to set FilterInstalledDate before 1st Jan 2017
  public minInstallDate = moment('2017-01-01').format();

  constructor(
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private warrantyService: WarrantyService,
  ) { }

  ngOnChanges() {
    if (!this.fg)
      return;
    if (!this.readOnly)
      this.fg.enable();
    else
      this.fg.disable();

    this.updateCleanerFields();
    this.updateSerialFields();
    this.updateWaterFields();
    this.updateProtectionFields();
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    if (this.postcodeValueSub)
      this.postcodeValueSub.unsubscribe();
    if (this.filterSerialSub)
      this.filterSerialSub.unsubscribe();
  }


  /**
   * Builds the Job form
   */
  buildForm() {
    const newJob = !this.job;
    const disabled = this.readOnly;
    const d = new Date();

    // Unsubscribe from valueChanges Observables if necessary
    if (this.postcodeValueSub) {
      this.postcodeValueSub.unsubscribe();
      this.postcodeValueSub = null;
    }
    if (this.filterSerialSub) {
      this.filterSerialSub.unsubscribe();
      this.filterSerialSub = null;
    }

    // If editing an existing Job, assume filter serial is valid
    if (!newJob)
      this.filterSerialValidation = Object.assign(
        {}, this.filterSerialValidation, {
        serial: this.job.filterSerial,
        valid: true,
      }
      );

    this.expiryYears = [];

    for (let i = d.getFullYear(); i < d.getFullYear() + 80; i++)
      this.expiryYears.push(Number(i));

    this.fg = this._fb.group({
      userId: [{ value: this.getModelStr('userId', this.defaultUserId), disabled }],
      id: [{ value: this.getModelStr('id'), disabled }],

      cleanerUsed: [{ value: this.getModelBool('cleanerUsed'), disabled }, []],
      cleanerSerial: [{ value: this.getModelStr('cleanerSerial'), disabled }, []],

      magnaCleanseUsed: [{ value: this.getModelBool('magnaCleanseUsed'), disabled }, []],
      filterProductID: [{ value: this.getModelStr('filterProductID'), disabled }, []],
      filterUsed: [{ value: this.getModelBool('filterUsed'), disabled }, []],
      filterInstall: [{ value: this.getModelStr('filterInstall', 'New'), disabled }],
      filterInstallDate: [{ value: this.getModelDate('filterInstallDate'), disabled }, []],
      filterExpiryDate: [{ value: this.getModelDate('filterExpiryDate'), disabled }],
      filterSerial: [{ value: this.getModelStr('filterSerial'), disabled }, []],

      waterTestComplete: [{ value: this.getModelBool('waterTestComplete'), disabled }, []],
      waterSerialNo: [{ value: this.getModelStr('waterSerialNo'), disabled }, []],

      protectionUsed: [{ value: this.getModelBool('protectionUsed'), disabled }, []],
      protectionSerial: [{ value: this.getModelStr('protectionSerial'), disabled }, []],

      boilerBrand: [{ value: this.getModelStr('boilerBrand'), disabled }],
      warrantyLength: [{ value: this.getModelStr('warrantyLength', null), disabled }],
      systemState: [{ value: this.getModelStr('systemState', 'New'), disabled }, [Validators.required]],
      systemRads: [{ value: this.getModelNum('systemRads'), disabled }],

      radiatorsType: [{ value: this.getModelStr('radiatorsType'), disabled }],
      pipeworkType: [{ value: this.getModelStr('pipeworkType'), disabled }],
      smartThermostatInstalled: [{ value: this.getModelStr('smartThermostatInstalled'), disabled }],
      smartThermostatModel: [{ value: this.getModelStr('smartThermostatModel'), disabled }],


      customer: this._fb.group({
        customerId: [{ value: this.getCustomerStr('customerId'), disabled }],
        customerTitle: [{ value: this.getCustomerStr('customerTitle', 'Mr'), disabled }, [Validators.required]],
        customerForename: [{ value: this.getCustomerStr('customerForename'), disabled }],
        customerSurname: [{ value: this.getCustomerStr('customerSurname'), disabled }, [Validators.required]],
        customerAddress1: [{ value: this.getCustomerStr('customerAddress1'), disabled }, [Validators.required]],
        customerAddress2: [{ value: this.getCustomerStr('customerAddress2'), disabled }],
        customerTown: [{ value: this.getCustomerStr('customerTown'), disabled }],
        customerCounty: [{ value: this.getCustomerStr('customerCounty'), disabled }],
        customerPostcode: [{ value: this.getCustomerStr('customerPostcode'), disabled }, [Validators.required]],
        customerPhone: [{ value: this.getCustomerStr('customerPhone'), disabled }],
        emails: this._fb.group({
          customerEmail: [{ value: this.getCustomerStr('customerEmail'), disabled }, Validators.email],
          customerConfirmEmail: [{ value: this.getCustomerStr('customerEmail'), disabled }, Validators.email],
        }, { validator: [Validators.required, this.checkEmails] }),

        customerContactByPost: [{ value: this.getCustomerBool('customerContactByPost'), disabled }],
        customerContactByEmail: [{ value: this.getCustomerBool('customerContactByEmail'), disabled }],
        customerContactByPhone: [{ value: this.getCustomerBool('customerContactByPhone'), disabled }],
      })
    });

    this.updateCleanerFields();
    this.updateSerialFields();
    this.updateWaterFields();
    this.updateProtectionFields();

    // Handle changes to filterSerial: perform validation if necessary and
    // update this.filterSerialValidation and the field error states
    // accordingly
    this.filterSerialSub = this.fg.get('filterSerial')
      .valueChanges
      .debounceTime(500)
      .switchMap((serial: string): Observable<CheckSerialNumberResponse> => {
        const productID: string = this.fg.get('filterProductID').value;

        const minLength: number = 5;
        const prefixLength: number = this.currentPrefix ? this.currentPrefix.toString().length : 0;

        // If the length of the entered serial number without the prefix is <
        // the minimum length, return immediate validation failure
        if (minLength > 0 && serial.length - prefixLength < minLength)
          return Observable.of({
            valid: false,
            message: null,
            serial,
          });

        // Perform validation if: -
        //  - A product has been selected
        //  - The serial is different to the previously validated serial (if any)
        //  - The serial is not an empty string
        //  - The serial is not simply the prefix
        if (
          productID &&
          this.filterSerialValidation.serial !== serial &&
          serial.toString().trim().length > 0 &&
          serial !== this.currentPrefix
        ) {
          this.filterSerialValidation = Object.assign({}, this.filterSerialValidation, { pending: true });
          return this.warrantyService.checkSerialNumber(productID, serial);
        }

        // Otherwise, return the current validation settings
        else
          return Observable.of({
            valid: this.filterSerialValidation.serial === serial
              ? this.filterSerialValidation.valid
              : false,
            message: null,
            serial,
          });

      })
      .subscribe((v: CheckSerialNumberResponse) => {
        this.filterSerialValidation = Object.assign(
          {}, this.filterSerialValidation, {
          pending: false,
          message: v.message,
          valid: v.valid,
          serial: this.fg.get('filterSerial').value,
        }
        );
        this.fg.get('filterSerial').setErrors(v.valid ? null : { serial: v.message });
      });

    // Enable/disable postcode lookup button when the customerPostcode field
    // value changes
    this.postcodeValueSub = this.fg.get('customer').get('customerPostcode')
      .valueChanges
      .subscribe((v: string) => {
        this.enablePostcodeLookup = CheckValidPostcode(v);
      });

    this.handleProductChange();
  }

  /**
   * Takes a flag and an array of FormControl and updates each field based on
   * the flag. If the flag is set, the each field will be enabled and a
   * required Validator will be added. If unset, each field will be disabled
   * and all Validators removed.
   *
   * @param {boolean}           flag
   * @param {AbstractControl[]} fields
   */
  updateOptionalFields(flag: boolean, fields: AbstractControl[], required: boolean = true) {
    fields.forEach((f: any) => {
      if (flag && !this.readOnly) {
        f.enable();
      }
      else {
        f.disable();
      }
      f.setValidators(flag && required ? [Validators.required] : []);
      f.updateValueAndValidity();
    });
  }

  /**
   * Updates fields related to the cleaner. Enables/disables and sets
   * validators.
   */
  updateCleanerFields() {
    this.updateOptionalFields(this.fg.get('cleanerUsed').value, [this.fg.get('cleanerSerial')], false);
  }

  /**
   * Updates fields related to the filter. Enables/disables and sets
   * validators.
   */
  updateSerialFields() {
    this.updateOptionalFields(
      this.fg.get('filterUsed').value,
      [
        this.fg.get('filterProductID'),
        this.fg.get('filterInstallDate'),
        this.fg.get('filterSerial'),
      ],
      true
    );
  }

  /**
   * Updates fields related to the water test. Enables/disables and sets
   * validators.
   */
  updateWaterFields() {
    this.updateOptionalFields(this.fg.get('waterTestComplete').value, [this.fg.get('waterSerialNo')], false);
  }

  /**
   * Updates fields related to the system protection. Enables/disables and sets
   * validators.
   */
  updateProtectionFields() {
    this.updateOptionalFields(this.fg.get('protectionUsed').value, [this.fg.get('protectionSerial')], false);
  }


  updateThermostatInstalledField(bool) {
    this.fg.get('smartThermostatInstalled').setValue(bool);
  }

  /**
   * Called when the FilterInstallDate is changed, if the expiry date is before
   * the install date, the expiry date value will be set to the install date
   */
  filterInstallDateChanged() {
    const filterInstallDate = this.fg.controls['filterInstallDate'].value;
    const filterExpiryDate = this.fg.controls['filterExpiryDate'].value;

    if (moment(filterExpiryDate).isBefore(filterInstallDate)) {
      this.fg.controls['filterExpiryDate'].setValue(filterInstallDate);
    }
  }

  /**
   * Updates the filterSerial field with the product prefix when a product is
   * selected if the serial field is blank or contains the previous product
   * prefix.
   */
  handleProductChange() {
    if (!this.productTypes || !this.productTypes.products)
      return;
    for (let i = 0; i < this.productTypes.products.length; i++) {
      if (this.fg.value.filterProductID) {
        if (this.fg.value.filterProductID.toString() === this.productTypes.products[i].id.toString()) {
          if (this.fg.value.filterSerial === '' || this.fg.value.filterSerial === this.currentPrefix) {
            this.fg.get('filterSerial').setValue(this.productTypes.products[i].prefix);
          }
          this.currentPrefix = this.productTypes.products[i].prefix;
        }
      }
    }
  }

  /**
   * Given a FullAddressItem from an address lookup, fills the appropriate
   * field values accordingly
   *
   * @param {FullAddressItem} address
   */
  autofillAddress(address: FullAddressItem) {
    const g: AbstractControl = this.fg.get('customer');
    g.get('customerAddress1').setValue(address.address1);
    g.get('customerAddress2').setValue(address.address2);
    g.get('customerTown').setValue(address.town);
    g.get('customerCounty').setValue(address.county);
    g.get('customerPostcode').setValue(address.postcode);
  }

  /**
   * Opens PostcodeLookupModalComponent with a postcode query in order to
   * perform a lookup. On dialog close, call this.autofillAddress() if a valid
   * address was returned.
   */
  postcodeLookup() {
    this.dialogRef_postcode = this.dialog.open(PostcodeLookupModalComponent, {
      data: {
        postcode: this.fg.value.customer.customerPostcode,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_postcode.afterClosed().subscribe(result => {
      if (result)
        this.autofillAddress(result);
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.fg.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity (customer sub-group fields)
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupCustomerClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    if (!this.fg.controls.customer.get(fieldName))
      return classes;

    const ff = this.fg.controls.customer.get(fieldName);
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Gets a field value as a Boolean or returns the default
   *
   * @param {string}  field
   * @param {boolean} defaultValue Default value if field does not exist
   * @return {boolean}
   */
  getModelBool(field: string, defaultValue: boolean = false): boolean {
    if (!this.job)
      return defaultValue;
    return this.job[field];
  }

  /**
   * Gets a field value as a Date or returns the default
   *
   * @param {string} field
   * @param {Date}   defaultValue Default value if field does not exist
   * @return {Date}
   */
  getModelDate(field: string, defaultValue: Date = new Date()): Date {
    if (!this.job || !this.job[field])
      return defaultValue;
    return moment(this.job[field]).toDate();
  }

  /**
   * Gets a field value as a time string (HH:mm) or returns the default
   *
   * @param {string} field
   * @param {string} defaultValue Default value if field does not exist
   * @return {string}
   */
  getModelTime(field: string, defaultValue: string = moment().format('HH:mm')): string {
    if (!this.job || !this.job[field])
      return defaultValue;
    return moment().format('HH:mm');
  }

  /**
   * Gets a field value as a number or returns the default
   *
   * @param {string} field
   * @param {number} defaultValue Default value if field does not exist
   * @return {number}
   */
  getModelNum(field: string, defaultValue: number = 0): number {
    if (!this.job)
      return defaultValue;
    return this.job[field];
  }

  /**
   * Gets a field value as a string or returns the default
   *
   * @param {string} field
   * @param {string} defaultValue Default value if field does not exist
   * @return {string}
   */
  getModelStr(field: string, defaultValue: string = ''): string {
    if (!this.job || !this.job[field])
      return defaultValue;
    return this.job[field];
  }

  /**
   * Gets a field value as a string or returns the default (customer group)
   *
   * @param {string} field
   * @param {string} defaultValue Default value if field does not exist
   * @return {string}
   */
  getCustomerStr(field: string, defaultValue: string = ''): string {
    if (!this.job || !this.job.customer[field])
      return defaultValue;
    return this.job.customer[field];
  }

  /**
   * Gets a field value as a Boolean or returns the default (customer group)
   *
   * @param {string}  field
   * @param {boolean} defaultValue Default value if field does not exist
   * @return {boolean}
   */
  getCustomerBool(field: string, defaultValue: boolean = false): boolean {
    if (!this.job)
      return defaultValue;
    return this.job.customer[field];
  }

  /**
   * Custom validator which requires that an e-mail address is valid if set but
   * also valid if undefined
   *
   * @return {ValidatorFn}
   */
  nonRequiredEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } =>
      (!control.value || control.value.toString().length === 0)
        ? null
        : Validators.email(control);
  }

  /**
   * Called when the form is submitted: emits the new model via this.onSubmit()
   */
  onSubmitForm() {
    this.errors = null;
    this.submitted = true;

    if (window)
      window.scrollTo(0, 0);

    this.onPreSubmit.emit(this.fg.valid);

    if (this.fg.valid) {
      this.onSubmit.emit(Job.fromFormData(this.fg.value));
      this.fg.controls['filterSerial'].setValue('');
    } else {
      this.errors = 'The form contains errors. Please correct the highlighted fields and try again.';
    }
  }

  /**
   * Compare both inputs to ensure emails match
   * @param group Form group to validate
   */
  private checkEmails(group: FormGroup): null | object {
    const pass = group.get('customerEmail').value;
    const confirmPass = group.get('customerConfirmEmail').value;
    return pass === confirmPass ? null : { error: 'Email addresses do not match.' };
  }
}
