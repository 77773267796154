import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { CountryService } from '../../services/country.service';

import * as ActionTypes from '../actions/country';

import { CountryResponse } from '../../models/country';

@Injectable()
export class CountryEffects {
  constructor(
    private countryService: CountryService,
    private actions$: Actions
  ) { }

  @Effect() countryRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.COUNTRY_REQUEST)
    .switchMap((): Observable<Action> => {
      return this.countryService.getCountries()
        .map((res: CountryResponse): ActionTypes.CountryResponseAction => new ActionTypes.CountryResponseAction(res));
    });

}
