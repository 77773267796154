/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * Modal component for registering sense warranty
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".radio-group[_ngcontent-%COMP%]{display:inline-flex;flex-direction:column}.detail-height[_ngcontent-%COMP%]{min-height:66.5px;line-height:66.5px}.search-icon[_ngcontent-%COMP%]{text-decoration:none}.search-icon-mat[_ngcontent-%COMP%]{min-width:80px}"];
export { styles as styles };
