/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resource-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/waiting-spinner/waiting-spinner.component.ngfactory";
import * as i3 from "../../../shared/waiting-spinner/waiting-spinner.component";
import * as i4 from "../../content-archive/content-item/content-item.component.ngfactory";
import * as i5 from "../../content-archive/content-item/content-item.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./resource-widget.component";
import * as i10 from "@angular/router";
var styles_ResourceWidgetComponent = [i0.styles];
var RenderType_ResourceWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResourceWidgetComponent, data: {} });
export { RenderType_ResourceWidgetComponent as RenderType_ResourceWidgetComponent };
function View_ResourceWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-waiting-spinner", [["kind", "spinner"]], null, null, null, i2.View_WaitingSpinnerComponent_0, i2.RenderType_WaitingSpinnerComponent)), i1.ɵdid(3, 114688, null, 0, i3.WaitingSpinnerComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = "spinner"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ResourceWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      No resources to display. Please click below to see all available\n      resources.\n    "]))], null, null); }
function View_ResourceWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-content-item", [["class", "col-xs-4 border-light-gray content-item"]], null, null, null, i4.View_ContentItemComponent_0, i4.RenderType_ContentItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.ContentItemComponent, [i6.MatDialog, i7.DomSanitizer], { resourceItem: [0, "resourceItem"], widget: [1, "widget"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ResourceWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceWidgetComponent_4)), i1.ɵdid(3, 802816, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resources.slice(0, 3); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ResourceWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "widget-body border-box border-lighter-gray resource-widget background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "content-container col-xs-12 no-pad"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceWidgetComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceWidgetComponent_2)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceWidgetComponent_3)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "widget-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "widget-btn col-xs-12 background-lighter-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn background-green color-white full-width no-radius"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToResources() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["See all resources"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pending; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.resources && (_co.resources.length === 0)) && !_co.pending); _ck(_v, 8, 0, currVal_1); var currVal_2 = ((_co.resources && (_co.resources.length > 0)) && !_co.pending); _ck(_v, 11, 0, currVal_2); }, null); }
export function View_ResourceWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resource-widget", [], null, null, null, View_ResourceWidgetComponent_0, RenderType_ResourceWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i9.ResourceWidgetComponent, [i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceWidgetComponentNgFactory = i1.ɵccf("app-resource-widget", i9.ResourceWidgetComponent, View_ResourceWidgetComponent_Host_0, { resources: "resources", pending: "pending" }, {}, []);
export { ResourceWidgetComponentNgFactory as ResourceWidgetComponentNgFactory };
