import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import * as moment from 'moment';


import { ServiceRequest } from '../../../models/service-record';


/**
 * Summary
 *    Form used for adding a new service request
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-add-service-request',
  templateUrl: './add-service-request.component.html',
  styleUrls: ['./add-service-request.component.scss']
})
export class AddServiceRequestComponent implements OnInit {

  // Flag to indicate if the submit button is displayed
  @Input() showSubmit: boolean = true;

  // Label for submit button (if visible)
  @Input() submitLabel: string = 'Add job record';

  // Fired when the form is valid and submitted
  @Output() onSave = new EventEmitter<ServiceRequest>();


  // Errors for display
  public errors: string = null;

  // Edit form
  public fg: FormGroup;


  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
  }


  /**
   * Builds the main edit form
   */
  buildForm() {
    this.fg = this.fb.group({
      date:        [moment().toDate(),           Validators.required],
      serviceDate: [moment().toDate(),           Validators.required],
      serviceTime: [moment().format('HH:mm:ss'), Validators.required],

      customerTitle:    [null, Validators.required],
      customerForename: [null, Validators.required],
      customerSurname:  [null, Validators.required],
      customerAddress1: [null, Validators.required],
      customerAddress2: [null],
      customerTown:     [null, Validators.required],
      customerCounty:   [null, Validators.required],
      customerPostcode: [null, Validators.required],

      typeOfService: [null, Validators.required],
    });
  }

  /**
   * Emits the ServiceRequest if the form is valid, otherwise sets page errors
   */
  onSubmitForm() {
    if (this.fg.valid)
    {
      this.errors = null;
      this.onSave.emit(ServiceRequest.fromFormData(this.fg.value));
    }
    else
      this.errors = 'The form contains errors. Please correct them and try again';
  }
}
