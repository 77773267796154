import {Injectable} from '@angular/core';
import {TableTypes} from '../components/tableresults/TableTypes.enum';

@Injectable()
export class CheckBoxListService {

  constructor() { }

  /**
   * @param data
   * Collect the ids from the data provided
   *
   * @param {array} data
   * @param {boolean} lastTest
   * @param {string} tableType
   **/
  collectIds(data: any[], lastTest: boolean = false, tableType: TableTypes = null) {

    const ids = [];
      if (data.length > 0 ) {
        data.forEach(function(v){

          if (lastTest && tableType === TableTypes.waterTester) {
            ids.push(parseInt(v['last_test_id'], 10));
          }else {
            ids.push(parseInt(v['id'], 10));
          }
        });
      }
    return ids;
  }

}
