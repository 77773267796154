<div class="col-xs-12 form-email-send-invitation" >
  <h2>{{title}}</h2>
  <div class="email-container">
    <div class="formInput" *ngIf="invitation$ | async; let sendEmail">
      <div *ngIf="(!refreshDisplayMessage)">
        <app-waiting-spinner kind="bar" class="icon-bar text-center color-white" *ngIf="sendEmail.pending">
          {{sendEmail.message}}
        </app-waiting-spinner>
        <div *ngIf="(sendEmail.error || error_form)" class="alert alert-warning text-center">
          {{sendEmail.error || error_form}}
        </div>
        <div *ngIf="sendEmail.success && !error_form" class="alert alert-success text-center">
          {{sendEmail.success}}
        </div>
      </div>
      <form [formGroup]="emailForm" (ngSubmit)="onFormSubmit()">
        <div [class]="formGroupClass('email_address','col-xs-12')">
          <input  type="email" placeholder="Email Address" class="form-control" formControlName="emailAddress" />
        </div>
        <br>
        <br>
        <div [class]="formGroupClass('name','col-xs-12')">
          <input  type="text" placeholder="Name" class="form-control" formControlName="name" style="width: 70%" />
          <button class="btn background-green color-white pull-right no-radius submit-button btn-margin btn-email">{{buttonTextInvite}}</button>
        </div>
        <div [class]="formGroupClass('admin','col-xs-12')">
          <mat-checkbox md-no-ink class="md-primary" formControlName="admin">
            Admin Access rights to company account
          </mat-checkbox>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-btn">
    <button class="btn background-adey-red color-white" (click)="CloseTesterModal()">{{this.closeModalText}}</button>
  </div>
</div>
