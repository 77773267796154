/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activate-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../common/message-panel/message-panel.component.ngfactory";
import * as i3 from "../common/message-panel/message-panel.component";
import * as i4 from "@angular/router";
import * as i5 from "../../shared/waiting-spinner/waiting-spinner.component.ngfactory";
import * as i6 from "../../shared/waiting-spinner/waiting-spinner.component";
import * as i7 from "@angular/common";
import * as i8 from "./activate-profile.component";
import * as i9 from "@ngrx/store";
var styles_ActivateProfileComponent = [i0.styles];
var RenderType_ActivateProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivateProfileComponent, data: {} });
export { RenderType_ActivateProfileComponent as RenderType_ActivateProfileComponent };
function View_ActivateProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-message-panel", [["kind", "ERROR"]], null, null, null, i2.View_MessagePanelComponent_0, i2.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(2, 0, ["\n        ", "\n        "])), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "button", [["class", "btn btn-default"], ["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Return to homepage"])), (_l()(), i1.ɵted(-1, 0, ["\n      "]))], function (_ck, _v) { var currVal_0 = "ERROR"; _ck(_v, 1, 0, currVal_0); var currVal_2 = "/"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.activationError; _ck(_v, 2, 0, currVal_1); }); }
function View_ActivateProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-message-panel", [["kind", "ERROR"]], null, null, null, i2.View_MessagePanelComponent_0, i2.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        The required activation email and/or token could not be found. Please\n        follow the link that was sent in your welcome e-mail. If this does not\n        work, please try copying and pasting the link into your browser's\n        address bar.\n        "])), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "button", [["class", "btn btn-default"], ["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Return to homepage"])), (_l()(), i1.ɵted(-1, 0, ["\n      "]))], function (_ck, _v) { var currVal_0 = "ERROR"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "/"; _ck(_v, 6, 0, currVal_1); }, null); }
function View_ActivateProfileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-message-panel", [["kind", "INFO"]], null, null, null, i2.View_MessagePanelComponent_0, i2.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        Account successfully activated, please close this window and log in to the HomeZone app to continue registering your filters.\n      "]))], function (_ck, _v) { var currVal_0 = "INFO"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ActivateProfileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-waiting-spinner", [["kind", "spinner"]], null, null, null, i5.View_WaitingSpinnerComponent_0, i5.RenderType_WaitingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i6.WaitingSpinnerComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        Your account is being activated, please wait...\n      "]))], function (_ck, _v) { var currVal_0 = "spinner"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ActivateProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "col-xs-12 background-white main-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateProfileComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateProfileComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateProfileComponent_4)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateProfileComponent_5)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.activationError; _ck(_v, 5, 0, currVal_0); var currVal_1 = (!_co.token || !_co.email); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_v.context.$implicit.activationMessage && _co.homeowner); _ck(_v, 11, 0, currVal_2); var currVal_3 = ((_co.token && _co.email) && _v.context.$implicit.activationPending); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_ActivateProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ActivateProfileComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.loginDetails$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ActivateProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activate-profile", [], null, null, null, View_ActivateProfileComponent_0, RenderType_ActivateProfileComponent)), i1.ɵdid(1, 245760, null, 0, i8.ActivateProfileComponent, [i4.ActivatedRoute, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivateProfileComponentNgFactory = i1.ɵccf("app-activate-profile", i8.ActivateProfileComponent, View_ActivateProfileComponent_Host_0, {}, {}, []);
export { ActivateProfileComponentNgFactory as ActivateProfileComponentNgFactory };
