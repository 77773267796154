import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {State as WaterTesterState} from '../../../watertest/state-management/reducers/watertester';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Store} from '@ngrx/store';
import {RemoveTesterQueryRequestAction} from '../../state-management/actions/watertesters';
import {State as LoginDetailsState} from '../../../state-management/reducers/login-details';

@Component({
  selector: 'app-remove-tester-modal',
  templateUrl: './remove-tester-modal.component.html',
  styleUrls: ['./remove-tester-modal.component.scss']
})
export class RemoveTesterModalComponent implements OnInit {
  // "watertester" from store
  public waterTester$: Observable<WaterTesterState>;

  // Reference to user state
  public loginDetailsState$: Observable<LoginDetailsState>;

  //tester company relation id
  public testerRoleId: any = null;

  //Tester id
  public testerId: number = null;

  //my id
  public myId: string = null;

  //display info message every time a open a new modal
  public refreshDisplayMessage: boolean = false;

  //display text close cancel modal
  public closeModalText: string = 'Cancel';

  //display text close success modal
  public closeModalTextSuccess: string = 'Close';

  //message display to confirm
  public info_message_any_member: string = 'Are you sure you want to remove this member?';

  public info_message_self_member: string = 'Are you sure you want to remove your self as admin from the company?';

  //message display when you open modal
  public info_message: string = null;

  public loginRequired: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RemoveTesterModalComponent>,
    private dialog:    MatDialog,
    private _store: Store<any>,
  ) {
    this.waterTester$ = this._store.select('WaterTester');
    this.loginDetailsState$ = this._store.select('loginDetails');
  }

  ngOnInit() {

    this.testerRoleId = this.data.testerRoleId;
    this.refreshDisplayMessage = true;
    this.closeModalText = 'Cancel';

    this.loginDetailsState$.subscribe((state: LoginDetailsState) => {

      this.myId = state.currentProfile.id;
      this.info_message = this.info_message_any_member;
      if (this.myId === this.data.testerId) {
        this.loginRequired = true;
        this.info_message = this.info_message_self_member;
      }
    });

  }


  /**
   * Close TesterModal
   *
   */
  CloseTesterModal() {
    this.dialogRef.close();
  }

  /**
   * Confirm removing tester
   *
   */
  ConfirmRemoveTesterModal() {
    this.refreshDisplayMessage = false;
    this._store.dispatch(new RemoveTesterQueryRequestAction({
      testerRoleId: this.testerRoleId,
      loginRequired: this.loginRequired
    }));
  }

}
