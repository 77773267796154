/**
 * Provides NGRX Reducer for hosted companies
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import {DFResponse, DFTablePaging} from '../../models/df-proclub';
import {HostedCompany} from '../../models/hosted-companies';

import * as actions from '../actions/admin-companies';

// Created using PhpStorm

export interface State {
    companiesLoaded: boolean;
    companies: HostedCompany[];
    selectedCompany: HostedCompany;
    paging: DFTablePaging;
}

export const defaultState: State = {
    companiesLoaded: false,
    companies: undefined,
    selectedCompany: undefined,
    paging: undefined,
};

/**
 * NGRX Reducer interpreting actions to perform against the Company store
 *
 * @param state
 * @param action
 */
export function reducer(
    state: State = defaultState,
    action: actions.Actions
): State {
    let newState = {...defaultState, ...state};

    switch (action.type) {
        case actions.LOAD_COMPANIES:
            newState = {
                ...newState, ...{
                    companiesLoaded: false,
                    companies: null,
                    paging: null,
                }
            };
            break;

        case actions.LOAD_COMPANIES_SUCCESS:
            newState = {
                ...newState, ...{
                    companiesLoaded: true,
                    companies: action.payload.data,
                    paging: action.payload.paging
                }
            };
            break;

        case actions.SELECT_COMPANY:
            newState = {
                ...newState, ...{
                    selectedCompany: newState.companies.find(c => c.id === action.payload.id)
                }
            };
            // console.log(state, action, newState);
            break;
    }

    return newState;
}
