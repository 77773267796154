import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { SocialFeedRequestAction } from '../../state-management/actions/social';
import { State as SocialState } from '../../state-management/reducers/social';

/**
 * Summary
 *    Social media hub page
 *
 * Description
 *    Displays social media feeds (Facebook, Twitter and Instagram)
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-social-hub',
  templateUrl: './social-hub.component.html',
  styleUrls: ['./social-hub.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialHubComponent {

  // Store state
  public state$: Observable<SocialState>;

  constructor(
    private store: Store<any>,
  ) {
    this.state$ = this.store.select('social');

    this.store.dispatch(new SocialFeedRequestAction());
  }

  /**
   * Uses window.open() to open the specified URL in a new tab
   *
   * @param {string} url
   */
  openLink(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
