import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { UserProfile } from '../../../../models/user-profile';
import { ProfileDetailsComponent } from '../../../user-profile/profile-details/profile-details.component';

/**
 * Summary
 *    Displays a dialog used for editing a user (admin details and profile)
 *
 * Description
 *    A user's admin-level details can be edited (e.g. "admin" flag) as well as
 *    their profile. For editing the profile, ProfileDetailsComponent is used
 *    internally.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-user-edit-modal',
  templateUrl: './admin-user-edit-modal.component.html',
  styleUrls: ['./admin-user-edit-modal.component.scss']
})
export class AdminUserEditModalComponent {

  // Reference to the ProfileDetailsComponent child
  @ViewChild(ProfileDetailsComponent) compProfile: ProfileDetailsComponent;

  // Admin part of the form (active and admin flags)
  public fgAdmin: FormGroup;

  // New password to be saved (added via UserEditPasswordModalComponent)
  public newPassword: string = null;

  // The selected Users Profile that will be passed down to the ProfileDetails component
  public profileData: UserProfile = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminUserEditModalComponent>,
    private fb: FormBuilder,
  ) {
    this.fgAdmin = this.fb.group({
      active: [this.data.user ? this.data.user.active : true, Validators.required],
      pointsClaimable: [this.data.user ? this.data.user.profile.pointsClaimable : false, Validators.required],
      admin: [this.data.user ? this.data.user.admin : false, Validators.required],
      userType: [this.data.user ? this.data.user.profile.userType : 2, Validators.required]
    });

    this.profileData = (this.data.user) ? this.data.user.profile : null;

    this.fgAdmin.get('userType').valueChanges.subscribe((val) => {
      if (this.data.user) {
        const updatedProfile = Object.assign(this.data.user.profile, { userType: val });
        this.profileData = updatedProfile;
      }
    });
  }

  /**
   * Closes the dialog and sends the updated AdminUser model if the form is
   * valid. Called by ProfileDetailsComponent::onSubmit().
   *
   * @param {UserProfile} profile Updated UserProfile model from ProfileDetailsComponent
   */
  onProfileDataSubmit(profile: UserProfile) {
    if (this.fgAdmin.valid) {
      profile.pointsClaimable = this.fgAdmin.get('pointsClaimable').value;
      profile.userType = this.fgAdmin.get('userType').value;
    }

    this.dialogRef.close(
      Object.assign(
        {},
        this.data.user ? this.data.user : { id: null, active: true, profile: null },
        {
          active: this.fgAdmin.value.active,
          admin: this.fgAdmin.value.admin,
          profile: profile,
        }
      )
    );
  }

  /**
   * Called when the "Save" button is clicked and calls onSubmitForm() on
   * ProfileDetailsComponent in order to trigger the submit callback
   * (this.onProfileDataSubmit()).
   */
  onSave() {
    this.compProfile.onSubmitForm();
  }
}
