<div class="container-fluid company-page">
  <div class="row content-page">
    <div class="content-watertest">
      <div class="container-title has-sub">
        <h1 class="white">{{companyName}}</h1>
      </div>
      <br>
      <div class="navigator-watertest-table">
        <button class="btn background-adey-red color-white btn-result" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
        &nbsp;
        <button class="btn background-adey-red color-white" (click)="editWatertestCompany(companyId)">Edit Company</button>
        &nbsp;
        <button *ngIf="(isCustomerService)" class="btn background-adey-red color-white" (click)="listMemberWatertestCompany(companyId)">List Members</button>
      </div>
      <div class="container-title has-sub adjust-left">
        <h2 class="white">Results by tester</h2>
      </div>
      <div class="panel-table col-xs-12">
        <table datatable [dtOptions]="dtOptions" class="row-border colCenter hover">
          <thead>
          <tr>
            <th class="colCenter">Tester Name</th>
            <th class="colCenter">Email Address</th>
            <th class="colCenter">Total Tests</th>
          </tr>
          </thead>
          <tbody>

          <tr (click)="goToResultsMember(member.hosted_company_id, member.user_id, member.member)" class="row-data" *ngFor="let member of resultsMember">
            <td>{{ member.member }}</td>
            <td>{{ member.email }}</td>
            <td>{{ member.total }}</td>
          </tr>
          <tr *ngIf="resultsMember?.length == 0">
            <td colspan="2" class="no-data-available">No data!</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="container-title has-sub adjust-left">
        <h2 class="white">Results by address</h2>
      </div>
      <div class="panel-table col-xs-12">
        <table datatable [dtOptions]="dtOptionsAddress" class="row-border colCenter hover">
          <thead>
          <tr>
            <th class="colCenter">Address</th>
            <th class="colCenter">Total Results</th>
          </tr>
          </thead>
          <tbody>
          <tr class="row-data" (click)="goToResultsAddress(address.hosted_company_id, address.location_id, address.full_address)" *ngFor="let address of resultsAddress">
            <td>{{ address.full_address }}</td>
            <td>{{ address.total }}</td>
          </tr>
          <tr *ngIf="resultsAddress?.length == 0">
            <td colspan="2" class="no-data-available">No data!</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
