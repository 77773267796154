import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { ActivateUserResponse, CreatePromoShopTokenResponse, FetchUserProfileResponse, LoadLoginTokenResponse, LoginResponse, RequestResetPasswordResponse, ResetPasswordResponse, TokenCheckResponse, WaterTesterRegisterResponse, } from '../../models/login';
import { NavItems } from '../../models/nav-items';
import { ProCheckSite } from '../../models/procheck-site';
import { LoginService } from '../../services/login.service';
import { GetFeatures } from '../actions/feature';
import * as ActionTypes from '../actions/login-details';
var LoginEffects = /** @class */ (function () {
    function LoginEffects(actions$, loginService, navItems, router, store, translate) {
        var _this = this;
        this.actions$ = actions$;
        this.loginService = loginService;
        this.navItems = navItems;
        this.router = router;
        this.store = store;
        this.translate = translate;
        /**
         * For an ActivateUserRequestAction, call LoginService::activateUser() and
         * dispatch a new ActivateUserResponseAction with the response.
         */
        this.activateUser$ = this.actions$
            .ofType(ActionTypes.ACTIVATE_USER_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.activateUser(req.payload).map(function (res) {
                return new ActionTypes.ActivateUserResponseAction(res);
            });
        });
        /**
         * For an ActivateUserResponseAction, redirect the user to /login. Do not
         * dispatch any further actions.
         */
        this.activateUserResponse$ = this.actions$
            .ofType(ActionTypes.ACTIVATE_USER_RESPONSE)
            .map(function (res) {
            if (!res.payload.error && !res.payload.homeowner)
                _this.router.navigate(['/login']);
        });
        /**
         * For a CheckLoginTokenRequestAction, call LoginService::checkToken() and
         * dispatch a new CheckLoginTokenResponseAction with the response.
         */
        this.checkToken$ = this.actions$
            .ofType(ActionTypes.CHECK_LOGIN_TOKEN_REQUEST)
            .concatMap(function (req) {
            return _this.loginService.checkToken()
                .map(function (res) {
                var u = res.user;
                var proCheck = ProCheckSite.getCheck();
                if (u && u.optIns.length === 0 && !proCheck) {
                    _this.router.navigate(['/optin']);
                }
                else {
                    // Redirect client for other page if token is valid
                    // requested
                    if (res.valid && req.payload.redirectPage) {
                        // console.log('effect : ' + req.payload.redirectPage);
                        _this.router.navigate([req.payload.redirectPage]);
                    }
                    else {
                        // Redirect client if token is invalid and if redirection has been
                        // requested
                        if (!res.valid && req.payload.redirectClientInvalid) {
                            _this.router.navigate([req.payload.redirectClientInvalid]);
                        }
                        // Redirect client if token is valid and if redirection has been
                        // requested (based on user type and confirmed flag)
                        if (res.valid && req.payload.redirectClientValid) {
                            _this.router.navigate([_this.navItems.getDefaultRouteForUser(res.user)]);
                        }
                    }
                }
                return res;
            })
                .map(function (res) {
                return new ActionTypes.CheckLoginTokenResponseAction(res);
            });
        });
        /**
         * For a CheckLoginTokenResponseAction, dispatch a
         * FetchUserProfileRequestAction to get the latest user profile if the
         * response was valid.
         */
        this.checkTokenResponse$ = this.actions$
            .ofType(ActionTypes.CHECK_LOGIN_TOKEN_RESPONSE)
            .filter(function (res) { return res.payload.valid; })
            .map(function (res) {
            _this.store.dispatch(new GetFeatures());
            return new ActionTypes.FetchUserProfileRequestAction();
        });
        /**
         * For a CreatePromoShopTokenRequestAction, call
         * LoginService::createPromoShopToken() and dispatch a new
         * CreatePromoShopTokenResponseAction with the response.
         */
        this.createPromoShopToken$ = this.actions$
            .ofType(ActionTypes.CREATE_PROMOSHOP_TOKEN_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.createPromoShopToken()
                .map(function (res) { return new ActionTypes.CreatePromoShopTokenResponseAction(res); });
        });
        /**
         * For a FetchUserProfileRequestAction, call LoginService::fetchProfile() and
         * dispatch a new FetchUserProfileResponseAction with the response.
         */
        this.fetchProfile$ = this.actions$
            .ofType(ActionTypes.FETCH_USER_PROFILE_REQUEST)
            .concatMap(function (_req) {
            return _this.loginService.fetchProfile()
                .map(function (res) {
                _this.translate.use(res.profile.countries_by_country_id.country_code);
                return new ActionTypes.FetchUserProfileResponseAction(res);
            });
        });
        /**
         * For a LoadLoginTokenRequestAction, call LoginService::loadToken() and
         * dispatch a new LoadLoginTokenResponseAction with the response.
         */
        this.loadToken$ = this.actions$
            .ofType(ActionTypes.LOAD_LOGIN_TOKEN_REQUEST)
            .switchMap(function () {
            return _this.loginService.loadToken()
                .map(function (res) {
                return new ActionTypes.LoadLoginTokenResponseAction(res);
            });
        });
        /**
         * For a LoginRequestAction, call LoginService::login() and dispatch a new
         * LoginResponseAction with the response. Also store the user's token if the
         * response was valid.
         */
        this.loginRequest$ = this.actions$
            .ofType(ActionTypes.LOGIN_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.login(req.payload)
                .map(function (res) {
                if (res.profile)
                    _this.translate.use(res.profile.countries_by_country_id.country_code);
                // Handle redirects if login was successful
                var u = res.user;
                var proCheck = ProCheckSite.getCheck();
                // If login is successful, redirect
                if (u) {
                    if (u.optIns.length === 0 && !proCheck) {
                        _this.router.navigate(['/optin']);
                    }
                    // Redirect client for other page if logged successfully
                    if (req.payload.redirectPage) {
                        _this.router.navigate([req.payload.redirectPage]);
                    }
                    _this.router.navigate([_this.navItems.getDefaultRouteForUser(u)]);
                }
                return new ActionTypes.LoginResponseAction(res);
            });
        });
        /**
         * Resend user account activation email
         * Password reset models are used here as they are identical
         */
        this.resendActivationEmailRequest$ = this.actions$
            .ofType(ActionTypes.RESEND_ACTIVATION_EMAIL_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.resendActivationEmail(req.payload)
                .map(function (res) {
                return new ActionTypes.ResendActivationEmailResponseAction(res);
            });
        });
        /**
         * For a LoginRequestAction, call LoginService::login() and dispatch a new
         * LoginResponseAction with the response. Also store the user's token if the
         * response was valid.
         */
        this.loginExistingRequest$ = this.actions$
            .ofType(ActionTypes.LOGIN_EXISTING_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.loginExisting(req.payload)
                .map(function (res) {
                _this.router.navigate([_this.navItems.getDefaultRouteForUser(res.user)]);
                return new ActionTypes.LoginResponseAction(res);
            });
        });
        /**
         * For a LogoutAction, call LoginService::logout() and redirect the client to
         * "/". Do not dispatch any further actions.
         */
        this.logout$ = this.actions$
            .ofType(ActionTypes.LOGOUT)
            .map(function (a) {
            _this.loginService.logout();
            _this.router.navigate(['/login']);
        });
        /**
         * For a RequestResetPasswordRequestAction, call
         * LoginService::requestResetPassword() and dispatch a new
         * RequestResetPasswordResponseAction with the response.
         */
        this.requestResetPassword$ = this.actions$
            .ofType(ActionTypes.REQUEST_RESET_PASSWORD_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.requestResetPassword(req.payload)
                .map(function (res) {
                return new ActionTypes.RequestResetPasswordResponseAction(res);
            });
        });
        /**
         * For a ResetPasswordRequestAction, call LoginService::resetPassword() and
         * dispatch a new ResetPasswordResponseAction with the response.
         */
        this.resetPassword$ = this.actions$
            .ofType(ActionTypes.RESET_PASSWORD_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.resetPassword(req.payload)
                .map(function (res) {
                return new ActionTypes.ResetPasswordResponseAction(res);
            });
        });
        /**
         * For an UpdateUserProfileRequestAction, call
         * LoginService::updateUserProfile() and dispatch a new
         * UpdateUserProfileResponseAction with the response.
         */
        this.updateProfile$ = this.actions$
            .ofType(ActionTypes.UPDATE_USER_PROFILE_REQUEST)
            .switchMap(function (req) {
            return _this.loginService.updateUserProfile(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateUserProfileResponseAction(res);
            });
        });
        /**
         * For a FetchAccreditationsRequestAction, call LoginService::fetchAccreditations() and
         * dispatch a new FetchAccreditationsResponseAction with the response.
         */
        this.fetchAccreditations$ = this.actions$
            .ofType(ActionTypes.FETCH_ACCREDITATIONS_REQUEST)
            .switchMap(function () {
            return _this.loginService.fetchAccreditations()
                .map(function (res) {
                return new ActionTypes.FetchAccreditationsResponseAction(res);
            });
        });
        this.loginWaterTester$ = this.actions$
            .ofType(ActionTypes.LOGIN_WATER_TESTER_REQUEST)
            .concatMap(function (req) {
            return _this.loginService.loginWaterTester(req.payload)
                .map(function (res) {
                return new ActionTypes.LoginWaterTesterResponseAction(res);
            });
        });
        /**
         * For a LoginWaterTesterRequestAction, call LoginService::loginWaterTester() and dispatch a new
         * LoginWaterTesterResponseAction with the response. Also store the user's token if the
         * response was valid.
         */
        this.registerWaterTester$ = this.actions$
            .ofType(ActionTypes.REGISTER_WATER_TESTER_REQUEST)
            .concatMap(function (req) {
            return _this.loginService.registerWaterTester(req.payload)
                .map(function (res) {
                return new ActionTypes.RegisterWaterTesterResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "activateUser$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "activateUserResponse$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "checkToken$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "checkTokenResponse$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "createPromoShopToken$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "fetchProfile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "loadToken$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "loginRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "resendActivationEmailRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "loginExistingRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "logout$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "requestResetPassword$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "resetPassword$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "updateProfile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "fetchAccreditations$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "loginWaterTester$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginEffects.prototype, "registerWaterTester$", void 0);
    return LoginEffects;
}());
export { LoginEffects };
