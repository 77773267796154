import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Router }       from '@angular/router';
import { Store }        from '@ngrx/store';
import { Observable }   from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

// Store variables
import { StoreState }                 from '../../state-management/store';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { State as EmailState }        from '../../state-management/reducers/email';

import {
  InitEmailStateAction,
  PostContactUsRequestAction
} from '../../state-management/actions/email';

import { PostContactUsRequest } from '../../models/email';


/**
 * Summary
 *    Allow the user to contact ADEY.
 *
 *
 * Description
 *    Displays a contact form that allows the user to enter their
 *    name, email and reason for request. An email is then automatically
 *    generated using the API and sent onto ADEY.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  // Store states
  public loginDetails$: Observable<LoginDetailsState>;
  public emailDetails$: Observable<EmailState>;

  // Subscription to store state
  private loginSub: Subscription = null;

  // Contact form
  public fg: FormGroup = null;

  // Flag to indicate if form has been submitted
  public submitted: boolean = false;

  /**
   * Constructor for page, initialises store, router and formbuilder.
   *
   * @param {FormBuilder} _fb         Initialises a formBuilder instance
   * @param {Router} router           Initialises router object providing navigation between pages
   * @param {Store<StoreState>} store Initialises Store object
   */
  constructor(
    private _fb:    FormBuilder,
    private router: Router,
    private store:  Store<StoreState>
  ) {
    // Dispatch InitEmailStateAction to reset the state variables
    store.dispatch(new InitEmailStateAction());

    // Create a new form using the FormBuilder instance, the form
    // will contain values for the user's name, email and message
    this.fg = this._fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],

    });
    
    // Set references to the login and email states
    this.loginDetails$ = this.store.select('loginDetails');
    this.emailDetails$ = this.store.select('email');
  }

  /**
   * Subscribe to the login state when the page loads in order to retrieve and
   * auto-fill the user's name and email address
   */
  ngOnInit() {
    this.loginSub = this.loginDetails$.subscribe((res) => {
      if (res && res.currentProfile)
      {
        // Set the form values to equal the user's name and email address
        this.fg.get('name').setValue(res.currentProfile.title + ' ' + res.currentProfile.firstName + ' ' + res.currentProfile.lastName);
        this.fg.get('email').setValue(res.currentProfile.email);
      }
    });
  }

  /**
   * Unsubscribe from the login subscription when the page is destroyed to free up space
   */
  ngOnDestroy() {
    if (this.loginSub)
      this.loginSub.unsubscribe();
  }

  /**
   * Apply a class to the form field dependant on the form's state
   *
   * @param {string} fieldName     The name of the field to check
   * @param {extraClasses} string  Extra classes to add to the html
   *
   * @return {string} Return a string of classes to the form field object
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    
    // Add extra classes to the string if any were passed in via html
    if (extraClasses)
      classes += ` ${extraClasses}`;

    // Get a reference to a specific field
    const ff = this.fg.controls[fieldName];
    if (!ff)
      return classes;

    // Return a string of classes based on the form and the current field's state
    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Navigate the user back to the home page
   */
  goHome() {
    // Use the router to navigate to the home page
    this.router.navigate(['/home']);
  }

  /**
   * Called when the user submits the form
   */
  submitContactForm() {
    // Set the submitted flag to true
    this.submitted = true;

    // If the form is valid, dispatch PostContactUsRequestAction with the form values
    // so that the data can be submitted to the API and an email can be sent.
    if (this.fg.valid) {
      this.store.dispatch(new PostContactUsRequestAction(PostContactUsRequest.fromFormData(this.fg.value)));
    }
  }
}
