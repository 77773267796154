/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promotion-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "./promotion-modal.component";
import * as i8 from "@ngrx/store";
var styles_PromotionModalComponent = [i0.styles];
var RenderType_PromotionModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromotionModalComponent, data: {} });
export { RenderType_PromotionModalComponent as RenderType_PromotionModalComponent };
export function View_PromotionModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Win back your heating bills"], ["class", "promo-image"], ["src", "assets/i/promotional/wbyhb.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(11, 0, null, null, 10, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, 0, ["Tell Me More"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, 0, ["Maybe Later"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 15).disabled || null); _ck(_v, 14, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 19).disabled || null); _ck(_v, 18, 0, currVal_1); }); }
export function View_PromotionModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promotion-modal", [], null, null, null, View_PromotionModalComponent_0, RenderType_PromotionModalComponent)), i1.ɵdid(1, 245760, null, 0, i7.PromotionModalComponent, [i2.MatDialogRef, i8.Store, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromotionModalComponentNgFactory = i1.ɵccf("app-promotion-modal", i7.PromotionModalComponent, View_PromotionModalComponent_Host_0, {}, {}, []);
export { PromotionModalComponentNgFactory as PromotionModalComponentNgFactory };
