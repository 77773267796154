import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Store }        from '@ngrx/store';
import { Observable }   from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { MatDialog } from '@angular/material';


import {
  AddEventAction,
  DeleteEventAction,
  FetchEventsRequestAction,
  FetchEventTypesRequestAction,
  UpdateEventAction,
} from '../../state-management/actions/diary';

import { StoreState }                 from '../../state-management/store';
import { State as ContentPagesState } from '../../state-management/reducers/content-pages';
import { State as DiaryState }        from '../../state-management/reducers/diary';

import { DiaryEvent, EventType } from '../../models/diary';

import { DiarySingleEventComponent } from './diary-single-event/diary-single-event.component';
import { ConfirmPromptComponent } from '../common/confirm-prompt/confirm-prompt.component';


/**
 * Summary
 *    The main component for the diary page.
 *    
 *
 * Description
 *    Handles all of the state management for the diary, child components receive data from the state
 *    and will callback to this component in order to interact when interacting with the state.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DiaryComponent implements OnDestroy, OnInit {

  // Store states
  public diary$: Observable<DiaryState>;

  // Subscription to "diary" store state, used to fetch local copy of event
  // types array
  private diarySub$: Subscription;

  // Reference to event edit modal
  private dialogRef_edit;

  // Event types (obtained from store)
  public eventTypes: EventType[] = [];

  /**
   * Constructor for page
   *
   * @param {MatDialog} dialog        Initialises a MatDialog component so that a new modal can be created
   * @param {Store<StoreState>} store Initialises Store object
   */
  constructor(
    private dialog: MatDialog,
    private store:  Store<StoreState>,
  ) {
    // Set a reference to the diary state
    this.diary$ = this.store.select('diary');
  }

  /**
   * Dispatch FetchEventTypesRequestAction and FetchEventsRequestAction to the store in order to
   * retrieve the user's events and event types listed in the API
   */
  ngOnInit() {
    this.store.dispatch(new FetchEventTypesRequestAction());
    this.store.dispatch(new FetchEventsRequestAction());

    // Subscribe to the diary state in order to retrieve a list of event types
    this.diarySub$ = this.diary$.subscribe((state: DiaryState) => {
      this.eventTypes = state.eventTypes;
    });
  }

  /**
   * Unsubscribe from the diary subscription when the page is destroyed to free up space
   */
  ngOnDestroy() {
    if (this.diarySub$)
      this.diarySub$.unsubscribe();
  }


  /**
   * Called from DiaryListEventsComponent when an event is clicked, will open a new 
   * DiarySingleEventComponent within a popup to display the event information
   *
   * @param {DiaryEvent} e Data for the selected event
   */
  editEvent(e: DiaryEvent): void {
    // Open a new DiarySingleEventComponent modal with the event data
    this.dialogRef_edit = this.dialog.open(DiarySingleEventComponent, {
      data: {
        event:      e,
        readOnly:   false,
        eventTypes: this.eventTypes,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    // Determine which action to dispatch based on the data passed back when
    // the modal is closed.
    this.dialogRef_edit.afterClosed().subscribe(result => {
      if (result && result.returnRef) {
        if (result.returnRef === 'SAVE')
          this.handleEditEvent(Object.assign({}, e, result));
        else if (result.returnRef === 'DELETE')
          this.handleDeleteEvent(Object.assign({}, e, result));
      }
    });
  }

  /**
   * Called from DiaryCalendarComponent when a new event has been created
   *
   * @param {DiaryEvent} event Data for the selected event
   */
  handleAddEvent(event: DiaryEvent) {
    this.store.dispatch(new AddEventAction(event));
  }

  /**
   * Called when an event has been edited
   *
   * @param {DiaryEvent} event Data for the selected event
   */
  handleEditEvent(event: DiaryEvent) {
    this.store.dispatch(new UpdateEventAction(event));
  }

  /**
   * Called when an event is to be deleted
   *
   * @param {DiaryEvent} event Data for the selected event
   */
  handleDeleteEvent(event: DiaryEvent) {
    // Display a confirmation modal in order to allow the user to confirm
    // that they wish to delete the selected event.
    const confirmModal = this.dialog.open(ConfirmPromptComponent, {
      data: {
        title: 'Delete event?',
        description: `Are you sure you want to delete the event "${event.details}"?`,
        close: 'Cancel',
        confirm: 'Delete'
      },
      panelClass: 'feature-modal-dialog',
    });

    // Dispatch DeleteEventAction with the event id in order to delete the event
    confirmModal.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new DeleteEventAction(event.id));
    });
  }
}
