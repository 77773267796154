import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import {
  InstallerHubVoucherRequest,
  InstallerHubVoucherValues,
  RewardsResponse,
  SparesRewardsRequest,
} from '../models/rewards';
import { ApiService } from './api.service';

@Injectable()
export class RewardsService {

  constructor(
    private apiService: ApiService,
  ) { }

  getInstallerHubVoucher(req: InstallerHubVoucherRequest): Observable<RewardsResponse<string>> {
    return this.apiService.apiPost('/rewards/installer-hub', req.form)
      .map((res: RewardsResponse<string>): RewardsResponse<string> => {
        return {
          ...res,
          callback: req.callback,
        };
      })
      .catch((err: any): Observable<RewardsResponse> => {
        return Observable.of({
          error: true,
          message: err.error ? err.error.message : err.message,
        });
      });
  }

  getInstallerHubVoucherValues(): Observable<RewardsResponse<InstallerHubVoucherValues>> {
    return this.apiService.apiGet('/rewards/installer-hub/values')
      .map((res: InstallerHubVoucherValues): RewardsResponse<InstallerHubVoucherValues> => {
        return {
          error: null,
          message: 'OK',
          payload: res,
        };
      })
      .catch((): Observable<RewardsResponse> => {
        return Observable.of({
          error: true,
          message: 'Unable to retrieve available vouchers. Please try again later.',
        });
      });
  }

  redeemSparesRewards(req: SparesRewardsRequest): Observable<RewardsResponse> {
    return this.apiService.apiPost('/rewards/spares', req.form)
      .map((res: RewardsResponse): RewardsResponse => {
        return {
          callback: req.callback,
          message: res.message,
          error: null,
        };
      })
      .catch((err: any): Observable<RewardsResponse> => {
        return Observable.of({
          message: err.message,
          error: true,
        });
      });
  }
}
