/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./diary-list-events-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../diary-event/diary-event.component.ngfactory";
import * as i3 from "../diary-event/diary-event.component";
import * as i4 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i5 from "@angular/material/list";
import * as i6 from "@angular/common";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "./diary-list-events-modal.component";
var styles_DiaryListEventsModalComponent = [i0.styles];
var RenderType_DiaryListEventsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DiaryListEventsModalComponent, data: {} });
export { RenderType_DiaryListEventsModalComponent as RenderType_DiaryListEventsModalComponent };
function View_DiaryListEventsModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    No events on this date\n  "]))], null, null); }
function View_DiaryListEventsModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-diary-event", [["colorScheme", "dark"]], null, [[null, "onEventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEventClick" === en)) {
        var pd_0 = (_co.onSelectEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DiaryEventComponent_0, i2.RenderType_DiaryEventComponent)), i1.ɵdid(1, 114688, null, 0, i3.DiaryEventComponent, [], { event: [0, "event"], colorScheme: [1, "colorScheme"] }, { onEventClick: "onEventClick" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "dark"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DiaryListEventsModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list", [["class", "mat-list"]], null, null, null, i4.View_MatList_0, i4.RenderType_MatList)), i1.ɵdid(1, 49152, null, 0, i5.MatList, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DiaryListEventsModalComponent_3)), i1.ɵdid(4, 802816, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.events; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_DiaryListEventsModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["Events on ", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DiaryListEventsModalComponent_1)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DiaryListEventsModalComponent_2)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(16, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "button", [["mat-button", ""], ["matDialogClose", ""], ["type", "button"]], [[8, "disabled", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).dialogRef.close(i1.ɵnov(_v, 21).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor], null, null), i1.ɵdid(21, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = !_co.data.events; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.data.events; _ck(_v, 13, 0, currVal_3); var currVal_6 = ""; _ck(_v, 21, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.data.date)); _ck(_v, 3, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 20).disabled || null); var currVal_5 = i1.ɵnov(_v, 21).ariaLabel; _ck(_v, 19, 0, currVal_4, currVal_5); }); }
export function View_DiaryListEventsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-diary-list-events-modal", [], null, null, null, View_DiaryListEventsModalComponent_0, RenderType_DiaryListEventsModalComponent)), i1.ɵdid(1, 114688, null, 0, i12.DiaryListEventsModalComponent, [i7.MAT_DIALOG_DATA, i7.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DiaryListEventsModalComponentNgFactory = i1.ɵccf("app-diary-list-events-modal", i12.DiaryListEventsModalComponent, View_DiaryListEventsModalComponent_Host_0, {}, {}, []);
export { DiaryListEventsModalComponentNgFactory as DiaryListEventsModalComponentNgFactory };
