import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '../../services/api.service';
import {Company} from '../models/company';

@Injectable()
export class CompanyService {

  constructor(
      private apiService: ApiService
  ) {
  }

  public createCompany(company: Company) {
    return this.apiService.apiPost('/watertest/company', company);
  }

  public editCompany(company: Company, id: number) {
    return this.apiService.apiPut('/watertest/company/' + id, company);
  }

  public getCompanyById(id) {
    return this.apiService.apiGet('/watertest/company/' + id);
  }

}
