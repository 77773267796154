<div class="container-fluid company-page">
    <div class="row content-page">
        <div class="content-watertest">
            <div class="container-title has-sub">
                <h1 class="white">Companies</h1>
            </div>
            <br>
            <div class="navigator-watertest-table">
              <button class="btn background-adey-red color-white btn-result" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
              &nbsp;
              <button class="btn background-adey-red color-white" (click)="regWatertestCompany()">Register Company</button>
            </div>
            <div class="panel-table col-xs-12">
                <table datatable [dtOptions]="dtOptions" class="row-border hover">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Company Name</th>
                        <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="row-data" (click)="goToCompany(company.id)" *ngFor="let company of companies">
                        <td>{{ company.id }}</td>
                        <td>{{ company.name }}</td>
                        <td>{{ company.address_line_1 }}</td>
                    </tr>
                    <tr *ngIf="companies?.length == 0">
                        <td colspan="3" class="no-data-available">No data!</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
