/**
 * ResourceService: provides all functionality related to handling Resources
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


import { ApiService } from './api.service';

import {
  BooleanOperator,
  FetchResourceItemsRequest,
  FetchResourceItemsResponse,
  ResourceItem,
  ResourceItemSearchCriteria,
} from '../models/resources';


@Injectable()
export class ResourceService {

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Fetches ResourceItem models based on search criteria
   *
   * @param {FetchResourceItemsRequest} req
   * @return {Observable<FetchResourceItemsResponse>}
   */
  fetchResources(req: FetchResourceItemsRequest): Observable<FetchResourceItemsResponse> {
    return this.apiService.apiPost(
      '/search-resources',
      {
        page:     req.page,
        category: req.category,
        search:   ResourceItemSearchCriteria.toAPI(req.search),
      }
    )
      .map((res: any): FetchResourceItemsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          items: res.resource.map(ResourceItem.fromAPI),
        };
      })
      .catch((err: any): Observable<FetchResourceItemsResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to get resources: ${err.error.message}`
            : 'Unable to get resources',
          items: null,
        })
      );
  }

}
