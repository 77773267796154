import { Component, Input } from '@angular/core';

@Component({
  selector: 'battery-icon',
  templateUrl: 'battery-icon.component.html',
  styleUrls: ['battery-icon.component.scss']
})
export class BatteryIconComponent {

  @Input('extraClasses') extraClasses;
  @Input('battLevel') battLevel;

  constructor() {}

  battLevelClass(battLevel) {
    if (this.battLevel >= 3) return 'full';
    if (this.battLevel < 3 && this.battLevel > 1.5) return 'half';
    if (this.battLevel <= 1.5) return 'quarter';
  }

}
