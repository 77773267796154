<form class="service-record-form" id="fg" [formGroup]="fg" novalidate (ngSubmit)="onSubmitForm()">

  <app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>

  <div class="row">

    <div class="col-sm-6 col-xs-12">
      <h4>Main details</h4>
      <div class="form-group">
        <mat-form-field>
          <input matInput formControlName="date" min="2017-01-01" [matDatepicker]="dpDate" placeholder="Date" />
          <mat-datepicker #dpDate></mat-datepicker>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpDate"></mat-datepicker-toggle>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input matInput formControlName="lastServiceDate" min="2017-01-01" [matDatepicker]="dpLastServiceDate" placeholder="Last service date" />
          <mat-datepicker #dpLastServiceDate></mat-datepicker>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpLastServiceDate"></mat-datepicker-toggle>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="lastServiceStatus" placeholder="Last service status" />
        </mat-form-field>
      </div>
      <div class="form-group flags" formGroupName="flags">
        <mat-checkbox md-no-ink class="md-primary" formControlName="mc3Used">MC3 + used?</mat-checkbox>
        <mat-checkbox md-no-ink class="md-primary" formControlName="magnaCleanseFlush">MagnaCleanse flush?</mat-checkbox>
        <mat-checkbox md-no-ink class="md-primary" formControlName="magnaCleanFitted">MagnaClean fitted?</mat-checkbox>
        <mat-checkbox md-no-ink class="md-primary" formControlName="mc1Used">MC1 used?</mat-checkbox>
        <mat-checkbox md-no-ink class="md-primary" formControlName="waterTestComplete">Water test complete?</mat-checkbox>
        <mat-checkbox md-no-ink class="md-primary" formControlName="serviceComplete">Service complete?</mat-checkbox>
      </div>
    </div>

    <div class="col-sm-6 col-xs-12" formGroupName="customer">
      <h4>Customer details</h4>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerTitle" placeholder="Title" />
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerForename" placeholder="First name" />
          <mat-error>First name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerSurname" placeholder="Surname" />
          <mat-error>Surname is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input type="text" matInput formControlName="customerPostcode" placeholder="Postcode" />
          <mat-error>Postcode is required</mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="row" *ngIf="!readOnly && showSubmit">
    <div class="btn-group">
      <input class="btn background-green color-white full-width" type="submit" [value]="submitLabel" />
    </div>
  </div>

</form>
