import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { WarrantyService } from '../../services/warranty.service';

import * as ActionTypes from '../actions/warranty-stats';

import {
  FetchWarrantyFullStatsResponse,
  FetchMonthlyWarrantyStatsResponse,
} from '../../models/warranty';

@Injectable()
export class WarrantyStatsEffects {
  constructor(
    private service:  WarrantyService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchWarrantyFullStatsRequestAction, call
   * WarrantyService::fetchFullStats() and dispatch a new
   * FetchWarrantyFullStatsResponseAction with the response.
   */
  @Effect() fetchWarrantieFullStats$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_WARRANTY_FULL_STATS_REQUEST)
    .switchMap((req: ActionTypes.FetchWarrantyFullStatsRequestAction): Observable<ActionTypes.FetchWarrantyFullStatsResponseAction> => {
      return this.service.fetchFullStats()
        .map((res: FetchWarrantyFullStatsResponse): ActionTypes.FetchWarrantyFullStatsResponseAction =>
          new ActionTypes.FetchWarrantyFullStatsResponseAction(res)
        );
    });

  /**
   * For a FetchWarrantyMonthlyStatsRequestAction, call
   * WarrantyService::fetchMonthlyWarrantyStats() and dispatch a new
   * FetchWarrantyMonthlyStatsResponseAction with the response.
   */
  @Effect() fetchWarrantyMonthlyStats$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_WARRANTY_MONTHLY_STATS_REQUEST)
    .switchMap(
      (req: ActionTypes.FetchWarrantyMonthlyStatsRequestAction): Observable<ActionTypes.FetchWarrantyMonthlyStatsResponseAction> => {
        return this.service.fetchMonthlyWarrantyStats(req.payload)
          .map((res: FetchMonthlyWarrantyStatsResponse): ActionTypes.FetchWarrantyMonthlyStatsResponseAction =>
            new ActionTypes.FetchWarrantyMonthlyStatsResponseAction(res)
          );
      }
    );
}
