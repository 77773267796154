/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-archive.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../common/page-content/page-content.component.ngfactory";
import * as i4 from "../common/page-content/page-content.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@ngrx/store";
import * as i7 from "./content-archive.component";
import * as i8 from "@angular/router";
var styles_ContentArchiveComponent = [i0.styles];
var RenderType_ContentArchiveComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ContentArchiveComponent, data: {} });
export { RenderType_ContentArchiveComponent as RenderType_ContentArchiveComponent };
function View_ContentArchiveComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "item-note"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.additionalText; _ck(_v, 1, 0, currVal_0); }); }
function View_ContentArchiveComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "category-item col-sm-4 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToList(_v.context.$implicit.link, _v.context.$implicit.resource) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentArchiveComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n          "]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.additionalText; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.link ? "background-white hover-background-adey-red hover-color-white pointer" : "background-light-gray") + " selection-box  align-center"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.icon; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 9, 0, currVal_2); }); }
function View_ContentArchiveComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "category-row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "color-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "row row-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentArchiveComponent_2)), i1.ɵdid(8, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.categoryTitle; _ck(_v, 3, 0, currVal_0); }); }
export function View_ContentArchiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Resources"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-page-content", [["field", "intro"], ["page", "CONTENT_ARCHIVE"]], null, null, null, i3.View_PageContentComponent_0, i3.RenderType_PageContentComponent)), i1.ɵdid(11, 245760, null, 0, i4.PageContentComponent, [i5.DomSanitizer, i6.Store], { page: [0, "page"], field: [1, "field"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "content-container col-xs-12 no-pad"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentArchiveComponent_1)), i1.ɵdid(16, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "CONTENT_ARCHIVE"; var currVal_1 = "intro"; _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_2 = _co.resourceArray; _ck(_v, 16, 0, currVal_2); }, null); }
export function View_ContentArchiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-archive", [], null, null, null, View_ContentArchiveComponent_0, RenderType_ContentArchiveComponent)), i1.ɵdid(1, 114688, null, 0, i7.ContentArchiveComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentArchiveComponentNgFactory = i1.ɵccf("app-content-archive", i7.ContentArchiveComponent, View_ContentArchiveComponent_Host_0, {}, {}, []);
export { ContentArchiveComponentNgFactory as ContentArchiveComponentNgFactory };
