import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from '../reducers/login-details';

export const selectLoginDetailsState = createFeatureSelector<State>('loginDetails');

export const getProfileIncomplete: MemoizedSelector<object, boolean> = createSelector(
  selectLoginDetailsState,
  loginDetails => {
    const required: string[] = [
      'address1',
      'companyName',
      'email',
      'firstName',
      'lastName',
      'mobile',
      'postCode',
    ];
    if (loginDetails.currentProfile) {
      if (required.every((property) => property in loginDetails.currentProfile)) {
        const c = loginDetails.currentProfile;
        return required.some((p) => c[p] === null || c[p] === ''  || c[p] === ' ');
      } else {
        return true;
      }
    } else {
      // The user's details haven't loaded yet - don't return true
      return false;
    }
  },
);
