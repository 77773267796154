import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { PostcodeLookupService } from '../../services/postcode-lookup.service';
import * as ActionTypes from '../actions/postcode-lookup';
var PostcodeLookupEffects = /** @class */ (function () {
    function PostcodeLookupEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a PostcodeLookupRequestAction, call
         * PostcodeLookupService::queryPostcode() and dispatch a new
         * PostcodeLookupResponseAction with the response.
         */
        this.postcodeLookupRequest$ = this.actions$
            .ofType(ActionTypes.POSTCODE_LOOKUP_REQUEST)
            .switchMap(function (req) {
            return _this.service.queryPostcode(req.payload)
                .map(function (res) {
                return new ActionTypes.PostcodeLookupResponseAction(res);
            });
        });
        /**
         * For a PostcodeLookupResponseAction, call the response's "callback"
         * function if it exists. Do not dispatch any further actions.
         */
        this.postcodeLookupResponse$ = this.actions$
            .ofType(ActionTypes.POSTCODE_LOOKUP_RESPONSE)
            .map(function (res) {
            if (res.payload.callback && !res.payload.error)
                res.payload.callback(res.payload.results);
        });
        /**
         * For a GetPostcodeAddressRequestAction, call
         * PostcodeLookupService::getAddressByID() and dispatch a new
         * GetPostcodeAddressResponseAction with the response.
         */
        this.getPostcodeAddressRequest$ = this.actions$
            .ofType(ActionTypes.GET_POSTCODE_ADDRESS_REQUEST)
            .switchMap(function (req) {
            return _this.service.getAddressByID(req.payload)
                .map(function (res) {
                return new ActionTypes.GetPostcodeAddressResponseAction(res);
            });
        });
        /**
         * For a GetPostcodeAddressResponseAction, call the response's "callback"
         * function if it exists. Do not dispatch any further actions.
         */
        this.getPostcodeAddressResponse$ = this.actions$
            .ofType(ActionTypes.GET_POSTCODE_ADDRESS_RESPONSE)
            .map(function (res) {
            if (res.payload.callback && !res.payload.error)
                res.payload.callback(res.payload.address);
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], PostcodeLookupEffects.prototype, "postcodeLookupRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], PostcodeLookupEffects.prototype, "postcodeLookupResponse$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], PostcodeLookupEffects.prototype, "getPostcodeAddressRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], PostcodeLookupEffects.prototype, "getPostcodeAddressResponse$", void 0);
    return PostcodeLookupEffects;
}());
export { PostcodeLookupEffects };
