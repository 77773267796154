import {
  Component,
  Inject,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';

@Component({
  selector: 'app-admin-promotions-create-modal',
  templateUrl: './admin-promotions-create-modal.component.html',
  styleUrls: ['./admin-promotions-create-modal.component.scss']
})
export class AdminPromotionsCreateModalComponent {

  public fg: FormGroup;
  public errors: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminPromotionsCreateModalComponent>,
    private fb: FormBuilder,
  ) {
    this.fg = this.fb.group({
      productId: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', Validators.maxLength(65535)],
      reference: ['', [Validators.required, Validators.maxLength(255)]],
      points: ['', [Validators.required, Validators.maxLength(11), Validators.min(0)]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  public onSave(): void {
    if (this.fg.valid) {
      this.dialogRef.close(this.fg.value);
    } else {
      this.errors = 'The form is invalid. Please correct any errors and try again.';
    }
  }
}
