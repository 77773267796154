import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import * as ActionTypes from '../actions/business-tools';
import { BusinessToolsService } from '../../services/business-tools.service';
var BusinessToolsEffects = /** @class */ (function () {
    function BusinessToolsEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        this.fetchBusinessToolsRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_BUSINESS_TOOLS_REQUEST)
            .switchMap(function (req) {
            return _this.service.getBusinessTools()
                .map(function (res) {
                return new ActionTypes.FetchBusinessToolsResponseAction(res);
            });
        });
        this.fetchBusinessToolsVerificationRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST)
            .switchMap(function (req) {
            return _this.service.getBusinessToolVerificationToken(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchBusinessToolsVerificationTokenResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], BusinessToolsEffects.prototype, "fetchBusinessToolsRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], BusinessToolsEffects.prototype, "fetchBusinessToolsVerificationRequest$", void 0);
    return BusinessToolsEffects;
}());
export { BusinessToolsEffects };
