/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-content-pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/message-panel/message-panel.component.ngfactory";
import * as i3 from "../../../common/message-panel/message-panel.component";
import * as i4 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/common";
import * as i11 from "./admin-content-pages.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "@ngrx/store";
var styles_AdminContentPagesComponent = [i0.styles];
var RenderType_AdminContentPagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminContentPagesComponent, data: {} });
export { RenderType_AdminContentPagesComponent as RenderType_AdminContentPagesComponent };
function View_AdminContentPagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-message-panel", [["kind", "ERROR"]], null, null, null, i2.View_MessagePanelComponent_0, i2.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "ERROR"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.error; _ck(_v, 2, 0, currVal_1); }); }
function View_AdminContentPagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "col-xs-12 col-sm-6 col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 22, "mat-card", [["class", "mat-card"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i5.MatCard, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i5.MatCardTitle, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i5.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(11, null, ["", " field(s)"])), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i5.MatCardContent, [], null, null), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(17, 0, null, 0, 6, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(18, 16384, null, 0, i5.MatCardActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, 0, ["Edit page content"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 7, 0, currVal_0); var currVal_1 = _v.context.$implicit.fields.length; _ck(_v, 11, 0, currVal_1); var currVal_2 = _v.context.$implicit.description; _ck(_v, 15, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 18).align === "end"); _ck(_v, 17, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 21).disabled || null); _ck(_v, 20, 0, currVal_4); }); }
function View_AdminContentPagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "content-pages row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminContentPagesComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminContentPagesComponent_3)), i1.ɵdid(6, 802816, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.error; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.pages; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AdminContentPagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AdminContentPagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.contentPages$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdminContentPagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-content-pages", [], null, null, null, View_AdminContentPagesComponent_0, RenderType_AdminContentPagesComponent)), i1.ɵdid(1, 49152, null, 0, i11.AdminContentPagesComponent, [i12.MatDialog, i13.Store], null, null)], null, null); }
var AdminContentPagesComponentNgFactory = i1.ɵccf("app-admin-content-pages", i11.AdminContentPagesComponent, View_AdminContentPagesComponent_Host_0, {}, {}, []);
export { AdminContentPagesComponentNgFactory as AdminContentPagesComponentNgFactory };
