import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';

import * as ActionTypes from '../actions/admin-stats';

import {
  AdminStats,
  FetchAdminStatsResponse,
  FetchAdminStatsDownloadRequest,
  FetchAdminStatsDownloadResponse,
} from '../../models/admin-stats';

@Injectable()
export class AdminStatsEffects {
  constructor(
    private service:  AdminService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchAdminStatsRequestAction, call AdminService::fetchStats() and
   * dispatch a new FetchAdminStatsResponseAction with the response.
   */
  @Effect() fetchStats$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_STATS_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminStatsRequestAction): Observable<ActionTypes.FetchAdminStatsResponseAction> =>
      this.service.fetchStats(req.payload)
        .map((res: FetchAdminStatsResponse): ActionTypes.FetchAdminStatsResponseAction =>
          new ActionTypes.FetchAdminStatsResponseAction(res)
        )
    );
/*
  @Effect() fetchEvents$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_EVENTS_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminEventsRequestAction): Observable<ActionTypes.FetchAdminEventsResponseAction> =>
      this.service.fetchEvents(req.payload)
        .map((res: FetchAdminEventsResponse): ActionTypes.FetchAdminEventsResponseAction =>
          new ActionTypes.FetchAdminEventsResponseAction(res)
        )
    );
*/


  /**
   * For a FetchAdminStatsDownloadRequestAction, call
   * AdminService::fetchStatsDownload() and dispatch a new
   * FetchAdminStatsDownloadResponseAction with the response.
   */
  @Effect() fetchStatsDownload$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_STATS_DOWNLOAD_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminStatsDownloadRequestAction): Observable<ActionTypes.FetchAdminStatsDownloadResponseAction> =>
      this.service.fetchStatsDownload(req.payload)
        .map((res: FetchAdminStatsDownloadResponse): ActionTypes.FetchAdminStatsDownloadResponseAction =>
          new ActionTypes.FetchAdminStatsDownloadResponseAction(res)
        )
    );
}
