import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DatatableService } from './datatable.service';
import {Observable} from 'rxjs/Observable';
import {
  AdminTesterQueryRequest,
  AdminTesterQueryResponse,
  CompanyTotalQueryResponse,
  GetTesterLocalQueryRequest,
  GetTesterLocalQueryResponse,
  GetTesterQueryRequest,
  GetTesterQueryResponse,
  RemoveTesterQueryRequest,
  RemoveTesterQueryResponse,
  ResultEmailQueryRequest,
  ResultEmailQueryResponse,
  TesterRoleQueryRequest,
  TesterRoleQueryResponse,
  TesterTotalQueryResponse,
  TestExportQueryRequest,
  TestExportQueryResponse,
  TestOverDueTotalQueryRequest,
  TestOverDueTotalQueryResponse,
  TestStatusPropertyTotalQueryResponse,
  TestTotalQueryResponse
} from '../models/watertester';
import * as moment from 'moment';

@Injectable()
export class WatertestersService {

  constructor(
      private apiService: ApiService,
      private dataTable: DatatableService
  ) { }

  /**
   * List water testers on company
   *
   * @param dataTablesParameters
   */
  public getUsers(dataTablesParameters) {
    const param = this.dataTable.joinParameters(dataTablesParameters);
    return this.apiService.apiGet('/watertest/user' + param);
  }

  /**
   * List water testers
   *
   * @param dataTablesParameters
   * @param search
   * @param type
   */

  public getWatertesters(dataTablesParameters, search, type) {
    const param = this.dataTable.joinParameters(dataTablesParameters, search);

    if (type === 'company') {
      return this.apiService.apiGet('/watertest/all-testers-company' + param);
    } else {
      return this.apiService.apiGet('/watertest/all-testers' + param);
    }
  }


  /**
   * Get Company Total
   *
   * @return {Observable<CompanyTotalQueryResponse>}
   */
  getCompanyTotal(): Observable<CompanyTotalQueryResponse> {
    return this.apiService.apiGet('/watertest/company?pagesize=1')
      .map((res: any): CompanyTotalQueryResponse => {
        const total = res['paging']['results'];
        return {
          message: 'Get company total successfully.',
          totalCompany: total,
          valid: true,
        };
      })
      .catch((err: any): Observable<CompanyTotalQueryResponse> => {
        //console.log('total company : error');
        return Observable.of({
          message: 'Invalid Request API.',
          totalCompany: 0,
          valid: false
        });
      });
  }

  /**
   * Get Tester Total
   *
   * @return {Observable<TesterTotalQueryResponse>}
   */
  getTesterTotal(): Observable<TesterTotalQueryResponse> {
    return this.apiService.apiGet('/watertest/all-testers?pagesize=1')
      .map((res: any): TesterTotalQueryResponse => {
        //console.log('total tester : ', res['paging']['results']);
        return {
          message: 'Get tester total successfully.',
          totalTester: res['paging']['results'],
          valid: true,
        };
      })
      .catch((err: any): Observable<TesterTotalQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          totalTester: 0,
          valid: false
        });
      });
  }

  /**
   * Get Test Total
   *
   * @return {Observable<TestTotalQueryResponse>}
   */
  getTestTotal(): Observable<TestTotalQueryResponse> {
    return this.apiService.apiGet('/watertest/result-with-address?pagesize=1')
      .map((res: any): TestTotalQueryResponse => {
        return {
          message: 'Get test total successfully.',
          testTotal: res['paging']['results'],
          valid: true,
        };
      })
      .catch((err: any): Observable<TestTotalQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          testTotal: 0,
          valid: false
        });
      });
  }

  /**
   * Get latest test status property Total by filter pass or fail
   *
   * @param filter {number}
   * @return {Observable<TestStatusPropertyTotalQueryResponse>}
   */
  getTestStatusPropertyTotal(filter: number): Observable<TestStatusPropertyTotalQueryResponse> {
    return this.apiService.apiGet('/watertest/test-log?filter=last_test_pass=' + filter)
      .map((res: any): TestStatusPropertyTotalQueryResponse => {
        return {
          message: 'Get test status property total successfully.',
          testStatusPropertyTotal: res['paging']['results'],
          valid: true,
        };
      })
      .catch((err: any): Observable<TestStatusPropertyTotalQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          testStatusPropertyTotal: 0,
          valid: false
        });
      });
  }


  /**
   * Get tests over due by months
   *
   * @param req {TestOverDueTotalQueryRequest}
   * @return {Observable<TestOverDueTotalQueryResponse>}
   */
  getTestDueTotal(req: TestOverDueTotalQueryRequest): Observable<TestOverDueTotalQueryResponse> {
    const startDate = this.getDate(req.monthStart);
    const endDate = (req.monthEnd) ? this.getDate(req.monthEnd) : false;
    let query;
    if (req.monthStart && req.monthEnd) {
      //console.log('next month over due');
      query = '(((last_pass IS NOT NULL) AND (last_pass > ' + startDate + ') AND (last_pass <= ' + endDate + '))';
      query += ' OR ((last_pass IS NULL) AND (last_test > ' + startDate + ') AND (last_test <= ' + endDate + ')))';
    } else {
      //console.log('over due');
      query = '((last_pass IS NOT NULL) AND (last_pass <= ' + startDate + ')';
      query += ' OR ((last_pass IS NULL) AND (last_test <= ' + startDate + ')))';
    }
    //console.log(query);
    return this.apiService.apiGet('/watertest/test-log?filter=' + query)
      .map((res: any): TestOverDueTotalQueryResponse => {
        return {
          message: 'Get test over due successfully.',
          testOverDueTotal: res['paging']['results'],
          valid: true,
        };
      })
      .catch((err: any): Observable<TestOverDueTotalQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          testOverDueTotal: 0,
          valid: false
        });
      });
  }

  /**
   * Remove tester from company
   *
   * @param {RemoveTesterQueryRequest} req
   * @return {Observable<RemoveTesterQueryResponse>}
   */
  removeTesterFromCompany(req: RemoveTesterQueryRequest): Observable<RemoveTesterQueryResponse> {

    return this.apiService.apiDelete('/watertest/user-role/' + req.testerRoleId)
      .map((res: any): RemoveTesterQueryResponse => {
        if (req.loginRequired) {
            window.localStorage.removeItem('login_token');
            window.location.href = '/';
        }
        $('#table-watertesters').DataTable().ajax.reload();
        return {
          message: 'Member removed successfully.',
          results: res,
          valid: true,
        };
      })
      .catch((err: any): Observable<RemoveTesterQueryResponse> => {
        //console.log('err : ', err);
        return Observable.of({
          message: err.error ? err.error.message : 'Invalid Request API.',
          results: null,
          valid: false
        });
      });
  }

  /**
   * Admin tester company
   *
   * @param {AdminTesterQueryRequest} req
   * @return {Observable<AdminTesterQueryResponse>}
   */
  adminTesterCompany(req: AdminTesterQueryRequest): Observable<AdminTesterQueryResponse> {
      const params = {
        'new_user_id': req.testerId,
        'hosted_company_id': req.companyId,
        'new_role_id': req.roleId
      };
      return this.apiService.apiPost('/watertest/user-role', params)
        .map((res: any): AdminTesterQueryResponse => {
          if (req.loginRequired) {
            window.localStorage.removeItem('login_token');
            window.location.href = '/';
          }
          $('#table-watertesters').DataTable().ajax.reload();
          return {
            message: 'Member role changed successfully.',
            results: res,
            valid: true,
          };
        })
        .catch((err: any): Observable<AdminTesterQueryResponse> => {
          //console.log('err : ', err);
          return Observable.of({
            message: err.error ? err.error.message : 'Invalid Request API.',
            results: null,
            valid: false
          });
        });
  }

  /**
   * Get tester
   *
   * @param {GetTesterQueryRequest} req
   * @return {Observable<GetTesterQueryResponse>}
   */
  getTester(req: GetTesterQueryRequest): Observable<GetTesterQueryResponse> {
      //console.log('Request details tester from company ', req);
      return this.apiService.apiGet('/watertest/all-testers?filter=user_id=' + req.testerId)
        .map((res: any): GetTesterQueryResponse => {
          return {
            message: 'Member details successfully.',
            results: res,
            valid: true,
          };
        })
        .catch((err: any): Observable<GetTesterQueryResponse> => {
          return Observable.of({
            message: 'Invalid Request API.',
            results: null,
            valid: false
          });
        });
  }

  /**
   * Get tester local
   *
   * @param {GetTesterLocalQueryRequest} req
   * @return {Observable<GetTesterLocalQueryResponse>}
   */
  getTesterLocal(req: GetTesterLocalQueryRequest): Observable<GetTesterLocalQueryResponse> {
    return this.apiService.apiGet('/watertest/test-log?filter=watertest_location_id=' + req.localId)
      .map((res: any): GetTesterLocalQueryResponse => {
        return {
          message: 'Location details successfully.',
          results: res,
          valid: true,
        };
      })
      .catch((err: any): Observable<GetTesterLocalQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          results: null,
          valid: false
        });
      });
  }

  /**
   * Send Result email
   *
   * @param {ResultEmailQueryRequest} req
   * @return {Observable<ResultEmailQueryResponse>}
   */
  sendResultEmail(req: ResultEmailQueryRequest): Observable<ResultEmailQueryResponse> {
    const data = {'emails': [req.email], 'resultIds': req.resultId, body: req.body};
    return this.apiService.apiPost('/watertest/reports/test-result/email', data)
      .map((res: any): ResultEmailQueryResponse => {
        return {
          message: 'Results sent successfully.',
          results: res,
          valid: true,
        };
      })
      .catch((err: any): Observable<ResultEmailQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          results: null,
          valid: false
        });
      });
  }

  /**
   * Get Tester Role
   *
   * @param {TesterRoleQueryRequest} req
   * @return {Observable<TesterRoleQueryResponse>}
   */
  getTesterRole(req: TesterRoleQueryRequest): Observable<TesterRoleQueryResponse> {
    return this.apiService.apiGet('/watertest/user-role?filter=user_id=' + req.testerId)
      .map((res: any): TesterRoleQueryResponse => {
        let isWaterTesterSingle = false;
        let isWaterTesterAdmin = false;
        res['data'].forEach(function (v) {
            if (v['role_id'] === 5) {
              isWaterTesterAdmin = true;
            } else if ( v['role_id'] === 4) {
              isWaterTesterSingle = true;
            }
        });
        return {
          message: 'Get tester role successfully.',
          isWaterTesterSingle: isWaterTesterSingle,
          isWaterTesterAdmin: isWaterTesterAdmin,
          valid: true,
        };
      })
      .catch((err: any): Observable<TesterRoleQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          isWaterTesterSingle: false,
          isWaterTesterAdmin: false,
          valid: false
        });
      });
  }


  /**
   * Get tests to export
   *
   * @return {Observable<TestExportQueryResponse>}
   */
  getExportData(req: TestExportQueryRequest): Observable<TestExportQueryResponse> {
    const admin = req.isAdmin ? '/admin' : '';
    return this.apiService.apiGet('/watertest' + admin + '/reports/export?filter=' + req.filter)
      .map((res: any): TestExportQueryResponse => {
        return {
          message: 'Get tests to export successfully.',
          data: res['data'],
          valid: true,
        };
      })
      .catch((err: any): Observable<TestExportQueryResponse> => {
        return Observable.of({
          message: 'Invalid Request API.',
          data: [],
          valid: false
        });
      });
  }

  /**
   * return x months ago date
   */
  public getDate(month: number) {
    return moment().subtract(month, 'months').format('Y-M-D');
  }

}
