/**
 * Mock for WarrantyService (see main service for full details)
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import { Injectable }      from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable }      from 'rxjs/Observable';

import * as moment from 'moment';

import { Customer } from '../models/customer';

import {
  ProductType,
  PromoCode,
  Warranty,

  CheckPromoCodeResponse,
  CheckSerialNumberResponse,
  FetchProductTypesResponse,
  FetchMonthlyWarrantyStatsRequest,
  FetchMonthlyWarrantyStatsResponse,
  FetchWarrantyRequest,
  FetchWarrantyResponse,
  FetchWarrantiesResponse,
  FetchWarrantyPdfRequest,
  FetchWarrantyPdfResponse,
  FetchWarrantyFullStatsResponse,
  RegisterWarrantyRequest,
  RegisterWarrantyResponse,
  UpdateWarrantyResponse,
} from '../models/warranty';

@Injectable()
export class WarrantyService {

  checkPromoCode(code: string): Observable<CheckPromoCodeResponse> {
    return Observable
      .of({
        error: code === 'ERROR' ? 'Unable to check promotional code' : null,
        valid: code !== 'INVALID' && code !== 'ERROR',
        promoCode: code === 'INVALID' || code === 'ERROR'
          ? null
          : Object.assign({}, PromoCode.getExample(), {code}),
      })
      .delay(1000);
  }

  checkSerialNumber(productId: string, serialNumber: string): Observable<CheckSerialNumberResponse> {
    const taken = serialNumber === 'TAKEN';
    const valid = !taken && serialNumber !== 'INVALID';

    return Observable
      .of({
        valid,
        message: valid ? null : (
          taken
            ? 'This serial number has already been registered'
            : 'This serial number is invalid'
        ),
      })
      .delay(1000);
  }

  fetchFullStats(): Observable<FetchWarrantyFullStatsResponse> {
    return Observable
      .of({
        error: null,
        stats: {
          completeWarranties:    12,
          incompleteWarranties:   2,
          totalWarranties:       78,
          warrantiesLastMonth:    3,
          pointsAvailable:      106,
          pointsEarned:         160,
          pointsSpent:           60,
        },
      })
      .delay(1000);
  }

  fetchMonthlyWarrantyStats(req: FetchMonthlyWarrantyStatsRequest): Observable<FetchMonthlyWarrantyStatsResponse> {
    const sd = moment(req.dateStart);
    const ed = moment(req.dateEnd);

    const valid = ed.diff(sd) > 0;
    /*
    let labels = null;
    let years  = null;
    let data   = null;

    if (valid)
    {
      // Generate labels for all months
      labels = [];
      for (let m = 0; m < Math.floor(moment.duration(ed.diff(sd)).asMonths()) + 1; m++)
        labels.push(moment(sd).add(m, 'months').format('YYYY-MM'));

      // Generate list of unique years between sd and ed
      years = [];
      for (let y = 0; y < Math.ceil(moment.duration(ed.diff(sd)).asYears()) + 1; y++)
        years.push(moment(sd).add(y, 'years').year());

      data = [];

      for (let y = 0; y < years.length; y++)
      {
        const yd = [];
        for (let l = 0; l < labels.length; l++)
          yd.push(Math.floor(Math.random() * 50));
        data.push({data: yd, label: `Warranties registered ${years[y]}`});
      }
    }
    */

    return Observable
      .of({
        error: valid ? null : 'Specified end date is before start date',
        data: null,
        //data: {data, labels},
      })
      .delay(1000);
  }

  fetchWarranty(req: FetchWarrantyRequest): Observable<FetchWarrantyResponse> {
    const warrantyData: Warranty = Warranty.getExample();

    warrantyData.customer = Customer.toFormData(warrantyData.customer);

    return Observable.of({
      error: null,
      warranty: warrantyData
    })
    .delay(1000);
  }

  fetchWarranties(): Observable<FetchWarrantiesResponse> {
    return Observable
      .of({
        error: null,
        stats: {
          count:      4,
          complete:   3,
          incomplete: 1,
        },
        totalPages:   1,
        items: [
          {
            id:                       '1',
            productType:              'type1',
            serialNumber:             'AB123',
            dateOfInstallation:       '2017-08-25',
            expiryDate:               '2018-08-25',
            typeOfBoiler:             'NEW',
            typeOfFilter:             'EXISTING',
            oldFilterBrand:           'Filters Ltd.',
            customer:                 Customer.getExample(),
            customerID:               1,
            addRenewalDateToCalendar: true,
            notifyClientBeforeExpiry: true,
            promoCode:                'promo-001',
          },
          {
            id:                       '2',
            productType:              'type2',
            serialNumber:             'AB124',
            dateOfInstallation:       '2017-08-26',
            expiryDate:               '2018-08-26',
            typeOfBoiler:             'EXISTING',
            typeOfFilter:             'NEW',
            oldFilterBrand:           'Filters \'r\' Us.',
            customerID:               2,
            customer:                 Customer.getExample(),
            addRenewalDateToCalendar: false,
            notifyClientBeforeExpiry: false,
            promoCode:                'promo-002',
          },
          {
            id:                       '3',
            productType:              'type3',
            serialNumber:             'AB125',
            dateOfInstallation:       '2017-08-24',
            expiryDate:               '2018-08-24',
            typeOfBoiler:             'NEW',
            typeOfFilter:             'NEW',
            oldFilterBrand:           null,
            customerID:               3,
            customer:                 Customer.getExample(),
            addRenewalDateToCalendar: true,
            notifyClientBeforeExpiry: false,
            promoCode:                'promo-003',
          },
        ],
      })
      .delay(1000);
  }

  FetchProductTypes(): Observable<FetchProductTypesResponse> {
    return Observable
      .of({
        products: [
          {id: 'type1', name: 'Product type 1'},
          {id: 'type2', name: 'Product type 2'},
          {id: 'type3', name: 'Product type 3'},
          {id: 'type4', name: 'Product type 4'},
          {id: 'type5', name: 'Product type 5'},
        ]
      })
      .delay(1000);
  }

  getWarrantyPDF(req: FetchWarrantyPdfRequest): Observable<FetchWarrantyPdfResponse> {
    const valid: boolean = !!req.id;
    return Observable
      .of({
        error: valid ? null : 'Invalid Warranty ID',
        pdfData: valid
          ? 'JVBERi0xLjAKMSAwIG9iajw8L1R5cGUvQ2F0YWxvZy9QYWdlcyAyIDAgUj4+ZW5kb2JqIDIgMCBvYmo8PC9UeXBlL1BhZ2VzL0tpZHNbMyAwIFJdL0NvdW50IDE+PmVuZG9iaiAzIDAgb2JqPDwvVHlwZS9QYWdlL01lZGlhQm94WzAgMCAzIDNdPj5lbmRvYmoKeHJlZgowIDQKMDAwMDAwMDAwMCA2NTUzNSBmCjAwMDAwMDAwMTAgMDAwMDAgbgowMDAwMDAwMDUzIDAwMDAwIG4KMDAwMDAwMDEwMiAwMDAwMCBuCnRyYWlsZXI8PC9TaXplIDQvUm9vdCAxIDAgUj4+CnN0YXJ0eHJlZgoxNDkKJUVPRgo='
          : null,
      })
      .delay(2000);
  }

  submit(req: RegisterWarrantyRequest): Observable<RegisterWarrantyResponse> {
    //const valid = req.warranty.serialNumber !== 'INVALID';
    const valid = true;

    // NOTE: submit req.warranty as request payload to API

    return Observable
      .of({
        error:   !valid,
        message: valid ? 'Warranty registration successful' : 'The request contained an invalid serial number',
      })
      .delay(1000);
  }

  updateWarranty(w: Warranty): Observable<UpdateWarrantyResponse> {
    const valid = !!w.id;

    return Observable
      .of({
        error:    valid ? null : 'Unable to update warranty: warranty ID not specified',
        warranty: valid ? w : null,
      })
      .delay(1000);
  }

}
