import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-events';
import { AddAdminEventResponse, AddAdminEventTypeResponse, DeleteAdminEventResponse, DeleteAdminEventTypeResponse, FetchAdminEventsResponse, UpdateAdminEventResponse, UpdateAdminEventTypeResponse, } from '../../models/admin-events';
var AdminEventsEffects = /** @class */ (function () {
    function AdminEventsEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For an AddAdminEventRequestAction, call AdminService::addEvent() and
         * dispatch a new AddAdminEventResponseAction with the response.
         */
        this.addEvent$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_EVENT_REQUEST)
            .switchMap(function (req) {
            return _this.service.addEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminEventResponseAction(res);
            });
        });
        /**
         * For an AddAdminEventTypeRequestAction, call AdminService::addEventType()
         * and dispatch a new AddAdminEventTypeResponseAction with the response.
         */
        this.addEventType$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_EVENT_TYPE_REQUEST)
            .switchMap(function (req) {
            return _this.service.addEventType(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminEventTypeResponseAction(res);
            });
        });
        /**
         * For a DeleteAdminEventRequestAction, call AdminService::deleteEvent() and
         * dispatch a new DeleteAdminEventResponseAction with the response.
         */
        this.deleteEvent$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_EVENT_REQUEST)
            .switchMap(function (req) {
            return _this.service.deleteEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminEventResponseAction(res);
            });
        });
        /**
         * For a DeleteAdminEventTypeRequestAction, call
         * AdminService::deleteEventType() and dispatch a new
         * DeleteAdminEventTypeResponseAction with the response.
         */
        this.deleteEventType$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_EVENT_TYPE_REQUEST)
            .switchMap(function (req) {
            return _this.service.deleteEventType(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminEventTypeResponseAction(res);
            });
        });
        /**
         * For a FetchAdminEventsRequestAction, call AdminService::fetchEvents() and
         * dispatch a new FetchAdminEventsResponseAction with the response.
         */
        this.fetchEvents$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_EVENTS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchEvents(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminEventsResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminEventRequestAction, call AdminService::updateEvent() and
         * dispatch a new UpdateAdminEventResponseAction with the response.
         */
        this.updateEvent$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_EVENT_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateEvent(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminEventResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminEventTypeRequestAction, call
         * AdminService::updateEventType() and dispatch a new
         * UpdateAdminEventTypeResponseAction with the response.
         */
        this.updateEventType$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_EVENT_TYPE_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateEventType(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminEventTypeResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "addEvent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "addEventType$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "deleteEvent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "deleteEventType$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "fetchEvents$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "updateEvent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminEventsEffects.prototype, "updateEventType$", void 0);
    return AdminEventsEffects;
}());
export { AdminEventsEffects };
