/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/waiting-spinner/waiting-spinner.component.ngfactory";
import * as i3 from "../../../shared/waiting-spinner/waiting-spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./service-widget.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/router";
var styles_ServiceWidgetComponent = [i0.styles];
var RenderType_ServiceWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceWidgetComponent, data: {} });
export { RenderType_ServiceWidgetComponent as RenderType_ServiceWidgetComponent };
function View_ServiceWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-waiting-spinner", [["kind", "spinner"]], null, null, null, i2.View_WaitingSpinnerComponent_0, i2.RenderType_WaitingSpinnerComponent)), i1.ɵdid(3, 114688, null, 0, i3.WaitingSpinnerComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = "spinner"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ServiceWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "widget-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClickRecord(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["class", "record-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " | "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "record-details border-lighter-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getRecordDate(_v.context.$implicit); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getRecordTime(_v.context.$implicit); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.getRecordDescription(_v.context.$implicit); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.getRecordLocation(_v.context.$implicit); _ck(_v, 13, 0, currVal_3); }); }
function View_ServiceWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "widget-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceWidgetComponent_4)), i1.ɵdid(3, 802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.records.slice(0, 4); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ServiceWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceWidgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceWidgetComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "text-center widget-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn background-green color-white no-radius"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToDashboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Job record dashboard"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn background-green color-white no-radius"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToJob() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add new record"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pending; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.pending; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("service-container border-box border-lighter-gray " + _co.additionalClasses); _ck(_v, 0, 0, currVal_0); }); }
export function View_ServiceWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "widget-body service-widget background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceWidgetComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.records; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ServiceWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-widget", [], null, null, null, View_ServiceWidgetComponent_0, RenderType_ServiceWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i5.ServiceWidgetComponent, [i6.MatDialog, i7.Router], null, null)], null, null); }
var ServiceWidgetComponentNgFactory = i1.ɵccf("app-service-widget", i5.ServiceWidgetComponent, View_ServiceWidgetComponent_Host_0, { records: "records", pending: "pending", additionalClasses: "additionalClasses" }, { onRequestAdded: "onRequestAdded" }, []);
export { ServiceWidgetComponentNgFactory as ServiceWidgetComponentNgFactory };
