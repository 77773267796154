import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { WarrantyService } from '../../services/warranty.service';

import * as ActionTypes from '../actions/warranty-list';

import {
  FetchWarrantyResponse,
  FetchWarrantiesResponse,
  FetchWarrantyPdfRequest,
  FetchWarrantyPdfResponse,
  UpdateWarrantyResponse,
  Warranty,
} from '../../models/warranty';

@Injectable()
export class WarrantiesEffects {
  constructor(
    private service:  WarrantyService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchWarrantyRequestAction, call
   * WarrantyService::fetchWarranty() and dispatch a new
   * FetchWarrantyResponseAction with the response.
   */
  @Effect() fetchWarrantyRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_WARRANTY_REQUEST)
    .switchMap((req: ActionTypes.FetchWarrantyRequestAction): Observable<Action> => {
      return this.service.fetchWarranty(req.payload)
        .map((res: FetchWarrantyResponse): ActionTypes.FetchWarrantyResponseAction => {
          return new ActionTypes.FetchWarrantyResponseAction(res);
        });
    });

  /**
   * For a FetchWarrantiesRequestAction, call
   * WarrantyService::fetchWarranties() and dispatch a new
   * FetchWarrantiesResponseAction with the response.
   */
  @Effect() fetchWarrantiesRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_WARRANTIES_REQUEST)
    .switchMap((req: ActionTypes.FetchWarrantiesRequestAction): Observable<Action> => {
      return this.service.fetchWarranties(req.payload)
        .map((res: FetchWarrantiesResponse): ActionTypes.FetchWarrantiesResponseAction =>
          new ActionTypes.FetchWarrantiesResponseAction(res)
        );
    });

  /**
   * For a FetchWarrantyPdfRequestAction, call
   * WarrantyService::getWarrantyPDF() and dispatch a new
   * FetchWarrantyPdfResponseAction with the response.
   */
  @Effect() fetchWarrantyPdfRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_WARRANTY_PDF_REQUEST)
    .switchMap((req: ActionTypes.FetchWarrantyPdfRequestAction): Observable<ActionTypes.FetchWarrantyPdfResponseAction> => {
      return this.service.getWarrantyPDF(req.payload)
        .map((res: FetchWarrantyPdfResponse): ActionTypes.FetchWarrantyPdfResponseAction =>
          new ActionTypes.FetchWarrantyPdfResponseAction(res)
        );
    });

  /**
   * For an UpdateWarrantyRequestAction, call WarrantyService::updateWarranty()
   * and dispatch a new UpdateWarrantyResponseAction with the response.
   */
  @Effect() updateWarrantyRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_WARRANTY_REQUEST)
    .switchMap((req: ActionTypes.UpdateWarrantyRequestAction): Observable<Action> =>
      this.service.updateWarranty(req.payload)
        .map((res: UpdateWarrantyResponse): ActionTypes.UpdateWarrantyResponseAction =>
          new ActionTypes.UpdateWarrantyResponseAction(res)
        )
    );
}
