import { Component, OnInit} from '@angular/core';
import { CompanyMemberService } from '../../services/companymember.service';
import { CompanyService } from '../../services/company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store }                 from '@ngrx/store';
import { Observable }            from 'rxjs/Observable';

import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import {SetTitleFilterAction} from '../../state-management/actions/watertesters';

@Component({
  selector: 'app-companymember',
  templateUrl: './companymember.component.html',
  styleUrls: ['./companymember.component.scss']
})
export class CompanyMemberComponent implements OnInit {

  // Reference to user state
  public  loginDetailsState$: Observable<LoginDetailsState>;

  isCustomerService = false;
  companyName = '';
  companyId = 0;

  dtOptions: DataTables.Settings = {

  };

  dtOptionsAddress: DataTables.Settings = {

  };

  resultsMember  = [];
  resultsAddress = [];
  pageLength = 10;

  constructor(
      private _store: Store<any>,
      private companyMemberService: CompanyMemberService,
      private companyService: CompanyService,
      private router: Router,
      private route: ActivatedRoute) {

    this.loginDetailsState$ = _store.select('loginDetails');
    // Subscribe to the login state in order to set a reference to the available user roles
    this.loginDetailsState$.subscribe((state: LoginDetailsState) => {
      if (state.currentProfile) {
        //Customer Service
        if (state.currentProfile.userType === 1) {
          this.isCustomerService = true;
        }
      }
    });
  }

  ngOnInit(): void {

    this.route.params.subscribe( params => {
      this.companyId = (params['id'] > 0 ) ? params['id'] : 0;
    } );

    if (!(this.companyId > 0)) {
      this.router.navigate(['/watertest/company']);
    } else {
      this.companyService.getCompanyById(this.companyId).subscribe(
          resp => {
            this.companyName = resp['data'][0]['name'];
          }
      );
    }

    const that = this;

    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    //results by member
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.companyMemberService.getResultsByMember(dataTablesParameters, this.companyId).subscribe(resp => {
          //console.log('results member ', resp);
          that.resultsMember = resp['data'];

          callback({
            recordsTotal: resp['paging']['results'],
            recordsFiltered: resp['paging']['results'],
            data: []
          });
        });
      },
      columns: [{data: 'member'}, {data: 'email'}, { data: 'total' }],
      columnDefs: [{ 'searchable': false, 'targets': 1 }],
      language: {
        'zeroRecords': ''
      }
    };
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    //results by address
    this.dtOptionsAddress = {
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.companyMemberService.getResultsByAddress(dataTablesParameters, this.companyId).subscribe(resp => {
          //console.log(resp);
          that.resultsAddress = resp['data'];

          callback({
            recordsTotal: resp['paging']['results'],
            recordsFiltered: resp['paging']['results'],
            data: []
          });
        });
      },
      columns: [{data: 'postcode'}, { data: 'total' }],
      language: {
        'zeroRecords': ''
      }
    };
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////


  }

  /**
   * Navigates to the /watertest/company/id_company/member/id_member/result
   */
  goToResultsMember(companyId: number, testerId: number, testerName: string) {
    //save tester name on state
    this._store.dispatch(new SetTitleFilterAction({title: testerName}));
    this.router.navigate(['/watertest/admin/result/watertester/' + testerId + '/all/' + companyId]);
  }

  /**
   * Navigates to the /watertest/admin/result/address/addressId
   */
  goToResultsAddress(companyId: number, addressId: number, address: string) {
    //save address on states
    this._store.dispatch(new SetTitleFilterAction({title: address}));
    this.router.navigate(['watertest/admin/result/address/' + addressId + '/company/' + companyId]);

  }

  /**
   * Navigates to the /watertest/company/edit/:id route
   */
  editWatertestCompany(id) {
    this.router.navigate(['/watertest/company/edit/' + id]);
  }

  /**
   * Navigates to the /watertest/company/:id/add-member
   */
  listMemberWatertestCompany(id) {
    this.router.navigate(['/watertest/company/' + id + '/member']);
  }

  /**
   * Navigates to the /watertest/company/:id/invite-member
   */
  inviteMemberWatertestCompany(id) {
    this.router.navigate(['/watertest/company/' + id + '/invite-member']);
  }

  /**
   * Navigates to the /watertest/company route
   */
  watertestListCompany() {
    this.router.navigate(['/watertest/company']);
  }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }

}
