<div class="container-fluid company-page" *ngIf="loginDetails$ | async; let loginDetails">
 <div class="row content-page">
  <div class="content-watertest">
    <div class="container-title has-sub">
     <h1 class="white">Property Result</h1>
    </div>
    <div class="panel-table col-xs-12">
      <div class="navigator-watertest-table">
        <button class="btn background-adey-red color-white btn-result btn-margin-right" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
        <button class="btn background-adey-red color-white btn-result btn-margin-right" (click)="navigatesBack()">Back to previous page</button>
        <button class="btn background-green color-white btn-result" (click)="openResultEmail()">Email result</button>
      </div>
     <div class="clearfix">
     </div>
     <app-waiting-spinner kind="bar" *ngIf="loadingData">
      Loading data...
     </app-waiting-spinner>
        <div *ngIf="!loadingData" class="col-xs-12">
              <div id="area-to-print">
                <div class="logo-header">
                  <img src="assets/i/common/logos/adey-pro-check-logo-r.png" alt="ADEY® Logo" class="result-logo"/>
                  <h1>Water Test Result</h1>
                </div>
                <div class="col-xs-12">
                  <table class="header-test">
                     <tbody>
                      <tr>
                          <td>Date of Test: </td>
                          <td>{{dateTest}}</td>
                      </tr>
                      <tr>
                          <td>Engineer: </td>
                          <td>{{engineer}}</td>
                      </tr>
                      <tr>
                          <td>E-mail: </td>
                          <td>{{email}}</td>
                      </tr>
                      <tr>
                          <td>Time of Test: </td>
                          <td>{{timeTest}}</td>
                      </tr>
                      <tr>
                      <tr>
                          <td>Address: </td>
                          <td>{{location}}</td>
                      </tr>
                      <tr>
                          <td>Reason for system check: </td>
                          <td>{{reason}}</td>
                      </tr>
                      <tr>
                          <td>Type: </td>
                          <td>{{installationType}}</td>
                      </tr>
                      <tr>
                          <td>Filter installed: </td>
                          <td>{{usingFilter}}</td>
                      </tr>
                      <tr *ngIf="serialBoiler">
                        <td>Boiler serial No: </td>
                        <td>{{serialBoiler}}</td>
                      </tr>
                      <tr *ngIf="jobNumber">
                        <td>Job reference ID: </td>
                        <td>{{jobNumber}}</td>
                      </tr>
                     </tbody>
                  </table>
                </div>
                <div *ngIf="notes_address!=''" class="col-xs-12" style="text-align: left">
                  <br>
                  <h3>Address Notes</h3>
                  <div [innerHTML]="notes_address"></div>
                </div>
                <br>
                <div class="col-xs-6">
                  <h3 class="text-left">Water Test Results</h3>
                   <table style="margin-top: 10px" class="row-border hover">
                    <thead>
                    <tr>
                     <th align="right">Test</th>
                     <th align="left">Result</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="row-data" *ngIf="test['Moly'] !== null">
                     <td align="right">Inhibitor Level</td>
                     <td align="left" [innerHTML]="test['Moly']"></td>
                    </tr>
                    <tr class="row-data" *ngIf="test['Iron'] !== null">
                     <td align="right">Corrosion Level</td>
                     <td align="left" [innerHTML]="test['Iron']"></td>
                    </tr>
                    <tr class="row-data" *ngIf="test['pH1'] !== null">
                     <td align="right">pH Level</td>
                     <td align="left" [innerHTML]="test['pH1']"></td>
                    </tr>
                    <tr class="row-data" *ngIf="test['Turbidity'] !== null">
                      <td align="right">Turbidity</td>
                      <td align="left" [innerHTML]="test['Turbidity']"></td>
                    </tr>
                    <tr class="row-data">
                     <td align="right">Overall</td>
                     <td align="left" [innerHTML]="test['OverAll']"></td>
                    </tr>
                    </tbody>
                   </table>
                  <table>
                    <tbody>
                    <tr>
                      <td align="left"><img src="/assets/recommended.png"/></td>
                      <td align="left">= Recommendation</td>
                    </tr>
                    <tr>
                      <td align="left"><img src="/assets/tick.png"/></td>
                      <td align="left">= Pass</td>
                    </tr>
                    </tbody>
                  </table>
                 </div>
                <div class="col-xs-6">
                    <div id="water_sample" class="col-xs-6">
                      <h3>Water Sample</h3>
                      <img class="img-result" appSecuredSrc="/watertest/result/{{resultId}}/image/{{test['image']}}" />
                    </div>
                    <div id="test_card" class="col-xs-6" *ngIf="loginDetails.user && loginDetails.user.admin">
                      <h3>Test Card</h3>
                      <img class="img-result" appSecuredSrc="/watertest/result/{{resultId}}/image/snapshot2.jpg" />
                    </div>
                </div>
                <div class="col-xs-12" style="text-align: left">
                     <br>
                     <h3>Summary</h3>
                     <div [innerHTML]="recommendation"></div>
                 </div>
                  <div *ngIf="notes_test!=''" class="col-xs-12" style="text-align: left">
                    <br>
                    <h3>Test Notes</h3>
                    <div [innerHTML]="notes_test"></div>
                  </div>
                </div>
              <div class="map-result">
                <div *ngIf="!loadingData" class="col-xs-12" style="text-align: left">
                  <br>
                  <h3>Location of test</h3>
                  <agm-map  [latitude]="test['latitude']" [longitude]="test['longitude']" >
                    <agm-marker [latitude]="test['latitude']" [longitude]="test['longitude']"></agm-marker>
                  </agm-map>
                </div>
              </div>
        </div>
       <div class="col-xs-12">
        <button id="print-btn" *ngIf="!loadingData" class="btn background-adey-red color-white" style="margin-top: 5px" (click)="printTest('area-to-print')">Print Result</button>
       </div>
    </div>
  </div>
 </div>
</div>

