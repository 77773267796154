/**
 * Provides AdminPro3Effects
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

//required classes
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {Actions, Effect} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';

import {AdminPro3Service} from '../../services/admin-pro3.service';

import * as ActionTypes from '../actions/admin-pro3';

import {DFResponse} from '../../models/df-proclub';

import {
    AdminDevice,
    AdminDeviceStatus,
    AdminDeviceDetailItem,
    AdminDeviceAlert,
    AdminDismissAlertResultItem, AdminDeviceNote,
} from '../../models/admin-pro3';
import {catchError, map, tap} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {AdminInstallerService} from '../../services/admin-installer.service';
import {UserProfile} from '../../models/user-profile';

/**
 * NGRX Actions for the Pro3 Admin Portal
 */
@Injectable()
export class AdminPro3Effects {
    /**
     * Set up required injected thingies
     *
     * @param adminPro3Service
     * @param actions$
     */
    constructor(
        private adminPro3Service: AdminPro3Service,
        private actions$: Actions,
        private adminInstallerService: AdminInstallerService
    ) {
    }

    @Effect() adminDeviceAlertRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_DEVICE_ALERT_REQUEST)
        .switchMap((req: ActionTypes.AdminDeviceAlertRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceAlerts(req.payload)
                .map((res: DFResponse<AdminDeviceAlert>): ActionTypes.AdminDeviceAlertResponseAction =>
                    new ActionTypes.AdminDeviceAlertResponseAction(res)
                );
        });

    @Effect() adminDeviceAlertExportRequest: Observable<Action> = this.actions$
      .ofType(ActionTypes.ADMIN_DEVICE_ALERT_EXPORT_REQUEST)
      .switchMap((req: ActionTypes.AdminDeviceAlertExportRequestAction): Observable<Action> => {
        return this.adminPro3Service.getDeviceAlerts(req.payload, true)
          .map((res: DFResponse<AdminDeviceAlert>): ActionTypes.AdminDeviceAlertExportResponseAction =>
            new ActionTypes.AdminDeviceAlertExportResponseAction(res)
          );
      });

    @Effect() adminDeviceListRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_DEVICE_REQUEST)
        .switchMap((req: ActionTypes.AdminDeviceRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceList(req.payload)
                .map((res: DFResponse<AdminDevice>): ActionTypes.AdminDeviceResponseAction =>
                    new ActionTypes.AdminDeviceResponseAction(res)
                );
        });

    @Effect() adminDeviceListExportRequest: Observable<Action> = this.actions$
      .ofType(ActionTypes.ADMIN_DEVICE_EXPORT_REQUEST)
      .switchMap((req: ActionTypes.AdminDeviceExportRequestAction): Observable<Action> => {
        return this.adminPro3Service.getDeviceList(req.payload, true)
          .map((res: DFResponse<AdminDevice>): ActionTypes.AdminDeviceExportResponseAction =>
            new ActionTypes.AdminDeviceExportResponseAction(res)
          );
      });

    @Effect() adminDeviceStatusRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_DEVICE_STATUS_REQUEST)
        .switchMap((req: ActionTypes.AdminDeviceStatusRequestAction): Observable<Action> => {
            return this.adminPro3Service.getPro3Status(req.payload)
                .map((res: DFResponse<AdminDeviceStatus>): ActionTypes.AdminDeviceStatusResponseAction =>
                    new ActionTypes.AdminDeviceStatusResponseAction(res)
                );
        });

    @Effect() adminDeviceDetailRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_DEVICE_DETAIL_REQUEST)
        .switchMap((req: ActionTypes.AdminDeviceDetailRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceDetail(req.payload)
                .map((res: DFResponse<AdminDeviceDetailItem>): ActionTypes.AdminDeviceDetailResponseAction =>
                    new ActionTypes.AdminDeviceDetailResponseAction(res)
                );
        });

    @Effect() adminDeviceNoteRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_DEVICE_NOTE_REQUEST)
        .switchMap((req: ActionTypes.AdminDeviceNoteRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceNote(req.payload)
                .map((res: DFResponse<AdminDeviceNote>): ActionTypes.AdminDeviceNoteResponseAction =>
                    new ActionTypes.AdminDeviceNoteResponseAction(res)
                );
        });

    @Effect() adminGetExistingWarranty: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_GET_MAGSENSE_WARRANTY_INFO)
        .switchMap((req: ActionTypes.AdminGetMagsenseWarrantyInfoAction) => {
                return this.adminPro3Service.getWarrantyInfo(req.payload)
                    .pipe(
                        map((result) => new ActionTypes.AdminGetMagsenseWarrantyInfoActionSuccess(result)),
                        catchError(err => of(new ActionTypes.AdminGetMagsenseWarrantyInfoActionFailed(err)))
                    );
            }
        );

    @Effect() adminGetInstallerForWarranty: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST)
        // .pipe(tap(() => console.log('Hello')))
        .switchMap((req: ActionTypes.AdminGetInstallerFromPartialRequest): Observable<Action> => {
            return this.adminInstallerService.findUserByPartial(req.payload)
                .pipe(
                    map((result: any) =>
                        new ActionTypes.AdminGetInstallerFromPartialResponse(result.data.pop()))
                );
        });

    @Effect() adminDismissAlertRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_DISMISS_ALERT_REQUEST)
        .switchMap((req: ActionTypes.AdminDismissAlertRequestAction): Observable<Action> => {
            return this.adminPro3Service.dismissAlert(req.payload)
                .map((res: DFResponse<AdminDismissAlertResultItem>): ActionTypes.AdminDismissAlertResponseAction =>
                    new ActionTypes.AdminDismissAlertResponseAction(res)
                );
        });

    @Effect() adminRegisterWarrantyRequest: Observable<Action> = this.actions$
        .ofType(ActionTypes.ADMIN_REGISTER_MAGSENSE_DEVICE_REQUEST)
        .switchMap((req: ActionTypes.AdminRegisterMagsenseDeviceRequestAction): Observable<Action> => {
            return this.adminPro3Service.registerWarranty(req.payload).pipe(
                map((res?: any): ActionTypes.AdminRegisterMagsenseDeviceResponseAction =>
                    new ActionTypes.AdminRegisterMagsenseDeviceResponseAction()
                ),
                catchError((err) => of(new ActionTypes.AdminRegisterMagsenseDeviceFailureAction(err)))
            );
        });
}
