import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-users';
var AdminUserEffects = /** @class */ (function () {
    function AdminUserEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For an AddAdminUserRequestAction, call AdminService::addUser() and
         * dispatch a new AddAdminUserResponseAction with the response.
         */
        this.addUser$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_USER_REQUEST)
            .switchMap(function (req) {
            return _this.service.addUser(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminUserResponseAction(res);
            });
        });
        /**
         * For an DeleteAdminUserRequestAction, call AdminService::deleteUser() and
         * dispatch a new DeleteAdminUserResponseAction with the response.
         */
        this.deleteUser$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_USER_REQUEST)
            .switchMap(function (req) {
            return _this.service.deleteUser(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminUserResponseAction(res);
            });
        });
        /**
         * For a FetchAdminUsersRequestAction, call AdminService::fetchUsers() and
         * dispatch a new FetchAdminUsersResponseAction with the response.
         */
        this.fetchUsers$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_USERS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchUsers(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminUsersResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminUserRequestAction, call AdminService::updateUser() and
         * dispatch a new UpdateAdminUserResponseAction with the response.
         */
        this.updateUser$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_USER_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateUser(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminUserResponseAction(res);
            });
        });
        /**
         * For a FetchAccreditationsRequestAction, call AdminService::fetchAccreditations() and
         * dispatch a new FetchAccreditationsResponseAction with the response.
         */
        this.fetchAccreditations$ = this.actions$
            .ofType(ActionTypes.FETCH_ACCREDITATIONS_REQUEST)
            .switchMap(function () {
            return _this.service.fetchAccreditations()
                .map(function (res) {
                return new ActionTypes.FetchAccreditationsResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminUserEffects.prototype, "addUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminUserEffects.prototype, "deleteUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminUserEffects.prototype, "fetchUsers$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminUserEffects.prototype, "updateUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminUserEffects.prototype, "fetchAccreditations$", void 0);
    return AdminUserEffects;
}());
export { AdminUserEffects };
