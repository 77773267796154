/**
 * SocialService: provides all functionality related to the social hub
 */
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { SocialFeedResponse } from '../models/social';
import { ApiService } from './api.service';

@Injectable()
export class SocialService {

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Fetches social media feeds from the API
   *
   * @return {Observable<SocialFeedResponse>}
   */
  getSocialFeeds(): Observable<SocialFeedResponse> {
    return this.apiService.apiGet('/social-feeds')
      .map((res: any): SocialFeedResponse => {
        return {
          facebookFeed:  res.facebook.items ? res.facebook.items.map(SocialFeedResponse.getFacebookFeedDataFromApi) : [],
          instagramFeed: res.instagram.items ? res.instagram.items.slice(0, 3).map(SocialFeedResponse.getInstagramFeedDataFromApi) : [],
          twitterFeed:   res.twitter.length ? res.twitter.map(SocialFeedResponse.getTwitterFeedDataFromApi) : [],
          error:         null,
        };
      })
      .catch((err: any): Observable<SocialFeedResponse> => {
        return Observable.of({
          facebookFeed:  [],
          instagramFeed: [],
          twitterFeed:   [],
          error:         err.message,
        });
      });
  }
}
