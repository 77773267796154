<h2 matDialogTitle>Register Warranty for Sense Device: {{ device.filterNo }}</h2>

<form (ngSubmit)="saveWarranty(warrantyForm)" #warrantyForm="ngForm">
    <mat-dialog-content>
        <ng-template #warrantyNotReady>
            <app-waiting-spinner kind="bar"></app-waiting-spinner>
        </ng-template>
        <ng-container *ngIf="!editMode || existingLoaded; else warrantyNotReady">
            <mat-horizontal-stepper
                    #warrantyWizard
                    (selectionChange)="stepperWatch($event)"
            >
                <!-- User (Installer) info -->
                <mat-step label="Installer">
                  <mat-form-field>
                    <input matInput type="email" name="email" id="email" placeholder="Search by E-mail" #email
                           (input)="resetEmailNotFound()" (keydown.enter)="$event.preventDefault();" (keyup.enter)="findAndUpdateInstaller({email: email.value})"
                    />
                      <mat-icon class="search-icon-mat"  matSuffix><a (click)="findAndUpdateInstaller({email: email.value})" href="javascript:void(0);" class="search-icon">search</a></mat-icon>
                  </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="User ID" name="installer_id" [(ngModel)]="warranty.installer_id" required/>
                      <mat-icon  matSuffix class="text-success" *ngIf="emailFound">check</mat-icon>
                    </mat-form-field>
                  <div class="alert alert-danger" *ngIf="emailNotFound">
                    <span *ngIf="email.value !== ''; else noEmailEntered">User not found matching '{{ email.value }}'</span>
                    <ng-template #noEmailEntered>Please enter an e-mail address to search</ng-template>
                  </div>
                </mat-step>


                <!-- Customer (Homeowner) info -->
                <mat-step label="Homeowner">
                    <h3>Homeowner / Warranty Customer Info</h3>

                    <mat-form-field>
                        <input matInput placeholder="Customer Title" name="customer_title"
                               [(ngModel)]="warranty.customer_title"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer First Name" name="customer_first_name"
                               [(ngModel)]="warranty.customer_first_name" required/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer Surname" name="customer_surname"
                               [(ngModel)]="warranty.customer_surname" required/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer Phone" name="customer_phone"
                               [(ngModel)]="warranty.customer_phone"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer E-mail" name="customer_email"
                               [(ngModel)]="warranty.customer_email"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer Address Line 1" name="customer_address_1"
                               [(ngModel)]="warranty.customer_address_1" required/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer Address Line 2" name="customer_address_2"
                               [(ngModel)]="warranty.customer_address_2"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer Town/City" name="customer_town_city"
                               [(ngModel)]="warranty.customer_town_city" required/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer County/State" name="customer_county_state"
                               [(ngModel)]="warranty.customer_county_state" required/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Customer Postcode" name="customer_postcode"
                               [(ngModel)]="warranty.customer_postcode" required/>
                    </mat-form-field>

                </mat-step>

                <!--Job / Property details-->
                <mat-step label="Property">
                    <h3>Job / Property Details</h3>
                    <div class="col-xs-12 col-sm-12 col-md-6">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                            <mat-form-field>
                                <input matInput placeholder="Boiler Brand" name="job_boilerbrand"
                                       [(ngModel)]="warranty.job_boilerbrand"/>
                            </mat-form-field>
                            </div>
                            <div class="col-xs-12 col-sm-6 mat-form-field detail-height">
                            <mat-slide-toggle name="warranty_new_boiler" [(ngModel)]="warranty.warranty_new_boiler">
                                New Boiler?
                            </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-6">
                              <mat-form-field>
                                  <input matInput placeholder="Radiator Count" name="job_radiator_count"
                                         [(ngModel)]="warranty.job_radiator_count" type="number" min="0" step="1"/>
                              </mat-form-field>
                          </div>
                          <div class="col-xs-12 col-sm-6">
                              <mat-form-field>
                                  <mat-select name="job_radiators_type" [(ngModel)]="warranty.job_radiators_type"
                                              placeholder="Radiator Type"

                                  >
                                      <mat-option value="old" matTooltip="All radiators older than 12 months">Old
                                      </mat-option>
                                      <mat-option value="new" matTooltip="All radiators less than 12 months old">New
                                      </mat-option>
                                      <mat-option value="mixed"
                                                  matTooltip="Some radiators less than 12 months old, some older">Mixed
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-6">
                              <mat-form-field>
                                  <mat-select name="job_pipework_type" [(ngModel)]="warranty.job_pipework_type"
                                              placeholder="Pipework">
                                      <mat-option value="plastic">Plastic</mat-option>
                                      <mat-option value="copper">Copper</mat-option>
                                      <mat-option value="mixed">Mixed</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                          <div class="col-xs-12 col-sm-6">

                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-6 detail-height">
                              <mat-slide-toggle name="job_smart_thermostat_installed"
                              [(ngModel)]="warranty.job_smart_thermostat_installed"
                              >Smart Thermostat?
                              </mat-slide-toggle>
                          </div>
                          <div class="col-xs-12 col-sm-6">
                              <mat-form-field>
                                  <input matInput placeholder="Thermostat Model" name="job_smart_thermostat_model"
                                         [(ngModel)]="warranty.job_smart_thermostat_model"/>
                              </mat-form-field>
                          </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6">
                        <div class="row">
                          <div class="col-xs-12 col-sm-6 detail-height">
                              <mat-slide-toggle name="job_new_system" [(ngModel)]="warranty.job_new_system">New System?
                                </mat-slide-toggle>
                          </div>
                          <div class="col-xs-12 col-sm-6"></div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 detail-height">
                                <mat-slide-toggle name="job_cleaned" [(ngModel)]="warranty.job_cleaned">Cleaner Added?
                                  </mat-slide-toggle>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <mat-form-field>
                                    <input matInput placeholder="Cleaner Serial No." name="job_cleaner_serial_no"
                                           [(ngModel)]="warranty.job_cleaner_serial_no"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 detail-height">
                                <mat-slide-toggle name="job_magnacleanse" [(ngModel)]="warranty.job_magnacleanse">
                                    Magnacleanse?
                                </mat-slide-toggle>
                            </div>
                            <div class="col-xs-12 col-sm-6"></div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <mat-form-field>
                                    <input matInput placeholder="Filter Expiry Date" name="job_filter_expiry_date"
                                           [matDatepicker]="expiryPicker" [(ngModel)]="warranty.job_filter_expiry_date"/>
                                    <mat-datepicker-toggle matSuffix [for]="expiryPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #expiryPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-xs-12 col-sm-6 detail-height">
                                <mat-slide-toggle name="job_new_filter" [(ngModel)]="warranty.job_new_filter">New Filter?
                                  </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 detail-height">
                                <mat-slide-toggle name="job_protect" [(ngModel)]="warranty.job_protect">Protector Added?
                                  </mat-slide-toggle>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <mat-form-field>
                                    <input matInput placeholder="Protector Serial No." name="job_protect_serial_no"
                                           [(ngModel)]="warranty.job_protect_serial_no"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 detail-height">
                                <mat-slide-toggle name="job_test" [(ngModel)]="warranty.job_test">Water Test?
                                  </mat-slide-toggle>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <mat-form-field>
                                    <input matInput placeholder="Water Test Serial No." name="job_water_serial_no"
                                           [(ngModel)]="warranty.job_water_serial_no"/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--Device / Warranty Details-->
                <mat-step label="Device">
                    <h3>Device / Warranty Details</h3>

                    <!--Filter No-->
                    <mat-form-field>
                        <input matInput
                               placeholder="Filter No."
                               (ngModel)="warranty.device_filterNo"
                               readonly [value]="device.filterNo"
                               required
                        />
                    </mat-form-field>

                    <!--MAC Address-->
                    <mat-form-field>
                        <input matInput placeholder="Serial (MAC Address)"
                               (ngModel)="warranty.device_serialNo"
                               readonly [value]="device.serialNo"
                               pattern="[0-9a-fA-F]{12}"
                               required
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput placeholder="Warranty Install Date" name="warranty_install_date"
                               [(ngModel)]="warranty.warranty_install_date" required
                               [matDatepicker]="installPicker"/>
                        <mat-datepicker-toggle matSuffix [for]="installPicker"></mat-datepicker-toggle>
                        <mat-datepicker #installPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Old Filter Brand" name="warranty_old_filter_brand"
                               [(ngModel)]="warranty.warranty_old_filter_brand"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Service Date" name="servicerecord_service_date"
                               [(ngModel)]="warranty.servicerecord_service_date"
                               [matDatepicker]="servicePicker" required/>
                        <mat-datepicker-toggle matSuffix [for]="servicePicker"></mat-datepicker-toggle>
                        <mat-datepicker #servicePicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Last Service Date"
                               name="servicerecord_last_service_date"
                               [(ngModel)]="warranty.servicerecord_last_service_date"
                               [matDatepicker]="lastServicePicker"
                        />
                        <mat-datepicker-toggle matSuffix [for]="lastServicePicker"></mat-datepicker-toggle>
                        <mat-datepicker #lastServicePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput placeholder="Device Name" name="device_name"
                               [(ngModel)]="warranty.device_name"/>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select name="device_systemType" [(ngModel)]="warranty.device_systemType"
                                    placeholder="System Type">
                            <mat-option value="O">Open</mat-option>
                            <mat-option value="C">Closed</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-slide-toggle name="device_isWarrantyReplacement"
                                      [(ngModel)]="warranty.device_isWarrantyReplacement"
                    >Warranty Replacement?
                    </mat-slide-toggle>

                  <br />
                    <mat-divider></mat-divider>
                    <div class="row">
                      <div class="col-xs-12 col-sm-6">

                      </div>
                      <div class="col-xs-12 col-sm-6 align-right">
                          <mat-slide-toggle
                          [disabled]="!existingLoaded"
                          name="device_isTestDevice"
                          [(ngModel)]="warranty.device_isTestDevice"
                          matTooltip="This identifies the filter as a field trial or for development or training purposes."
                  >Development Filter?
                  </mat-slide-toggle>
                        </div>
                    </div>

                    <div *ngIf="!existingLoaded" class="alert alert-danger">To avoid breaking changes, we are currently
                        unable to set test flag on registration - please save and edit
                    </div>

                </mat-step>

            </mat-horizontal-stepper>

        </ng-container>
    </mat-dialog-content>

    <mat-action-row class="row">
        <div class="col-lg-6">
            <button mat-button (click)="selfRef.close()" type="button">Cancel</button>
            <button *ngIf="!stepperFirst" mat-button (click)="stepper.previous()" type="button">Back</button>
        </div>
        <div class="col-lg-6 align-right">
            <button *ngIf="!stepperLast" mat-button (click)="stepper.next()" type="button">Next</button>
            <button mat-button type="submit" (click)="saveWarranty(warrantyForm)"
                    class="btn btn-success" value="Finish">Finish
            </button>
        </div>
    </mat-action-row>
</form>
