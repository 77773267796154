<div class="widget-body social-widget background-white col-xs-12">
  <div class="social-body border-box border-lighter-gray">
    <!-- <div class="radio-buttons" [formGroup]="fg"> -->
      <!-- <input type="radio" value="facebook" id="facebook" formControlName="social_view" name="social_view">
      <label for="facebook"
        [class]="fg.controls.social_view.value == 'facebook' ? 'background-adey-red color-white' : 'background-beige color-gray'">Facebook</label> -->
      <!-- <input type="radio" value="twitter" id="twitter" formControlName="social_view" name="social_view">
      <label for="twitter"
        [class]="fg.controls.social_view.value == 'twitter' ? 'background-adey-red color-white' : 'background-beige color-gray'">Twitter</label> -->
      <!-- <input type="radio" value="instagram" id="instagram" formControlName="social_view" name="social_view">
      <label for="instagram"
        [class]="fg.controls.social_view.value == 'instagram' ? 'background-adey-red color-white' : 'background-beige color-gray'">Instagram</label> -->
    <!-- </div> -->
    <!-- <div class="social-items" *ngIf="fg.controls.social_view.value == 'facebook'">
      <div [class]="'social-item-container' + (socialItem.url ? ' pointer': '')"
        *ngFor="let socialItem of facebookItems" (click)="openLink(socialItem.url)">
        <div class="icon"><i aria-hidden="true" class="fa fa-facebook-square"></i> ADEY</div>
        <br>
        <div *ngIf="socialItem.description" [innerHTML]="socialItem.description"></div>
        <div class="ie-image-patch" *ngIf="socialItem.image" [style.backgroundImage]="'url(' + socialItem.image + ')'">
          <img [src]="socialItem.image">
        </div>
        <hr>
      </div>
    </div> -->
    <div class="social-items" *ngIf="fg.controls.social_view.value == 'twitter'">
      <div [class]="'social-item-container' + (socialItem.url ? ' pointer': '')" *ngFor="let socialItem of twitterItems"
        (click)="openLink(socialItem.url)">
        <div class="icon"><i aria-hidden="true" class="fa fa-twitter-square"></i> @ADEY_Pro</div>
        <br>
        <p *ngIf="socialItem.description">{{socialItem.description}}</p>
        <div class="ie-image-patch" *ngIf="socialItem.image" [style.backgroundImage]="'url(' + socialItem.image + ')'">
          <img [src]="socialItem.image">
        </div>
        <hr>
      </div>
    </div>
    <!-- <div class="social-items" *ngIf="fg.controls.social_view.value == 'instagram'">
      <div [class]="'social-item-container' + (socialItem.url ? ' pointer': '')"
        *ngFor="let socialItem of instagramItems" (click)="openLink(socialItem.url)">
        <div class="icon"><i aria-hidden="true" class="fa fa-instagram"></i> @adey_pro</div>
        <br>
        <div *ngIf="socialItem.description" [innerHTML]="socialItem.description"></div>
        <div class="ie-image-patch" *ngIf="socialItem.image" [style.backgroundImage]="'url(' + socialItem.image + ')'">
          <img [src]="socialItem.image">
        </div>
        <hr>
      </div>
    </div> -->

    <div class="widget-button-container">
      <button class="btn background-green no-radius color-white" (click)="goToPage('/social-hub')">Social hub</button>
    </div>
  </div>
</div>