import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/withLatestFrom';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { ServiceRecordService } from '../../services/service-record.service';
import * as ActionTypes from '../actions/service-record';
import { AddServiceRequestResponse, FetchServiceRecordStatsResponse, FetchServiceRecordsResponse, SetServiceRecordFlagResponse, UpdateServiceRecordResponse, } from '../../models/service-record';
var ServiceRecordEffects = /** @class */ (function () {
    function ServiceRecordEffects(actions$, service, store) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        /**
         * For an AddServiceRequestRequestAction, call
         * ServiceRecordsService::addServiceRequest() and dispatch a new
         * AddServiceRequestResponseAction with the response.
         */
        this.addServiceRequest$ = this.actions$
            .ofType(ActionTypes.ADD_SERVICE_REQUEST_REQUEST)
            .switchMap(function (req) {
            return _this.service.addServiceRequest(req.payload)
                .map(function (res) {
                return new ActionTypes.AddServiceRequestResponseAction(res);
            });
        });
        /**
         * For a FetchServiceRecordsRequestAction, call
         * ServiceRecordsService::fetchRecords() and dispatch a new
         * FetchServiceRecordsResponseAction with the response.
         */
        this.fetchServiceRecords$ = this.actions$
            .ofType(ActionTypes.FETCH_SERVICE_RECORDS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchRecords(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchServiceRecordsResponseAction(res);
            });
        });
        /**
         * For a FetchServiceRecordStatsRequestAction, call
         * ServiceRecordsService::fetchStats() and dispatch a new
         * FetchServiceRecordStatsResponseAction with the response.
         */
        this.fetchServiceRecordStats$ = this.actions$
            .ofType(ActionTypes.FETCH_SERVICE_RECORD_STATS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchStats()
                .map(function (res) {
                return new ActionTypes.FetchServiceRecordStatsResponseAction(res);
            });
        });
        /**
         * For a NextServiceRecordPageAction, dispatch a new
         * FetchServiceRecordsRequestAction with the updated page number from the
         * state.
         */
        this.nextPage$ = this.actions$
            .ofType(ActionTypes.NEXT_SERVICE_RECORD_PAGE)
            .withLatestFrom(this.store.select('serviceRecord'))
            .map(function (_a) {
            var a = _a[0], s = _a[1];
            return new ActionTypes.FetchServiceRecordsRequestAction({
                perPage: s.perPage,
                pageNo: s.currentPage,
                upcoming: s.upcoming,
                customerQuery: s.customerQuery,
            });
        });
        /**
         * For a NextFiveServiceRecordPageAction, dispatch a new
         * FetchServiceRecordsRequestAction with the updated page number from the
         * state.
         */
        this.nextFivePages$ = this.actions$
            .ofType(ActionTypes.NEXT_FIVE_SERVICE_RECORD_PAGE)
            .withLatestFrom(this.store.select('serviceRecord'))
            .map(function (_a) {
            var a = _a[0], s = _a[1];
            return new ActionTypes.FetchServiceRecordsRequestAction({
                perPage: s.perPage,
                pageNo: s.currentPage,
                upcoming: s.upcoming,
                customerQuery: s.customerQuery,
            });
        });
        /**
         * For a PrevFiveServiceRecordPageAction, dispatch a new
         * FetchServiceRecordsRequestAction with the updated page number from the
         * state.
         */
        this.prevFivePages$ = this.actions$
            .ofType(ActionTypes.PREV_FIVE_SERVICE_RECORD_PAGE)
            .withLatestFrom(this.store.select('serviceRecord'))
            .map(function (_a) {
            var a = _a[0], s = _a[1];
            return new ActionTypes.FetchServiceRecordsRequestAction({
                perPage: s.perPage,
                pageNo: s.currentPage,
                upcoming: s.upcoming,
                customerQuery: s.customerQuery,
            });
        });
        /**
         * For a PrevServiceRecordPageAction, dispatch a new
         * FetchServiceRecordsRequestAction with the updated page number from the
         * state.
         */
        this.prevPage$ = this.actions$
            .ofType(ActionTypes.PREV_SERVICE_RECORD_PAGE)
            .withLatestFrom(this.store.select('serviceRecord'))
            .map(function (_a) {
            var a = _a[0], s = _a[1];
            return new ActionTypes.FetchServiceRecordsRequestAction({
                perPage: s.perPage,
                pageNo: s.currentPage,
                upcoming: s.upcoming,
                customerQuery: s.customerQuery,
            });
        });
        /**
         * For a SetServiceRecordFlagRequestAction, call
         * ServiceRecordService::setFlags() and dispatch a new
         * SetServiceRecordFlagResponseAction with the response.
         *
         * Uses concatMap() so that multiple actions will be processed serially
         * rather than being merged as with switchMap().
         */
        this.setFlags$ = this.actions$
            .ofType(ActionTypes.SET_SERVICE_RECORD_FLAG_REQUEST)
            .concatMap(function (req) {
            return _this.service.setFlags(req.payload)
                .map(function (res) {
                return new ActionTypes.SetServiceRecordFlagResponseAction(res);
            });
        });
        /**
         * For an UpdateServiceRecordRequestAction, call
         * ServiceRecordService::update() and dispatch a new
         * UpdateServiceRecordResponseAction with the response.
         */
        this.update$ = this.actions$
            .ofType(ActionTypes.UPDATE_SERVICE_RECORD_REQUEST)
            .switchMap(function (req) {
            return _this.service.update(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateServiceRecordResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "addServiceRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "fetchServiceRecords$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "fetchServiceRecordStats$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "nextPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "nextFivePages$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "prevFivePages$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "prevPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "setFlags$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ServiceRecordEffects.prototype, "update$", void 0);
    return ServiceRecordEffects;
}());
export { ServiceRecordEffects };
