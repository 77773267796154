import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MatDialog, MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgxFaviconService } from 'ngx-favicon';
import { Observable } from 'rxjs/Observable';
import { interval } from 'rxjs/observable/interval';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators/switchMap';
import { tap } from 'rxjs/operators/tap';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { CustomFavicon } from '../favicon.config';
import { ProfileEditModalComponent } from './components/user-profile/profile-edit-modal/profile-edit-modal.component';
import { DFTableRequest } from './models/df-proclub';
import { ExternalLinks, SocialItems } from './models/main';
import { NavItem, NavItems } from './models/nav-items';
import { ProCheckSite } from './models/procheck-site';
import { UserRole } from './models/registration';
import { AccreditationItem, UserProfile } from './models/user-profile';
import { AdminCompaniesLoadCompanyAction } from './state-management/actions/admin-companies';
import { FetchPageContentRequestAction } from './state-management/actions/content-pages';
import {
  CheckLoginTokenRequestAction,
  LoadLoginTokenRequestAction,
  LogoutAction,
  UpdateUserProfileRequestAction,
} from './state-management/actions/login-details';
import { State as ContentPagesState } from './state-management/reducers/content-pages';
import { State as LoginDetailsState } from './state-management/reducers/login-details';
import { getProfileIncomplete } from './state-management/selectors/login-details';
import { StoreState } from './state-management/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {

  // Store states
  public contentPages$: Observable<ContentPagesState>;
  public loginDetails$: Observable<LoginDetailsState>;
  public profileIncomplete$: Observable<boolean>;

  // Flag to indicate if login token is being checked
  public bCheckToken: boolean = false;

  // Subscription to store state changes
  private loginSub: Subscription = null;

  // Subscription to router events
  private routerEventSub: Subscription = null;

  //Observable to fire on destruction
  private destroying$ = new Subject<any>();

  // The current router URL
  public currentUrl: string = null;

  // The user's current login JWT
  public loginToken: string = null;

  public localeLogo: string = null;

  //redirecting page
  public redirectPage: string = null;

  public proCheckView: boolean = false;

  // Array of UserRole obtained from loginDetails Subscription
  public roles: UserRole[] = [];

  //show nav and footer
  public navFooter: boolean = true;

  // Dynamic favicons
  public CustomFavicon: typeof CustomFavicon = CustomFavicon;

  /**
   * Get items for navigation
   */
  public getNavItems$(): Observable<NavItem[]> {
    //Use the login details state
    return this.loginDetails$.map(loginDetails => {
      //Check a valid user is logged in
      if (!loginDetails.user || (loginDetails.user.optIns.length === 0 && !this.proCheckView)) {
        return undefined;
      }

      return this.navItems.getItemsForUser(loginDetails.user);
    });
  }

  /**
   * Get the default route
   */
  public getDefaultRoute$(): Observable<string> {
    //Use the login details state
    return this.loginDetails$.map(loginDetails => {
      //Check a valid user is logged in
      if (!loginDetails.user || (loginDetails.user.optIns.length === 0 && !this.proCheckView)) {
        return undefined;
      }

      return this.navItems.getDefaultRouteForUser(loginDetails.user);
    });
  }

  constructor(
    private dialog: MatDialog,
    private navItems: NavItems,
    private ngxFaviconService: NgxFaviconService<CustomFavicon>,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<StoreState>,
    private titleService: Title,
    private translate: TranslateService,
    public extLinks: ExternalLinks,
    public socialItems: SocialItems,
    dateAdapter: DateAdapter<Date>,
    snackBar: MatSnackBar,
    updates: SwUpdate,
  ) {
    // Default languages
    translate.setDefaultLang('GB');
    translate.use('GB');

    dateAdapter.setLocale('en-GB');

    // Check for service worker browser support
    if (updates.isEnabled) {
      // Check service worker for updates every 30 minutes
      interval(30 * 60 * 1000).subscribe(() => {
        updates.checkForUpdate();
      });

      updates.available.subscribe(event => {
        const updateSnackBarRef = snackBar.open('There is a new version of the ProClub available.', 'UPDATE');
        updateSnackBarRef.onAction().subscribe(() => {
          updates.activateUpdate().then(() => document.location.reload());
        });
      });
    }

    this.contentPages$ = this.store.select('contentPages');
    this.loginDetails$ = this.store.select('loginDetails');
    this.profileIncomplete$ = this.store.select(getProfileIncomplete);

    // Subscribe to router events
    this.routerEventSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        // Store the current URL
        this.currentUrl = event.url;

        // Check token if necessary
        if (!this.bCheckToken) {
          this.checkLoginAndRedirect();
        }

        // As route has changed, scroll view to top
        window.scrollTo(0, 0);
      }),
      //Get extra info out of the route
      switchMap(() => this.route.firstChild.data)).subscribe();

  }

  ngOnInit() {

    this.navFooter = this.showNavAndFooter();
    this.proCheckView = ProCheckSite.getCheck();

    switch (this.proCheckView) {
      case true:
        this.titleService.setTitle('ADEY ProCheck');
        this.ngxFaviconService.setCustomFavicon(CustomFavicon.FAVICON_PROCHECK);
        break;

      default:
        this.titleService.setTitle('ADEY ProClub');
        this.ngxFaviconService.setCustomFavicon(CustomFavicon.FAVICON_PROCLUB);
        break;
    }

    // Dispatch an action to load a stored login token if possible
    this.store.dispatch(new LoadLoginTokenRequestAction());

    // Dispatch an action to load page content for all pages from the API
    this.store.dispatch(new FetchPageContentRequestAction());

    //Dispatch an action to load all available companies
    this.store.dispatch(new AdminCompaniesLoadCompanyAction(new DFTableRequest()));

    // Store and check the login token whenever it changes
    this.loginSub = this.loginDetails$.subscribe(res => {
      this.loginToken = res && res.token ? res.token : '';
      this.checkLoginAndRedirect();
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.localeLogo = event.translations.LOCALE_LOGO;
    });
  }

  ngOnDestroy() {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }

    this.destroying$.next();
  }


  /**
   * Based on the current URL and login token, issues a check token request and
   * handles redirection of the user back to /login unless they land on an
   * allowed route (e.g. /register).
   */
  checkLoginAndRedirect() {
    // Abort if current URL has not been obtained yet
    if (!this.currentUrl)
      return;

    // List of pages to blacklist from redirection logic
    const blacklist: RegExp[] = [
      /^\/login.*/i,
      /^\/not-found.*/i,
      /^\/reset-password.*/i,
      /^\/register.*/i,
      /^\/rewards.*/i,
      /^\/activate-profile.*/i,
      /^\/review-invitation.*/i,
      /^\/register-water-tester.*/i,
      /^\/tc-portal-login.*/i,
      /^\/tc-app-login.*/i
    ];

    // List of pages to whitelist from redirection logic
    const whitelist: RegExp[] = [
    ];

    // Should the user be redirected if their token is invalid?
    const redirectInvalid: boolean = blacklist.filter((v: RegExp): boolean => v.test(this.currentUrl)).length === 0;

    // User will be redirected after login
    const redirectPageValid: boolean = whitelist.filter((v: RegExp): boolean => v.test(this.currentUrl)).length === 0;


    // Should user be redirected if their token is valid?
    const redirectValid: boolean = !!(/^\/login/i.test(this.currentUrl) || /^\/$/.test(this.currentUrl));

    if (!redirectPageValid && !this.loginToken) {
      // Check if we need to login
      this.redirectPage = this.currentUrl;
      this.router.navigate(['/login'], { queryParams: { redirectTo: this.currentUrl } });
    } else
      if (this.loginToken && this.loginToken.length > 0 && !this.bCheckToken) {

        this.checkUserToken(this.loginToken, redirectInvalid, redirectValid, redirectPageValid);
        // Else redirect to /login if the token is empty
      } else if (this.loginToken !== null && this.loginToken.length === 0 && !this.bCheckToken && redirectInvalid) {
        //console.log('redirecting -- app');
        //this.store.dispatch(new RedirectLoginRequestAction({returnPage: this.currentUrl}));
        this.router.navigate(['/login']);
      }
  }

  /**
   * Performs a test on a token and potentially redirects the client if the
   * token is invalid.
   *
   * @param {string} token Token string for testing
   * @param redirectInvalid
   * @param redirectValid
   * @param redirectPageValid
   */
  checkUserToken(token: string, redirectInvalid: boolean, redirectValid: boolean, redirectPageValid: boolean) {
    this.bCheckToken = true;

    this.store.dispatch(
      new CheckLoginTokenRequestAction(
        {
          token,
          redirectClientInvalid: redirectInvalid ? '/login' : null,
          redirectClientValid: redirectValid ? '/home' : null,
          redirectClientValidAdmin: redirectValid ? '/admin-dashboard' : null,
          redirectPage: redirectPageValid ? this.redirectPage : null,
        }
      )
    );
  }

  /**
   * Dispatches a logout action
   */
  onLogout() {
    this.store.dispatch(new LogoutAction());
  }

  /**
   * Handles a click on a navbar item. If the item has "link", the router is
   * used to load the route, otherwise the item's "href" attribute (if
   * available) is passed to window.open().
   *
   * @param {object} navItem The navbar item that was clicked
   */
  navClick(navItem: any) {
    if (navItem.link)
      this.router.navigate([navItem.link]);
    else if (navItem.href)
      window.open(navItem.href, '_blank');
  }

  /**
   * Use this method to determine the cutoff CSS classes for the nav items
   *
   * It is a horrible fudge, but makes it look a lot better :)
   *
   * @param count
   */
  public getRoleClassFromCount(count: number) {
    if (count >= 13) {
      return 'installer';
    }

    if (count >= 4) {
      return 'admin';
    }

    return 'no-responsive';
  }

  /**
   * Opens ProfileEditModalComponent to edit a user's profile
   *
   * @param {UserProfile}         p Current user profile model
   * @param {AccreditationItem[]} accreditations
   */
  editProfileProCheckDetails(p: UserProfile, accreditations: AccreditationItem[]) {
    const dialogRef = this.dialog.open(ProfileEditModalComponent, {
      data: {
        userProfile: p,
        accreditations: accreditations,
        roles: this.roles,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new UpdateUserProfileRequestAction({ profile: result }));
      }
    });
  }

  /**
   * List of pages dont't require nav and footer
   */
  showNavAndFooter() {
    // List of pages dont require nav and footer
    const list: RegExp[] = [
      /\/review-invitation.*/i,
      /\/register-water-tester.*/i
    ];

    return !(list.filter((v: RegExp): boolean => v.test(window.location.href)).length !== 0);
  }

}
