import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { Job } from '../../models/job';
import { Warranty } from '../../models/warranty';
import { FetchEventsRequestAction, FetchEventTypesRequestAction } from '../../state-management/actions/diary';
import { GetUserPopup } from '../../state-management/actions/popup';
import {
  AlertDataRequestAction,
  DismissAlertRequestAction,
  FilterDataRequestAction,
} from '../../state-management/actions/pro3';
import { UpdateJobRequestAction } from '../../state-management/actions/register-job';
import { FetchResourceItemsRequestAction } from '../../state-management/actions/resources';
import { FetchServiceRecordsRequestAction } from '../../state-management/actions/service-record';
import { SocialFeedRequestAction } from '../../state-management/actions/social';
import { SystemMessageRequestAction } from '../../state-management/actions/system-message';
import { UpdateWarrantyRequestAction } from '../../state-management/actions/warranty-list';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { State as ResourcesState } from '../../state-management/reducers/resources';
import { State as ServiceRecordsState } from '../../state-management/reducers/service-record';
import { State as SocialState } from '../../state-management/reducers/social';
import { selectPopupDisplayCount } from '../../state-management/selectors/popup';
import { StoreState } from '../../state-management/store';
import { PromotionModalComponent } from '../common/promotion-modal/promotion-modal.component';

const PROMO_MODAL_START_DATE = '2021-10-12';
const PROMO_MODAL_STOP_DATE = '2023-01-01';
const PROMO_MODAL_MAX_TIMES_TO_DISPLAY = 2;

/**
 * Summary
 *    Displays a series of widgets.
 *
 *
 * Description
 *    Retrieves information from a variety of states and passes the data into the
 *    corresponding widgets.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnDestroy, OnInit {

  // Store states
  public resources$: Observable<ResourcesState>;
  public loginDetails$: Observable<LoginDetailsState>;
  public serviceRecords$: Observable<ServiceRecordsState>;
  public socialState$: Observable<SocialState>;
  public loginDetailsSub$: Subscription = null;
  public userId: string = null;

  private selectPopupDisplayCount$: Observable<Number>;
  private selectPopupDisplayCountSub: Subscription;
  private popupDisplayCount: number;
  private popupShown = false;

  constructor(
    private store: Store<StoreState>,
    public dialog: MatDialog
  ) {
    // Set references to the different states
    this.resources$ = this.store.select('resources');
    this.loginDetails$ = this.store.select('loginDetails');
    this.serviceRecords$ = this.store.select('serviceRecord');
    this.socialState$ = this.store.select('social');
    this.selectPopupDisplayCount$ = this.store.select(selectPopupDisplayCount('win-back-your-heating'));
  }

  /**
   * Dispatch a series of actions to the store in order to retrieve all of
   * the required data for the widgets
   */
  ngOnInit() {
    this.store.dispatch(new GetUserPopup());
    this.store.dispatch(new FetchEventTypesRequestAction());
    this.store.dispatch(new FetchEventsRequestAction());
    this.store.dispatch(new FetchResourceItemsRequestAction({
      page: 'Dashboard',
      category: null,
      search: null,
    }));
    this.store.dispatch(new SocialFeedRequestAction());
    this.store.dispatch(
      new FetchServiceRecordsRequestAction({
        pageNo: 1,
        perPage: 4,
        customerQuery: null,
        upcoming: false,
      })
    );
    this.loginDetailsSub$ = this.loginDetails$.subscribe((state: LoginDetailsState) => {
      if (state.user && state.currentProfile) {
        this.store.dispatch(new FilterDataRequestAction({ uid: state.currentProfile.id }));
        this.store.dispatch(new AlertDataRequestAction({ installerID: state.currentProfile.id }));
        this.store.dispatch(
          new SystemMessageRequestAction({ type: '' })
        );
        this.selectPopupDisplayCountSub = this.selectPopupDisplayCount$.subscribe((count) => {
          if (count || count === 0) {
            this.popupDisplayCount = Number(count);
            this.showPromotionModal();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.loginDetailsSub$) {
      this.loginDetailsSub$.unsubscribe();
    }
    if (this.selectPopupDisplayCountSub) {
      this.selectPopupDisplayCountSub.unsubscribe();
    }
  }

  /**
   * Allow the user to edit a job from the homepage
   *
   * @param {Job} job The data for the new service record
   */
  handleJobUpdateRequest(job: Job) {
    this.store.dispatch(new UpdateJobRequestAction({ job }));
  }

  handleWarrantyUpdateRequest(warranty: Warranty) {
    this.store.dispatch(new UpdateWarrantyRequestAction(warranty));
  }

  handleAlertDismissRequest(id: string) {
    this.store.dispatch(new DismissAlertRequestAction({ id: id }));
  }

  private showPromotionModal(): void {
    // timeout prevents modal race condition error when a new user signs in for the first time
    setTimeout(() => {
      if (!this.dialog.getDialogById('promo') && !this.popupShown && moment().isBetween(PROMO_MODAL_START_DATE, PROMO_MODAL_STOP_DATE) && this.popupDisplayCount < PROMO_MODAL_MAX_TIMES_TO_DISPLAY) {
        this.popupShown = true;
        this.dialog.open(PromotionModalComponent, {
          id: 'promo',
          panelClass: 'feature-modal-dialog',
          width: '500px',
        });
      }
    }, 0);
  }

}
