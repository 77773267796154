/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../user-profile/profile-percentage/profile-percentage.component.ngfactory";
import * as i3 from "../../user-profile/profile-percentage/profile-percentage.component";
import * as i4 from "../../user-profile/profile-header/profile-header.component.ngfactory";
import * as i5 from "../../user-profile/profile-header/profile-header.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./profile-widget.component";
var styles_ProfileWidgetComponent = [i0.styles];
var RenderType_ProfileWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileWidgetComponent, data: {} });
export { RenderType_ProfileWidgetComponent as RenderType_ProfileWidgetComponent };
function View_ProfileWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "profile-details"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-profile-percentage", [["class", "profile-complete-percentage"]], null, null, null, i2.View_ProfilePercentageComponent_0, i2.RenderType_ProfilePercentageComponent)), i1.ɵdid(3, 49152, null, 0, i3.ProfilePercentageComponent, [], { userProfile: [0, "userProfile"], isWidget: [1, "isWidget"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-profile-header", [["class", "profile-details"]], null, null, null, i4.View_ProfileHeaderComponent_0, i4.RenderType_ProfileHeaderComponent)), i1.ɵdid(6, 49152, null, 0, i5.ProfileHeaderComponent, [i6.Router], { userProfile: [0, "userProfile"], isWidget: [1, "isWidget"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentProfile; var currVal_1 = true; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.currentProfile; var currVal_3 = true; _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
export function View_ProfileWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "widget-body border-box border-lighter-gray profile-widget background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  \n  "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "widget-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn background-green color-white full-width no-radius"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/profile") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Open profile"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.profilePending; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ProfileWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-widget", [], null, null, null, View_ProfileWidgetComponent_0, RenderType_ProfileWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i8.ProfileWidgetComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileWidgetComponentNgFactory = i1.ɵccf("app-profile-widget", i8.ProfileWidgetComponent, View_ProfileWidgetComponent_Host_0, { currentProfile: "currentProfile", profilePending: "profilePending" }, {}, [".widget-loading"]);
export { ProfileWidgetComponentNgFactory as ProfileWidgetComponentNgFactory };
