/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./file-download.component";
import * as i4 from "@angular/common/http";
var styles_FileDownloadComponent = [i0.styles];
var RenderType_FileDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileDownloadComponent, data: {} });
export { RenderType_FileDownloadComponent as RenderType_FileDownloadComponent };
function View_FileDownloadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["\n  ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("btn " + (_co.btnClass ? _co.btnClass : "")); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.btnText ? _co.btnText : "Download"); _ck(_v, 1, 0, currVal_1); }); }
function View_FileDownloadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "className", 0], [8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n  ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("btn " + (_co.btnClass ? _co.btnClass : "")); var currVal_1 = _co.fileURL; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.btnText ? _co.btnText : "Download"); _ck(_v, 1, 0, currVal_2); }); }
export function View_FileDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileDownloadComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileDownloadComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.downloadBlob; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.downloadBlob; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FileDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-download", [], null, null, null, View_FileDownloadComponent_0, RenderType_FileDownloadComponent)), i1.ɵdid(1, 114688, null, 0, i3.FileDownloadComponent, [i4.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileDownloadComponentNgFactory = i1.ɵccf("app-file-download", i3.FileDownloadComponent, View_FileDownloadComponent_Host_0, { fileURL: "fileURL", fileType: "fileType", fileName: "fileName", btnText: "btnText", btnClass: "btnClass", downloadBlob: "downloadBlob" }, {}, []);
export { FileDownloadComponentNgFactory as FileDownloadComponentNgFactory };
