import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

/**
 * Summary
 *    Self-contained component to handle the upload of files.
 *    
 *
 * Description
 *    This component will display the currently stored image along with a button to upload a new image. 
 *    If a new image is uploaded, this image will be displayed temporarily in place of the stored image 
 *    as a preview until the user chooses to save or remove the new image.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  // URL of an existing file
  @Input() initialUrl: string = null;

  // If true, a preview image will be displayed
  @Input() imageMode: boolean = true;

  // Placeholder text for file input
  @Input() placeholder: string = 'Choose file...';

  // Fired when file upload data becomes available (sends a data URL)
  @Output() onData = new EventEmitter<string>();


  // Flag to indicate if the File API is supported in the browser
  public fileAPISupported: boolean = true;

  // Errors for display
  public errors: string  = null;

  // New file data (data URL)
  public fileData: string  = null;

  /**
   * Constructor for page
   *
   * @param {ChangeDetectorRef} cdRef Initialises reference to ChangeDetectorRef so that page updates can be triggered manually
   */
  constructor(
    private cdRef: ChangeDetectorRef,
  ) { }

  /**
   * Detect if the file utilities are supported in the current browser and set the fileAPISupported flag
   */
  ngOnInit() {
    this.fileAPISupported = window
      ? !!((window as any).File && (window as any).FileReader && (window as any).FileList && (window as any).Blob)
      : true;
  }


  /**
   * Handle the upload of a new image and emit the result to the parent component
   *
   * @param {any} evt Reference to the event passed to the function from the input
   */
  handleFile(evt) {
    // Set error string and return if the file array is empty
    if (evt.target.files.length === 0)
    {
      this.errors = 'No files could be uploaded';
      return;
    }

    // Create a new instance of FileReader
    const reader = new FileReader();
    // Reset the errors string
    this.errors = null;

    // Trigger additional code when the file has began uploading
    reader.onloadstart = (e) => {
    };
    // Trigger additional code while the file is uploading
    reader.onprogress = (e) => {
    };
    
    // Trigger additional code once the file has finished uploading
    reader.onload = (e) => {
      // Reference to the file data returned from the reader.result instance
      this.fileData = reader.result;
      // Reset the errors string
      this.errors = null;
      // Manually prompt the display to update in order to show the new image
      this.cdRef.detectChanges();
      // Emit the data to the parent component
      this.onData.emit(this.fileData);
    };
    
    // Trigger additional code if the file failed to upload
    reader.onerror = (e) => {
      this.errors = `Unable to upload file: ${e.toString()}`;
    };
    // Trigger additional code if the upload process was aborted
    reader.onabort = () => {
      this.errors = 'Photo upload was aborted';
    };
    
    // Tell the reader to begin reading the files provided in order to trigger the events above
    reader.readAsDataURL(evt.target.files[0]);
  }

  /**
   * Remove the uploaded image and emit a null value to the parent component in order to
   * display the currently stored image once again
   */
  resetUpload() {
    this.fileData = null;
    this.onData.emit(null);
  }
}
