import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { UserProfile } from '../../../models/user-profile';
import { ProfileDetailsComponent } from '../profile-details/profile-details.component';
import { UserEditPasswordModalComponent } from '../user-edit-password-modal/user-edit-password-modal.component';

/**
 * Summary
 *    Modal dialog used to display ProfileDetailsComponent
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-profile-edit-modal',
  templateUrl: './profile-edit-modal.component.html',
  styleUrls: ['./profile-edit-modal.component.scss']
})
export class ProfileEditModalComponent {

  // Reference to the child ProfileDetailsComponent
  @ViewChild(ProfileDetailsComponent) compProfile: ProfileDetailsComponent;

  public formChanged = false;
  // New password to save, as set by UserEditPasswordModalComponent
  public newPassword: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogref: MatDialogRef<ProfileEditModalComponent>,
    private dialog: MatDialog,
  ) { }

  /**
   * Called when the "Save" button is clicked and calls
   * ProfileDetailsComponent::onSubmitForm()
   */
  onSave() {
    this.compProfile.onSubmitForm();
  }

  /**
   * Called when the ProfileDetailsComponent has been submitted successfully.
   * Combines the new profile data with the new password (if changed) and
   * closes the dialog with the result.
   *
   * @param {UserProfile} p Updated UserProfile model from ProfileDetailsComponent
   */
  onSaveDetails(p: UserProfile) {
    this.dialogref.close(this.newPassword ? Object.assign({}, p, { password: this.newPassword }) : p);
  }

  /**
   * Opens the UserEditPasswordModalComponent and sets this.newPassword with
   * the result upon success
   */
  openResetPassword() {
    const updateRef = this.dialog.open(UserEditPasswordModalComponent, {
      data: {
        patchData: this.data.userProfile
      },
      panelClass: 'feature-modal-dialog',
    });

    updateRef.afterClosed().subscribe((result) => {
      this.newPassword = result;
    });
  }
}
