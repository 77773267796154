/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./diary-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../diary/diary-calendar/diary-calendar.component.ngfactory";
import * as i3 from "angular-calendar/dist/esm/src/providers/calendarDateFormatter.provider";
import * as i4 from "../../diary/diary-calendar/custom-date-formatter";
import * as i5 from "../../diary/diary-calendar/diary-calendar.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "./diary-widget.component";
import * as i8 from "@angular/router";
var styles_DiaryWidgetComponent = [i0.styles];
var RenderType_DiaryWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DiaryWidgetComponent, data: {} });
export { RenderType_DiaryWidgetComponent as RenderType_DiaryWidgetComponent };
export function View_DiaryWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "app-diary-calendar", [], null, null, null, i2.View_DiaryCalendarComponent_0, i2.RenderType_DiaryCalendarComponent)), i1.ɵprd(4608, null, i3.CalendarDateFormatter, i4.CustomDateFormatter, []), i1.ɵdid(4, 770048, null, 0, i5.DiaryCalendarComponent, [i6.MatDialog], { events: [0, "events"], eventTypes: [1, "eventTypes"], readOnly: [2, "readOnly"], small: [3, "small"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "widget-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn background-green color-white full-width no-radius"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToDiary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Open diary"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.events; var currVal_2 = _co.eventTypes; var currVal_3 = true; var currVal_4 = true; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("widget-body diary-widget border-box border-lighter-gray background-white col-xs-12 " + _co.additionalClasses); _ck(_v, 0, 0, currVal_0); }); }
export function View_DiaryWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-diary-widget", [], null, null, null, View_DiaryWidgetComponent_0, RenderType_DiaryWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i7.DiaryWidgetComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DiaryWidgetComponentNgFactory = i1.ɵccf("app-diary-widget", i7.DiaryWidgetComponent, View_DiaryWidgetComponent_Host_0, { events: "events", additionalClasses: "additionalClasses", eventTypes: "eventTypes" }, {}, []);
export { DiaryWidgetComponentNgFactory as DiaryWidgetComponentNgFactory };
