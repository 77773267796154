<button mat-button [matDialogClose]="false" style="float: right; font-size: small;"><i class="fa fa-times"></i></button>
<h2 matDialogTitle>
    {{deviceAlert.description}}
</h2>

<mat-dialog-content>
    <div>
        <h3>Device Information</h3>
        {{ deviceAlert.serialNo }} - {{ deviceAlert.filterNo }} (Device ID: {{ deviceAlert.deviceID }})

        <h3>Alert Information</h3>
        <p>Alert Code: {{ deviceAlert.code }}</p>
        <p *ngIf="deviceAlert.transient">Alert is Transient</p>

        <p>Created: {{ deviceAlert.created_at | dateTimeFormat }}</p>
        <p *ngIf="deviceAlert.created_at != deviceAlert.updated_at">
            Then Updated: {{ deviceAlert.updated_at | dateTimeFormat }}
        </p>
        <p>Source: {{ deviceAlert.source | titlecase }}</p>

        <p>Alert ID: {{ deviceAlert.id }}</p>


        <h3>Visibility</h3>
        <table>
            <thead>
            <tr>
                <th>User</th>
                <th>Name</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Installer</td>
                <td>
                    <span *ngIf="deviceAlert.installer; else noInstaller">
                        {{ deviceAlert.installer }} ({{ deviceAlert.installerID }})
                    </span>
                    <ng-template #noInstaller>(Not Registered)</ng-template>
                </td>
                <td>
                    <span *ngIf="!deviceAlert.showInstaller; else visibleToInstaller">
                    <span *ngIf="deviceAlert.definitionShowInstaller; else hiddenFromInstaller">
                        Dismissed
                    </span>
                        <ng-template #hiddenFromInstaller>
                            Hidden
                        </ng-template>
                    </span>
                    <ng-template #visibleToInstaller>
                        <span *ngIf="deviceAlert.installer">
                        Visible
                        </span>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td>Homeowner</td>
                <td>
                    <span *ngIf="deviceAlert.homeowner; else noHomeowner">
                        {{ deviceAlert.homeowner_name }} ({{ deviceAlert.homeowner }})
                    </span>
                    <ng-template #noHomeowner>(Not Registered)</ng-template>
                </td>
                <td>
                    <span *ngIf="!deviceAlert.showCustomer; else visibleToCustomer">
                    <span *ngIf="deviceAlert.definitionShowCustomer; else hiddenFromCustomer">
                        Dismissed
                    </span>
                        <ng-template #hiddenFromCustomer>
                            Hidden
                        </ng-template>
                    </span>
                    <ng-template #visibleToCustomer>
                        <span *ngIf="deviceAlert.homeowner">
                            Visible
                        </span>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td colspan="2">Customer Services</td>
                <td>
                    <span *ngIf="!deviceAlert.showAdmin; else visibleToAdmin">
                    <span *ngIf="deviceAlert.definitionShowAdmin; else hiddenFromAdmin">
                        Dismissed
                    </span>
                        <ng-template #hiddenFromAdmin>
                            Hidden
                        </ng-template>
                    </span>
                    <ng-template #visibleToAdmin>
                        <i
                                class="fa fa-trash color-adey-red"
                                (click)="dismissAlert($event)"
                        ></i>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>

        <h3>Customer Information</h3>
        <p>Warranty Customer:
            <span *ngIf="deviceAlert.customerID">{{ deviceAlert.customer }}</span>
        </p>

        <p>Homeowner User:
            <span *ngIf="deviceAlert.homeowner">{{ deviceAlert.homeowner_name }}</span>
        </p>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <!--<button mat-button [matDialogClose]="false">Close</button>-->
    <!--<button mat-button (click)="onSave()">Save</button>-->
</mat-dialog-actions>