import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService }   from '../../services/admin.service';
import { ContentService } from '../../services/content.service';

import * as ActionTypes from '../actions/content-pages';

import {
  AdminUser,
} from '../../models/admin-users';

import {
  FetchPageContentResponse,
  UpdatePageContentRequest,
  UpdatePageContentResponse,
} from '../../models/content-pages';

@Injectable()
export class ContentPagesEffects {
  constructor(
    private adminService:   AdminService,
    private contentService: ContentService,
    private actions$:       Actions
  ) {}

  /**
   * For a FetchPageContentRequestAction, call
   * ContentService::fetchPageContent() and dispatch a new
   * FetchPageContentResponseAction with the response.
   */
  @Effect() fetchPageContent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_PAGE_CONTENT_REQUEST)
    .switchMap((req: ActionTypes.FetchPageContentRequestAction): Observable<ActionTypes.FetchPageContentResponseAction> =>
      this.contentService.fetchPageContent()
        .map((res: FetchPageContentResponse): ActionTypes.FetchPageContentResponseAction =>
          new ActionTypes.FetchPageContentResponseAction(res)
        )
    );

  /**
   * For an UpdatePageContentRequestAction, call
   * AdminService::updatePageContent() and dispatch a new
   * UpdatePageContentResponseAction with the response.
   */
  @Effect() updatePageContent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_PAGE_CONTENT_REQUEST)
    .switchMap((req: ActionTypes.UpdatePageContentRequestAction): Observable<ActionTypes.UpdatePageContentResponseAction> =>
      this.adminService.updatePageContent(req.payload)
        .map((res: UpdatePageContentResponse): ActionTypes.UpdatePageContentResponseAction =>
          new ActionTypes.UpdatePageContentResponseAction(res)
        )
    );
}
