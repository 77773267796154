/**
 * Mock for ProductSelectorService (see main service for full details)
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Product } from '../models/product';
import {
  ProductSelectQueryRequest,
  ProductSelectQueryResponse,
} from '../models/product-selector';

import {
  ProductCheckSerialRequest,
  ProductCheckSerialResponse,
} from '../models/product';


@Injectable()
export class ProductSelectorService {

  query(q: ProductSelectQueryRequest): Observable<ProductSelectQueryResponse> {

    const products = [];
    for (let i = 0; i < 10; i++)
      products.push(Product.getExample());

    return Observable
      .of({
        error: null,
        products,
      })
      .delay(1000);
  }

  checkSerial(q: ProductCheckSerialRequest): Observable<ProductCheckSerialResponse> {
    return Observable
      .of({
        valid: (q.serialNumber === 'INVALID' ? false : true),
        message: (q.serialNumber === 'INVALID' ? 'Serial number has already been used' : null),
      })
      .delay(1000);
  }

}
