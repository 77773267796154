import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-resources';
import { AddAdminResourceResponse, DeleteAdminResourceResponse, FetchAdminResourcesResponse, UpdateAdminResourceResponse, } from '../../models/admin-resources';
var AdminResourcesEffects = /** @class */ (function () {
    function AdminResourcesEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For an AddAdminResourceRequestAction, call AdminService::addResource() and
         * dispatch a new AddAdminResourceResponseAction with the response.
         */
        this.addResource$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_RESOURCE_REQUEST)
            .switchMap(function (req) {
            return _this.service.addResource(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminResourceResponseAction(res);
            });
        });
        /**
         * For a DeleteAdminResourceRequestAction, call
         * AdminService::deleteResource() and dispatch a new
         * DeleteAdminResourceResponseAction with the response.
         */
        this.deleteResource$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_RESOURCE_REQUEST)
            .switchMap(function (req) {
            return _this.service.deleteResource(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminResourceResponseAction(res);
            });
        });
        /**
         * For a FetchAdminResourcesRequestAction, call
         * AdminService::fetchResources() and dispatch a new
         * FetchAdminResourcesResponseAction with the response.
         */
        this.fetchResources$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_RESOURCES_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchResources(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminResourcesResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminResourceRequestAction, call
         * AdminService::updateResource() and dispatch a new
         * UpdateAdminResourceResponseAction with the response.
         */
        this.updateResource$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_RESOURCE_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateResource(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminResourceResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminResourcesEffects.prototype, "addResource$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminResourcesEffects.prototype, "deleteResource$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminResourcesEffects.prototype, "fetchResources$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminResourcesEffects.prototype, "updateResource$", void 0);
    return AdminResourcesEffects;
}());
export { AdminResourcesEffects };
