import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { WarrantyService } from '../../services/warranty.service';
import * as ActionTypes from '../actions/register-warranty';
import { CheckPromoCodeResponse, FetchProductTypesResponse, RegisterWarrantyResponse, } from '../../models/warranty';
var RegisterWarrantyEffects = /** @class */ (function () {
    function RegisterWarrantyEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a CheckPromoCodeRequestAction, call WarrantyService::checkPromoCode()
         * and dispatch a new CheckPromoCodeResponseAction with the response.
         */
        this.checkPromoCode$ = this.actions$
            .ofType(ActionTypes.CHECK_PROMO_CODE_REQUEST)
            .switchMap(function (req) {
            return _this.service.checkPromoCode(req.payload)
                .map(function (res) {
                return new ActionTypes.CheckPromoCodeResponseAction(res);
            });
        });
        /**
         * For a FetchProductTypesRequestAction, call
         * WarrantyService::FetchProductTypes() and dispatch a new
         * FetchProductTypesResponseAction with the response.
         */
        this.FetchProductTypesRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_PRODUCT_TYPES_REQUEST)
            .switchMap(function (req) {
            return _this.service.FetchProductTypes(true)
                .map(function (res) {
                return new ActionTypes.FetchProductTypesResponseAction(res);
            });
        });
        /**
         * For a RegisterWarrantyRequestAction, call
         * WarrantyService::submit() and dispatch a new
         * RegisterWarrantyResponseAction with the response.
         */
        this.registerWarrantyRequest$ = this.actions$
            .ofType(ActionTypes.REGISTER_WARRANTY_REQUEST)
            .switchMap(function (req) {
            return _this.service.submit(req.payload)
                .map(function (res) {
                return new ActionTypes.RegisterWarrantyResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterWarrantyEffects.prototype, "checkPromoCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterWarrantyEffects.prototype, "FetchProductTypesRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegisterWarrantyEffects.prototype, "registerWarrantyRequest$", void 0);
    return RegisterWarrantyEffects;
}());
export { RegisterWarrantyEffects };
