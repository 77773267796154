import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {State as SystemMessageState} from '../../state-management/reducers/system-message';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Store} from '@ngrx/store';
import {StoreState} from '../../state-management/store';
import * as moment from 'moment';

@Component({
  selector: 'app-system-message-modal',
  templateUrl: './system-message-modal.component.html',
  styleUrls: ['./system-message-modal.component.scss']
})

export class SystemMessageModalComponent implements OnInit {

  public systemMessage$: Observable<SystemMessageState> = null;

  public startDate: string;
  public endDate: string;

  public typeMessage: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SystemMessageModalComponent>,
    private store:     Store<StoreState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // @ts-ignore
    this.systemMessage$ = this.store.select('systemMessage');
  }

  ngOnInit() {

    if (this.data !== 'system-down') {

        this.startDate = moment(this.data[0].start_date).format('DD-MM-YYYY HH:mm');
        this.endDate = moment(this.data[0].end_date).format('DD-MM-YYYY HH:mm');
        this.typeMessage = false;

    } else {
        this.typeMessage = true;
    }

    // console.log('data : ', this.data);
    // console.log('type : ', this.typeMessage);
  }


  public dismiss(): void {
    this.dialogRef.close();
  }


}

