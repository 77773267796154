import { Injectable } from '@angular/core';

import { ApiService } from '../../services/api.service';

@Injectable()
export class ResultCompanyListService {

  constructor(
      private apiService: ApiService
  ) { }

  public getResultsCustomerService(query, type, company_id) {

    let search = '';
    if (type === 'member') {
      search = '((user_id = ' + query + ') and (hosted_company_id = ' + company_id + '))';
    }else {
      search = '((watertest_location_id = ' + query + ') and (hosted_company_id = ' + company_id + '))';
    }
    return search;

  }

  public getUserById(id) {
    return this.apiService.apiGet('/watertest/user/' + id);
  }

  public getLocationById(id) {
    return this.apiService.apiGet('/watertest/location-result?filter=(location_id = ' + id + ')');
  }
}
