import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';

import * as ActionTypes from '../actions/admin-resources';

import {
  AddAdminResourceRequest,
  AddAdminResourceResponse,
  DeleteAdminResourceRequest,
  DeleteAdminResourceResponse,
  FetchAdminResourcesRequest,
  FetchAdminResourcesResponse,
  UpdateAdminResourceRequest,
  UpdateAdminResourceResponse,
} from '../../models/admin-resources';

@Injectable()
export class AdminResourcesEffects {
  constructor(
    private service:  AdminService,
    private actions$: Actions
  ) {}

  /**
   * For an AddAdminResourceRequestAction, call AdminService::addResource() and
   * dispatch a new AddAdminResourceResponseAction with the response.
   */
  @Effect() addResource$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_RESOURCE_REQUEST)
    .switchMap((req: ActionTypes.AddAdminResourceRequestAction): Observable<ActionTypes.AddAdminResourceResponseAction> =>
      this.service.addResource(req.payload)
        .map((res: AddAdminResourceResponse): ActionTypes.AddAdminResourceResponseAction =>
          new ActionTypes.AddAdminResourceResponseAction(res)
        )
    );

  /**
   * For a DeleteAdminResourceRequestAction, call
   * AdminService::deleteResource() and dispatch a new
   * DeleteAdminResourceResponseAction with the response.
   */
  @Effect() deleteResource$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_RESOURCE_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminResourceRequestAction): Observable<ActionTypes.DeleteAdminResourceResponseAction> =>
      this.service.deleteResource(req.payload)
        .map((res: DeleteAdminResourceResponse): ActionTypes.DeleteAdminResourceResponseAction =>
          new ActionTypes.DeleteAdminResourceResponseAction(res)
        )
    );

  /**
   * For a FetchAdminResourcesRequestAction, call
   * AdminService::fetchResources() and dispatch a new
   * FetchAdminResourcesResponseAction with the response.
   */
  @Effect() fetchResources$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_RESOURCES_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminResourcesRequestAction): Observable<ActionTypes.FetchAdminResourcesResponseAction> =>
      this.service.fetchResources(req.payload)
        .map((res: FetchAdminResourcesResponse): ActionTypes.FetchAdminResourcesResponseAction =>
          new ActionTypes.FetchAdminResourcesResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminResourceRequestAction, call
   * AdminService::updateResource() and dispatch a new
   * UpdateAdminResourceResponseAction with the response.
   */
  @Effect() updateResource$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_RESOURCE_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminResourceRequestAction): Observable<ActionTypes.UpdateAdminResourceResponseAction> =>
      this.service.updateResource(req.payload)
        .map((res: UpdateAdminResourceResponse): ActionTypes.UpdateAdminResourceResponseAction =>
          new ActionTypes.UpdateAdminResourceResponseAction(res)
        )
    );
}
