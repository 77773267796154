/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./waiting-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "./waiting-spinner.component";
var styles_WaitingSpinnerComponent = [i0.styles];
var RenderType_WaitingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WaitingSpinnerComponent, data: {} });
export { RenderType_WaitingSpinnerComponent as RenderType_WaitingSpinnerComponent };
function View_WaitingSpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 49152, null, 0, i3.MatProgressBar, [i1.ElementRef], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.colorType; var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).value; var currVal_1 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_WaitingSpinnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i4.View_MatProgressSpinner_0, i4.RenderType_MatProgressSpinner)), i1.ɵdid(1, 573440, null, 0, i5.MatProgressSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT]], { color: [0, "color"], diameter: [1, "diameter"], strokeWidth: [2, "strokeWidth"], mode: [3, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.colorType; var currVal_7 = _co.spinnerSize; var currVal_8 = _co.spinnerStroke; var currVal_9 = "indeterminate"; _ck(_v, 1, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._elementSize; var currVal_1 = i1.ɵnov(_v, 1)._elementSize; var currVal_2 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_3 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_4 = i1.ɵnov(_v, 1).value; var currVal_5 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_WaitingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitingSpinnerComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitingSpinnerComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.kind === "bar"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.kind === "spinner"); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("spinner-container" + ((_co.kind === "spinner") ? " spinner-inline" : "")); _ck(_v, 0, 0, currVal_0); }); }
export function View_WaitingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-waiting-spinner", [], null, null, null, View_WaitingSpinnerComponent_0, RenderType_WaitingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i8.WaitingSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WaitingSpinnerComponentNgFactory = i1.ɵccf("app-waiting-spinner", i8.WaitingSpinnerComponent, View_WaitingSpinnerComponent_Host_0, { kind: "kind", colorType: "colorType", spinnerSize: "spinnerSize", spinnerStroke: "spinnerStroke" }, {}, ["*"]);
export { WaitingSpinnerComponentNgFactory as WaitingSpinnerComponentNgFactory };
