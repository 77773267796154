import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

import {
  ActivateUserResponse,
  ConfirmProfileResponse,
  CreatePromoShopTokenResponse,
  FetchUserProfileResponse,
  LoadLoginTokenResponse,
  LoginResponse,
  RequestResetPasswordResponse,
  ResetPasswordResponse,
  TokenCheckResponse,
  WaterTesterRegisterResponse,
} from '../../models/login';
import { NavItems } from '../../models/nav-items';
import { ProCheckSite } from '../../models/procheck-site';
import { FetchAccreditationsResponse, UpdateUserProfileResponse } from '../../models/user-profile';
import { LoginService } from '../../services/login.service';
import { GetFeatures } from '../actions/feature';
import * as ActionTypes from '../actions/login-details';
import { StoreState } from '../store';
import { LoginWaterTesterRequestAction, ModalRegisterWaterTesterRequestAction } from '../actions/login-details';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private navItems: NavItems,
    private router: Router,
    private store: Store<StoreState>,
    private translate: TranslateService,
  ) { }

  /**
   * For an ActivateUserRequestAction, call LoginService::activateUser() and
   * dispatch a new ActivateUserResponseAction with the response.
   */
  @Effect() activateUser$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ACTIVATE_USER_REQUEST)
    .switchMap((req: ActionTypes.ActivateUserRequestAction): Observable<ActionTypes.ActivateUserResponseAction> =>
      this.loginService.activateUser(req.payload).map((res: ActivateUserResponse): ActionTypes.ActivateUserResponseAction =>
        new ActionTypes.ActivateUserResponseAction(res)
      )
    );

  /**
   * For an ActivateUserResponseAction, redirect the user to /login. Do not
   * dispatch any further actions.
   */
  @Effect({ dispatch: false }) activateUserResponse$: Observable<void> = this.actions$
    .ofType(ActionTypes.ACTIVATE_USER_RESPONSE)
    .map((res: ActionTypes.ActivateUserResponseAction): void => {
      if (!res.payload.error && !res.payload.homeowner)
        this.router.navigate(['/login']);
    });

  /**
   * For a CheckLoginTokenRequestAction, call LoginService::checkToken() and
   * dispatch a new CheckLoginTokenResponseAction with the response.
   */
  @Effect() checkToken$: Observable<Action> = this.actions$
    .ofType(ActionTypes.CHECK_LOGIN_TOKEN_REQUEST)
    .concatMap((req: ActionTypes.CheckLoginTokenRequestAction): Observable<Action> =>
      this.loginService.checkToken()
        .map((res: TokenCheckResponse): TokenCheckResponse => {
          const u = res.user;
          const proCheck = ProCheckSite.getCheck();
          if (u && u.optIns.length === 0 && !proCheck) {
            this.router.navigate(['/optin']);
          } else {
            // Redirect client for other page if token is valid
            // requested
            if (res.valid && req.payload.redirectPage) {
              // console.log('effect : ' + req.payload.redirectPage);
              this.router.navigate([req.payload.redirectPage]);
            } else {

              // Redirect client if token is invalid and if redirection has been
              // requested
              if (!res.valid && req.payload.redirectClientInvalid) {
                this.router.navigate([req.payload.redirectClientInvalid]);
              }

              // Redirect client if token is valid and if redirection has been
              // requested (based on user type and confirmed flag)
              if (res.valid && req.payload.redirectClientValid) {
                this.router.navigate([this.navItems.getDefaultRouteForUser(res.user)]);
              }

            }
          }
          return res;
        })
        .map((res: TokenCheckResponse): ActionTypes.CheckLoginTokenResponseAction =>
          new ActionTypes.CheckLoginTokenResponseAction(res)
        )
    );

  /**
   * For a CheckLoginTokenResponseAction, dispatch a
   * FetchUserProfileRequestAction to get the latest user profile if the
   * response was valid.
   */
  @Effect() checkTokenResponse$: Observable<Action> = this.actions$
    .ofType(ActionTypes.CHECK_LOGIN_TOKEN_RESPONSE)
    .filter((res: ActionTypes.CheckLoginTokenResponseAction): boolean => res.payload.valid)
    .map((res: ActionTypes.CheckLoginTokenResponseAction): ActionTypes.FetchUserProfileRequestAction => {
      this.store.dispatch(new GetFeatures());
      return new ActionTypes.FetchUserProfileRequestAction();
    });

  /**
   * For a CreatePromoShopTokenRequestAction, call
   * LoginService::createPromoShopToken() and dispatch a new
   * CreatePromoShopTokenResponseAction with the response.
   */
  @Effect() createPromoShopToken$: Observable<Action> = this.actions$
    .ofType(ActionTypes.CREATE_PROMOSHOP_TOKEN_REQUEST)
    .switchMap((req: ActionTypes.CreatePromoShopTokenRequestAction): Observable<ActionTypes.CreatePromoShopTokenResponseAction> =>
      this.loginService.createPromoShopToken()
        .map((res: CreatePromoShopTokenResponse) => new ActionTypes.CreatePromoShopTokenResponseAction(res))
    );

  /**
   * For a FetchUserProfileRequestAction, call LoginService::fetchProfile() and
   * dispatch a new FetchUserProfileResponseAction with the response.
   */
  @Effect() fetchProfile$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_USER_PROFILE_REQUEST)
    .concatMap((_req: ActionTypes.FetchUserProfileRequestAction): Observable<ActionTypes.FetchUserProfileResponseAction> =>
      this.loginService.fetchProfile()
        .map((res: FetchUserProfileResponse) => {

          this.translate.use(res.profile.countries_by_country_id.country_code);

          return new ActionTypes.FetchUserProfileResponseAction(res);
        })
    );

  /**
   * For a LoadLoginTokenRequestAction, call LoginService::loadToken() and
   * dispatch a new LoadLoginTokenResponseAction with the response.
   */
  @Effect() loadToken$: Observable<Action> = this.actions$
    .ofType(ActionTypes.LOAD_LOGIN_TOKEN_REQUEST)
    .switchMap((): Observable<Action> =>
      this.loginService.loadToken()
        .map((res: LoadLoginTokenResponse): ActionTypes.LoadLoginTokenResponseAction =>
          new ActionTypes.LoadLoginTokenResponseAction(res))
    );

  /**
   * For a LoginRequestAction, call LoginService::login() and dispatch a new
   * LoginResponseAction with the response. Also store the user's token if the
   * response was valid.
   */
  @Effect() loginRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.LOGIN_REQUEST)
    .switchMap((req: ActionTypes.LoginRequestAction): Observable<ActionTypes.LoginResponseAction> =>
      this.loginService.login(req.payload)
        .map((res: LoginResponse): ActionTypes.LoginResponseAction => {
          if (res.profile) this.translate.use(res.profile.countries_by_country_id.country_code);
          // Handle redirects if login was successful
          const u = res.user;
          const proCheck = ProCheckSite.getCheck();

          // If login is successful, redirect
          if (u) {
            if (u.optIns.length === 0 && !proCheck) {
              this.router.navigate(['/optin']);
            }
            // Redirect client for other page if logged successfully
            if (req.payload.redirectPage) {
              this.router.navigate([req.payload.redirectPage]);
            }
            this.router.navigate([this.navItems.getDefaultRouteForUser(u)]);
          }

          return new ActionTypes.LoginResponseAction(res);
        })
    );

  /**
   * Resend user account activation email
   * Password reset models are used here as they are identical
   */
  @Effect() resendActivationEmailRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.RESEND_ACTIVATION_EMAIL_REQUEST)
    .switchMap((req: ActionTypes.ResendActivationEmailRequestAction): Observable<ActionTypes.ResendActivationEmailResponseAction> =>
      this.loginService.resendActivationEmail(req.payload)
        .map((res: RequestResetPasswordResponse): ActionTypes.ResendActivationEmailResponseAction => {
          return new ActionTypes.ResendActivationEmailResponseAction(res);
        })
    );

  /**
   * For a LoginRequestAction, call LoginService::login() and dispatch a new
   * LoginResponseAction with the response. Also store the user's token if the
   * response was valid.
   */
  @Effect() loginExistingRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.LOGIN_EXISTING_REQUEST)
    .switchMap((req: ActionTypes.LoginExistingRequestAction): Observable<ActionTypes.LoginResponseAction> =>
      this.loginService.loginExisting(req.payload)
        .map((res: LoginResponse): ActionTypes.LoginResponseAction => {
          this.router.navigate([this.navItems.getDefaultRouteForUser(res.user)]);
          return new ActionTypes.LoginResponseAction(res);
        })
    );

  /**
   * For a LogoutAction, call LoginService::logout() and redirect the client to
   * "/". Do not dispatch any further actions.
   */
  @Effect({ dispatch: false }) logout$: Observable<void> = this.actions$
    .ofType(ActionTypes.LOGOUT)
    .map((a: ActionTypes.LogoutAction): void => {
      this.loginService.logout();
      this.router.navigate(['/login']);
    });

  /**
   * For a RequestResetPasswordRequestAction, call
   * LoginService::requestResetPassword() and dispatch a new
   * RequestResetPasswordResponseAction with the response.
   */
  @Effect() requestResetPassword$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REQUEST_RESET_PASSWORD_REQUEST)
    .switchMap((req: ActionTypes.RequestResetPasswordRequestAction): Observable<Action> =>
      this.loginService.requestResetPassword(req.payload)
        .map((res: RequestResetPasswordResponse): ActionTypes.RequestResetPasswordResponseAction =>
          new ActionTypes.RequestResetPasswordResponseAction(res)
        )
    );

  /**
   * For a ResetPasswordRequestAction, call LoginService::resetPassword() and
   * dispatch a new ResetPasswordResponseAction with the response.
   */
  @Effect() resetPassword$: Observable<Action> = this.actions$
    .ofType(ActionTypes.RESET_PASSWORD_REQUEST)
    .switchMap((req: ActionTypes.ResetPasswordRequestAction): Observable<Action> =>
      this.loginService.resetPassword(req.payload)
        .map((res: ResetPasswordResponse): ActionTypes.ResetPasswordResponseAction =>
          new ActionTypes.ResetPasswordResponseAction(res)
        )
    );

  /**
   * For an UpdateUserProfileRequestAction, call
   * LoginService::updateUserProfile() and dispatch a new
   * UpdateUserProfileResponseAction with the response.
   */
  @Effect() updateProfile$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_USER_PROFILE_REQUEST)
    .switchMap((req: ActionTypes.UpdateUserProfileRequestAction): Observable<ActionTypes.UpdateUserProfileResponseAction> =>
      this.loginService.updateUserProfile(req.payload)
        .map((res: UpdateUserProfileResponse): ActionTypes.UpdateUserProfileResponseAction =>
          new ActionTypes.UpdateUserProfileResponseAction(res)
        )
    );

  /**
   * For a FetchAccreditationsRequestAction, call LoginService::fetchAccreditations() and
   * dispatch a new FetchAccreditationsResponseAction with the response.
   */
  @Effect() fetchAccreditations$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ACCREDITATIONS_REQUEST)
    .switchMap((): Observable<Action> =>
      this.loginService.fetchAccreditations()
        .map((res: FetchAccreditationsResponse): ActionTypes.FetchAccreditationsResponseAction =>
          new ActionTypes.FetchAccreditationsResponseAction(res)
        )
    );

  @Effect() loginWaterTester$: Observable<Action> = this.actions$
    .ofType(ActionTypes.LOGIN_WATER_TESTER_REQUEST)
    .concatMap((req: ActionTypes.LoginWaterTesterRequestAction): Observable<ActionTypes.LoginWaterTesterResponseAction> =>
      this.loginService.loginWaterTester(req.payload)
        .map((res: LoginResponse): ActionTypes.LoginWaterTesterResponseAction => {
              return new ActionTypes.LoginWaterTesterResponseAction(res);
        })
    );
  /**
   * For a LoginWaterTesterRequestAction, call LoginService::loginWaterTester() and dispatch a new
   * LoginWaterTesterResponseAction with the response. Also store the user's token if the
   * response was valid.
   */
  @Effect() registerWaterTester$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REGISTER_WATER_TESTER_REQUEST)
    .concatMap((req: ActionTypes.RegisterWaterTesterRequestAction): Observable<ActionTypes.RegisterWaterTesterResponseAction> =>
      this.loginService.registerWaterTester(req.payload)
        .map((res: WaterTesterRegisterResponse): ActionTypes.RegisterWaterTesterResponseAction => {
          return new ActionTypes.RegisterWaterTesterResponseAction(res);
        })
    );
}
