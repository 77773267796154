import {FilterChoice} from './filter-choice';
import {getType} from '@angular/core/src/errors';
import {HttpParams} from '@angular/common/http';

/**
 * Class DFTableRequest
 *
 * Provides the structure for requests to a DF table (using the df-proclub composer package)
 */
export class DFTableRequest {
    //Detailed filter:
    filter: string = '';

    //Quick filter string
    quickFilter?: string;

    //Page number to return
    page?: number;

    //Number of results on a page
    pagesize?: number;

    //Sort order
    orderBy?: string;

    //Bring in configurable filter:
    filterChoices?: FilterChoice;

    buildFilter(): string {
        let newFilter = '';
        if (typeof(this.filterChoices) !== 'undefined') {
            newFilter = this.filterChoices.getFilterString();
        }

        if (newFilter === '') {
            return this.filter;
        }

        //Check for undefined filter:
        if (typeof(this.filter) === 'undefined') {
            this.filter = '';
        }

        // Allow for any existing filter
        if (this.filter !== '') {
            this.filter = '(' + this.filter + ') AND ';
        }

        this.filter += newFilter;

        return this.filter;
    }

    /**
     * Method to build the query to send over to the middle API (df-proclub composer package)
     *
     * @returns String of a query
     */
    public getFullQuery(): string {
        let query: string = '';

        if (typeof(this.quickFilter) !== 'undefined')
        {
            query += '&quickFilter=' + encodeURIComponent(this.quickFilter);
        }

        if (typeof(this.filter) !== 'undefined')
        {
            query += '&filter=' + encodeURIComponent(this.filter);
        }

        if (typeof(this.page) !== 'undefined')
        {
            query += '&page=' + encodeURIComponent('' + this.page);
        }

        if (typeof(this.pagesize) !== 'undefined')
        {
            query += '&pagesize=' + encodeURIComponent('' + this.pagesize);
        }

        if (typeof(this.orderBy) !== 'undefined')
        {
            query += '&orderby=' + encodeURIComponent('' + this.orderBy);
        }

        return query;
    }
}

/**
 * Class DFSPRequest
 *
 * Provides the structure for accessing SPs in DF
 */
export class DFSPRequest<T> {
    params: T;

    constructor(params: T) {
        this.params = params;
    }

    getQueryString(): string {
        let queryString = '';

        //See if there are any parameters, and add them if so
        if (this.propertyIsEnumerable('params')) {
          Object.keys(this.params).forEach((param) => {
            const value = <Object>this.params[param];
            let valueString = '';
            if (value) {
              valueString = value.toString();
            }

                queryString += encodeURIComponent(param) + '=';
                queryString += encodeURIComponent(valueString) + '&';
          }, this);
        }

        return queryString;
    }
}


/**
 *
 */
export class DFResponse<T> {
    paging?: DFTablePaging;
    timing?: DFTableTiming;
    data?: T[];
    error?: string;
}

export class DFTablePaging {
    results: number;
    pagesize: number;
    page: number;
    pages: number;
}

export class DFTableTiming {
    start: any[];
    end: any[];
    processing: any[];
}
