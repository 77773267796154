/**
 * Mock for DiaryService (see main service for full details)
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import {
  CalendarEventAction,
} from 'angular-calendar';

import {
  DiaryEvent,
  EventType,

  CommitEventResponse,
  FetchEventsResponse,
  FetchEventTypesResponse,
} from '../models/diary';

@Injectable()
export class DiaryService {

  addEvent(req: DiaryEvent): Observable<CommitEventResponse> {
    const valid = req.id === null;
    return Observable
      .of({
        error: valid ? null : 'Cannot specify an ID when adding an event',
        id:    null,
        newId: Math.floor(Math.random() * 100000).toString(),
      })
      .delay(1000);
  }

  bookEvent(id: string): Observable<CommitEventResponse> {
    const valid = id !== null;
    return Observable.of({
      error: valid ? null : 'Please specify an event ID to book',
      id,
    });
  }

  deleteEvent(id: string): Observable<CommitEventResponse> {
    const valid = id !== null;
    return Observable
      .of({
        error: valid ? null : 'Please specify an event ID to delete',
        id,
      })
      .delay(1000);
  }

  fetchEvents(): Observable<FetchEventsResponse> {
    const items: DiaryEvent[] = [];
    for (let i = 0; i < 25; i++)
      items.push(DiaryEvent.getExample());

    return Observable
      .of({
        error: null,
        items,
      })
      .delay(1000);
  }

  fetchEventTypes(): Observable<FetchEventTypesResponse> {
    return Observable
      .of({
        error: null,
        types: [
          { id: '0', name: 'Service' },
          { id: '1', name: 'Installation' },
          { id: '2', name: 'Training' },
          { id: '3', name: 'Meeting' },
          { id: '4', name: 'Quote' },
          { id: '5', name: 'Customer visit' },
          { id: '6', name: 'Other' }
        ],
      })
      .delay(1000);
  }

  updateEvent(req: DiaryEvent): Observable<CommitEventResponse> {
    const valid = req.id !== null;
    return Observable
      .of({
        error: valid ? null : 'Please specify an event ID to update',
        id:    req.id
      })
      .delay(1000);
  }

}
