import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {State as WaterTesterState} from '../../state-management/reducers/watertester';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Store} from '@ngrx/store';
import {
  AdminTesterQueryRequestAction,
} from '../../state-management/actions/watertesters';

import {State as LoginDetailsState} from '../../../state-management/reducers/login-details';
import {Router} from '@angular/router';
import {LogoutAction} from '../../../state-management/actions/login-details';

@Component({
  selector: 'app-admin-tester-modal',
  templateUrl: './admin-tester-modal.component.html',
  styleUrls: ['./admin-tester-modal.component.scss']
})
export class AdminTesterModalComponent implements OnInit {
  // "watertester" from store
  public waterTester$: Observable<WaterTesterState>;

  // Reference to user state
  public loginDetailsState$: Observable<LoginDetailsState>;

  //display info message every time a open a new modal
  public refreshDisplayMessage: boolean = false;

  // user role id is the id from user_roles table
  public userRoleId: number = null;

  // role id
  public roleId: number = null;

  //tester id
  public testerId: number = null;

  //company id
  public companyId: number = null;

  //message display when you open modal
  public message: string = 'Are you sure you want to change the member role?';
  public info_message: string = 'Are you sure you want to change the member role?';

  //message display when you are trying to delete your self from the company
  public info_message_self_member: string = 'Are you sure you want to remove your self as admin from the company?';

  //my id
  public myId: string = null;

  //redirectToDashboard
  public redirectToDashboard: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminTesterModalComponent>,
    private dialog:    MatDialog,
    private _store: Store<any>,
    private router: Router,
  ) {
    this.waterTester$ = this._store.select('WaterTester');
    this.loginDetailsState$ = this._store.select('loginDetails');
  }

  ngOnInit() {
    this.loginDetailsState$.subscribe((state: LoginDetailsState) => {

        this.myId = state.currentProfile.id;
        this.message = this.info_message;
        if (this.myId === this.data.testerId) {
            this.redirectToDashboard = true;
            this.message = this.info_message_self_member;
        }
    });

    this.refreshDisplayMessage = true;
    this.userRoleId = this.data.userRoleId;
    this.roleId = this.data.roleId;
    this.testerId = this.data.testerId;
    this.companyId = this.data.companyId;
  }

  /**
   * Close AdminTesterModal
   *
   */
  CloseTesterModal() {
    $('#table-watertesters').DataTable().ajax.reload();
    this.dialogRef.close();
  }

  /**
   * Confirm change role tester
   *
   */
  ConfirmChangeRoleTesterModal() {
    this.refreshDisplayMessage = false;
    this._store.dispatch(new AdminTesterQueryRequestAction({
      userRoleId: this.userRoleId,
      roleId: this.roleId,
      testerId: this.testerId,
      companyId: this.companyId,
      loginRequired: this.redirectToDashboard
    }));
  }

}
