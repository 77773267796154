/**
 * Mock for PostcodeLookupService (see main service for full details)
 */

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';

import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


import {
  AddressListItem,
  FullAddressItem,
  GetPostcodeAddressRequest,
  GetPostcodeAddressResponse,
  PostcodeLookupRequest,
  PostcodeLookupResponse,
} from '../models/postcode-lookup';


@Injectable()
export class PostcodeLookupService {

  queryPostcode(req: PostcodeLookupRequest): Observable<PostcodeLookupResponse> {
    const valid: boolean = !!req.postcode;
    return Observable
      .of({
        error:    valid ? null : 'Invalid postcode',
        callback: req.callback,
        results:  !valid ? null : [
          {
            'Id':          'GB|RM|A|53327590',
            'Type':        'Address',
            'Text':        'Lock Keepers Cottage, Basin Road',
            'Highlight':   '',
            'Description': 'Worcester, WR5 3DA'
          },
          {
            'Id':          'GB|RM|A|26772356',
            'Type':        'Address',
            'Text':        'Lock View, Basin Road',
            'Highlight':   '',
            'Description': 'Worcester, WR5 3DA'
          },
          {
            'Id':          'GB|RM|A|52509479',
            'Type':        'Address',
            'Text':        'P C A Predict, Waterside, Basin Road',
            'Highlight':   '',
            'Description': 'Worcester, WR5 3DA'
          },
          {
            'Id':          'GB|RM|A|52509479|1361760',
            'Type':        'Address',
            'Text':        'P C A Predict, Waterside, Basin Road',
            'Highlight':   '',
            'Description': 'Worcester, WR5 3DA'
          },
        ].map(AddressListItem.fromAPI),
      })
      .delay(1000);
  }

  getAddressByID(req: GetPostcodeAddressRequest): Observable<GetPostcodeAddressResponse> {
    const valid: boolean = !!req.id;
    return Observable
      .of({
        error:    valid ? null : 'Invalid address ID provided',
        callback: req.callback,
        address:  !valid ? null : FullAddressItem.fromAPI({
          'Id':                   'GB|RM|A|53327590',
          'DomesticId':           '53327590',
          'Language':             'ENG',
          'LanguageAlternatives': 'ENG',
          'Department':           '',
          'Company':              '',
          'SubBuilding':          '',
          'BuildingNumber':       '',
          'BuildingName':         'Lock Keepers Cottage',
          'SecondaryStreet':      '',
          'Street':               'Basin Road',
          'Block':                '',
          'Neighbourhood':        '',
          'District':             '',
          'City':                 'Worcester',
          'Line1':                'Lock Keepers Cottage',
          'Line2':                'Basin Road',
          'Line3':                '',
          'Line4':                '',
          'Line5':                '',
          'AdminAreaName':        'Worcestershire',
          'AdminAreaCode':        '',
          'Province':             'Worcestershire',
          'ProvinceName':         'Worcestershire',
          'ProvinceCode':         '',
          'PostalCode':           'WR5 3DA',
          'CountryName':          'United Kingdom',
          'CountryIso2':          'GB',
          'CountryIso3':          'GBR',
          'CountryIsoNumber':     '826',
          'SortingNumber1':       '94142',
          'SortingNumber2':       '',
          'Barcode':              '(WR53DA1U2)',
          'POBoxNumber':          '',
          'Label':                'Lock Keepers Cottage\nBasin Road\nWORCESTER\nWR5 3DA\nUNITED KINGDOM',
          'Type':                 'Residential',
          'DataLevel':            'Premise',
          'Field1':               '',
          'Field2':               '',
          'Field3':               '',
          'Field4':               '',
          'Field5':               '',
          'Field6':               '',
          'Field7':               '',
          'Field8':               '',
          'Field9':               '',
          'Field10':              '',
          'Field11':              '',
          'Field12':              '',
          'Field13':              '',
          'Field14':              '',
          'Field15':              '',
          'Field16':              '',
          'Field17':              '',
          'Field18':              '',
          'Field19':              '',
          'Field20':              '',
        }),
      })
      .delay(1000);
  }

}
