import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { Pro3Service } from '../../services/pro3.service';
import * as ActionTypes from '../actions/pro3';
var Pro3Effects = /** @class */ (function () {
    function Pro3Effects(filterService, actions$) {
        var _this = this;
        this.filterService = filterService;
        this.actions$ = actions$;
        this.filterDataRequest$ = this.actions$
            .ofType(ActionTypes.FILTER_DATA_REQUEST)
            .switchMap(function (req) {
            return _this.filterService.getFilterData(req.payload)
                .map(function (res) {
                return new ActionTypes.FilterDataResponseAction(res);
            });
        });
        this.deleteFilterRequest$ = this.actions$
            .ofType(ActionTypes.DELETE_FILTER_REQUEST)
            .switchMap(function (req) {
            return _this.filterService.deleteFilter(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteFilterResponseAction(res);
            });
        });
        this.timelineDataRequest$ = this.actions$
            .ofType(ActionTypes.TIMELINE_DATA_REQUEST)
            .switchMap(function (req) {
            return _this.filterService.getTimelineData(req.payload)
                .map(function (res) {
                return new ActionTypes.TimelineDataResponseAction(res);
            });
        });
        this.alertDataRequest$ = this.actions$
            .ofType(ActionTypes.ALERT_DATA_REQUEST)
            .switchMap(function (req) {
            return _this.filterService.getAlertData(req.payload)
                .map(function (res) {
                return new ActionTypes.AlertDataResponseAction(res);
            });
        });
        this.DismissAlertRequest$ = this.actions$
            .ofType(ActionTypes.DISMISS_ALERT_REQUEST)
            .switchMap(function (req) {
            return _this.filterService.dismissAlert(req.payload)
                .map(function (res) {
                return new ActionTypes.DismissAlertResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], Pro3Effects.prototype, "filterDataRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], Pro3Effects.prototype, "deleteFilterRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], Pro3Effects.prototype, "timelineDataRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], Pro3Effects.prototype, "alertDataRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], Pro3Effects.prototype, "DismissAlertRequest$", void 0);
    return Pro3Effects;
}());
export { Pro3Effects };
