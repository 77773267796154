import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { PostDemokitRedeemForm } from '../../../models/email';
import { UserProfile } from '../../../models/user-profile';
import { InitEmailStateAction, PostDemokitRedeemRequestAction } from '../../../state-management/actions/email';
import { UpdateUserProfileRequestAction } from '../../../state-management/actions/login-details';
import { State as EmailState } from '../../../state-management/reducers/email';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { StoreState } from '../../../state-management/store';
import { GenericModalComponent } from '../../common/generic-modal/generic-modal.component';

interface AvailableDemokit {
  title: string;
  quantities?: Quantity[];
  pointCost?: number;
}

interface Quantity {
  quantity: number;
  pointCost: number;
}

/**
 * Summary
 *    Page allowing user to redeem points for Demo kit products
 *
 * Description
 *    Displays a form (some fields pre-populated) and fields to choose
 *    Demo kit products. Upon submit, the request to redeem these Demokit products
 *    is sent to the API.
 */
@Component({
  selector: 'app-demokit-redeem',
  templateUrl: './demokit-redeem.component.html',
  styleUrls: ['./demokit-redeem.component.scss']
})
export class DemokitRedeemComponent implements OnDestroy, OnInit {

  // Store states
  public loginDetails$: Observable<LoginDetailsState>;
  public emailDetails$: Observable<EmailState>;

  // Subscription to store state
  private loginSub: any = null;
  private user: UserProfile;

  // Form
  public fg: FormGroup = null;
  public fgChange = false;
  private fgChangeSub: Subscription;

  // Flag to indicate if form has been submitted
  public submitted: boolean = false;
  public pointsSpent: boolean = false;

  // Maximum number of points available to spend
  public maxPoints: number;

  public availableDemokits: AvailableDemokit[];

  public pointsToConvert: number = 0;

  public submitBtnDisabled = true;

  public submitBtnText = 'Select demo kit products';
  // The point value of the Rapid test kit
  public rapidTestKitCost = 11;
  // The point value of the MagnaClean demo jar
  public magnaCleanDemoJarCost = 14;
  // The point value of the MC1+ quick test kit
  public mc1QuickTestKitCost = 40;
  // The quantity sets and point costs for leaflets
  public leafletQuantities = [
    {
      quantity: 50,
      pointCost: 0,
    },
    {
      quantity: 100,
      pointCost: 0
    }
  ];

  constructor(
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private store: Store<StoreState>,
    private translate: TranslateService
  ) {
    store.dispatch(new InitEmailStateAction());

    this.fg = this._fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      company: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      town: [''],
      county: [''],
      postcode: ['', [Validators.required]],
      currency_type: [''],

      sludgeLeaflet: [false],
      pro3Leaflet: [false],
      rapidTestKit: [false],
      magnaCleanDemoJar: [false],
      mc1QuickTestKit: [false],

      sludgeLeafletQuantity: [0],
      pro3LeafletQuantity: [0],
      rapidTestKitQuantity: [0],
      magnaCleanDemoJarQuantity: [0],
      mc1QuickTestKitQuantity: [0],
    });

    this.fg.valueChanges.subscribe(form => {
      let sludgeLeafletCost = 0;
      let pro3LeafletCost = 0;
      let rapidTestKitCost = 0;
      let magnaCleanDemoJarCost = 0;
      let mc1QuickTestKitCost = 0;

      let leafletsRequested = false;

      if (form.sludgeLeaflet && form.sludgeLeafletQuantity > 0) {
        sludgeLeafletCost = this.leafletQuantities.find(q => q.quantity === Number(form.sludgeLeafletQuantity)).pointCost;
        leafletsRequested = true;
      }

      if (form.pro3Leaflet && form.pro3LeafletQuantity > 0) {
        pro3LeafletCost = this.leafletQuantities.find(q => q.quantity === Number(form.pro3LeafletQuantity)).pointCost;
        leafletsRequested = true;
      }

      if (form['rapidTestKit']) {
        rapidTestKitCost = this.rapidTestKitCost * form['rapidTestKitQuantity'];
      }

      if (form['magnaCleanDemoJar']) {
        magnaCleanDemoJarCost = this.magnaCleanDemoJarCost * form['magnaCleanDemoJarQuantity'];
      }

      if (form['mc1QuickTestKit']) {
        mc1QuickTestKitCost = this.mc1QuickTestKitCost * form['mc1QuickTestKitQuantity'];
      }

      const total = sludgeLeafletCost + pro3LeafletCost + rapidTestKitCost + magnaCleanDemoJarCost + mc1QuickTestKitCost;

      this.pointsToConvert = total;

      if (total > this.maxPoints) {
        this.submitBtnText = `Redeem for ${this.pointsToConvert} Pts`;
        this.submitBtnDisabled = true;
      } else {
        if (total === 0 && !leafletsRequested) {
          this.submitBtnText = 'Select demo kit products';
          this.submitBtnDisabled = true;
        } else {
          this.submitBtnText = `Redeem for ${this.pointsToConvert} Pts`;
          this.submitBtnDisabled = false;
        }
      }
    });

    this.loginDetails$ = this.store.select('loginDetails');
    this.emailDetails$ = this.store.select('email');
  }

  ngOnInit() {
    this.loginSub = this.loginDetails$.subscribe((res) => {
      if (res && res.currentProfile) {
        this.user = res.currentProfile;
        this.translate.getTranslation(res.currentProfile.countries_by_country_id.country_code).subscribe((translation) => {
          this.fg.get('currency_type').setValue(translation.CURRENCY);
        });

        // Pre-populate form fields with user profile values
        this.fg.get('name').setValue(res.currentProfile.title + ' ' + res.currentProfile.firstName + ' ' + res.currentProfile.lastName);
        this.fg.get('email').setValue(res.currentProfile.email);
        this.fg.get('company').setValue(res.currentProfile.companyName);
        this.fg.get('addressLine1').setValue(res.currentProfile.address1);
        this.fg.get('addressLine2').setValue(res.currentProfile.address2);
        this.fg.get('town').setValue(res.currentProfile.town);
        this.fg.get('county').setValue(res.currentProfile.county);
        this.fg.get('postcode').setValue(res.currentProfile.postCode);

        // Watch for form changes
        this.onFormChange();

        // Set the maximum number of redeemable points to the number of points
        // that the user has available
        this.maxPoints = parseInt(res.currentProfile.pointsCurrent, 10);
      }
    });
  }

  ngOnDestroy() {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
    if (this.fgChangeSub) {
      this.fgChangeSub.unsubscribe();
    }
  }

  private onFormChange(): void {
    const initialValue = this.fg.value;
    this.fgChangeSub = this.fg.valueChanges.subscribe(() => {
      this.fgChange = Object.keys(initialValue).some((key) => {
        const ignore = [
          'name',
          'currency_type',
          'sludgeLeaflet',
          'pro3Leaflet',
          'rapidTestKit',
          'magnaCleanDemoJar',
          'mc1QuickTestKit',
          'sludgeLeafletQuantity',
          'pro3LeafletQuantity',
          'rapidTestKitQuantity',
          'magnaCleanDemoJarQuantity',
          'mc1QuickTestKitQuantity',
        ];

        if (ignore.includes(key)) {
          return false;
        }
        return this.fg.value[key] !== initialValue[key];
      });
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.fg.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  public updateProfile(): void {
    const dialog = this.dialog.open(GenericModalComponent, {
      data: {
        title: 'Update profile details',
        content: 'Would you like to update your ProClub profile to match the details provided for the demo kit request?',
        confirmLabel: 'Update',
        dismissLabel: 'Cancel',
      },
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.user.email = this.fg.value.email;
        this.user.companyName = this.fg.value.company;
        this.user.address1 = this.fg.value.addressLine1;
        this.user.address2 = this.fg.value.addressLine2;
        this.user.town = this.fg.value.town;
        this.user.county = this.fg.value.county;
        this.user.postCode = this.fg.value.postcode;
        this.store.dispatch(new UpdateUserProfileRequestAction({ profile: this.user }));
      }
    });
  }

  /**
   * Dispatches the demokit request if the form is valid
   */
  submitForm() {
    this.submitted = true;

    if (this.fg.valid) {
      this.pointsSpent = true;
      const formValues = Object.assign(this.fg.value, { pointsToConvert: this.pointsToConvert });
      this.store.dispatch(new PostDemokitRedeemRequestAction({
        form: PostDemokitRedeemForm.fromFormData(formValues),
        callback: () => this.router.navigate(['/rewards']),
      }));
    }
  }
}
