import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';


import { environment } from '../../../../environments/environment';

import { Product } from '../../../models/product';

import {
  ProductSelectQueryRequest,
} from '../../../models/product-selector';


interface SelectOption {
  value:       string;
  label:       string;
  filterType?: string[];
}

interface ProductGroup {
  id:     number;
  label:  string;
  icon?:  string;
  colour: string;
}

/**
 * Summary
 *    Display the list of products based on the user's query.
 *
 *
 * Description
 *    Display a list of products categoriesed into separate groups, the products shown are based
 *    on the filters set by the user.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectorComponent implements OnInit, OnDestroy {

  // Flag to indicate if a query is in progress
  @Input() fetching: boolean = false;

  // List of products to display
  @Input() results: Product[] = null;

  // Called when a new query needs to be performed
  @Output() onQuery = new EventEmitter<ProductSelectQueryRequest>();


  // Query form
  public fgQuery: FormGroup = null;

  //Detect first query
  public firstQuery: boolean = false;

  // Options for query form
  public systemTypeOptions:   SelectOption[];
  public typeOfWorkOptions:   SelectOption[];
  public pipeworkOptions:     SelectOption[];
  public pipeworkSizeOptions: SelectOption[];
  public systemFilterOptions: SelectOption[];

  // List of product groupings
  public productGroups: ProductGroup[];

  // Query form errors
  public errors: string = null;

  // valueChanges Subscriptions for query form
  private formSub:       Subscription = null;
  private systemTypeSub: Subscription = null;

  // Base URL for result images from file server
  public imageBaseUrl: string = environment.files.baseUrl + '/img/';

  /**
   * Constructor for page
   *
   * @param {FormBuilder} fb Initialises a formBuilder instance
   */
  constructor(
    private fb: FormBuilder
  ) {
    // Create the query form
    this.buildQueryForm();

    // Set the filter options available to the form
    this.systemTypeOptions = [
      {value: 'domestic',   label: 'Domestic'},
      {value: 'commercial', label: 'Commercial'},
      {value: 'underfloor', label: 'Underfloor'},
    ];

    this.typeOfWorkOptions = [
      {value: 'new_install',             label: 'New install'},
      {value: 'boiler_replacement',      label: 'Boiler replacement'},
      {value: 'standard_boiler_service', label: 'Standard boiler service'},
      {value: 'maintenance_call_out',    label: 'Maintenance call-out'},
    ];

    this.pipeworkOptions = [
      {value: 'copper',  label: 'Copper',                    filterType: ['commercial', 'domestic', 'underfloor']},
      {value: 'plastic', label: 'Plastic',                   filterType: ['domestic',   'underfloor']},
      {value: 'both',    label: 'Both',                      filterType: ['commercial', 'domestic']},
      {value: 'ss_alu',  label: 'Stainless steel/aluminium', filterType: ['commercial', 'domestic']},
      {value: 'alu',     label: 'Aluminium',                 filterType: ['underfloor']},
      {value: 'steel',   label: 'Steel',                     filterType: ['underfloor']},
    ];

    this.pipeworkSizeOptions = [
      {value: 'null',  label: 'Not applicable', filterType: ['underfloor']},
      {value: '22mm',  label: '22mm',           filterType: ['domestic']},
      {value: '28mm',  label: '28mm',           filterType: ['domestic']},
      {value: '35mm',  label: '35mm',           filterType: ['commercial', 'domestic']},
      {value: '>42mm', label: '> 42mm',         filterType: ['commercial', ]},
    ];

    this.systemFilterOptions = [
      {value: 'no_filter', label: 'No filter installed'},
      {value: 'filter',    label: 'MagnaClean filter installed'},
    ];

    this.productGroups = [
      {id: 1, label: 'Clean',        colour: 'red',    icon: 'icon-mc3'},
      {id: 2, label: 'MagnaCleanse', colour: 'blue',   icon: 'icon-flush'},
      {id: 3, label: 'MagnaClean',   colour: 'blue',   icon: 'icon-mc3'},
      {id: 4, label: 'Protect',      colour: 'yellow', icon: 'icon-mc1'},
      {id: 5, label: 'Test',         colour: 'green',  icon: 'icon-water-test'},
      {id: 6, label: 'Maintain',     colour: 'orange', icon: 'icon-fitted'},
    ];
  }

  /**
   * Submit the form with the default values on page load to ensure the page isn't empty
   */
  ngOnInit() {
    this.handleQuerySubmit();
  }

  /**
   * Unsubscribe form events when the page is destroyed in order to free up memory
   */
  ngOnDestroy() {
    if (this.formSub)
      this.formSub.unsubscribe();
    if (this.systemTypeSub)
      this.systemTypeSub.unsubscribe();
  }


  /**
   * Build the query form to that the user can filter the product results based on their own parameters
   */
  buildQueryForm() {
    this.fgQuery = this.fb.group({
      systemType:   ['domestic',             [Validators.required]],
      typeOfWork:   ['maintenance_call_out', [Validators.required]],
      pipework:     ['ss_alu',               [Validators.required]],
      pipeworkSize: ['35mm',                 [Validators.required]],
      systemFilter: ['no_filter'],
    });

    // Subscribe to fgQuery.valueChanges in order to automatically submit the form when
    // any field is changed
    this.formSub = this.fgQuery.valueChanges.subscribe(data => {
      this.handleQuerySubmit();
    });

    // Set the default values within the filters when the user changes the system type value
    this.systemTypeSub = this.fgQuery.get('systemType').valueChanges.subscribe((v: string) => {
      this.setDefaultForType(v);
    });
  }

  /**
   * Set the values of pipework size and pipework when the system type value has been changed by the user
   *
   * @param {string} systemType The new system type value
   */
  setDefaultForType(systemType: string) {
    switch (systemType) {
      case 'domestic':
        this.fgQuery.get('pipeworkSize').setValue('22mm');
        this.fgQuery.get('pipework').setValue('copper');
        break;
      case 'commercial':
        this.fgQuery.get('pipeworkSize').setValue('35mm');
        this.fgQuery.get('pipework').setValue('copper');
        break;
      case 'underfloor':
        this.fgQuery.get('pipeworkSize').setValue('null');
        this.fgQuery.get('pipework').setValue('plastic');
        break;
    }
  }

  /**
   * Only return the pipework options based on the current systemType value within the form
   *
   * @return {SelectOption[]} An array of values for the pipework control
   */
  filteredPipeworkOptions(): SelectOption[] {
    return this.pipeworkOptions.filter((v: SelectOption): boolean =>
      (
        !v.filterType ||
        v.filterType.filter((t: string): boolean => t.toLowerCase() === this.fgQuery.value.systemType.toLowerCase()).length > 0
      )
    );
  }

  /**
   * Only return the pipeworkSize options based on the current systemType value within the form
   *
   * @return {SelectOption[]} An array of values for the pipeworkSize control
   */
  filteredPipeworkSizeOptions(): SelectOption[] {
    return this.pipeworkSizeOptions.filter((v: SelectOption): boolean =>
      (
        !v.filterType ||
        v.filterType.filter((t: string): boolean => t.toLowerCase() === this.fgQuery.value.systemType.toLowerCase()).length > 0
      )
    );
  }
  
  /**
   * Called when the user submits the form, will emit the form data to the parent component so that the query can be dispatched
   */
  handleQuerySubmit() {
    if (this.fgQuery.valid)
    {
      this.firstQuery = true;
      this.errors = null;
      // Dispatch the data to the parent component
      this.onQuery.emit(ProductSelectQueryRequest.fromForm(this.fgQuery.value));
    }
    else
      this.errors = 'The search form contains errors. Please correct them and try again.';
  }

  /**
   * Called for each product group, will filter the results based on the groupID and return an array of results matching the groupID
   *
   * @param {number} groupID The groupID to filter products against
   *
   * @return {product[]}  An array of products that match the groupID
   */
  productsInGroup(groupID: number): Product[] {
    if (!this.results || this.results.length === 0)
      return null;

    return this.results.filter((p: Product) => p.productGroupId === groupID);
  }
}
