import { Component, OnInit } from '@angular/core';
import { Store }             from '@ngrx/store';
import { Observable }        from 'rxjs/Observable';
import { Router }            from '@angular/router';


import { StoreState}                  from '../../state-management/store';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import {NavItems} from '../../models/nav-items';

/**
 * Summary
 *    Single page to display 404 error.
 *
 * Description
 *    This page will redirect users dependent on the current login state.
 *
 * @copyright 2017 ReallyB2B Limited
 */

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  // Observable reference of type LoginDetailState
  public loginDetails$: Observable<LoginDetailsState>;

  /**
   * Constructor for page, initialises store and router values
   *
   * @param {Store<StoreState>} store  Initialises Store object
   * @param {Router}            router Initialises router object
   */
  constructor(
    private store:  Store<StoreState>,
    private router: Router,
    private navItems: NavItems
  ) {
    this.loginDetails$ = this.store.select('loginDetails');
  }

  ngOnInit() {
  }


  /**
   * Utility function to navigate users to the correct page
   *
   * @param {string} page Determines the route
   */
  goToPage(page: string) {
    this.router.navigate([page]);
  }

  /**
   * Get the default route
   */
  public getDefaultRoute$(): Observable<string> {
    //Use the login details state
    return this.loginDetails$.map(loginDetails => {
      //Check a valid user is logged in
      if (!loginDetails.user || loginDetails.user.optIns.length === 0) {
        return undefined;
      }

      return this.navItems.getDefaultRouteForUser(loginDetails.user);
    });
  }
}
