import 'rxjs/add/operator/debounceTime';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { Job } from '../../../models/job';
import { CheckValidPostcode, FullAddressItem } from '../../../models/postcode-lookup';
import { CheckSerialNumberResponse, Warranty } from '../../../models/warranty';
import { WarrantyService } from '../../../services/warranty.service';
import { SetUserPopupDisplayCount } from '../../../state-management/actions/popup';
import { selectPopupDisplayCount } from '../../../state-management/selectors/popup';
import { StoreState } from '../../../state-management/store';
import { GenericModalComponent } from '../../common/generic-modal/generic-modal.component';
import { PostcodeLookupModalComponent } from '../../common/postcode-lookup-modal/postcode-lookup-modal.component';

const TEN_YEAR_WARRANTY_PRODUCTS = [1, 2, 3, 4, 40, 52, 55, 56, 57];

/**
 * Summary
 *    Creates a form for submitting, viewing and editing Warranties
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-warranty-details',
  templateUrl: './warranty-details.component.html',
  styleUrls: ['./warranty-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WarrantyDetailsComponent implements OnChanges, OnDestroy, OnInit {

  // List of product types to display in select
  @Input() productTypes: any = null;
  // If set, admin fields will be displayed in the form
  @Input() adminMode: boolean = false;
  // The default User ID used in admin mode when adding a new Warranty.
  @Input() defaultUserId: string = null;
  // If set, form will not be editable
  @Input() readOnly: boolean = false;
  // If set, form submit button will be displayed
  @Input() showSubmit: boolean = true;
  // Label to display on the form submit button
  @Input() submitLabel: string = 'Save';
  // The Warranty model being viewed/edited. If NULL then the form will be in
  // add mode.
  @Input() warranty: Warranty = null;
  // Object containing the current validated PromoCode details
  @Input() promoCode: any = null;
  // Register a new warranty mode
  @Input() register: boolean = false;
  // Fired when a new promotional code has been entered and needs to be checked
  @Output() onPromoCode = new EventEmitter<string>();
  // Fired when the form is submitted with the Warranty model
  @Output() onSubmit = new EventEmitter<any>();

  // Page errors
  public errors: string = null;
  // Flag to indicate if form has been submitted
  public submitted: boolean = false;
  // List of years for warrantyLength options
  public expiryYears: number[] = [];
  // Main Warranty form
  public fg: FormGroup = null;
  // If set, the postcode lookup button will be enabled
  public enablePostcodeLookup: boolean = false;
  // Don't allow installers to register warranties in the future
  public maxDate = moment().format();
  // Don't allow installers to register warranties before the 1st of Jan 2017
  public minDate = moment('2017-01-01').format();
  public showTypeOfFilterQuestion = true;

  // Subscription to the value of "promoCode", used for checking the code
  private promoCodeValueSub$: Subscription;
  // The current serial number prefix of the currently selected product
  private currentPrefix: string = null;
  // Subscription to valueChanges Observable for "customerPostcode" form field
  private postcodeValueSub: Subscription = null;
  // Reference to the PostcodeLookupModalComponent
  private dialogRef_postcode: any = null;
  // For form validation, if the old serial == new. no need to validate;
  private oldSerial = '';
  private selectPopupDisplayCount$: Observable<Number>;
  private selectPopupDisplayCountSub: Subscription;
  private popupDisplayCount = 0;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private service: WarrantyService,
    private store: Store<StoreState>,
  ) {
    this.selectPopupDisplayCount$ = this.store.select(selectPopupDisplayCount('10-year-warranty'));
  }

  ngOnChanges() {
    if (!this.fg) {
      return;
    }
    if (!this.readOnly && !this.fg.enabled) {
      this.fg.enable();

      if (this.warranty && Number(this.warranty.productId) === 40) {
        this.fg.controls['productType'].disable();
      }

    } else if (this.readOnly && this.fg.enabled) {
      this.fg.disable();
    }
  }

  ngOnInit() {
    this.buildForm();
    if (this.warranty) {
      this.oldSerial = this.warranty.serialNumber;
    }
    this.selectPopupDisplayCountSub = this.selectPopupDisplayCount$.subscribe((count) => {
      if (count) {
        this.popupDisplayCount = Number(count);
      }
    });
  }

  ngOnDestroy() {
    if (this.postcodeValueSub) {
      this.postcodeValueSub.unsubscribe();
    }
    if (this.promoCodeValueSub$) {
      this.promoCodeValueSub$.unsubscribe();
    }
    if (this.selectPopupDisplayCountSub) {
      this.selectPopupDisplayCountSub.unsubscribe();
    }
  }


  /**
   * Builds the Warranty form
   */
  buildForm() {
    const newWarranty = !this.warranty;
    const disabled = this.readOnly;
    const d = new Date();

    // Build list of expiry year options
    this.expiryYears = [];
    for (let i = d.getFullYear(); i < d.getFullYear() + 80; i++)
      this.expiryYears.push(Number(i));

    // Unsubscribe from valueChanges Observables if necessary
    if (this.postcodeValueSub) {
      this.postcodeValueSub.unsubscribe();
      this.postcodeValueSub = null;
    }
    if (this.promoCodeValueSub$) {
      this.promoCodeValueSub$.unsubscribe();
      this.promoCodeValueSub$ = null;
    }

    this.fg = this._fb.group({
      userId: [{ value: this.getModelStr('userId', this.defaultUserId), disabled }],
      id: [{ value: this.getModelStr('id'), disabled }],
      productType: [{ value: this.getModelStr('productType'), disabled }, [Validators.required]],
      dateOfInstallation: [{ value: this.getModelDate('dateOfInstallation'), disabled }, [Validators.required]],

      // TODO: removed temporarily from form, needs to be restored when ready
      //expiryDate:         [{value: this.getModelDate('expiryDate'),                disabled}],
      expiryDate: [{ value: null, disabled }],

      typeOfBoiler: [{ value: this.getModelStr('typeOfBoiler'), disabled }, [Validators.required]],
      typeOfFilter: [{ value: this.getModelStr('typeOfFilter'), disabled }, [Validators.required]],
      oldFilterBrand: [{ value: this.getModelStr('oldFilterBrand'), disabled }],
      warrantyLength: [{ value: this.getModelNum('warrantyLength'), disabled }],

      customer: this._fb.group({
        customerId: [{ value: this.getCustomerStr('customerId'), disabled }],
        customerTitle: [{ value: this.getCustomerStr('customerTitle', 'Mr'), disabled }, [Validators.required]],
        customerForename: [{ value: this.getCustomerStr('customerForename'), disabled }],
        customerSurname: [{ value: this.getCustomerStr('customerSurname'), disabled }, [Validators.required]],
        customerAddress1: [{ value: this.getCustomerStr('customerAddress1'), disabled }, [Validators.required]],
        customerAddress2: [{ value: this.getCustomerStr('customerAddress2'), disabled }],
        customerTown: [{ value: this.getCustomerStr('customerTown'), disabled }],
        customerCounty: [{ value: this.getCustomerStr('customerCounty'), disabled }],
        customerPostcode: [{ value: this.getCustomerStr('customerPostcode'), disabled }, [Validators.required]],
        customerPhone: [{ value: this.getCustomerStr('customerPhone'), disabled }],
        emails: this._fb.group({
          customerEmail: [this.getCustomerStr('customerEmail'), Validators.email],
          customerConfirmEmail: [this.getCustomerStr('customerEmail'), Validators.email],
        }, { validator: [Validators.required, this.checkEmails] }),

        customerContactByPost: [{ value: this.getCustomerBool('customerContactByPost'), disabled }],
        customerContactByEmail: [{ value: this.getCustomerBool('customerContactByEmail'), disabled }],
        customerContactByPhone: [{ value: this.getCustomerBool('customerContactByPhone'), disabled }],
      }),

      addRenewalDateToCalendar: [{ value: this.getModelBool('addRenewalDateToCalendar'), disabled }],
      notifyClientBeforeExpiry: [{ value: this.getModelBool('notifyClientBeforeExpiry'), disabled }],
      promoCode: [{ value: this.getModelStr('promoCode'), disabled }],

      serialNumber: [
        { value: this.getModelStr('serialNumber'), disabled },
        [Validators.required], [this.serialNumberValidator(this)],
      ],
    });

    // Emit a new promo code when the field value changes
    this.promoCodeValueSub$ = this.fg.get('promoCode')
      .valueChanges
      .debounceTime(500)
      .subscribe((v: string) => {
        if (v)
          this.onPromoCode.emit(v);
      });

    // Enable/disable postcode lookup button when the customerPostcode field
    // value changes
    this.postcodeValueSub = this.fg.get('customer').get('customerPostcode')
      .valueChanges
      .subscribe((v: string) => {
        this.enablePostcodeLookup = CheckValidPostcode(v);
      });

    // Set existing values that do not match up with the model
    if (!newWarranty) {
      this.fg.controls['typeOfBoiler'].setValue(this.warranty.typeOfBoiler ? 'New' : 'Existing');
      this.fg.controls['typeOfFilter'].setValue(this.warranty.typeOfFilter ? 'New' : 'Replacement');
      this.fg.controls['productType'].setValue(this.warranty.productId);
    }

    this.handleProductChange();
  }

  /**`
   * Sets the product serial number prefix whenever the currently selected
   * product changes
   */
  handleProductChange() {
    if (!this.productTypes || !this.productTypes.products) {
      return;
    }

    if (TEN_YEAR_WARRANTY_PRODUCTS.includes(Number(this.fg.value.productType))) {
      // Only show the 10 year warranty popup to a installer once
      if (this.popupDisplayCount < 1) {
        this.dialog.open(GenericModalComponent, {
          data: {
            title: 'New extended warranty!',
            content: `We're so confident in the quality of our products, we've extended all second and third generation MagnaClean filter warranties to 10 years.
              <br>
              Simply register your product as normal to activate.`,
            dismissLabel: 'Close',
          },
        });
        this.store.dispatch(new SetUserPopupDisplayCount({ name: '10-year-warranty', count: 1 }));
      }
    }

    for (let i = 0; i < this.productTypes.products.length; i++) {
      if (this.fg.value.productType.toString() === this.productTypes.products[i].id.toString()) {
        if (this.fg.value.serialNumber === '' || this.fg.value.serialNumber === this.currentPrefix) {
          this.fg.get('serialNumber').setValue(this.productTypes.products[i].prefix);
        }
        this.currentPrefix = this.productTypes.products[i].prefix;
      }
    }

    this.checkShouldShowFilterQuestion();
  }

  /**
   * Called when handleProductChange is called or the typeOfBoiler value changes,
   * updates showTypeOfFilterQuestion and the forms typeOfFilter value
   */
  checkShouldShowFilterQuestion() {
    const serial = this.fg.value.serialNumber.toLowerCase();
    this.showTypeOfFilterQuestion = (!serial.startsWith('n-') && !serial.startsWith('k-'));

    if (!this.showTypeOfFilterQuestion) {
      this.fg.controls['typeOfFilter'].setValue(null);
      this.fg.controls['typeOfFilter'].clearValidators();
      this.fg.controls['typeOfBoiler'].setValue(null);
      this.fg.controls['typeOfBoiler'].clearValidators();
    } else {
      this.fg.controls['typeOfBoiler'].setValidators(Validators.required);

      if (this.fg.get('typeOfBoiler').value === 'New') {
        this.fg.controls['typeOfFilter'].clearValidators();
      } else {
        this.fg.controls['typeOfFilter'].setValue(null);
        this.fg.controls['typeOfFilter'].setValidators(Validators.required);
      }
    }

    this.fg.controls['typeOfBoiler'].updateValueAndValidity();
    this.fg.controls['typeOfFilter'].updateValueAndValidity();
  }

  /**
   * Given a FullAddressItem from an address lookup, fills the appropriate
   * field values accordingly
   *
   * @param {FullAddressItem} address
   */
  autofillAddress(address: FullAddressItem) {
    const g: AbstractControl = this.fg.get('customer');
    g.get('customerAddress1').setValue(address.address1);
    g.get('customerAddress2').setValue(address.address2);
    g.get('customerTown').setValue(address.town);
    g.get('customerCounty').setValue(address.county);
    g.get('customerPostcode').setValue(address.postcode);
  }

  /**
   * Opens PostcodeLookupModalComponent with a postcode query in order to
   * perform a lookup. On dialog close, call this.autofillAddress() if a valid
   * address was returned.
   */
  postcodeLookup() {
    this.dialogRef_postcode = this.dialog.open(PostcodeLookupModalComponent, {
      data: {
        postcode: this.fg.value.customer.customerPostcode,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_postcode.afterClosed().subscribe(result => {
      if (result)
        this.autofillAddress(result);
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses) {
      classes += ` ${extraClasses}`;
    }

    const ff = this.fg.controls[fieldName];
    if (!ff) {
      return classes;
    }

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity (customer sub-group fields)
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupCustomerClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses) {
      classes += ` ${extraClasses}`;
    }

    if (!this.fg.controls.customer.get(fieldName)) {
      return classes;
    }

    const ff = this.fg.controls.customer.get(fieldName);
    if (!ff) {
      return classes;
    }

    return `${classes}${!ff.valid && (this.submitted || ff.dirty) ? ' has-error' : ''}`;
  }

  /**
   * Gets a field value as a Boolean or returns the default
   *
   * @param {string}  field
   * @param {boolean} defaultValue Default value if field does not exist
   * @return {boolean}
   */
  getModelBool(field: string, defaultValue: boolean = false): boolean {
    if (!this.warranty)
      return defaultValue;
    return this.warranty[field];
  }

  /**
   * Gets a field value as a Date or returns the default
   *
   * @param {string} field
   * @param {Date}   defaultValue Default value if field does not exist
   * @return {Date}
   */
  getModelDate(field: string, defaultValue: Date = new Date()): Date {
    if (!this.warranty || !this.warranty[field])
      return defaultValue;
    return moment(this.warranty[field]).toDate();
  }

  /**
   * Gets a field value as a number or returns the default
   *
   * @param {string} field
   * @param {number} defaultValue Default value if field does not exist
   * @return {number}
   */
  getModelNum(field: string, defaultValue: number = 0): number {
    if (!this.warranty)
      return defaultValue;
    return this.warranty[field];
  }

  /**
   * Gets a field value as a string or returns the default
   *
   * @param {string} field
   * @param {string} defaultValue Default value if field does not exist
   * @return {string}
   */
  getModelStr(field: string, defaultValue: string = ''): string {
    if (!this.warranty || !this.warranty[field])
      return defaultValue;

    return this.warranty[field];
  }

  /**
   * Gets a field value as a string or returns the default (customer group)
   *
   * @param {string} field
   * @param {string} defaultValue Default value if field does not exist
   * @return {string}
   */
  getCustomerStr(field: string, defaultValue: string = ''): string {
    if (!this.warranty || !this.warranty.customer[field])
      return defaultValue;
    return this.warranty.customer[field];
  }

  /**
   * Gets a field value as a Boolean or returns the default (customer group)
   *
   * @param {string}  field
   * @param {boolean} defaultValue Default value if field does not exist
   * @return {boolean}
   */
  getCustomerBool(field: string, defaultValue: boolean = false): boolean {
    if (!this.warranty)
      return defaultValue;
    return this.warranty.customer[field];
  }

  /**
   * Custom validator which requires that an e-mail address is valid if set but
   * also valid if undefined
   *
   * @return {ValidatorFn}
   */
  nonRequiredEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } =>
      (!control.value || control.value.toString().length === 0)
        ? null
        : Validators.email(control);
  }

  typeOfFilterConditionallyRequiredValidator(formControl: AbstractControl): ValidationErrors | null {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('typeOfBoiler').value === 'Existing') {
      return Validators.required(formControl);
    }

    return null;
  }

  /**
   * Marks all controls in a form group as touched to highlight errors
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).map(x => formGroup.controls[x]).forEach((control: FormGroup) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Called when the form is submitted: emits the new model via this.onSubmit()
   * as a Warranty if a Warranty is being edited or if the current user is an
   * admin, or a Job otherwise.
   */
  onSubmitForm() {
    this.errors = null;
    this.submitted = true;
    this.markFormGroupTouched(this.fg);

    if (this.fg.valid) {
      for (let i = 0; i < this.productTypes.products.length; i++) {
        if (this.fg.value.productType.toString() === this.productTypes.products[i].id.toString()) {
          this.fg.value.productTypeName = this.productTypes.products[i].name;
        }
      }
      this.onSubmit.emit(
        this.warranty || this.adminMode
          ? Warranty.fromFormData(this.fg.value)
          : Job.fromWarrantyFormData(this.fg.value)
      );
      this.fg.controls['serialNumber'].setValue('');
      this.handleProductChange();
    } else {
      this.errors = 'The form contains errors. Please correct the highlighted fields and try again.';
    }
  }

  /**
   * Custom product serial number validator: performs a minimum length check
   * and then validates it via WarrantyService::checkSerialNumber().
   *
   * @param {WarrantyDetailsComponent} obj Reference to this
   * @return {AsyncValidatorFn}
   */
  serialNumberValidator(obj: WarrantyDetailsComponent): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any }> => {

      if (!obj.fg) {
        return Observable.of({ message: 'No form group' });
      }

      // A product must have been selected
      if (!obj.fg.value.productType)
        return Observable.of({ message: 'No product selected' });

      const minLength: number = 5;
      const prefixLength: number = obj.currentPrefix ? obj.currentPrefix.toString().length : 0;

      // If the length of the entered serial number without the prefix is < the
      // minimum length, return immediate validation failure
      if (minLength > 0 && control.value.toString().length - prefixLength < minLength)
        return Observable.of({ message: null });

      return obj.service.checkSerialNumber(obj.fg.value.productType, control.value)
        .map((res: CheckSerialNumberResponse) => {
          obj.changeDetector.markForCheck();
          return (res.valid || this.serialUnchanged(control.value)) ? null : { message: res.message };
        });
    };
  }

  serialUnchanged(serial: any): Boolean {
    if (serial === this.oldSerial) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the error message if the serialNumber field is invalid
   *
   * @return {string|null}
   */
  serialNumberError(): any {
    const ff = this.fg.controls.serialNumber;
    return ff.errors && ff.errors.message;
  }

  /**
   * Returns TRUE if the serialNumber field is valid
   *
   * @return {boolean}
   */
  serialNumberValid(): boolean {
    const ff = this.fg.controls.serialNumber;
    return !(!ff.valid && (this.submitted || ff.dirty || ff.touched));
  }

  /**
   * Compare both inputs to ensure emails match
   * @param group Form group to validate
   */
  private checkEmails(group: FormGroup): null | object {
    const pass = group.get('customerEmail').value;
    const confirmPass = group.get('customerConfirmEmail').value;
    return pass === confirmPass ? null : { error: 'Email addresses do not match.' };
  }
}
