import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { Pro3Service } from '../../services/pro3.service';

import * as ActionTypes from '../actions/pro3';

import {
  FilterDataResponse,
  TimelineDataResponse,
  AlertDataResponse,
  DismissAlertResponse,
  DeleteFilterResponse,
} from '../../models/pro3';


@Injectable()
export class Pro3Effects {
  constructor(
    private filterService: Pro3Service,
    private actions$: Actions
  ) {}

  @Effect() filterDataRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FILTER_DATA_REQUEST)
    .switchMap((req: ActionTypes.FilterDataRequestAction): Observable<Action> => {
      return this.filterService.getFilterData(req.payload)
        .map((res: FilterDataResponse): ActionTypes.FilterDataResponseAction =>
          new ActionTypes.FilterDataResponseAction(res)
        );
    });

  @Effect() deleteFilterRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_FILTER_REQUEST)
    .switchMap((req: ActionTypes.DeleteFilterRequestAction): Observable<Action> => {
      return this.filterService.deleteFilter(req.payload)
        .map((res: DeleteFilterResponse): ActionTypes.DeleteFilterResponseAction =>
          new ActionTypes.DeleteFilterResponseAction(res)
        );
    });

  @Effect() timelineDataRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TIMELINE_DATA_REQUEST)
    .switchMap((req: ActionTypes.TimelineDataRequestAction): Observable<Action> => {
      return this.filterService.getTimelineData(req.payload)
        .map((res: TimelineDataResponse): ActionTypes.TimelineDataResponseAction =>
          new ActionTypes.TimelineDataResponseAction(res)
        );
    });

  @Effect() alertDataRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ALERT_DATA_REQUEST)
    .switchMap((req: ActionTypes.AlertDataRequestAction): Observable<Action> => {
      return this.filterService.getAlertData(req.payload)
        .map((res: AlertDataResponse): ActionTypes.AlertDataResponseAction =>
          new ActionTypes.AlertDataResponseAction(res)
        );
    });

  @Effect() DismissAlertRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DISMISS_ALERT_REQUEST)
    .switchMap((req: ActionTypes.DismissAlertRequestAction): Observable<Action> => {
      return this.filterService.dismissAlert(req.payload)
        .map((res: DismissAlertResponse): ActionTypes.DismissAlertResponseAction =>
          new ActionTypes.DismissAlertResponseAction(res)
        );
    });

}
