import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { StoreState } from '../../state-management/store';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import {
  ResetPasswordRequest,
} from '../../models/login';
import {
  ResetPasswordRequestAction,
} from '../../state-management/actions/login-details';
import { ProCheckSite } from '../../models/procheck-site';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordResponseModalComponent } from '../reset-password-response-modal/reset-password-response-modal.component';

/**
 * Summary
 *    Allow the user to enter a new password.
 *
 *
 * Description
 *    Allow the user to reset their password if they have forgotten it. User's will only
 *    be able to reset their password if a valid reset password token is available within the url.
 *    They will receive a link including this token from an automatically generated email when they
 *    request a reset password link from the login page.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy, OnInit {

  // Subscription to route params to get token
  private tokenSub: Subscription;
  // Password reset token, obtained from route params
  public token: string = null;
  // Form used to set new password
  public forgottenForm: FormGroup;
  // Store state
  private loginDetails$: Observable<LoginDetailsState>;
  private loginDetailsSub: Subscription;
  public proCheckView: boolean = false;
  public passwordHide: boolean = true;
  public passwordConfirmHide: boolean = true;

  /**
   * Constructor for page
   *
   * @param {ActivatedRoute} route    Contains information about the associated route
   * @param {Router} router           Initialises router object providing navigation between pages
   * @param {Store<StoreState>} store Initialises Store object
   */
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<StoreState>,
  ) {
    // Create a simple validation control to ensure that the passwords match
    const passwordMatcher = (control: AbstractControl): { [key: string]: boolean } => {

      if (control.value === '' || !control.root.get('password') || control.root.get('password').value === '') {
        return null;
      }

      return control.value === control.root.get('password').value ? null : { nomatch: true };
    };

    // Create the form controls
    this.forgottenForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$')]),
      password_confirm: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$'), passwordMatcher])
    });
  }

  /**
   * Create a reference to the login state and subscribe to route.paramMap in order to check if a token is present
   */
  ngOnInit() {
    // Set a reference to the login state
    this.loginDetails$ = this.store.select('loginDetails');
    this.proCheckView = ProCheckSite.getCheck();

    // Subscribe to route.paramMap in order to retrieve the url from the url
    this.tokenSub = this.route.paramMap
      .map((params: ParamMap) => {
        return params.get('token');
      })
      .subscribe((token: string) => {
        this.token = token;
      });

    this.loginDetailsSub = this.loginDetails$.subscribe((state: LoginDetailsState) => {
      if (state.resetError) {
        this.dialog.open(ResetPasswordResponseModalComponent, {
          data: { success: false },
          panelClass: 'feature-modal-dialog',
        });
      }
      if (state.resetMessage) {
        this.forgottenForm.reset();
        this.forgottenForm.get('username').setErrors(null);
        this.forgottenForm.get('password').setErrors(null);
        this.forgottenForm.get('password_confirm').setErrors(null);
        this.dialog.open(ResetPasswordResponseModalComponent, {
          data: { success: true },
          panelClass: 'feature-modal-dialog',
        });
        this.goToHome();
      }
    });

  }

  /**
   * Unsubscribe from route.paramMap when the page is destroyed in order to free up memory
   */
  ngOnDestroy() {
    if (this.tokenSub) {
      this.tokenSub.unsubscribe();
    }

    if (this.loginDetailsSub) {
      this.loginDetailsSub.unsubscribe();
    }
  }

  /**
   * Navigate the user back to the login page
   */
  goToHome() {
    this.router.navigate(['/']);
  }

  /**
   * Called when the form is submitted, will dispatch ResetPasswordRequestAction with the new password
   * and token in order to successfully reset the user's password
   */
  onSubmitForm() {
    // Mark the form as dirty to manually prompt validation
    this.forgottenForm.markAsDirty();

    // Dispatch ResetPasswordRequestAction with the form values
    this.store.dispatch(
      new ResetPasswordRequestAction(
        new ResetPasswordRequest(
          this.token,
          this.forgottenForm.value.username,
          this.forgottenForm.value.password,
          this.forgottenForm.value.password_confirm
        )
      )
    );
  }

  public getErrorMessage(controlName: string): string {
    switch (controlName) {
      case 'username':
        return this.forgottenForm.get('username').hasError('required') ? 'You must enter a value' :
          this.forgottenForm.get('username').hasError('email') ? 'Not a valid email' :
            '';
      case 'password':
        return this.forgottenForm.get('password').hasError('required') ? 'You must enter a password' :
          this.forgottenForm.get('password').hasError('pattern') ? 'Please make your new password stronger' :
            '';
      case 'password_confirm':
        return this.forgottenForm.get('password_confirm').hasError('required') ? 'You must enter a password' :
          this.forgottenForm.get('password_confirm').hasError('nomatch') ? 'Your passwords do not match' :
            '';
      default:
        return '';
    }
  }
}
