/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./warranty-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./warranty-widget.component";
import * as i3 from "@angular/router";
var styles_WarrantyWidgetComponent = [i0.styles];
var RenderType_WarrantyWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WarrantyWidgetComponent, data: {} });
export { RenderType_WarrantyWidgetComponent as RenderType_WarrantyWidgetComponent };
export function View_WarrantyWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "widget-body border-box border-lighter-gray warranty-widget background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "feature-image-left"], ["src", "assets/i/widgets/magnaclean.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "color-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Need to register a job or warranty?"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [["class", "color-adey-red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign it up below..."])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "widget-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn background-green no-radius color-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/submission-selection") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Register"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_WarrantyWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-warranty-widget", [], null, null, null, View_WarrantyWidgetComponent_0, RenderType_WarrantyWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i2.WarrantyWidgetComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WarrantyWidgetComponentNgFactory = i1.ɵccf("app-warranty-widget", i2.WarrantyWidgetComponent, View_WarrantyWidgetComponent_Host_0, {}, {}, []);
export { WarrantyWidgetComponentNgFactory as WarrantyWidgetComponentNgFactory };
