<div class="col-xs-12 form-email-send-invitation" *ngIf="resultEmail$ | async; let resultEmail" >
  <h2>{{title}}</h2>
  <div class="email-container">
    <div *ngIf="noSelected" class="alert alert-warning text-center">
      {{noSelected}}
    </div>
    <div *ngIf="!noSelected">
      <div class="formInput">
        <div *ngIf="(!refreshDisplayMessage)">
          <app-waiting-spinner kind="bar" class="icon-bar text-center color-white" *ngIf="resultEmail.pendingEmail">
            {{resultEmail.message}}
          </app-waiting-spinner>
          <div *ngIf="(errorForm)" class="alert alert-warning text-center">
            {{errorForm}}
          </div>
          <div *ngIf="(!resultEmail.pendingEmail && resultEmail.error && !errorForm)" class="alert alert-warning text-center">
            {{resultEmail.message}}
          </div>
          <div *ngIf="!resultEmail.pendingEmail && resultEmail.success && !errorForm" class="alert alert-success text-center">
            {{resultEmail.message}}
          </div>
        </div>
        <form [formGroup]="emailForm" (ngSubmit)="onFormSubmit()">
          <div [class]="formGroupClass('emailAddress','col-xs-12')">
            <input  type="email" placeholder="Email Address" class="form-control" formControlName="emailAddress" style="width: 70%" />
            <button [disabled]="resultEmail.pendingEmail" class="btn background-green color-white pull-right no-radius submit-button btn-margin btn-email">{{buttonTextSend}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-btn">
    <br>
    <button class="btn background-adey-red color-white" (click)="CloseResultEmail()">{{this.closeModalText}}</button>
  </div>
</div>
