import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { SocialService } from '../../services/social.service';
import * as ActionTypes from '../actions/social';
var SocialEffects = /** @class */ (function () {
    function SocialEffects(socialService, actions$) {
        var _this = this;
        this.socialService = socialService;
        this.actions$ = actions$;
        /**
         * For a SocialFeedRequestAction, call SocialService::getSocialFeeds() and
         * dispatch a new SocialFeedResponseAction with the response.
         */
        this.socialFeedRequest$ = this.actions$
            .ofType(ActionTypes.SOCIAL_FEED_REQUEST)
            .switchMap(function () {
            return _this.socialService.getSocialFeeds()
                .map(function (res) { return new ActionTypes.SocialFeedResponseAction(res); });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], SocialEffects.prototype, "socialFeedRequest$", void 0);
    return SocialEffects;
}());
export { SocialEffects };
