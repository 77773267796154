import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import {
  AddAdminUserResponse,
  DeleteAdminUserResponse,
  FetchAdminUsersResponse,
  UpdateAdminUserResponse,
} from '../../models/admin-users';
import { FetchAccreditationsResponse } from '../../models/user-profile';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-users';

@Injectable()
export class AdminUserEffects {
  constructor(
    private service: AdminService,
    private actions$: Actions
  ) { }

  /**
   * For an AddAdminUserRequestAction, call AdminService::addUser() and
   * dispatch a new AddAdminUserResponseAction with the response.
   */
  @Effect() addUser$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_USER_REQUEST)
    .switchMap((req: ActionTypes.AddAdminUserRequestAction): Observable<ActionTypes.AddAdminUserResponseAction> =>
      this.service.addUser(req.payload)
        .map((res: AddAdminUserResponse): ActionTypes.AddAdminUserResponseAction =>
          new ActionTypes.AddAdminUserResponseAction(res)
        )
    );

  /**
   * For an DeleteAdminUserRequestAction, call AdminService::deleteUser() and
   * dispatch a new DeleteAdminUserResponseAction with the response.
   */
  @Effect() deleteUser$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_USER_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminUserRequestAction): Observable<ActionTypes.DeleteAdminUserResponseAction> =>
      this.service.deleteUser(req.payload)
        .map((res: DeleteAdminUserResponse): ActionTypes.DeleteAdminUserResponseAction =>
          new ActionTypes.DeleteAdminUserResponseAction(res)
        )
    );

  /**
   * For a FetchAdminUsersRequestAction, call AdminService::fetchUsers() and
   * dispatch a new FetchAdminUsersResponseAction with the response.
   */
  @Effect() fetchUsers$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_USERS_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminUsersRequestAction): Observable<ActionTypes.FetchAdminUsersResponseAction> =>
      this.service.fetchUsers(req.payload)
        .map((res: FetchAdminUsersResponse): ActionTypes.FetchAdminUsersResponseAction =>
          new ActionTypes.FetchAdminUsersResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminUserRequestAction, call AdminService::updateUser() and
   * dispatch a new UpdateAdminUserResponseAction with the response.
   */
  @Effect() updateUser$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_USER_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminUserRequestAction): Observable<ActionTypes.UpdateAdminUserResponseAction> =>
      this.service.updateUser(req.payload)
        .map((res: UpdateAdminUserResponse): ActionTypes.UpdateAdminUserResponseAction =>
          new ActionTypes.UpdateAdminUserResponseAction(res)
        )
    );

  /**
   * For a FetchAccreditationsRequestAction, call AdminService::fetchAccreditations() and
   * dispatch a new FetchAccreditationsResponseAction with the response.
   */
  @Effect() fetchAccreditations$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ACCREDITATIONS_REQUEST)
    .switchMap((): Observable<Action> =>
      this.service.fetchAccreditations()
        .map((res: FetchAccreditationsResponse): ActionTypes.FetchAccreditationsResponseAction =>
          new ActionTypes.FetchAccreditationsResponseAction(res)
        )
    );
}
