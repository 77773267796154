import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { RegisterService } from '../../services/register.service';

import * as ActionTypes from '../actions/registration';

import {
  FetchUserRolesResponse,
  RegistrationRequest,
  RegistrationResponse,
} from '../../models/registration';

@Injectable()
export class RegistrationEffects {
  constructor(
    private registerService: RegisterService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchUserRolesRequestAction, call RegisterService::fetchUserRoles$()
   * and dispatch a new FetchUserRolesResponseAction with the response.
   */
  @Effect() fetchUserRoles$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_USER_ROLES_REQUEST)
    .switchMap((req: ActionTypes.FetchUserRolesRequestAction): Observable<ActionTypes.FetchUserRolesResponseAction> =>
      this.registerService.fetchUserRoles()
        .map((res: FetchUserRolesResponse): ActionTypes.FetchUserRolesResponseAction =>
          new ActionTypes.FetchUserRolesResponseAction(res)
        )
    );

  /**
   * For a RegistrationRequestAction, call RegisterService::registerNewUser()
   * and dispatch a new RegistrationResponseAction with the response.
   */
  @Effect() registerRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REGISTRATION_REQUEST)
    .switchMap((req: ActionTypes.RegistrationRequestAction): Observable<Action> => {
      return this.registerService.registerNewUser(req.payload)
        .map((res: RegistrationResponse): ActionTypes.RegistrationResponseAction => new ActionTypes.RegistrationResponseAction(res));
    });

    /**
     * For a SimpleRegistrationRequestAction, call RegisterService::registerNewUser()
     * and dispatch a new RegistrationResponseAction with the response.
     */
    @Effect() simpleRegisterRequest$: Observable<Action> = this.actions$
      .ofType(ActionTypes.SIMPLE_REGISTRATION_REQUEST)
      .switchMap((req: ActionTypes.SimpleRegistrationRequestAction): Observable<Action> => {
        return this.registerService.registerNewSimpleUser(req.payload)
          .map((res: RegistrationResponse): ActionTypes.RegistrationResponseAction => new ActionTypes.RegistrationResponseAction(res));
      });

  /**
   * For a RegistrationWaterTesterRequestAction, call RegisterService::registerNewWaterTesterUser()
   * and dispatch a new RegistrationWaterTesterResponseAction with the response.
   */
  @Effect() registerWaterTesterRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REGISTRATION_WATER_TESTER_REQUEST)
    .switchMap((req: ActionTypes.RegistrationWaterTesterRequestAction): Observable<Action> => {
      return this.registerService.registerNewWaterTesterUser(req.payload)
        .map((res: RegistrationResponse): ActionTypes.RegistrationWaterTesterResponseAction => new ActionTypes.RegistrationWaterTesterResponseAction(res));
    });
}
