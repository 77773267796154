import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


import { ServiceRequest } from '../../../models/service-record';

import { AddServiceRequestComponent } from '../add-service-request/add-service-request.component';


/**
 * Summary
 *    Container modal component for displaying AddServiceRequestModalComponent
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-add-service-request-modal',
  templateUrl: './add-service-request-modal.component.html',
  styleUrls: ['./add-service-request-modal.component.scss']
})
export class AddServiceRequestModalComponent implements OnInit {

  // Reference to AddServiceRequestComponent child component
  @ViewChild(AddServiceRequestComponent) compDetails: AddServiceRequestComponent;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddServiceRequestModalComponent>,
  ) { }

  ngOnInit() { }


  /**
   * Called via AddServiceRequestComponent::onSave() and closes the dialog
   * passing through the result
   *
   * @param {ServiceRequest} sr ServiceRequest model from AddServiceRequestComponent
   */
  handleSubmitForm(sr: ServiceRequest) {
    this.dialogRef.close(sr);
  }

  /**
   * Called whrn "Add service request" is clicked: calls
   * AddServiceRequestComponent::onSubmitForm()
   */
  onSaveClick() {
    this.compDetails.onSubmitForm();
  }
}
