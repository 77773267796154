<h2 matDialogTitle *ngIf="!viewMode">Edit job record</h2>
<h2 matDialogTitle *ngIf="viewMode">View job record</h2>

<mat-dialog-content>
  <app-service-record
    [readOnly]="viewMode"
    [serviceRecord]="data.serviceRecord"
    [showSubmit]="false"
    (onSave)="handleSubmitForm($event)"
  >
  </app-service-record>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button mat-button *ngIf="!viewMode" (click)="onSaveClick()">Save changes</button>
</mat-dialog-actions>
