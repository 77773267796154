import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { RewardsService } from '../../services/rewards.service';
import * as ActionTypes from '../actions/rewards';
var RewardsEffects = /** @class */ (function () {
    function RewardsEffects(rewardsService, actions$) {
        var _this = this;
        this.rewardsService = rewardsService;
        this.actions$ = actions$;
        this.installerHubVoucherRequest$ = this.actions$
            .ofType(ActionTypes.INSTALLER_HUB_VOUCHER_REQUEST)
            .switchMap(function (req) {
            return _this.rewardsService.getInstallerHubVoucher(req.payload)
                .map(function (res) {
                if (res && res.callback) {
                    res.callback();
                }
                return new ActionTypes.InstallerHubVoucherResponseAction(res);
            });
        });
        this.installerHubVoucherValuesRequest$ = this.actions$
            .ofType(ActionTypes.INSTALLER_HUB_VOUCHER_VALUES_REQUEST)
            .switchMap(function () {
            return _this.rewardsService.getInstallerHubVoucherValues()
                .map(function (res) { return new ActionTypes.InstallerHubVoucherValuesResponseAction(res); });
        });
        this.sparesRewardsRequest$ = this.actions$
            .ofType(ActionTypes.SPARES_REWARDS_REQUEST)
            .switchMap(function (req) {
            return _this.rewardsService.redeemSparesRewards(req.payload)
                .map(function (res) {
                if (res && res.callback) {
                    res.callback();
                }
                return new ActionTypes.SparesRewardsResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RewardsEffects.prototype, "installerHubVoucherRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RewardsEffects.prototype, "installerHubVoucherValuesRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RewardsEffects.prototype, "sparesRewardsRequest$", void 0);
    return RewardsEffects;
}());
export { RewardsEffects };
