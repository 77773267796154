/**
 * Provides AdminPro3AlertDetailModalComponent
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

//required classes
import {Component, EventEmitter, Inject, Output} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {
    AdminDeviceAlert,
} from '../../../../models/admin-pro3';

/**
 * The format for incoming data
 */
export interface AdminPro3AlertDetailDialogData {
    deviceAlert: AdminDeviceAlert;
}

/**
 * Component providing the modal alert view
 */
@Component({
  selector: 'app-admin-pro3-alert-detail',
  templateUrl: './admin-pro3-alert-detail-modal.component.html',
  styleUrls: ['./admin-pro3-alert-detail-modal.component.scss']
})
export class AdminPro3AlertDetailModalComponent {

    //The alert to display
    public deviceAlert: AdminDeviceAlert;

    //Event to fire when a user clicks to remove
    @Output() alertRemoved = new EventEmitter<AdminDeviceAlert>();

    /**
     * Get the incoming data and inject a reference to the dialog for scripted closing
     *
     * @param data
     * @param dialogRef
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AdminPro3AlertDetailDialogData,
        public dialogRef: MatDialogRef<AdminPro3AlertDetailModalComponent>
    ) {
        //Get the device out of incoming data
        this.deviceAlert = data.deviceAlert;
    }

    /**
     * Trigger the alert removal
     *
     * @param $event Notify the host that the alert should be dismissed
     */
    dismissAlert($event) {
        //Stop the host tr-click event
        $event.stopPropagation();

        //Mark the alert for dismissal
        this.deviceAlert.showAdmin = false;
        this.alertRemoved.emit(this.deviceAlert);

        //Close the dialog
        this.dialogRef.close({removeDevice: this.deviceAlert});
    }
}
