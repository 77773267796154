import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { WarrantyService } from '../../services/warranty.service';
import * as ActionTypes from '../actions/warranty-stats';
var WarrantyStatsEffects = /** @class */ (function () {
    function WarrantyStatsEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a FetchWarrantyFullStatsRequestAction, call
         * WarrantyService::fetchFullStats() and dispatch a new
         * FetchWarrantyFullStatsResponseAction with the response.
         */
        this.fetchWarrantieFullStats$ = this.actions$
            .ofType(ActionTypes.FETCH_WARRANTY_FULL_STATS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchFullStats()
                .map(function (res) {
                return new ActionTypes.FetchWarrantyFullStatsResponseAction(res);
            });
        });
        /**
         * For a FetchWarrantyMonthlyStatsRequestAction, call
         * WarrantyService::fetchMonthlyWarrantyStats() and dispatch a new
         * FetchWarrantyMonthlyStatsResponseAction with the response.
         */
        this.fetchWarrantyMonthlyStats$ = this.actions$
            .ofType(ActionTypes.FETCH_WARRANTY_MONTHLY_STATS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchMonthlyWarrantyStats(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchWarrantyMonthlyStatsResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WarrantyStatsEffects.prototype, "fetchWarrantieFullStats$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WarrantyStatsEffects.prototype, "fetchWarrantyMonthlyStats$", void 0);
    return WarrantyStatsEffects;
}());
export { WarrantyStatsEffects };
