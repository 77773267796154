<div *ngIf="waterTester$ | async;let state">
  <app-waiting-spinner kind="bar" *ngIf="state.pending" class="col-xs-12">{{state.message}}</app-waiting-spinner>

  <div *ngIf="(!refreshDisplayMessage)">
    <div *ngIf="(state.message && !state.pending)">
      <div *ngIf="(state.error)" class="alert alert-warning text-center">
        {{state.message}}
      </div >
      <div *ngIf="(!state.error)" class="alert alert-success text-center">
        {{state.message}}
      </div>
    </div>
  </div>

  <div *ngIf="(refreshDisplayMessage)" class="alert alert-info text-center">
    {{message}}
  </div>

  <div class="modal-btn">
    <button *ngIf="(state.success && !refreshDisplayMessage)" class="btn background-adey-red color-white" (click)="CloseTesterModal()">Close</button>
    <div *ngIf="(refreshDisplayMessage || state.error)" >
      <button class="btn background-adey-red color-white" (click)="CloseTesterModal()">Cancel</button>
      <button class="btn background-green color-white" (click)="ConfirmChangeRoleTesterModal()">Yes, I confirm?</button>
    </div>
  </div>
</div>
