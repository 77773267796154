import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResultListAdminComponent} from '../resultlistadmin/resultlistadmin.component';
import {State as ResultEmailState} from '../../state-management/reducers/watertester';
import {Observable} from 'rxjs/Observable';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Store} from '@ngrx/store';
import {ResultEmailQueryRequestAction} from '../../state-management/actions/watertesters';

@Component({
  selector: 'app-result-email',
  templateUrl: './result-email.component.html',
  styleUrls: ['./result-email.component.scss']
})
export class ResultEmailComponent implements OnInit {
  public message = '';
  public formSubmitted = false;
  public emailForm: FormGroup;
  public errorForm = null;
  public emailAddress: string = '';
  public name = '';
  public buttonTextSend = 'Submit';
  public title = 'Send result by email';
  public resultIds: number[];

  public emailBody: string = '';

  //message no selected data
  public noSelected: string = null;

  //display info message every time a open a new modal
  public refreshDisplayMessage: boolean = false;

  //display text close cancel modal
  public closeModalText: string = 'Close';

  // "result email" from store
  public resultEmail$: Observable<ResultEmailState>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ResultEmailComponent>,
    private dialog:    MatDialog,
    private _store: Store<any>,
    private formBuilder: FormBuilder,
  ) {
    this.resultEmail$ = this._store.select('WaterTester');
  }

  ngOnInit() {
    this.refreshDisplayMessage = true;
    this.resultIds = this.data.resultIds;
    this.noSelected = this.data.noSelected;
    this.buildFormResult();
  }

  onFormSubmit() {

    this.errorForm = false;
    this.formSubmitted = true;
    this.refreshDisplayMessage = false;
    if (this.emailForm.valid) {
      this.emailAddress = this.emailForm.value['emailAddress'];
      this.name = this.emailForm.value['name'];
      this._store.dispatch(
        new ResultEmailQueryRequestAction(
          { email: this.emailAddress, resultId: this.resultIds, body: this.emailBody }
          )
      );
    }
    else {
      this.errorForm = 'The form contains errors.';
    }
  }

  buildFormResult() {
    this.emailForm = this.formBuilder.group({
      emailAddress: ['', [Validators.email, Validators.required] ],
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.emailForm.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.formSubmitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Close Result email
   *
   */
  CloseResultEmail() {
    this.dialogRef.close();
  }

}
