import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { PopupService } from '../../services/popup.service';
import * as ActionTypes from '../actions/popup';
import { GetUserPopup } from '../actions/popup';
var PopupEffects = /** @class */ (function () {
    function PopupEffects(actions$, service, store) {
        var _this = this;
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.userPopupRequest$ = this.actions$
            .ofType(ActionTypes.GET_USER_POPUP)
            .switchMap(function () { return _this.service.getUserPopup()
            .then(function (payload) { return new ActionTypes.GetUserPopupSuccess(payload); })
            .catch(function (error) { return new ActionTypes.GetUserPopupFailure(error); }); });
        this.userPopupDisplayCountRequest$ = this.actions$
            .ofType(ActionTypes.SET_USER_POPUP_DISPLAY_COUNT)
            .switchMap(function (action) { return _this.service.setUserPopupDisplayCount(action.request.name, action.request.count)
            .then(function () { return new ActionTypes.SetUserPopupDisplayCountSuccess(); })
            .catch(function (error) { return new ActionTypes.SetUserPopupDisplayCountFailure(error); }); });
        this.userPopupDisplayCountSuccess$ = this.actions$
            .ofType(ActionTypes.SET_USER_POPUP_DISPLAY_COUNT_SUCCESS)
            .map(function () { return _this.store.dispatch(new GetUserPopup()); });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], PopupEffects.prototype, "userPopupRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], PopupEffects.prototype, "userPopupDisplayCountRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], PopupEffects.prototype, "userPopupDisplayCountSuccess$", void 0);
    return PopupEffects;
}());
export { PopupEffects };
