import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {DatatableService} from './datatable.service';
import {TableTypes} from '../components/tableresults/TableTypes.enum';

@Injectable()
export class TableResultsService {

  constructor(
      private apiService: ApiService,
      private dataTable: DatatableService
  ) { }

  public getResults(dataTablesParameters, search, tableType: TableTypes) {
    if (tableType === TableTypes.normal) {
      return this.getResultsNormal(dataTablesParameters, search);
    }

    if (tableType === TableTypes.due) {
      return this.getResultsDue(dataTablesParameters, search);
    }

    if (tableType === TableTypes.waterTester) {
      return this.getResultsByWaterTester(dataTablesParameters, search);
    }

    if (tableType === TableTypes.byAddress || tableType === TableTypes.all) {
      return this.getResultsByAddress(dataTablesParameters, search);
    }

  }

  private getResultsNormal(dataTablesParameters, search) {
    //TODO: search by datetime functionality is not working
    const param = this.dataTable.joinParameters(dataTablesParameters, search);
    return this.apiService.apiGet('/watertest/test-log' + param);
  }

  private getResultsDue(dataTablesParameters, search) {
    //TODO: search by datetime functionality is not working
    const param = this.dataTable.joinParameters(dataTablesParameters, search);
    return this.apiService.apiGet('/watertest/test-log' + param);
  }

  private getResultsByWaterTester(dataTablesParameters, search) {
    //TODO: search by datetime functionality is not working
    const param = this.dataTable.joinParameters(dataTablesParameters, search);
    return this.apiService.apiGet('/watertest/result-with-address' + param);
  }

  private getResultsByAddress(dataTablesParameters, search) {
    //TODO: search by datetime functionality is not working
    const param = this.dataTable.joinParameters(dataTablesParameters, search);
    return this.apiService.apiGet('/watertest/result-with-address' + param);
  }

}
