<div *ngIf="loginDetails$ | async; let loginDetails">

  <div *ngIf="!proCheckView && navFooter" class="container-fluid background-black no-pad">
    <div *ngIf="profileIncomplete$ | async;" class="profile-completion-bar">
      <p class="color-white inline">
        Complete your profile to feel the full benefit of ProClub&trade;.
        <strong><a [routerLink]="['/profile']">Click here now</a></strong>
      </p>
    </div>
  </div>

  <div class="container-fluid background-beige no-pad"
    *ngIf="navFooter && (loginDetails.loggedIn && loginDetails?.user?.active )">
    <ng-container *ngIf="getDefaultRoute$() | async; let route">
      <a *ngIf="!proCheckView" [routerLink]="route"><img src="assets/i/common/adey-logo.png" alt="ADEY® ProClub Logo"
          class="nav-logo" /></a>
      <a *ngIf="!proCheckView && localeLogo" [routerLink]="route"><img [src]="localeLogo" alt="ADEY® ProClub Logo"
          class="nav-logo" /></a>
      <a *ngIf="proCheckView" [routerLink]="route"><img src="assets/i/common/logos/adey-pro-check-logo-r.png"
          alt="ADEY ProCheck Logo" class="nav-logo" /></a>
    </ng-container>

    <ul class="nav navbar-nav" *ngIf="getNavItems$() | async; let items">
      <button mat-icon-button [matMenuTriggerFor]="appMenu" class="main-menu-burger"
        [ngClass]="getRoleClassFromCount(items.length)">
        <i class="fa fa-bars color-light-gray" aria-hidden="true"></i>
      </button>
      <li (click)="navClick(nav)" *ngFor="let nav of items; " [class]="'nav-item desktop-nav-item ' + nav.customClasses"
        [ngClass]="getRoleClassFromCount(items.length)">
        <i *ngIf="nav.icon" [class]="nav.icon + ' color-adey-red'"></i>
        <p [class]="nav.textClass">{{nav.title}}</p>
      </li>
      <mat-menu #appMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
        <button mat-menu-item (click)="navClick(nav)" *ngFor="let nav of items">{{nav.title}}</button>
      </mat-menu>
    </ul>

    <div class="action-bar background-adey-red" *ngIf="loginDetails.loggedIn">
      <p class="color-white inline" *ngIf="loginDetails.currentProfile">
        Welcome back
        <strong>{{loginDetails.currentProfile.firstName}} {{loginDetails.currentProfile.lastName}}</strong>
        <strong *ngIf="loginDetails.user && loginDetails.user.admin"> (administrator)</strong>
      </p>
      <button class="btn btn-link pull-right color-white" (click)="onLogout()">Logout</button>
      <button *ngIf="proCheckView" class="btn btn-link pull-right color-white"
        (click)="editProfileProCheckDetails(loginDetails.currentProfile,loginDetails.accreditations)">Edit
        profile</button>
    </div>
  </div>

  <router-outlet></router-outlet>

  <div class="container-fluid footer border-adey-red"
    *ngIf="navFooter && loginDetails.loggedIn && ( proCheckView || loginDetails?.user?.optIns?.length !== 0 )">
    <div class="row">
      <div class="col-xs-12 social-icons no-pad" *ngIf="socialItems">
        <a class="social-icon background-white" [href]="social.url" *ngFor="let social of socialItems.items"
          target="_blank">
          <i aria-hidden="true" [class]="'fa ' + social.icon"></i>
        </a>
      </div>
      <div class="top-links col-xs-12 no-pad">
        <ng-container *ngFor="let topLink of extLinks.topLinks">
          <a *ngIf="topLink.url" [href]="topLink.url" target="_blank"
            class="color-white medium hover-color-white">{{topLink.label}}</a>
          <a *ngIf="topLink.routerLink" [routerLink]="topLink.routerLink" target="_blank"
            class="color-white medium hover-color-white">{{topLink.label}}</a>
        </ng-container>
      </div>
      <div class=" col-md-7 col-sm-12 col-xs-12 no-pad">
        <ng-container *ngFor="let siteLink of extLinks.siteLinks">
          <a *ngIf="siteLink.url" [href]="siteLink.url" target="_blank"
            class="color-light-gray hover-color-white">{{siteLink.label}}</a>
          <a *ngIf="siteLink.routerLink" [routerLink]="siteLink.routerLink"
            class="color-light-gray hover-color-white">{{siteLink.label}}</a>
        </ng-container>
      </div>
      <div class="col-md-5 col-sm-12 col-xs-12 color-light-gray copy align-right no-pad">
        Copyright ADEY Innovation Ltd &copy;2018. All rights reserved.
      </div>
    </div>
  </div>

</div>
