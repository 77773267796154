import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'magnetite-bar',
  templateUrl: './magnetite-bar.component.html',
  styleUrls: ['./magnetite-bar.component.scss']
})
export class MagnetiteBarComponent implements OnInit {

  @Input('magnetite') magnetite;

  constructor() { }

  magnetiteLevelClass(magnetite) {
    switch (magnetite) {
      case 3:
        return 'half';
      case 4:
        return 'three-quarters';
      case 5:
        return 'full';
      default:
        return 'quarter';
    }
  }

  ngOnInit() {
  }

}
