/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-service-request-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../add-service-request/add-service-request.component.ngfactory";
import * as i4 from "../add-service-request/add-service-request.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "./add-service-request-modal.component";
var styles_AddServiceRequestModalComponent = [i0.styles];
var RenderType_AddServiceRequestModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddServiceRequestModalComponent, data: {} });
export { RenderType_AddServiceRequestModalComponent as RenderType_AddServiceRequestModalComponent };
export function View_AddServiceRequestModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { compDetails: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Add service request"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-add-service-request", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.handleSubmitForm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AddServiceRequestComponent_0, i3.RenderType_AddServiceRequestComponent)), i1.ɵdid(9, 114688, [[1, 4]], 0, i4.AddServiceRequestComponent, [i5.FormBuilder], { showSubmit: [0, "showSubmit"] }, { onSave: "onSave" }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(13, 0, null, null, 11, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).dialogRef.close(i1.ɵnov(_v, 18).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor], null, null), i1.ɵdid(18, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSaveClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, 0, ["Add service request"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_1 = false; _ck(_v, 9, 0, currVal_1); var currVal_4 = false; _ck(_v, 18, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 17).disabled || null); var currVal_3 = i1.ɵnov(_v, 18).ariaLabel; _ck(_v, 16, 0, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 22).disabled || null); _ck(_v, 21, 0, currVal_5); }); }
export function View_AddServiceRequestModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-service-request-modal", [], null, null, null, View_AddServiceRequestModalComponent_0, RenderType_AddServiceRequestModalComponent)), i1.ɵdid(1, 114688, null, 0, i10.AddServiceRequestModalComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddServiceRequestModalComponentNgFactory = i1.ɵccf("app-add-service-request-modal", i10.AddServiceRequestModalComponent, View_AddServiceRequestModalComponent_Host_0, {}, {}, []);
export { AddServiceRequestModalComponentNgFactory as AddServiceRequestModalComponentNgFactory };
