/**
 * social actions: defines all actions relating to the social page (social media feeds)
 */

import { Action } from '@ngrx/store';

// Import models
import { SocialFeedResponse } from '../../models/social';

// Action type definitions
export const SOCIAL_FEED_REQUEST    = 'SOCIAL_FEED_REQUEST';
export const SOCIAL_FEED_RESPONSE   = 'SOCIAL_FEED_RESPONSE';

export class SocialFeedRequestAction implements Action {
  readonly type = SOCIAL_FEED_REQUEST;
}

export class SocialFeedResponseAction implements Action {
  readonly type = SOCIAL_FEED_RESPONSE;
  constructor(public payload: SocialFeedResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | SocialFeedRequestAction
  | SocialFeedResponseAction;
