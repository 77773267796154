<div class="container-fluid company-page" *ngIf="this.waterTester$ | async;let state">
  <div class="row content-page">
    <div class="content-watertest">
      <div class="container-title has-sub water-results-subtitle">
        <h1 class="white">{{title}}</h1>
        <h2 class="white" *ngIf="!state.pendingList && noSubTitle">
          <span *ngIf="state.titleFilter">
              {{state.titleFilter}}
          </span>
        </h2>
      </div>
      <br>
      <div class="navigator-watertest-table">
        <button class="btn background-adey-red color-white btn-result btn-margin-right" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
        <button class="btn background-adey-red color-white btn-result btn-margin-right" (click)="navigatesBack()">Back to previous page</button>
        <button class="btn background-green color-white btn-result btn-margin-right" (click)="sendResultByEmail()">Email results</button>
        <button *ngIf="showExportButton"  [disabled]="exportButtonDisabled" class="btn background-green color-white btn-result" (click)="exportResult()">Export results</button>
      </div>
      <app-tableresults (resultIds)="checkResultsIds($event)" search="{{search}}"  (tableSearch)="checkSearchTable($event)" [tableType]="tableType" admin="true" lastTest="{{lastTest}}">
        Loading table...
      </app-tableresults>
    </div>
  </div>
</div>
