/**
 * watertesters actions: defines actions for accept and collect the invitation details
 */

import { Action } from '@ngrx/store';


// Import models
import {
  AdminTesterQueryRequest,
  AdminTesterQueryResponse,
  CompanyTotalQueryResponse,
  GetTesterLocalQueryRequest,
  GetTesterLocalQueryResponse,
  GetTesterQueryRequest,
  GetTesterQueryResponse,
  RemoveTesterQueryRequest,
  RemoveTesterQueryResponse,
  ResultEmailQueryRequest,
  ResultEmailQueryResponse,
  TesterRoleQueryRequest,
  TesterRoleQueryResponse,
  TesterTotalQueryResponse,
  TestOverDueTotalQueryRequest,
  TestOverDueTotalQueryResponse,
  TestStatusPropertyTotalQueryResponse,
  TestTotalQueryResponse,
  TestExportQueryRequest,
  TestExportQueryResponse,
  SetTitleFilterRequest,
} from '../../models/watertester';


// Action type definitions
export const REMOVE_TESTER_QUERY_REQUEST     = 'REMOVE_TESTER_QUERY_REQUEST';
export const REMOVE_TESTER_QUERY_RESPONSE    = 'REMOVE_TESTER_QUERY_RESPONSE';
export const ADMIN_TESTER_QUERY_REQUEST      = 'ADMIN_TESTER_QUERY_REQUEST';
export const ADMIN_TESTER_QUERY_RESPONSE     = 'ADMIN_TESTER_QUERY_RESPONSE';
export const GET_TESTER_QUERY_REQUEST        = 'GET_TESTER_QUERY_REQUEST';
export const GET_TESTER_QUERY_RESPONSE       = 'GET_TESTER_QUERY_RESPONSE';
export const GET_TESTER_LOCAL_QUERY_REQUEST  = 'GET_TESTER_LOCAL_QUERY_REQUEST';
export const GET_TESTER_LOCAL_QUERY_RESPONSE = 'GET_TESTER_LOCAL_QUERY_RESPONSE';
export const RESULT_EMAIL_QUERY_REQUEST      = 'RESULT_EMAIL_QUERY_REQUEST';
export const RESULT_EMAIL_QUERY_RESPONSE     = 'RESULT_EMAIL_QUERY_RESPONSE';
export const TESTER_ROLE_QUERY_REQUEST       = 'TESTER_ROLE_QUERY_REQUEST';
export const TESTER_ROLE_QUERY_RESPONSE      = 'TESTER_ROLE_QUERY_RESPONSE';
export const COMPANY_TOTAL_QUERY_REQUEST     = 'COMPANY_TOTAL_QUERY_REQUEST';
export const COMPANY_TOTAL_QUERY_RESPONSE    = 'COMPANY_TOTAL_QUERY_RESPONSE';
export const TESTER_TOTAL_QUERY_REQUEST      = 'TESTER_TOTAL_QUERY_REQUEST';
export const TESTER_TOTAL_QUERY_RESPONSE     = 'TESTER_TOTAL_QUERY_RESPONSE';
export const TEST_TOTAL_QUERY_REQUEST        = 'TEST_TOTAL_QUERY_REQUEST';
export const TEST_TOTAL_QUERY_RESPONSE       = 'TEST_TOTAL_QUERY_RESPONSE';
export const TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_REQUEST  = 'TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_REQUEST';
export const TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_RESPONSE = 'TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_RESPONSE';
export const TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_REQUEST  = 'TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_REQUEST';
export const TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_RESPONSE = 'TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_RESPONSE';
export const TEST_OVER_DUE_TOTAL_QUERY_REQUEST  = 'TEST_OVER_DUE_TOTAL_QUERY_REQUEST';
export const TEST_OVER_DUE_TOTAL_QUERY_RESPONSE = 'TEST_OVER_DUE_TOTAL_QUERY_RESPONSE';
export const TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_REQUEST  = 'TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_REQUEST';
export const TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_RESPONSE = 'TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_RESPONSE';

export const TEST_EXPORT_QUERY_REQUEST  = 'TEST_EXPORT_QUERY_REQUEST';
export const TEST_EXPORT_QUERY_RESPONSE  = 'TEST_EXPORT_QUERY_RESPONSE';
export const TEST_EXPORT_CLEAR_REQUEST  = 'TEST_EXPORT_CLEAR_REQUEST';

export const SET_TITLE_FILTER_REQUEST = 'SET_TITLE_FILTER_REQUEST';
// Action definitions
export class RemoveTesterQueryRequestAction implements Action {
  readonly type = REMOVE_TESTER_QUERY_REQUEST;
  constructor(public payload: RemoveTesterQueryRequest) {}
}

export class RemoveTesterQueryResponseAction implements Action {
  readonly type = REMOVE_TESTER_QUERY_RESPONSE;
  constructor(public payload: RemoveTesterQueryResponse) {}
}


export class AdminTesterQueryRequestAction implements Action {
  readonly type = ADMIN_TESTER_QUERY_REQUEST;
  constructor(public payload: AdminTesterQueryRequest) {}
}

export class AdminTesterQueryResponseAction implements Action {
  readonly type = ADMIN_TESTER_QUERY_RESPONSE;
  constructor(public payload: AdminTesterQueryResponse) {}
}

export class GetTesterQueryRequestAction implements Action {
  readonly type = GET_TESTER_QUERY_REQUEST;
  constructor(public payload: GetTesterQueryRequest) {}
}

export class GetTesterQueryResponseAction implements Action {
  readonly type = GET_TESTER_QUERY_RESPONSE;
  constructor(public payload: GetTesterQueryResponse) {}
}


export class GetTesterLocalQueryRequestAction implements Action {
  readonly type = GET_TESTER_LOCAL_QUERY_REQUEST;
  constructor(public payload: GetTesterLocalQueryRequest) {}
}
export class GetTesterLocalQueryResponseAction implements Action {
  readonly type = GET_TESTER_LOCAL_QUERY_RESPONSE;
  constructor(public payload: GetTesterLocalQueryResponse) {}
}


export class ResultEmailQueryRequestAction implements Action {
  readonly type = RESULT_EMAIL_QUERY_REQUEST;
  constructor(public payload: ResultEmailQueryRequest) {}
}
export class ResultEmailQueryResponseAction implements Action {
  readonly type = RESULT_EMAIL_QUERY_RESPONSE;
  constructor(public payload: ResultEmailQueryResponse) {}
}

export class TesterRoleQueryRequestAction implements Action {
  readonly type = TESTER_ROLE_QUERY_REQUEST;
  constructor(public payload: TesterRoleQueryRequest) {}
}
export class TesterRoleQueryResponseAction implements Action {
  readonly type = TESTER_ROLE_QUERY_RESPONSE;
  constructor(public payload: TesterRoleQueryResponse) {}
}


export class CompanyTotalQueryRequestAction implements Action {
  readonly type = COMPANY_TOTAL_QUERY_REQUEST;
  constructor() {}
}
export class CompanyTotalQueryResponseAction implements Action {
  readonly type = COMPANY_TOTAL_QUERY_RESPONSE;
  constructor(public payload: CompanyTotalQueryResponse) {}
}

export class TesterTotalQueryRequestAction implements Action {
  readonly type = TESTER_TOTAL_QUERY_REQUEST;
  constructor() {}
}
export class TesterTotalQueryResponseAction implements Action {
  readonly type = TESTER_TOTAL_QUERY_RESPONSE;
  constructor(public payload: TesterTotalQueryResponse) {}
}


export class TestTotalQueryRequestAction implements Action {
  readonly type = TEST_TOTAL_QUERY_REQUEST;
  constructor() {}
}
export class TestTotalQueryResponseAction implements Action {
  readonly type = TEST_TOTAL_QUERY_RESPONSE;
  constructor(public payload: TestTotalQueryResponse) {}
}


export class TestStatusPropertyTotalPassQueryRequestAction implements Action {
  readonly type = TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_REQUEST;
  constructor() {}
}
export class TestStatusPropertyTotalPassQueryResponseAction implements Action {
  readonly type = TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_RESPONSE;
  constructor(public payload: TestStatusPropertyTotalQueryResponse) {}
}

export class TestStatusPropertyTotalFailQueryRequestAction implements Action {
  readonly type = TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_REQUEST;
  constructor() {}
}
export class TestStatusPropertyTotalFailQueryResponseAction implements Action {
  readonly type = TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_RESPONSE;
  constructor(public payload: TestStatusPropertyTotalQueryResponse) {}
}


export class TestOverDueTotalQueryRequestAction implements Action {
  readonly type = TEST_OVER_DUE_TOTAL_QUERY_REQUEST;
  constructor(public payload: TestOverDueTotalQueryRequest) {}
}
export class TestOverDueTotalQueryResponseAction implements Action {
  readonly type = TEST_OVER_DUE_TOTAL_QUERY_RESPONSE;
  constructor(public payload: TestOverDueTotalQueryResponse) {}
}


export class TestOverDueNextMonthTotalQueryRequestAction implements Action {
  readonly type = TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_REQUEST;
  constructor(public payload: TestOverDueTotalQueryRequest) {}
}
export class TestOverDueNextMonthTotalQueryResponseAction implements Action {
  readonly type = TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_RESPONSE;
  constructor(public payload: TestOverDueTotalQueryResponse) {}
}

export class TestExportQueryRequestAction implements Action {
  readonly type = TEST_EXPORT_QUERY_REQUEST;
  constructor(public payload: TestExportQueryRequest) {}
}

export class TestExportQueryResponseAction implements Action {
  readonly type = TEST_EXPORT_QUERY_RESPONSE;
  constructor(public payload: TestExportQueryResponse) {}
}

export class TestExportClearRequestAction implements Action {
  readonly type = TEST_EXPORT_CLEAR_REQUEST;
  constructor() {}
}

export class SetTitleFilterAction implements Action {
  readonly type = SET_TITLE_FILTER_REQUEST;
  constructor(public payload: SetTitleFilterRequest) {}
}

// Export Actions type as a union of all defined actions
export type Actions =
  | RemoveTesterQueryRequestAction
  | RemoveTesterQueryResponseAction
  | AdminTesterQueryRequestAction
  | AdminTesterQueryResponseAction
  | GetTesterQueryRequestAction
  | GetTesterQueryResponseAction
  | GetTesterLocalQueryRequestAction
  | GetTesterLocalQueryResponseAction
  | ResultEmailQueryRequestAction
  | ResultEmailQueryResponseAction
  | TesterRoleQueryRequestAction
  | TesterRoleQueryResponseAction
  | CompanyTotalQueryRequestAction
  | CompanyTotalQueryResponseAction
  | TesterTotalQueryRequestAction
  | TesterTotalQueryResponseAction
  | TestTotalQueryRequestAction
  | TestTotalQueryResponseAction
  | TestStatusPropertyTotalPassQueryRequestAction
  | TestStatusPropertyTotalPassQueryResponseAction
  | TestStatusPropertyTotalFailQueryRequestAction
  | TestStatusPropertyTotalFailQueryResponseAction
  | TestOverDueTotalQueryRequestAction
  | TestOverDueTotalQueryResponseAction
  | TestOverDueNextMonthTotalQueryRequestAction
  | TestOverDueNextMonthTotalQueryResponseAction
  | TestExportQueryRequestAction
  | TestExportQueryResponseAction
  | SetTitleFilterAction
  | TestExportClearRequestAction
  ;

