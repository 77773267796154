import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';


/**
 * Summary
 *    Allows a user to view their current profile photo and to upload a new one
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-profile-photo-upload',
  templateUrl: './profile-photo-upload.component.html',
  styleUrls: ['./profile-photo-upload.component.scss']
})
export class ProfilePhotoUploadComponent implements OnInit {

  // URL of current photo
  @Input() initialUrl: string = null;

  // Emits new photo data (as data URL) when a new photo is chosen
  @Output() onData = new EventEmitter<string>();


  // Flag to indicate if the File API is supported in the browser
  public fileAPISupported: boolean = true;

  // Errors for display
  public errors: string  = null;

  // New photo data (data URL)
  public photoData: string  = null;


  constructor(
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    // determine if the File API is supported by the browser
    this.fileAPISupported = window
      ? !!((window as any).File && (window as any).FileReader && (window as any).FileList && (window as any).Blob)
      : true;
  }


  /**
   * Called when the file input changes: reads the data using the File API (if
   * possible) and sets this.photoData and this.errors accordingly
   *
   * @param {Event} evt
   */
  handleFile(evt) {
    if (evt.target.files.length === 0)
    {
      this.errors = 'No files could be uploaded';
      return;
    }

    const reader = new FileReader();

    this.errors = null;

    reader.onloadstart = (e) => {
    };

    reader.onprogress = (e) => {
    };

    reader.onload = (e) => {
      this.photoData = reader.result;
      this.errors = null;
      this.cdRef.detectChanges();
      this.onData.emit(this.photoData);
    };

    reader.onerror = (e) => {
      this.errors = `Unable to upload file: ${e.toString()}`;
    };

    reader.onabort = () => {
      this.errors = 'Photo upload was aborted';
    };

    reader.readAsDataURL(evt.target.files[0]);
  }

  /**
   * Restores the original image
   */
  resetUpload() {
    this.photoData = null;
    this.onData.emit(null);
  }
}
