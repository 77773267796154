/**
 * user-profile models
 */
import * as moment from 'moment';

import { environment } from '../../environments/environment';

/**
 * Encapsulates a single user accreditation (accredited body name and flag)
 */
export class UserAccreditation {
  id: string;
  key?: string;
  active: boolean;
}

/**
 * Encapsulates a single user accreditation (accredited body name and flag)
 */
export class UserOptIn {
  id?: string;
  offers_by_email: any;
  offers_by_sms: any;
  offers_by_telephone: any;
  information_by_email: any;
  information_by_sms: any;
  information_by_telephone: any;
  add_to_find_an_installer: any;
}

/**
 * Encapsulates a user's profile information
 */
export class UserProfile {
  id?: string;

  // Timestamps (YYYY-MM-DD)
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;

  // Role ID
  userType: number;

  // YYYY-MM-DD
  dob: string;
  dob_dd: number;
  dob_mm: number;
  dob_yyyy: number;

  // Name and address
  title: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  town?: string;
  county?: string;
  postCode: string;
  countries_by_country_id: {
    country_code: string,
    country_name: string
  };
  country_id: string;

  // Contact details
  email: string;
  mobile: string;
  telephone?: string;

  // Password: only set when the user is changing their password
  password: string;

  // Company information
  companyName: string;
  companySize: string;

  // General questions and answers
  howDidYouHear?: number;
  preferredMerchant?: string;
  preferredBoiler?: string;
  projects?: string;
  gasSafeRegistered?: boolean;
  gasSafeNumber?: string;
  oftecRegistered?: boolean;
  oftecNumber?: string;
  magnaCleanseOwned?: boolean;
  magnaCleanseSerial?: string;
  contract?: boolean;
  preferredboiler?: string;

  // List of accreditations that the user has obtained
  accreditations?: UserAccreditation[];

  optIns?: UserOptIn[];

  // User's requested contact options
  contactByPost: boolean;
  contactByPhone: boolean;
  contactByEmail: boolean;

  // Flags to show if user has accepted terms
  dataProtectionConfirm?: boolean;
  termsAndConditionsConfirm?: boolean;

  // Current profile photo URL
  photoUrl?: string;

  // When updating profile photo, contains base64-encoded file data
  photoData?: string;

  // User's current points
  pointsRewarded?: string;
  pointsRedeemed?: string;
  pointsCurrent?: string;
  pointsClaimable?: boolean;

  // Membership level
  memberLevel?: string;

  // Current token used for accessing the PromoShop
  promoShopToken?: string;

  registered?: boolean;
  requestedDeletionAt?: string;

  // Auditing data
  lastLogin?: string;
  timesLoggedIn?: string;

  ignoreSerial?: any;

  transferred?: number;

  userPreferencesUpdate?: any;

  /**
   * Creates a model instance from form data
   *
   * @param {any}                 formData       Data from the form
   * @param {AccreditationItem[]} accreditations List of accreditations from form
   * @return {UserProfile}
   */
  static fromFormData(formData: any, accreditations: AccreditationItem[], optIns: OptInItem[], userPreferenceUpdate?: any, fromGDPR?: any): UserProfile {
    const p: UserProfile = new UserProfile();

    p.id = formData.id;
    p.userType = parseInt(formData.registerAs, 10);
    p.title = formData.title;
    p.firstName = formData.firstName;
    p.lastName = formData.lastName;
    p.dob = formData.dob_yyyy && formData.dob_yyyy && formData.dob_dd ? `${formData.dob_yyyy}-${formData.dob_mm}-${formData.dob_dd}` : null;
    p.address1 = formData.address1;
    p.address2 = formData.address2;
    p.town = formData.town;
    p.county = formData.county;
    p.postCode = formData.postCode;
    p.country_id = formData.country;
    p.email = formData.emails ? formData.emails.email : formData.email;
    p.password = formData.password;
    p.mobile = formData.mobile;
    p.telephone = formData.telephone;
    p.companyName = formData.companyName;
    p.companySize = formData.companySize;
    p.photoUrl = formData.photoUrl;
    p.howDidYouHear = formData.howDidYouHear;
    p.preferredMerchant = formData.preferredMerchant;
    p.projects = formData.projects;
    p.gasSafeRegistered = formData.gasSafeRegistered;
    p.gasSafeNumber = formData.gasSafeNumber;
    p.oftecRegistered = formData.oftecRegistered;
    p.oftecNumber = formData.oftecNumber;
    p.magnaCleanseOwned = formData.magnaCleanseOwned;
    p.magnaCleanseSerial = formData.magnaCleanseSerial;
    p.contract = formData.contract;
    p.preferredBoiler = formData.preferredBoiler;
    p.contactByPost = formData.contactByPost;
    p.contactByPhone = formData.contactByPhone;
    p.contactByEmail = formData.contactByEmail;
    p.dataProtectionConfirm = formData.dataProtectionConfirm;
    p.termsAndConditionsConfirm = formData.termsAndConditionsConfirm;
    p.photoData = formData.profilePhotoData;
    p.ignoreSerial = formData.ignoreSerial;
    p.userPreferencesUpdate = formData.userPreferencesUpdate;

    p.userPreferencesUpdate = userPreferenceUpdate;

    // If accreditations were specified then generate an array of objects each
    // containing the ID, key and active flag
    if (!fromGDPR) {
      if (accreditations)
        p.accreditations = accreditations.map((v: AccreditationItem): UserAccreditation => {
          return {
            id: v.id,
            key: v.title,
            active: formData.memberships[v.title],
          };
        });
      else
        p.accreditations = [];
    }

    p.optIns = optIns.map((v: OptInItem): UserOptIn => {
      return {
        id: v.id,
        offers_by_telephone: v.offers_by_telephone,
        offers_by_email: v.offers_by_email,
        offers_by_sms: v.offers_by_sms,
        information_by_email: v.information_by_email,
        information_by_sms: v.information_by_sms,
        information_by_telephone: v.information_by_telephone,
        add_to_find_an_installer: v.add_to_find_an_installer,
      };
    });

    return p;
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {UserProfile}
   */
  static fromAPIData(apiData: any): UserProfile {
    const dob = moment(apiData.dob);

    const p: UserProfile = new UserProfile();

    p.id = apiData.id;
    p.createdAt = apiData.created_at;
    p.updatedAt = apiData.updated_at;
    p.deletedAt = apiData.deleted_at;
    p.userType = apiData.role_id;
    p.title = apiData.title;
    p.firstName = apiData.first_name;
    p.lastName = apiData.last_name;
    p.dob = apiData.dob;
    p.dob_yyyy = dob.year();
    p.dob_mm = dob.month() + 1; // The moment month is zero indexed
    p.dob_dd = dob.date();
    p.address1 = apiData.address1;
    p.address2 = apiData.address2;
    p.town = apiData.town;
    p.county = apiData.county;
    p.postCode = apiData.postcode;
    p.countries_by_country_id = apiData.countries_by_country_id;
    p.country_id = apiData.country_id;
    p.email = apiData.email;
    p.mobile = apiData.mobile;
    p.telephone = apiData.telephone;
    p.companyName = apiData.companyname;
    p.companySize = apiData.companysize;
    p.photoUrl = apiData.photourl ? `${environment.files.baseUrl.replace(/\/+$/, '')}${apiData.photourl.toString()}` : null;
    p.howDidYouHear = apiData.howheard_id;
    p.preferredMerchant = apiData.preferred_merchant;
    p.projects = apiData.projects;
    p.gasSafeRegistered = apiData.gas_safe_registered;
    p.gasSafeNumber = apiData.gas_safe_number;
    p.oftecRegistered = apiData.oftec_registered;
    p.oftecNumber = apiData.oftec_number;
    p.magnaCleanseOwned = apiData.magnacleanse_owned;
    p.magnaCleanseSerial = apiData.magnacleanse_serialno;
    p.contract = apiData.receivecontractsupport_fromadey;
    p.preferredBoiler = apiData.preferred_boiler_manufacturer;
    p.contactByPost = apiData.contact_by_post;
    p.contactByPhone = apiData.contact_by_phone;
    p.contactByEmail = apiData.contact_by_email;
    p.dataProtectionConfirm = apiData.dataprotection_confirm;
    p.termsAndConditionsConfirm = apiData.agree_terms;

    p.pointsRewarded = apiData.points_rewarded;
    p.pointsRedeemed = apiData.points_redeemed;
    p.pointsCurrent = apiData.points_current;
    p.pointsClaimable = apiData.points_claimable;

    p.memberLevel = apiData.member_level;
    p.promoShopToken = apiData.promoshop_token;

    p.registered = apiData.registered;
    p.lastLogin = apiData.last_login;
    p.timesLoggedIn = apiData.times_logged_in;
    p.transferred = apiData.transferred;
    p.requestedDeletionAt = apiData.requested_deletion_at;

    if (apiData.user_opt_ins_by_user_id)
      p.optIns = apiData.user_opt_ins_by_user_id.map((v: any): UserOptIn => ({
        id: v.id.toString(),
        offers_by_email: v.offers_by_email,
        offers_by_sms: v.offers_by_sms,
        offers_by_telephone: v.offers_by_telephone,
        information_by_email: v.information_by_email,
        information_by_sms: v.information_by_sms,
        information_by_telephone: v.information_by_telephone,
        add_to_find_an_installer: v.add_to_find_an_installer,
      }));

    if (apiData.accreditation_by_accreditation_user)
      p.accreditations = apiData.accreditation_by_accreditation_user.map((v: any): UserAccreditation => ({
        id: v.id.toString(),
        key: v.title,
        active: true,
      }));

    return p;
  }

  /**
   * Converts the model to the API data format
   *
   * @param {UserProfile} appData
   * @return {object}
   */
  static toApiData(appData: UserProfile): any {

    const p: any = {
      role_id: appData.userType,
      title: appData.title,
      first_name: appData.firstName,
      last_name: appData.lastName,
      dob: appData.dob,
      address1: appData.address1,
      address2: appData.address2,
      town: appData.town,
      county: appData.county,
      postcode: appData.postCode,
      country_id: appData.country_id,
      email: appData.email,
      mobile: appData.mobile,
      telephone: appData.telephone,
      companyname: appData.companyName,
      companysize: appData.companySize,
      howheard_id: appData.howDidYouHear,
      preferred_merchant: appData.preferredMerchant,
      projects: appData.projects,
      gas_safe_registered: appData.gasSafeRegistered,
      gas_safe_number: appData.gasSafeNumber,
      oftec_registered: appData.oftecRegistered,
      oftec_number: appData.oftecNumber,
      magnacleanse_owned: appData.magnaCleanseOwned,
      magnacleanse_serialno: appData.magnaCleanseSerial,
      receivecontractsupport_fromadey: appData.contract,
      preferred_boiler_manufacturer: appData.preferredBoiler,
      contact_by_post: appData.contactByPost,
      contact_by_phone: appData.contactByPhone,
      contact_by_email: appData.contactByEmail,
      dataprotection_confirm: appData.dataProtectionConfirm,
      agree_terms: appData.termsAndConditionsConfirm,
      userPreferencesUpdate: appData.userPreferencesUpdate,
      points_claimable: appData.pointsClaimable,
    };

    // Has accreditations
    if (appData.accreditations) {
      p.accreditation_user_by_user_id = appData.accreditations.reduce((a: any, v: UserAccreditation): any => {
        a[parseInt(v.id, 10)] = v.active;
        return a;
      }, {});
    }

    // Has opt ins
    if (appData.optIns) {
      p.optIns = appData.optIns;
    }

    // If id exists in model then set id in the API data so that the API knows
    // to update the correct record
    if (appData.id)
      p.id = parseInt(appData.id, 10);

    // Set password only if a new password has been specified
    if (appData.password && appData.password.toString().trim().length > 0)
      p.password = appData.password;

    // Set photo_data only if new photo data exists
    if (appData.photoData)
      p.photo_data = appData.photoData;

    return p;
  }

  /**
   * Converts the model to the API data format (for initial registration)
   *
   * @param {UserProfile} appData
   * @return {object}
   */
  static toApiRegistrationData(appData: UserProfile): any {
    const p: any = UserProfile.toApiData(appData);

    // Accreditation data format is different when registering
    p.accreditation_user_by_user_id = appData.accreditations
      .filter((v: UserAccreditation): boolean => v.active)
      .map((v: UserAccreditation): any => ({
        accreditation_id: parseInt(v.id, 10),
      }));

    return p;
  }

  /**
   * Calculates the profile's completion percentage based on fields that have
   * been completed
   *
   * @param {UserProfile} profileData
   * @return {number}
   */
  static getProfileCompletePercentage(profileData: UserProfile): number {

    let currentCount: number = 0;

    const profileProperties: Array<any> = [
      'photoUrl',
      'title',
      'firstName',
      'lastName',
      'dob',
      'mobile',
      'email',
      'companyName',
      'companySize',
      'address1',
      'town',
      'county',
      'postCode',
      'country_id',
      'howDidYouHear',
      'projects',
      'preferredMerchant',
      'gasSafeRegistered',
      'gasSafeNumber',
      'oftecRegistered',
      'oftecNumber',
      'magnaCleanseSerial',
      'contract',
      'preferredBoiler',
      'accreditations'
    ];

    profileProperties.forEach(function (property) {
      if (profileData && profileData[property]) {
        currentCount++;
      }
    });

    return Math.ceil((currentCount / profileProperties.length) * 100);
  }

  /**
   * Returns an example model
   *
   * @return {UserProfile}
   */
  static getExample(): UserProfile {
    const titles: string[] = ['Mr'];
    const firstNamesM: string[] = ['Harry'];
    const firstNamesF: string[] = ['Harry'];
    const lastNames: string[] = ['Smith'];
    const userTypes: number[] = [0, 1];

    const title: string = 'Mr';
    const firstName: string = 'Harry';
    const lastName: string = 'Smith';
    const userType: number = userTypes[Math.floor(Math.random() * userTypes.length)];

    return {
      userType,
      title,
      firstName,
      lastName,
      createdAt: moment.utc().subtract(2, 'months').format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      deletedAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      dob: '1983-01-10',
      dob_yyyy: 1983,
      dob_mm: 1,
      dob_dd: 10,
      address1: 'Addr1',
      address2: 'Addr2',
      town: 'Town',
      county: 'County',
      postCode: 'TE123ST',
      countries_by_country_id: { 'country_code': 'GB', 'country_name': 'United Kingdom' },
      country_id: '2300',
      email: 'test@example.com',
      password: '',
      mobile: '07890 123456',
      telephone: '01234 567890',
      companyName: 'Test Company Ltd.',
      companySize: '2-5',
      howDidYouHear: 0,
      preferredMerchant: 'Merchant name',
      projects: 'Domestic',
      gasSafeRegistered: true,
      gasSafeNumber: 'GS123',
      oftecRegistered: true,
      oftecNumber: 'OFT123',
      magnaCleanseOwned: true,
      magnaCleanseSerial: 'MC123',
      contract: true,
      preferredboiler: 'Boilers \'r\' Us',
      contactByPost: false,
      contactByPhone: false,
      contactByEmail: true,
      dataProtectionConfirm: false,
      termsAndConditionsConfirm: false,
      pointsRewarded: '64',
      pointsRedeemed: '24',
      pointsCurrent: '40',
      pointsClaimable: true,
      photoUrl: '/assets/i/tmp/profile-photo.jpg',
      accreditations: [
        { id: '1', key: '', active: true },
        { id: '2', key: '', active: true },
        { id: '4', key: '', active: true },
      ],
      requestedDeletionAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    };
  }
}

/**
 * Encapsulates a simple user's profile information
 */
export class SimpleUserProfile {
  id?: string;

  // Timestamps (YYYY-MM-DD)
  createdAt?: string;
  updatedAt?: string;

  // DOB YYYY-MM-DD
  dob: string;

  // Role ID
  userType: number;

  // Contact details
  email: string;
  // Password: only set when the user is changing their password
  password: string;
  country_id: string;
  companyName: string;
  firstName: string;
  lastName: string;
  telephone: number;
  address1: string;
  address2?: string;
  town?: string;
  county?: string;
  postCode: string;

  // General questions and answers
  simpleMagnaCleanse?: boolean;

  // Flags to show if user has accepted terms
  dataProtectionConfirm?: boolean;
  termsAndConditionsConfirm?: boolean;

  // Auditing data
  lastLogin?: string;
  timesLoggedIn?: string;

  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;

  /**
   * Creates a model instance from form data
   *
   * @param {any}                 formData       Data from the form
   * @param {AccreditationItem[]} accreditations List of accreditations from form
   * @return {SimpleUserProfile}
   */
  static fromFormData(formData: any): SimpleUserProfile {
    const dob = `${formData.dob_yyyy}-${formData.dob_mm}-${formData.dob_dd}`;
    const p: SimpleUserProfile = new SimpleUserProfile();

    p.id = formData.id;
    p.userType = parseInt(formData.registerAs, 10);
    p.dob = dob;
    p.email = formData.emails.email;
    p.password = formData.password;
    p.country_id = formData.country;
    p.companyName = formData.companyName;
    p.firstName = formData.firstName;
    p.lastName = formData.lastName;
    p.telephone = formData.telephone;
    p.address1 = formData.address1;
    p.address2 = formData.address2;
    p.town = formData.town;
    p.county = formData.county;
    p.postCode = formData.postCode;
    p.simpleMagnaCleanse = formData.simpleMagnaCleanse;
    p.dataProtectionConfirm = formData.dataProtectionConfirm;
    p.termsAndConditionsConfirm = formData.termsAndConditionsConfirm;

    return p;
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {SimpleUserProfile}
   */
  static fromAPIData(apiData: any): SimpleUserProfile {
    const p: SimpleUserProfile = new SimpleUserProfile();

    p.id = apiData.id;
    p.createdAt = apiData.created_at;
    p.updatedAt = apiData.updated_at;
    p.userType = apiData.role_id;
    p.email = apiData.email;
    p.country_id = apiData.country_id;
    p.companyName = apiData.companyname;
    p.firstName = apiData.first_name;
    p.lastName = apiData.last_name;
    p.telephone = apiData.telephone;
    p.address1 = apiData.address1;
    p.address2 = apiData.address2;
    p.town = apiData.town;
    p.county = apiData.county;
    p.postCode = apiData.postcode;
    p.simpleMagnaCleanse = apiData.simpleMagnaCleanse;
    p.dataProtectionConfirm = apiData.dataprotection_confirm;
    p.termsAndConditionsConfirm = apiData.agree_terms;

    p.lastLogin = apiData.last_login;
    p.timesLoggedIn = apiData.times_logged_in;

    return p;
  }

  /**
   * Converts the model to the API data format
   *
   * @param {SimpleUserProfile} appData
   * @return {object}
   */
  static toApiData(appData: SimpleUserProfile): any {
    const p: any = {
      role_id: appData.userType,
      email: appData.email,
      country_id: appData.country_id,
      companyname: appData.companyName,
      first_name: appData.firstName,
      last_name: appData.lastName,
      telephone: appData.telephone,
      address1: appData.address1,
      address2: appData.address2,
      town: appData.town,
      county: appData.county,
      postcode: appData.postCode,
      simpleMagnaCleanse: appData.simpleMagnaCleanse,
      dataprotection_confirm: appData.dataProtectionConfirm,
      agree_terms: appData.termsAndConditionsConfirm,
      utm_source: appData.utm_source,
      utm_campaign: appData.utm_campaign,
      utm_medium: appData.utm_medium,
    };

    // If id exists in model then set id in the API data so that the API knows
    // to update the correct record
    if (appData.id)
      p.id = parseInt(appData.id, 10);

    // Set password only if a new password has been specified
    if (appData.password && appData.password.toString().trim().length > 0)
      p.password = appData.password;

    return p;
  }

  /**
   * Converts the model to the API data format (for initial registration)
   *
   * @param {SimpleUserProfile} appData
   * @return {object}
   */
  static toApiRegistrationData(appData: SimpleUserProfile): any {
    const p: any = SimpleUserProfile.toApiData(appData);
    return p;
  }

  /**
   * Calculates the profile's completion percentage based on fields that have
   * been completed
   *
   * @param {UserProfile} profileData
   * @return {number}
   */
  static getProfileCompletePercentage(profileData: UserProfile): number {

    let totalCount: number = 0;
    let currentCount: number = 0;
    if (profileData.userType) { currentCount++; }
    totalCount++;
    if (profileData.title) { currentCount++; }
    totalCount++;
    if (profileData.firstName) { currentCount++; }
    totalCount++;
    if (profileData.lastName) { currentCount++; }
    totalCount++;
    if (profileData.dob) { currentCount++; }
    totalCount++;
    if (profileData.address1) { currentCount++; }
    totalCount++;
    if (profileData.address2) { currentCount++; }
    totalCount++;
    if (profileData.town) { currentCount++; }
    totalCount++;
    if (profileData.county) { currentCount++; }
    totalCount++;
    if (profileData.postCode) { currentCount++; }
    totalCount++;
    if (profileData.email) { currentCount++; }
    totalCount++;
    if (profileData.password) { currentCount++; }
    totalCount++;
    if (profileData.mobile) { currentCount++; }
    totalCount++;
    if (profileData.telephone) { currentCount++; }
    totalCount++;
    if (profileData.companyName) { currentCount++; }
    totalCount++;
    if (profileData.companySize) { currentCount++; }
    totalCount++;
    if (profileData.howDidYouHear) { currentCount++; }
    totalCount++;
    //if(profileData.preferredMerchant) { currentCount++; }
    //totalCount ++;
    if (profileData.projects) { currentCount++; }
    totalCount++;
    if (profileData.gasSafeRegistered) {
      currentCount++;

      if (profileData.gasSafeNumber) { currentCount++; }
      totalCount++;
    }
    totalCount++;
    if (profileData.oftecRegistered) {
      currentCount++;

      if (profileData.oftecNumber) { currentCount++; }
      totalCount++;
    }
    totalCount++;
    if (profileData.magnaCleanseOwned) {
      currentCount++;

      if (profileData.magnaCleanseSerial) { currentCount++; }
      totalCount++;
    }
    totalCount++;
    if (profileData.contract) { currentCount++; }
    totalCount++;
    /*
    if (profileData.accreditations) { currentCount++; }
    totalCount ++;
    */
    if (profileData.preferredBoiler) { currentCount++; }
    totalCount++;
    if (profileData.contactByPost) { currentCount++; }
    totalCount++;
    if (profileData.contactByPhone) { currentCount++; }
    totalCount++;
    if (profileData.contactByEmail) { currentCount++; }
    totalCount++;
    if (profileData.dataProtectionConfirm) { currentCount++; }
    totalCount++;
    if (profileData.termsAndConditionsConfirm) { currentCount++; }
    totalCount++;
    if (profileData.photoUrl) { currentCount++; }
    totalCount++;

    return Math.ceil((currentCount / totalCount) * 100);
  }

  /**
   * Returns an example model
   *
   * @return {SimpleUserProfile}
   */
  static getExample(): UserProfile {
    const titles: string[] = ['Mr'];
    const firstNamesM: string[] = ['Harry'];
    const firstNamesF: string[] = ['Harry'];
    const lastNames: string[] = ['Smith'];
    const userTypes: number[] = [0, 1];

    const title: string = 'Mr';
    const firstName: string = 'Harry';
    const lastName: string = 'Smith';
    const userType: number = userTypes[Math.floor(Math.random() * userTypes.length)];

    return {
      userType,
      title,
      firstName,
      lastName,
      createdAt: moment.utc().subtract(2, 'months').format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      dob: '1983-01-10',
      dob_yyyy: 1983,
      dob_mm: 1,
      dob_dd: 19,
      address1: 'Addr1',
      address2: 'Addr2',
      town: 'Town',
      county: 'County',
      postCode: 'TE123ST',
      countries_by_country_id: { 'country_code': 'GB', 'country_name': 'United Kingdom' },
      country_id: '2300',
      email: 'test@example.com',
      password: '',
      mobile: '07890 123456',
      telephone: '01234 567890',
      companyName: 'Test Company Ltd.',
      companySize: '2-5',
      howDidYouHear: 0,
      preferredMerchant: 'Merchant name',
      projects: 'Domestic',
      gasSafeRegistered: true,
      gasSafeNumber: 'GS123',
      oftecRegistered: true,
      oftecNumber: 'OFT123',
      magnaCleanseOwned: true,
      magnaCleanseSerial: 'MC123',
      contract: true,
      preferredboiler: 'Boilers \'r\' Us',
      contactByPost: false,
      contactByPhone: false,
      contactByEmail: true,
      dataProtectionConfirm: false,
      termsAndConditionsConfirm: false,
      pointsRewarded: '64',
      pointsRedeemed: '24',
      pointsCurrent: '40',
      pointsClaimable: true,
      photoUrl: '/assets/i/tmp/profile-photo.jpg',
      accreditations: [
        { id: '1', key: '', active: true },
        { id: '2', key: '', active: true },
        { id: '4', key: '', active: true },
      ],
      requestedDeletionAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    };
  }
}

/**
 * Encapsulates a single accreditation that can be obtained by a user
 */
export class OptInItem {
  id: string;
  offers_by_email: string;
  offers_by_sms: string;
  offers_by_telephone: string;
  information_by_email: string;
  information_by_sms: string;
  information_by_telephone: string;
  add_to_find_an_installer: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {AccreditationItem}
   */
  static getFromApi(apiData: any): OptInItem {
    const a: OptInItem = new OptInItem();

    a.id = apiData.id;
    a.offers_by_email = apiData.offers_by_email;
    a.offers_by_sms = apiData.offers_by_sms;
    a.offers_by_telephone = apiData.offers_by_telephone;

    a.information_by_email = apiData.information_by_email;
    a.information_by_sms = apiData.information_by_sms;
    a.information_by_telephone = apiData.information_by_telephone;
    a.add_to_find_an_installer = apiData.add_to_find_an_installer;
    return a;
  }
}

/**
 * Encapsulates a single accreditation that can be obtained by a user
 */
export class AccreditationItem {
  id: string;
  title: string;
  image: string;
  checked: boolean;

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {AccreditationItem}
   */
  static getFromApi(apiData: any): AccreditationItem {
    const a: AccreditationItem = new AccreditationItem();

    a.id = apiData.id;
    a.title = apiData.title;
    a.image = apiData.image;
    a.checked = false;

    return a;
  }
}

export interface UpdateUserProfileRequest {
  profile: UserProfile;
}

export interface UpdateUserProfileResponse {
  error: string;
  profile: UserProfile;
}

export interface FetchAccreditationsResponse {
  error: string;
  accreditations: AccreditationItem[];
}

export interface FetchOptInsResponse {
  error: string;
  optIns: OptInItem[];
}
