import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-stats';
import { FetchAdminStatsResponse, FetchAdminStatsDownloadResponse, } from '../../models/admin-stats';
var AdminStatsEffects = /** @class */ (function () {
    function AdminStatsEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a FetchAdminStatsRequestAction, call AdminService::fetchStats() and
         * dispatch a new FetchAdminStatsResponseAction with the response.
         */
        this.fetchStats$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_STATS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchStats(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminStatsResponseAction(res);
            });
        });
        /*
          @Effect() fetchEvents$: Observable<Action> = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_EVENTS_REQUEST)
            .switchMap((req: ActionTypes.FetchAdminEventsRequestAction): Observable<ActionTypes.FetchAdminEventsResponseAction> =>
              this.service.fetchEvents(req.payload)
                .map((res: FetchAdminEventsResponse): ActionTypes.FetchAdminEventsResponseAction =>
                  new ActionTypes.FetchAdminEventsResponseAction(res)
                )
            );
        */
        /**
         * For a FetchAdminStatsDownloadRequestAction, call
         * AdminService::fetchStatsDownload() and dispatch a new
         * FetchAdminStatsDownloadResponseAction with the response.
         */
        this.fetchStatsDownload$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_STATS_DOWNLOAD_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchStatsDownload(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminStatsDownloadResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminStatsEffects.prototype, "fetchStats$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminStatsEffects.prototype, "fetchStatsDownload$", void 0);
    return AdminStatsEffects;
}());
export { AdminStatsEffects };
