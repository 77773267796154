<div class="col-xs-12" style="padding-left: 0;">
  <div class="panel-table ">
    <table id="tb-results" datatable [dtOptions]="dtOptions" class="row-border colCenter hover">
      <thead>
      <tr>
        <th *ngIf="!checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.all)" class="colCenter">Address</th>
        <th *ngIf="checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.all)" class="colCenter">Tester Name</th>
        <th *ngIf="checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.all) || checkTableType(tableTypes.waterTester)" class="colCenter">Company Name</th>
        <th *ngIf="checkTableType(tableTypes.normal) || checkTableType(tableTypes.due)" class="colCenter">Last Test Date</th>
        <th *ngIf="checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.all) || checkTableType(tableTypes.waterTester)" class="colCenter">Test Date</th>
        <th class="colCenter">Result</th>
        <th class="colCenter">
          <label class="row-data" for="select-all" >All
            <mat-checkbox id="select-all" [checked]="selectAll" (change)="checkCollective($event)" md-no-ink class="md-primary">
            </mat-checkbox>
          </label>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="row-data"  *ngFor="let result of results">

        <td *ngIf="checkTableType(tableTypes.all) || checkTableType(tableTypes.waterTester)" class="colLeft" (click)="goToResult(result.id)">{{ result.address }}</td>
        <td *ngIf="checkTableType(tableTypes.normal) || checkTableType(tableTypes.due)" class="colLeft" (click)="goToLocalResults(result.watertest_location_id)">{{ result.address }}</td>

        <td *ngIf="checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.all)" (click)="goToResult(result.id)">{{ result.tester_name }}</td>
        <td *ngIf="checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.all) || checkTableType(tableTypes.waterTester)" (click)="goToResult(result.id)">{{ result.company_name }}</td>

        <td *ngIf="checkTableType(tableTypes.byAddress) || checkTableType(tableTypes.waterTester) || checkTableType(tableTypes.all)" (click)="goToResult(result.id)">{{ dateFormat(result.test_timestamp) }}</td>
        <td *ngIf="checkTableType(tableTypes.normal) || checkTableType(tableTypes.due)" (click)="goToLocalResults(result.watertest_location_id)">{{ dateFormat(result.last_test) }}</td>
        <td *ngIf="checkTableType(tableTypes.all) || checkTableType(tableTypes.waterTester) || checkTableType(tableTypes.byAddress)" (click)="goToResult(result.id)" class="input-table"><img src="/assets/{{iconResult(result.pass)}}" /></td>
        <td *ngIf="checkTableType(tableTypes.normal) || checkTableType(tableTypes.due)" (click)="goToLocalResults(result.watertest_location_id)" class="input-table"><img src="/assets/{{ iconResult(result.last_test_id == result.last_pass_id) }}" /></td>
        <td class="input-table colCenter">
          <mat-checkbox *ngIf="checkTableType(tableTypes.all) || checkTableType(tableTypes.byAddress)" value="{{ result.id }}" [checked]="selectAll" (change)="checkIndividual($event)" md-no-ink class="md-primary">
          </mat-checkbox>
          <mat-checkbox *ngIf="checkTableType(tableTypes.normal) || checkTableType(tableTypes.waterTester) || checkTableType(tableTypes.due)" value="{{ result.last_test_id }}" [checked]="selectAll" (change)="checkIndividual($event)" md-no-ink class="md-primary">
          </mat-checkbox>
        </td>
      </tr>
      <tr *ngIf="results?.length == 0">
        <td colspan="5" class="no-data-available">No data!</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
