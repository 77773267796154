import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { EmailService } from '../../services/email.service';
import * as ActionTypes from '../actions/email';
var EmailEffects = /** @class */ (function () {
    function EmailEffects(emailService, actions$) {
        var _this = this;
        this.emailService = emailService;
        this.actions$ = actions$;
        /**
         * For a postContactUsRequestAction, call EmailService::postContactUsForm()
         * and dispatch a new PostContactUsResponseAction with the response.
         */
        this.postContactUsRequest$ = this.actions$
            .ofType(ActionTypes.POST_CONTACT_US_REQUEST)
            .switchMap(function (req) {
            return _this.emailService.postContactUsForm(req.payload)
                .map(function (res) { return new ActionTypes.PostContactUsResponseAction(res); });
        });
        /**
         * For a postVoucherRedeemRequestAction, call EmailService::postVoucherRedeemForm()
         * and dispatch a new PostContactUsResponseAction with the response.
         */
        this.postVoucherRedeemRequest$ = this.actions$
            .ofType(ActionTypes.POST_VOUCHER_REDEEM_REQUEST)
            .switchMap(function (req) {
            return _this.emailService.postVoucherRedeemForm(req.payload)
                .map(function (res) {
                if (res && res.callback) {
                    res.callback();
                }
                return new ActionTypes.PostVoucherRedeemResponseAction(res);
            });
        });
        /**
         * For a postDemokitRedeemRequestAction, call EmailService::postDemokitRedeemForm()
         * and dispatch a new PostDemokitResponseAction with the response.
         */
        this.postDemokitRedeemRequest$ = this.actions$
            .ofType(ActionTypes.POST_DEMOKIT_REDEEM_REQUEST)
            .switchMap(function (req) {
            return _this.emailService.postDemokitRedeemForm(req.payload)
                .map(function (res) {
                if (res && res.callback) {
                    res.callback();
                }
                return new ActionTypes.PostDemokitRedeemResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], EmailEffects.prototype, "postContactUsRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], EmailEffects.prototype, "postVoucherRedeemRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], EmailEffects.prototype, "postDemokitRedeemRequest$", void 0);
    return EmailEffects;
}());
export { EmailEffects };
