/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-selector-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../common/message-panel/message-panel.component.ngfactory";
import * as i3 from "../common/message-panel/message-panel.component";
import * as i4 from "@angular/common";
import * as i5 from "./product-selector/product-selector.component.ngfactory";
import * as i6 from "./product-selector/product-selector.component";
import * as i7 from "@angular/forms";
import * as i8 from "../common/page-content/page-content.component.ngfactory";
import * as i9 from "../common/page-content/page-content.component";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@ngrx/store";
import * as i12 from "./product-selector-container.component";
var styles_ProductSelectorContainerComponent = [i0.styles];
var RenderType_ProductSelectorContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductSelectorContainerComponent, data: {} });
export { RenderType_ProductSelectorContainerComponent as RenderType_ProductSelectorContainerComponent };
function View_ProductSelectorContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-message-panel", [["kind", "ERROR"]], null, null, null, i2.View_MessagePanelComponent_0, i2.RenderType_MessagePanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessagePanelComponent, [], { kind: [0, "kind"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "ERROR"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.error; _ck(_v, 2, 0, currVal_1); }); }
function View_ProductSelectorContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "product-selector-container background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductSelectorContainerComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-product-selector", [], null, [[null, "onQuery"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onQuery" === en)) {
        var pd_0 = (_co.performQuery($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ProductSelectorComponent_0, i5.RenderType_ProductSelectorComponent)), i1.ɵdid(6, 245760, null, 0, i6.ProductSelectorComponent, [i7.FormBuilder], { fetching: [0, "fetching"], results: [1, "results"] }, { onQuery: "onQuery" }), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.error; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.pending; var currVal_2 = _v.context.$implicit.results; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_ProductSelectorContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-title has-sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "white prod-selector"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Product selector"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-page-content", [["class", "color-white intro-above-content"], ["field", "intro"], ["page", "PRODUCT_SELECTOR"]], null, null, null, i8.View_PageContentComponent_0, i8.RenderType_PageContentComponent)), i1.ɵdid(11, 245760, null, 0, i9.PageContentComponent, [i10.DomSanitizer, i11.Store], { page: [0, "page"], field: [1, "field"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductSelectorContainerComponent_1)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "PRODUCT_SELECTOR"; var currVal_1 = "intro"; _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.state$)); _ck(_v, 14, 0, currVal_2); }, null); }
export function View_ProductSelectorContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-selector-container", [], null, null, null, View_ProductSelectorContainerComponent_0, RenderType_ProductSelectorContainerComponent)), i1.ɵdid(1, 114688, null, 0, i12.ProductSelectorContainerComponent, [i11.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductSelectorContainerComponentNgFactory = i1.ɵccf("app-product-selector-container", i12.ProductSelectorContainerComponent, View_ProductSelectorContainerComponent_Host_0, {}, {}, []);
export { ProductSelectorContainerComponentNgFactory as ProductSelectorContainerComponentNgFactory };
