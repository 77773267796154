<div class="container-fluid company-page">
  <div class="row content-page">
    <div class="content-watertest">
      <div class="container-title has-sub">
        <h1 class="white">{{companyName}}</h1>
      </div>
      <br>
      <div class="navigator-watertest-table">
        <button class="btn background-adey-red color-white btn-result" (click)="goToPage('/watertest/dashboard')">Back to dashboard</button>
        &nbsp;
        <button class="btn background-adey-red color-white" (click)="watertestBackResultsCompany()">Back to previous page</button>
      </div>
        <div class="container-title has-sub adjust-left">
        <h2 *ngIf="(type=='member')" class="white">Member {{memberName}} results</h2>
        <h2 *ngIf="(type=='address')" class="white">Postcode {{postCode}} results</h2>
      </div>
      <app-tableresults search="{{search}}">
        Loading table...
      </app-tableresults>
    </div>
  </div>
</div>

