import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { WatertestersService } from '../../services/watertesters.service';

import * as ActionTypes from '../actions/watertesters';

import {
  AdminTesterQueryResponse,
  CompanyTotalQueryResponse,
  GetTesterLocalQueryResponse,
  GetTesterQueryResponse,
  RemoveTesterQueryResponse,
  ResultEmailQueryResponse,
  TesterRoleQueryResponse,
  TesterTotalQueryResponse,
  TestExportQueryResponse,
  TestOverDueTotalQueryResponse,
  TestStatusPropertyTotalQueryResponse,
  TestTotalQueryResponse,
} from '../../models/watertester';

@Injectable()
export class WatertesterEffects {
  constructor(
    private service:  WatertestersService,
    private actions$: Actions
  ) {}

  /**
   * For a RemoveTesterQueryResponseAction, call service.removeTesterFromCompany() and
   * dispatch a new RemoveTesterQueryResponseAction with the response.
   */
  @Effect() removeTester$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REMOVE_TESTER_QUERY_REQUEST)
    .switchMap((req: ActionTypes.RemoveTesterQueryRequestAction): Observable<ActionTypes.RemoveTesterQueryResponseAction> =>
      this.service.removeTesterFromCompany(req.payload)
        .map((res: RemoveTesterQueryResponse): ActionTypes.RemoveTesterQueryResponseAction =>
          new ActionTypes.RemoveTesterQueryResponseAction(res))
    );

  /**
   * For a AdminTesterQueryResponseAction, call service.adminTesterCompany() and
   * dispatch a new AdminTesterQueryResponseAction with the response.
   */
  @Effect() adminTester$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADMIN_TESTER_QUERY_REQUEST)
    .switchMap((req: ActionTypes.AdminTesterQueryRequestAction): Observable<ActionTypes.AdminTesterQueryResponseAction> =>
      this.service.adminTesterCompany(req.payload)
        .map((res: AdminTesterQueryResponse): ActionTypes.AdminTesterQueryResponseAction =>
          new ActionTypes.AdminTesterQueryResponseAction(res))
    );

  /**
   * For a GetTesterQueryResponseAction, call service.getTester() and
   * dispatch a new GetTesterQueryResponseAction with the response.
   */
  @Effect() getTester$: Observable<Action> = this.actions$
    .ofType(ActionTypes.GET_TESTER_QUERY_REQUEST)
    .switchMap((req: ActionTypes.GetTesterQueryRequestAction): Observable<ActionTypes.GetTesterQueryResponseAction> =>
      this.service.getTester(req.payload)
        .map((res: GetTesterQueryResponse): ActionTypes.GetTesterQueryResponseAction =>
          new ActionTypes.GetTesterQueryResponseAction(res))
    );

  /**
   * For a GetTesterLocalQueryResponseAction, call service.getTesterLocal() and
   * dispatch a new GetTesterLocalQueryResponseAction with the response.
   */
  @Effect() getTesterLocal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.GET_TESTER_LOCAL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.GetTesterLocalQueryRequestAction): Observable<ActionTypes.GetTesterLocalQueryResponseAction> =>
      this.service.getTesterLocal(req.payload)
        .map((res: GetTesterLocalQueryResponse): ActionTypes.GetTesterLocalQueryResponseAction =>
          new ActionTypes.GetTesterLocalQueryResponseAction(res))
    );

  /**
   * For a ResultEmailQueryResponseAction, call service.sendResultEmail() and
   * dispatch a new ResultEmailQueryResponseAction with the response.
   */
  @Effect() sendResultEmail$: Observable<Action> = this.actions$
    .ofType(ActionTypes.RESULT_EMAIL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.ResultEmailQueryRequestAction): Observable<ActionTypes.ResultEmailQueryResponseAction> =>
      this.service.sendResultEmail(req.payload)
        .map((res: ResultEmailQueryResponse): ActionTypes.ResultEmailQueryResponseAction =>
          new ActionTypes.ResultEmailQueryResponseAction(res))
    );

  /**
   * For a TesterRoleQueryResponseAction, call service.getTesterRole() and
   * dispatch a new TesterRoleQueryResponseAction with the response.
   */
  @Effect() getTesterRole$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TESTER_ROLE_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TesterRoleQueryRequestAction): Observable<ActionTypes.TesterRoleQueryResponseAction> =>
      this.service.getTesterRole(req.payload)
        .map((res: TesterRoleQueryResponse): ActionTypes.TesterRoleQueryResponseAction =>
          new ActionTypes.TesterRoleQueryResponseAction(res))
    );

  /**
   * For a CompanyTotalQueryResponseAction, call service.getCompanyTotal() and
   * dispatch a new CompanyTotalQueryResponseAction with the response.
   */
  @Effect() getCompanyTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.COMPANY_TOTAL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.CompanyTotalQueryRequestAction): Observable<ActionTypes.CompanyTotalQueryResponseAction> =>
      this.service.getCompanyTotal()
        .map((res: CompanyTotalQueryResponse): ActionTypes.CompanyTotalQueryResponseAction =>
          new ActionTypes.CompanyTotalQueryResponseAction(res))
    );

  /**
   * For a TesterTotalQueryResponseAction, call service.getTesterTotal() and
   * dispatch a new TesterTotalQueryResponseAction with the response.
   */
  @Effect() getTesterTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TESTER_TOTAL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TesterTotalQueryRequestAction): Observable<ActionTypes.TesterTotalQueryResponseAction> =>
      this.service.getTesterTotal()
        .map((res: TesterTotalQueryResponse): ActionTypes.TesterTotalQueryResponseAction =>
          new ActionTypes.TesterTotalQueryResponseAction(res))
    );

  /**
   * For a TestTotalQueryResponseAction, call service.getTestTotal() and
   * dispatch a new TestTotalQueryResponseAction with the response.
   */
  @Effect() getTestTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TEST_TOTAL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TestTotalQueryRequestAction): Observable<ActionTypes.TestTotalQueryResponseAction> =>
      this.service.getTestTotal()
        .map((res: TestTotalQueryResponse): ActionTypes.TestTotalQueryResponseAction =>
          new ActionTypes.TestTotalQueryResponseAction(res))
    );


  /**
   * For a TestStatusPropertyTotalPassQueryResponseAction, call service.getTestStatusPropertyTotal(1) and
   * dispatch a new TestStatusPropertyTotalPassQueryResponseAction with the response.
   */
  @Effect() getTestStatusPropertyPassTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TestStatusPropertyTotalPassQueryRequestAction): Observable<ActionTypes.TestStatusPropertyTotalPassQueryResponseAction> =>
      this.service.getTestStatusPropertyTotal(1)
        .map((res: TestStatusPropertyTotalQueryResponse): ActionTypes.TestStatusPropertyTotalPassQueryResponseAction =>
          new ActionTypes.TestStatusPropertyTotalPassQueryResponseAction(res))
    );

  /**
   * For a TestStatusPropertyTotalFailQueryResponseAction, call service.getTestStatusPropertyTotal(0) and
   * dispatch a new TestStatusPropertyTotalFailQueryResponseAction with the response.
   */
  @Effect() getTestStatusPropertyFailTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TestStatusPropertyTotalFailQueryRequestAction): Observable<ActionTypes.TestStatusPropertyTotalFailQueryResponseAction> =>
      this.service.getTestStatusPropertyTotal(0)
        .map((res: TestStatusPropertyTotalQueryResponse): ActionTypes.TestStatusPropertyTotalFailQueryResponseAction =>
          new ActionTypes.TestStatusPropertyTotalFailQueryResponseAction(res))
    );

  /**
   * For a TestOverDueTotalQueryResponseAction, call service.getTestDueTotal(0) and
   * dispatch a new TestOverDueTotalQueryResponseAction with the response.
   */
  @Effect() getTestOverDueTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TEST_OVER_DUE_TOTAL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TestOverDueTotalQueryRequestAction): Observable<ActionTypes.TestOverDueTotalQueryResponseAction> =>
      this.service.getTestDueTotal(req.payload)
        .map((res: TestOverDueTotalQueryResponse): ActionTypes.TestOverDueTotalQueryResponseAction =>
          new ActionTypes.TestOverDueTotalQueryResponseAction(res))
    );


  /**
   * For a TestOverDueNextMonthTotalQueryResponseAction, call service.getTestDueTotal(0) and
   * dispatch a new TestOverDueNextMonthTotalQueryResponseAction with the response.
   */
  @Effect() getTestOverDueNextMonthTotal$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TestOverDueNextMonthTotalQueryRequestAction): Observable<ActionTypes.TestOverDueNextMonthTotalQueryResponseAction> =>
      this.service.getTestDueTotal(req.payload)
        .map((res: TestOverDueTotalQueryResponse): ActionTypes.TestOverDueNextMonthTotalQueryResponseAction =>
          new ActionTypes.TestOverDueNextMonthTotalQueryResponseAction(res))
    );

  /**
   * For a TestExportQueryRequestAction, call getExportData and
   * dispatch a new TestOverDueNextMonthTotalQueryResponseAction with the response.
   */
  @Effect() getExportData$: Observable<Action> = this.actions$
    .ofType(ActionTypes.TEST_EXPORT_QUERY_REQUEST)
    .switchMap((req: ActionTypes.TestExportQueryRequestAction): Observable<ActionTypes.TestExportQueryResponseAction> =>
      this.service.getExportData(req.payload)
        .map((res: TestExportQueryResponse): ActionTypes.TestExportQueryResponseAction =>
          new ActionTypes.TestExportQueryResponseAction(res))
    );


}
