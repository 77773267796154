/**
 Types for the table of results
 */

export enum TableTypes {
  byAddress,
  all,
  normal,
  waterTester,
  due
}
