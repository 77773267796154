import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { RegisterService } from '../../services/register.service';
import * as ActionTypes from '../actions/registration';
import { FetchUserRolesResponse, RegistrationResponse, } from '../../models/registration';
var RegistrationEffects = /** @class */ (function () {
    function RegistrationEffects(registerService, actions$) {
        var _this = this;
        this.registerService = registerService;
        this.actions$ = actions$;
        /**
         * For a FetchUserRolesRequestAction, call RegisterService::fetchUserRoles$()
         * and dispatch a new FetchUserRolesResponseAction with the response.
         */
        this.fetchUserRoles$ = this.actions$
            .ofType(ActionTypes.FETCH_USER_ROLES_REQUEST)
            .switchMap(function (req) {
            return _this.registerService.fetchUserRoles()
                .map(function (res) {
                return new ActionTypes.FetchUserRolesResponseAction(res);
            });
        });
        /**
         * For a RegistrationRequestAction, call RegisterService::registerNewUser()
         * and dispatch a new RegistrationResponseAction with the response.
         */
        this.registerRequest$ = this.actions$
            .ofType(ActionTypes.REGISTRATION_REQUEST)
            .switchMap(function (req) {
            return _this.registerService.registerNewUser(req.payload)
                .map(function (res) { return new ActionTypes.RegistrationResponseAction(res); });
        });
        /**
         * For a SimpleRegistrationRequestAction, call RegisterService::registerNewUser()
         * and dispatch a new RegistrationResponseAction with the response.
         */
        this.simpleRegisterRequest$ = this.actions$
            .ofType(ActionTypes.SIMPLE_REGISTRATION_REQUEST)
            .switchMap(function (req) {
            return _this.registerService.registerNewSimpleUser(req.payload)
                .map(function (res) { return new ActionTypes.RegistrationResponseAction(res); });
        });
        /**
         * For a RegistrationWaterTesterRequestAction, call RegisterService::registerNewWaterTesterUser()
         * and dispatch a new RegistrationWaterTesterResponseAction with the response.
         */
        this.registerWaterTesterRequest$ = this.actions$
            .ofType(ActionTypes.REGISTRATION_WATER_TESTER_REQUEST)
            .switchMap(function (req) {
            return _this.registerService.registerNewWaterTesterUser(req.payload)
                .map(function (res) { return new ActionTypes.RegistrationWaterTesterResponseAction(res); });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegistrationEffects.prototype, "fetchUserRoles$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegistrationEffects.prototype, "registerRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegistrationEffects.prototype, "simpleRegisterRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RegistrationEffects.prototype, "registerWaterTesterRequest$", void 0);
    return RegistrationEffects;
}());
export { RegistrationEffects };
