import { Injectable } from '@angular/core';

import { ApiService } from '../../services/api.service';

@Injectable()
export class ResultService {

  constructor(
      private apiService: ApiService
  ) { }

  public getResultById(id_result) {
    return this.apiService.apiGet('/watertest/result/'+id_result);
  }

  public getImageById(id_result) {
    return this.apiService.apiGetBlob('/watertest/result/'+id_result+'/image/sample.jpg');
  }

}
