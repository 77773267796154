import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
var FeatureService = /** @class */ (function () {
    function FeatureService(apiService) {
        this.apiService = apiService;
    }
    FeatureService.prototype.getUserFeatures = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.apiGet('/features').toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return FeatureService;
}());
export { FeatureService };
