import {Component, Inject, OnInit, ViewChild} from '@angular/core';
// import {WatertestersComponent} from '../../../watertest/components/watertesters/watertesters.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import { forwardRef } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {State as InvitationState} from '../../../state-management/reducers/invitation';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as invitationAction from '../../../state-management/actions/invitation';

@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {

  // // Reference to the child WatertestersComponent
  // @ViewChild(forwardRef(() => WatertestersComponent))
  // private  waterTester: WatertestersComponent;

  public message = '';
  public formSubmitted = false;
  public emailForm: FormGroup;
  public error_form = null;
  public emailAddress = '';
  public name = '';
  public buttonTextInvite = 'Send invite';
  public title = 'Invite member';

  //display info message every time a open a new modal
  public refreshDisplayMessage: boolean = false;

  //display text close cancel modal
  public closeModalText: string = 'Close';

  //Single watertester id role
  public roleSingleTesterId: number = 4;

  //Admin watertester id role
  public roleAdminTesterId: number = 5;


  // "invitation" from store
  public invitation$: Observable<InvitationState>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InviteModalComponent>,
    private dialog:    MatDialog,
    private _store: Store<any>,
    private formBuilder: FormBuilder,
  ) {
    this.invitation$ = this._store.select('ReviewInvitation');
  }

  ngOnInit() {
    this.refreshDisplayMessage = true;
    this.buildFormResult();
  }

  onFormSubmit() {

    this.error_form = false;
    this.formSubmitted = true;
    this.refreshDisplayMessage = false;
    if (this.emailForm.valid) {
      this.emailAddress = this.emailForm.value['emailAddress'];
      this.name = this.emailForm.value['name'];

      let roleId = this.roleSingleTesterId;

      if (this.emailForm.value['admin']) {
          roleId = this.roleAdminTesterId;
      }
      //console.log('company id dispatch: ' + this.data.companyId);
      this._store.dispatch(
        new invitationAction.SendInvitationQueryRequestAction(
          { name: this.name, email: this.emailAddress, hostedCompanyId: this.data.companyId, roleId: roleId }));
    }
    else {
      this.error_form = 'The form contains errors.';
    }
  }

  buildFormResult() {
    this.emailForm = this.formBuilder.group({
      emailAddress: ['', [Validators.email, Validators.required] ],
      name: ['', [Validators.required] ],
      admin: [ '', []]
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.emailForm.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.formSubmitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Close InviteModal
   *
   */
  CloseTesterModal() {
    this.dialogRef.close();
  }

}
