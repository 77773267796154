/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-optin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./optin/optin.component.ngfactory";
import * as i3 from "./optin/optin.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./user-optin.component";
var styles_UserOptinComponent = [i0.styles];
var RenderType_UserOptinComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserOptinComponent, data: {} });
export { RenderType_UserOptinComponent as RenderType_UserOptinComponent };
function View_UserOptinComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-optin", [], null, null, null, i2.View_OptinComponent_0, i2.RenderType_OptinComponent)), i1.ɵdid(3, 114688, null, 0, i3.OptinComponent, [i4.Store, i5.Router, i6.FormBuilder], { userProfile: [0, "userProfile"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.currentProfile; _ck(_v, 3, 0, currVal_0); }, null); }
function View_UserOptinComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid user-profile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserOptinComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.currentProfile; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_UserOptinComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserOptinComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loginDetails$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserOptinComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-optin", [], null, null, null, View_UserOptinComponent_0, RenderType_UserOptinComponent)), i1.ɵdid(1, 114688, null, 0, i8.UserOptinComponent, [i4.Store, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserOptinComponentNgFactory = i1.ɵccf("app-user-optin", i8.UserOptinComponent, View_UserOptinComponent_Host_0, {}, {}, []);
export { UserOptinComponentNgFactory as UserOptinComponentNgFactory };
