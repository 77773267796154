import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { WarrantyService } from '../../services/warranty.service';
import * as ActionTypes from '../actions/warranty-list';
import { FetchWarrantyResponse, FetchWarrantiesResponse, FetchWarrantyPdfResponse, UpdateWarrantyResponse, } from '../../models/warranty';
var WarrantiesEffects = /** @class */ (function () {
    function WarrantiesEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a FetchWarrantyRequestAction, call
         * WarrantyService::fetchWarranty() and dispatch a new
         * FetchWarrantyResponseAction with the response.
         */
        this.fetchWarrantyRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_WARRANTY_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchWarranty(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchWarrantyResponseAction(res);
            });
        });
        /**
         * For a FetchWarrantiesRequestAction, call
         * WarrantyService::fetchWarranties() and dispatch a new
         * FetchWarrantiesResponseAction with the response.
         */
        this.fetchWarrantiesRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_WARRANTIES_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchWarranties(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchWarrantiesResponseAction(res);
            });
        });
        /**
         * For a FetchWarrantyPdfRequestAction, call
         * WarrantyService::getWarrantyPDF() and dispatch a new
         * FetchWarrantyPdfResponseAction with the response.
         */
        this.fetchWarrantyPdfRequest$ = this.actions$
            .ofType(ActionTypes.FETCH_WARRANTY_PDF_REQUEST)
            .switchMap(function (req) {
            return _this.service.getWarrantyPDF(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchWarrantyPdfResponseAction(res);
            });
        });
        /**
         * For an UpdateWarrantyRequestAction, call WarrantyService::updateWarranty()
         * and dispatch a new UpdateWarrantyResponseAction with the response.
         */
        this.updateWarrantyRequest$ = this.actions$
            .ofType(ActionTypes.UPDATE_WARRANTY_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateWarranty(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateWarrantyResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WarrantiesEffects.prototype, "fetchWarrantyRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WarrantiesEffects.prototype, "fetchWarrantiesRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WarrantiesEffects.prototype, "fetchWarrantyPdfRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WarrantiesEffects.prototype, "updateWarrantyRequest$", void 0);
    return WarrantiesEffects;
}());
export { WarrantiesEffects };
