/**
 * Provides the SecuredImage directive
 *
 * Thanks largely to https://blog.strongbrew.io/safe-image-requests-in-angular
 *
 * TypeScript
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm: 13/11/2019, 13:27

import {Directive, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {ApiService} from '../../services/api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';

/**
 * Directive to serve images that require access through the API
 */
@Directive({
  selector: '[appSecuredSrc]',
})
export class SecuredImageDirective implements OnChanges, OnDestroy, OnInit {
  @Input() private appSecuredSrc: string;
  private src$ = new BehaviorSubject(this.appSecuredSrc);
  private apiService: ApiService;
  private domSanitizer: DomSanitizer;
  private el: ElementRef;

  destroying$: Subject<boolean> = new Subject<boolean>();
  private loader$: Observable<any>;

  /**
   * Set up the listener for changes to the src and get the image
   */
  ngOnInit(): void {
    this.loader$ = this.src$.switchMap(url => this.loadImage(url));

    this.loader$.pipe(takeUntil(this.destroying$)).subscribe(img => {
      this.el.nativeElement.src = img;
    });
  }

  /**
   * When the directive is destroyed, remove any listeners
   */
  ngOnDestroy(): void {
    this.destroying$.next();
  }

  /**
   * Listen for changes to the src
   */
  ngOnChanges(): void {
    this.src$.next(this.appSecuredSrc);
  }

  /**
   * Set up dependencies
   */
  constructor(injector: Injector) {
    this.apiService = injector.get(ApiService);
    this.el = injector.get(ElementRef);
  }

  /**
   * Call the API to get the image blob
   *
   * @param url
   */
  private loadImage(url: string): Observable<any> {
    return this.apiService.apiGetBlob(url).map(e => URL.createObjectURL(e));
  }
}
