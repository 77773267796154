import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


import { ServiceRecord } from '../../../models/service-record';

import { ServiceRecordComponent } from '../service-record/service-record.component';


/**
 * Summary
 *    Container modal for displaying ServiceRecordComponent
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-service-record-modal',
  templateUrl: './service-record-modal.component.html',
  styleUrls: ['./service-record-modal.component.scss']
})
export class ServiceRecordModalComponent implements OnInit {

  // Reference to the ServiceRecordComponent child component
  @ViewChild(ServiceRecordComponent) compDetails: ServiceRecordComponent;


  // If set, form will be read-only
  public viewMode: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ServiceRecordModalComponent>,
  ) { }

  ngOnInit() { }


  /**
   * Called via ServiceRecordComponent::onSave() with the updated ServiceRecord
   * model; closes the dialog with the updated model
   *
   * @param {ServiceRecord} sr
   */
  handleSubmitForm(sr: ServiceRecord) {
    this.dialogRef.close(sr);
  }

  /**
   * Called when "Save changes" is clicked: calls
   * ServiceRecordComponent::onSubmitForm()
   */
  onSaveClick() {
    this.compDetails.onSubmitForm();
  }
}
