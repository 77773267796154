import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-jobs';
import { AddAdminJobResponse, DeleteAdminJobResponse, FetchAdminJobsResponse, UpdateAdminJobResponse, AddAdminPromoCodeResponse, DeleteAdminPromoCodeResponse, FetchAdminPromoCodesResponse, UpdateAdminPromoCodeResponse, AddAdminWarrantyResponse, DeleteAdminWarrantyResponse, FetchAdminWarrantiesResponse, UpdateAdminWarrantyResponse, UpdateAdminCustomerResponse, UpdateRegisteredProductResponse, } from '../../models/admin-jobs';
import { Store } from '@ngrx/store';
import { FetchAdminWarrantiesRequestAction } from '../../state-management/actions/admin-jobs';
var AdminJobsEffects = /** @class */ (function () {
    function AdminJobsEffects(service, actions$, store) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        this.store = store;
        /**
         * For an AddAdminJobRequestAction, call AdminService::addJob() and dispatch
         * a new AddAdminJobResponseAction with the response.
         */
        this.addJob$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_JOB_REQUEST)
            .switchMap(function (req) {
            return _this.service.addJob(req.payload)
                .map(function (res) {
                _this.store.dispatch(new FetchAdminWarrantiesRequestAction({ type: 'WARRANTY', query: 'job_id=' + res.job.id, perPage: 50, pageNum: 1, upcoming: false }));
                return new ActionTypes.AddAdminJobResponseAction(res);
            });
        });
        /**
         * For a DeleteAdminJobRequestAction, call AdminService::deleteJob() and
         * dispatch a new DeleteAdminJobResponseAction with the response.
         */
        this.deleteJob$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_JOB_REQUEST)
            .switchMap(function (req) {
            return _this.service.deleteJob(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminJobResponseAction(res);
            });
        });
        /**
         * For a FetchAdminJobsRequestAction, call AdminService::fetchJobs() and
         * dispatch a new FetchAdminJobsResponseAction with the response.
         */
        this.fetchJobs$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_JOBS_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchJobs(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminJobsResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminJobRequestAction, call AdminService::updateJob() and
         * dispatch a new UpdateAdminJobResponseAction with the response.
         */
        this.updateJob$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_JOB_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateJob(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminJobResponseAction(res);
            });
        });
        /**
         * For an AddAdminPromoCodeRequestAction, call AdminService::addPromoCode()
         * and dispatch a new AddAdminPromoCodeResponseAction with the response.
         */
        this.addPromoCode$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_PROMO_CODE_REQUEST)
            .switchMap(function (req) {
            return _this.service.addPromoCode(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminPromoCodeResponseAction(res);
            });
        });
        /**
         * For a DeleteAdminPromoCodeRequestAction, call
         * AdminService::deletePromoCode() and dispatch a new
         * DeleteAdminPromoCodeResponseAction with the response.
         */
        this.deletePromoCode$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_PROMO_CODE_REQUEST)
            .switchMap(function (req) {
            return _this.service.deletePromoCode(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminPromoCodeResponseAction(res);
            });
        });
        /**
         * For a FetchAdminPromoCodesRequestAction, call
         * AdminService::fetchPromoCodes() and dispatch a new
         * FetchAdminPromoCodesResponseAction with the response.
         */
        this.fetchPromoCodes$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_PROMO_CODES_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchPromoCodes()
                .map(function (res) {
                return new ActionTypes.FetchAdminPromoCodesResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminPromoCodeRequestAction, call
         * AdminService::updatePromoCode() and dispatch a new
         * UpdateAdminPromoCodeResponseAction with the response.
         */
        this.updatePromoCode$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_PROMO_CODE_REQUEST)
            .switchMap(function (req) {
            return _this.service.updatePromoCode(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminPromoCodeResponseAction(res);
            });
        });
        /**
         * For an AddAdminWarrantyRequestAction, call AdminService::addWarranty() and
         * dispatch a new AddAdminWarrantyResponseAction with the response.
         */
        this.addWarranty$ = this.actions$
            .ofType(ActionTypes.ADD_ADMIN_WARRANTY_REQUEST)
            .switchMap(function (req) {
            return _this.service.addWarranty(req.payload)
                .map(function (res) {
                return new ActionTypes.AddAdminWarrantyResponseAction(res);
            });
        });
        /**
         * For a DeleteAdminWarrantyRequestAction, call
         * AdminService::deleteWarranty() and dispatch a new
         * DeleteAdminWarrantyResponseAction with the response.
         */
        this.deleteWarranty$ = this.actions$
            .ofType(ActionTypes.DELETE_ADMIN_WARRANTY_REQUEST)
            .switchMap(function (req) {
            return _this.service.deleteWarranty(req.payload)
                .map(function (res) {
                return new ActionTypes.DeleteAdminWarrantyResponseAction(res);
            });
        });
        /**
         * For a FetchAdminWarrantiesRequestAction, call
         * AdminService::fetchWarranties() and dispatch a new
         * FetchAdminWarrantiesResponseAction with the response.
         */
        this.fetchWarranties$ = this.actions$
            .ofType(ActionTypes.FETCH_ADMIN_WARRANTIES_REQUEST)
            .switchMap(function (req) {
            return _this.service.fetchWarranties(req.payload)
                .map(function (res) {
                return new ActionTypes.FetchAdminWarrantiesResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminWarrantyRequestAction, call
         * AdminService::updateWarranty() and dispatch a new
         * UpdateAdminWarrantyResponseAction with the response.
         */
        this.updateWarranty$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_WARRANTY_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateWarranty(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminWarrantyResponseAction(res);
            });
        });
        /**
         * For an UpdateAdminCustomerRequestAction, call
         * AdminService::updateCustomer() and dispatch a new
         * UpdateAdminCstomerResponseAction with the response.
         */
        this.updateCutomer$ = this.actions$
            .ofType(ActionTypes.UPDATE_ADMIN_CUSTOMER_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateCustomer(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateAdminCustomerResponseAction(res);
            });
        });
        /**
         * For an UpdateRegisteredProductRequestAction, call
         * AdminService::updateRegisteredProduct() and dispatch a new
         * UpdateRegisteredProductResponseAction with the response.
         */
        this.updateRegisteredProduct$ = this.actions$
            .ofType(ActionTypes.UPDATE_REGISTERED_PRODUCT_REQUEST)
            .switchMap(function (req) {
            return _this.service.updateRegisteredProduct(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdateRegisteredProductResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "addJob$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "deleteJob$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "fetchJobs$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "updateJob$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "addPromoCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "deletePromoCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "fetchPromoCodes$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "updatePromoCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "addWarranty$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "deleteWarranty$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "fetchWarranties$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "updateWarranty$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "updateCutomer$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AdminJobsEffects.prototype, "updateRegisteredProduct$", void 0);
    return AdminJobsEffects;
}());
export { AdminJobsEffects };
