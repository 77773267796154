import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BusinessTool, BusinessToolAPIResponse, BusinessToolResponse, BusinessToolVerificationToken, BusinessToolVerificationTokenAPIResponse, BusinessToolVerificationTokenRequest, BusinessToolVerificationTokenResponse } from '../models/business-tools';
import { ApiService } from './api.service';

@Injectable()
export class BusinessToolsService {
  /**
   * Inject the API service
   *
   * @param apiService Application's main API data connection
   */
  constructor(
    private apiService: ApiService,
  ) { }

  getBusinessTools(): Observable<BusinessToolResponse> {
    return this.apiService.apiGet('/business-tools')
      .map((res: BusinessToolAPIResponse[]): BusinessToolResponse => {
        return {
          error: null,
          data: res.map(BusinessTool.fromAPI),
          message: null,
        };
      })
      .catch((err: { message?: string }): Observable<BusinessToolResponse> =>
        Observable.of({
          error: true,
          data: null,
          message: err.message,
        })
      );
  }

  getBusinessToolVerificationToken(req: BusinessToolVerificationTokenRequest): Observable<BusinessToolVerificationTokenResponse> {
    return this.apiService.apiPost(`/business-tools/${req.tool}/token`, null)
      .map((res: BusinessToolVerificationTokenAPIResponse): BusinessToolVerificationTokenResponse => {
        return {
          error: null,
          data: BusinessToolVerificationToken.fromAPI(res),
          message: null,
        };
      })
      .catch((err: { message?: string }): Observable<BusinessToolVerificationTokenResponse> =>
        Observable.of({
          error: true,
          data: null,
          message: err.message,
        })
      );
  }
}
