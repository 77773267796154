/**
 * watertester reducer: maintains state
 */

import * as actions from '../actions/watertesters';


export interface State {

  // Pending flag and error/success message
  pendingList:          boolean;
  pendingEmail:         boolean;
  error:                 string;
  success:               string;
  message:               string;
  currentQuery:          string;
  results?:                 any;
  isWaterTesterSingle?: boolean;
  isWaterTesterAdmin?:  boolean;

  pendingTotalTester?:  boolean;
  totalTester?:          number;
  pendingTotalCompany?: boolean;
  totalCompany?:         number;

  pendingTestTotal?:    boolean;
  totalTest?:            number;
  pendingTestStatusPropertyTotalPass:  boolean;
  testStatusPropertyTotalPass:          number;
  pendingTestStatusPropertyTotalFail:  boolean;
  testStatusPropertyTotalFail:          number;
  pendingTestOverDueTotal?:    boolean;
  pendingTestOverDueNextMonthTotal?:    boolean;
  totalTestOverDue:          number;
  totalTestOverDueNextMonth: number;

  titleFilter?: string;
  exportData?: any;
}


const defaultState: State = {
  pendingList:         false,
  pendingEmail:        false,
  error:               null,
  success:             null,
  message:             null,
  currentQuery:        null,
  results:             null,
  isWaterTesterSingle: false,
  isWaterTesterAdmin:  false,

  pendingTotalTester:  false,
  totalTester:             0,
  pendingTotalCompany:  false,
  totalCompany:            0,

  pendingTestTotal:    false,
  totalTest:               0,
  pendingTestStatusPropertyTotalPass: false,
  testStatusPropertyTotalPass:            0,
  pendingTestStatusPropertyTotalFail: false,
  testStatusPropertyTotalFail:            0,
  pendingTestOverDueTotal: false,
  pendingTestOverDueNextMonthTotal: false,
  totalTestOverDue:               0,
  totalTestOverDueNextMonth:      0,

  titleFilter: null,
  exportData: null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request Remove tester
     */
    case actions.REMOVE_TESTER_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingList:  true,
        error:        null,
        message:      'Remove member request...',
        currentQuery: action.payload,
      });

    /**
     * Response Remove tester
     */
    case actions.REMOVE_TESTER_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingList:  false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        results:      action.payload.results,
      });

    /**
     * Request Admin tester
     */
    case actions.ADMIN_TESTER_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingList:  true,
        error:        null,
        message:      'Changing role member request...',
        currentQuery: action.payload,
      });

    /**
     * Response Admin tester
     */
    case actions.ADMIN_TESTER_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingList:  false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        results:      action.payload.results,
      });

    /**
     * Request Get tester
     */
    case actions.GET_TESTER_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingList:  true,
        error:        null,
        message:      'Get member request...',
        currentQuery: action.payload,
      });

    /**
     * Response Get tester
     */
    case actions.GET_TESTER_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingList:  false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        results:      action.payload.results,
      });

    /**
     * Request Get tester location
     */
    case actions.GET_TESTER_LOCAL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingList:  true,
        error:        null,
        message:      'Get location test request...',
        currentQuery: action.payload,
      });

    /**
     * Response Get tester location
     */
    case actions.GET_TESTER_LOCAL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingList:      false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        results:      action.payload.results,
      });

    /**
     * Request Result email
     */
    case actions.RESULT_EMAIL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingEmail:  true,
        error:        null,
        message:      'Result email request...',
        currentQuery: action.payload,
      });

    /**
     * Response Result email
     */
    case actions.RESULT_EMAIL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingEmail:  false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
      });

    /**
     * Request Get tester role
     */
    case actions.TESTER_ROLE_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingList:  true,
        error:        null,
        message:      'Get tester role request...',
        currentQuery: action.payload,
      });

    /**
     * Response Get tester Role
     */
    case actions.TESTER_ROLE_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingList:      false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        isWaterTesterSingle: action.payload.isWaterTesterSingle,
        isWaterTesterAdmin: action.payload.isWaterTesterAdmin,
      });

    /**
     * Request Get company total
     */
    case actions.COMPANY_TOTAL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTotalCompany:      true,
        error:        null,
        message:      'Get company total request...',
      });

    /**
     * Response Get company Total
     */
    case actions.COMPANY_TOTAL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTotalCompany: false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        totalCompany:  action.payload.totalCompany,
      });


    /**
     * Request Get tester total
     */
    case actions.TESTER_TOTAL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTotalTester:      true,
        error:        null,
        message:      'Get tester total request...',
      });

    /**
     * Response Get tester Total
     */
    case actions.TESTER_TOTAL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTotalTester: false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        totalTester:  action.payload.totalTester,
      });


    /**
     * Request Get test total
     */
    case actions.TEST_TOTAL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTestTotal:      true,
        error:        null,
        message:      'Get test total request...',
      });

    /**
     * Response Get tester Total
     */
    case actions.TEST_TOTAL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTestTotal:      false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        testTotal:  action.payload.testTotal,
      });


    /**
     * Request Get test status property total by pass
     */
    case actions.TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTestStatusPropertyTotalPass:      true,
        error:        null,
        message:      'Get test status property total request...',
      });

    /**
     * Response Get test status property total by pass
     */
    case actions.TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTestStatusPropertyTotalPass:      false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        testStatusPropertyTotalPass:  action.payload.testStatusPropertyTotal,
      });

    /**
     * Request Get test status property total by fail
     */
    case actions.TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTestStatusPropertyTotalFail:      true,
        error:        null,
        message:      'Get test status property total request...',
      });

    /**
     * Response Get test status property total by fail
     */
    case actions.TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTestStatusPropertyTotalFail:      false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        testStatusPropertyTotalFail:  action.payload.testStatusPropertyTotal,
      });

    /**
     * Request Get test over due total
     */
    case actions.TEST_OVER_DUE_TOTAL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTestOverDueTotal:      true,
        error:        null,
        message:      'Get test over due total request...',
      });

    /**
     * Response Get test over due Total
     */
    case actions.TEST_OVER_DUE_TOTAL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTestOverDueTotal: false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        totalTestOverDue:  action.payload.testOverDueTotal,
      });

    /**
     * Request Get test over due next month total
     */
    case actions.TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_REQUEST:
      return Object.assign({}, state, {
        pendingTestOverDueNextMonthTotal:      true,
        error:        null,
        message:      'Get test over due total request...',
      });

    /**
     * Response Get test over due next month Total
     */
    case actions.TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pendingTestOverDueNextMonthTotal: false,
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        totalTestOverDueNextMonth:  action.payload.testOverDueTotal,
      });

    /**
     * Request export test
     */
    case actions.TEST_EXPORT_QUERY_REQUEST:
      return Object.assign({}, state, {
        error:        null,
        message:      'Export test request...',
      });

    /**
     * Response export test
     */
    case actions.TEST_EXPORT_QUERY_RESPONSE:
      return Object.assign({}, state, {
        success:      action.payload.valid,
        error:        !action.payload.valid,
        message:      action.payload.message,
        exportData:  action.payload.data,
      });

    /**
     * Set title filter
     */
    case actions.SET_TITLE_FILTER_REQUEST:
      return Object.assign({}, state, {
        titleFilter: action.payload.title
      });

    /**
     * Clear export data
     */
    case actions.TEST_EXPORT_CLEAR_REQUEST:
      return Object.assign({}, state, {
        exportData: null
      });

    default:
      return state;
  }
}
