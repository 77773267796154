import { Component, OnInit} from '@angular/core';
import { CompanyListService } from '../../services/companylist.service';
import { Router } from '@angular/router';
import {Company} from '../../models/company';

@Component({
  selector: 'app-companylist',
  templateUrl: './companylist.component.html',
  styleUrls: ['./companylist.component.scss']
})

export class CompanyListComponent implements OnInit {

  dtOptions: DataTables.Settings = {
    dom: 'Rlfrtip'
  };
  companies: Company[];
  pageLength = 10;


  constructor(
      private companyListService: CompanyListService,
      private router: Router
  ) {
  }

  ngOnInit(): void {

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.companyListService.getCompanies(dataTablesParameters).subscribe(resp => {
          //console.log(resp);
          that.companies = resp['data'];

          callback({
            recordsTotal: resp['paging']['results'],
            recordsFiltered: resp['paging']['results'],
            data: []
          });
        });
      },
      columns: [{data: 'id'}, { data: 'name' }, { data: 'address_line_1' }],
      columnDefs: [{ 'searchable': false, 'targets': 0 }],
      language: {
        'zeroRecords': ''
      }
    };

  }

  /**
   * Navigates to the /watertest/company/create route
   */
  regWatertestCompany() {
    this.router.navigate(['/watertest/company/create']);
  }

  /**
   * Navigates to the /watertest/company/:id route
   */
  goToCompany(id) {
    this.router.navigate(['/watertest/company/' + id]);
  }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }

}
