/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./business-tools-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./business-tools-widget.component";
import * as i3 from "@angular/router";
var styles_BusinessToolsWidgetComponent = [i0.styles];
var RenderType_BusinessToolsWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BusinessToolsWidgetComponent, data: {} });
export { RenderType_BusinessToolsWidgetComponent as RenderType_BusinessToolsWidgetComponent };
export function View_BusinessToolsWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "widget-body border-box border-lighter-gray business-tools-widget background-white col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "widget-button-container col-sm-7 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn background-green full-width no-radius color-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/business-tools") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Find\n        out more"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_BusinessToolsWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-business-tools-widget", [], null, null, null, View_BusinessToolsWidgetComponent_0, RenderType_BusinessToolsWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i2.BusinessToolsWidgetComponent, [i3.Router], null, null)], null, null); }
var BusinessToolsWidgetComponentNgFactory = i1.ɵccf("app-business-tools-widget", i2.BusinessToolsWidgetComponent, View_BusinessToolsWidgetComponent_Host_0, {}, {}, []);
export { BusinessToolsWidgetComponentNgFactory as BusinessToolsWidgetComponentNgFactory };
