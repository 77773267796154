/**
 * Provides AdminPro3NoteAlertComponent
 *
 * @author        Adam Selley
 * @copyright    2016-2019 System 15 Limited
 */
import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import {AdminDevice} from '../../../../models/admin-pro3';

/**
 * Component to show an alert for saving a note against a device *
 */
@Component({
  selector: 'app-admin-pro3-note-alert',
  templateUrl: './admin-pro3-note-alert.component.html',
  styleUrls: ['./admin-pro3-note-alert.component.scss']
})
export class AdminPro3NoteAlertComponent implements OnInit {

  //Modal for saving a note that has attempted to be discarded
  private dialog: MatDialog;

  //The note content that was previously entered.
  noteContent = '';

  //The device that the note was meant to be saved against.
  device: AdminDevice;

  constructor(
    injector: Injector
  ) {
    //Retrieve the note content and the device from the parent modal.
    this.noteContent = <string>injector.get(MAT_DIALOG_DATA)['noteContent'];
    this.device = <AdminDevice>injector.get(MAT_DIALOG_DATA)['device'];
  }

  ngOnInit() {
  }

}
