import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';

import * as ActionTypes from '../actions/admin-events';

import {
  AddAdminEventRequest,
  AddAdminEventResponse,
  AddAdminEventTypeRequest,
  AddAdminEventTypeResponse,
  DeleteAdminEventRequest,
  DeleteAdminEventResponse,
  DeleteAdminEventTypeRequest,
  DeleteAdminEventTypeResponse,
  FetchAdminEventsRequest,
  FetchAdminEventsResponse,
  UpdateAdminEventRequest,
  UpdateAdminEventResponse,
  UpdateAdminEventTypeRequest,
  UpdateAdminEventTypeResponse,
} from '../../models/admin-events';

@Injectable()
export class AdminEventsEffects {
  constructor(
    private service:  AdminService,
    private actions$: Actions
  ) {}

  /**
   * For an AddAdminEventRequestAction, call AdminService::addEvent() and
   * dispatch a new AddAdminEventResponseAction with the response.
   */
  @Effect() addEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_EVENT_REQUEST)
    .switchMap((req: ActionTypes.AddAdminEventRequestAction): Observable<ActionTypes.AddAdminEventResponseAction> =>
      this.service.addEvent(req.payload)
        .map((res: AddAdminEventResponse): ActionTypes.AddAdminEventResponseAction =>
          new ActionTypes.AddAdminEventResponseAction(res)
        )
    );

  /**
   * For an AddAdminEventTypeRequestAction, call AdminService::addEventType()
   * and dispatch a new AddAdminEventTypeResponseAction with the response.
   */
  @Effect() addEventType$: Observable<Action> = this.actions$
    .ofType(ActionTypes.ADD_ADMIN_EVENT_TYPE_REQUEST)
    .switchMap((req: ActionTypes.AddAdminEventTypeRequestAction): Observable<ActionTypes.AddAdminEventTypeResponseAction> =>
      this.service.addEventType(req.payload)
        .map((res: AddAdminEventTypeResponse): ActionTypes.AddAdminEventTypeResponseAction =>
          new ActionTypes.AddAdminEventTypeResponseAction(res)
        )
    );

  /**
   * For a DeleteAdminEventRequestAction, call AdminService::deleteEvent() and
   * dispatch a new DeleteAdminEventResponseAction with the response.
   */
  @Effect() deleteEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_EVENT_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminEventRequestAction): Observable<ActionTypes.DeleteAdminEventResponseAction> =>
      this.service.deleteEvent(req.payload)
        .map((res: DeleteAdminEventResponse): ActionTypes.DeleteAdminEventResponseAction =>
          new ActionTypes.DeleteAdminEventResponseAction(res)
        )
    );

  /**
   * For a DeleteAdminEventTypeRequestAction, call
   * AdminService::deleteEventType() and dispatch a new
   * DeleteAdminEventTypeResponseAction with the response.
   */
  @Effect() deleteEventType$: Observable<Action> = this.actions$
    .ofType(ActionTypes.DELETE_ADMIN_EVENT_TYPE_REQUEST)
    .switchMap((req: ActionTypes.DeleteAdminEventTypeRequestAction): Observable<ActionTypes.DeleteAdminEventTypeResponseAction> =>
      this.service.deleteEventType(req.payload)
        .map((res: DeleteAdminEventTypeResponse): ActionTypes.DeleteAdminEventTypeResponseAction =>
          new ActionTypes.DeleteAdminEventTypeResponseAction(res)
        )
    );

  /**
   * For a FetchAdminEventsRequestAction, call AdminService::fetchEvents() and
   * dispatch a new FetchAdminEventsResponseAction with the response.
   */
  @Effect() fetchEvents$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_ADMIN_EVENTS_REQUEST)
    .switchMap((req: ActionTypes.FetchAdminEventsRequestAction): Observable<ActionTypes.FetchAdminEventsResponseAction> =>
      this.service.fetchEvents(req.payload)
        .map((res: FetchAdminEventsResponse): ActionTypes.FetchAdminEventsResponseAction =>
          new ActionTypes.FetchAdminEventsResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminEventRequestAction, call AdminService::updateEvent() and
   * dispatch a new UpdateAdminEventResponseAction with the response.
   */
  @Effect() updateEvent$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_EVENT_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminEventRequestAction): Observable<ActionTypes.UpdateAdminEventResponseAction> =>
      this.service.updateEvent(req.payload)
        .map((res: UpdateAdminEventResponse): ActionTypes.UpdateAdminEventResponseAction =>
          new ActionTypes.UpdateAdminEventResponseAction(res)
        )
    );

  /**
   * For an UpdateAdminEventTypeRequestAction, call
   * AdminService::updateEventType() and dispatch a new
   * UpdateAdminEventTypeResponseAction with the response.
   */
  @Effect() updateEventType$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_ADMIN_EVENT_TYPE_REQUEST)
    .switchMap((req: ActionTypes.UpdateAdminEventTypeRequestAction): Observable<ActionTypes.UpdateAdminEventTypeResponseAction> =>
      this.service.updateEventType(req.payload)
        .map((res: UpdateAdminEventTypeResponse): ActionTypes.UpdateAdminEventTypeResponseAction =>
          new ActionTypes.UpdateAdminEventTypeResponseAction(res)
        )
    );
}
