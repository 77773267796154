import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { JobService } from '../../services/job.service';

import * as ActionTypes from '../actions/register-job';

import {
  RegisterJobResponse,
  FetchJobResponse,
  FetchJobPdfResponse,
  UpdateJobResponse,
} from '../../models/job';

@Injectable()
export class RegisterJobEffects {
  constructor(
    private service: JobService,
    private actions$: Actions
  ) { }

  /**
   * For a RegisterJobRequestAction, call
   * JobService::submit() and dispatch a new
   * RegisterJobResponseAction with the response.
   */
  @Effect() registerJobRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REGISTER_JOB_REQUEST)
    .switchMap((req: ActionTypes.RegisterJobRequestAction): Observable<Action> => {
      return this.service.submitJob(req.payload)
        .map((res: RegisterJobResponse): ActionTypes.RegisterJobResponseAction =>
          new ActionTypes.RegisterJobResponseAction(res)
        );
    });

  /**
   * For a FetchJobRequestAction, call
   * JobService::getJobData() and dispatch a new
   * FetchJobResponseAction with the response.
   */
  @Effect() fetchJobRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_JOB_REQUEST)
    .switchMap((req: ActionTypes.FetchJobRequestAction): Observable<Action> => {
      return this.service.getJobData(req.payload)
        .map((res: FetchJobResponse): ActionTypes.FetchJobResponseAction =>
          new ActionTypes.FetchJobResponseAction(res)
        );
    });

  /**
   * For a FetchJobPdfRequestAction, call JobService::getJobPDF() and dispatch
   * a new FetchJobPdfResponseAction with the response.
   */
  @Effect() fetchJobPdfRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_JOB_PDF_REQUEST)
    .switchMap((req: ActionTypes.FetchJobPdfRequestAction): Observable<ActionTypes.FetchJobPdfResponseAction> => {
      return this.service.getJobPDF(req.payload)
        .map((res: FetchJobPdfResponse): ActionTypes.FetchJobPdfResponseAction =>
          new ActionTypes.FetchJobPdfResponseAction(res)
        );
    });

  /**
   * For a UpdateJobRequestAction, call
   * JobService::submit() and dispatch a new
   * UpdateJobResponseAction with the response.
   */
  @Effect() updateJobRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.UPDATE_JOB_REQUEST)
    .switchMap((req: ActionTypes.UpdateJobRequestAction): Observable<Action> => {
      return this.service.updateJob(req.payload)
        .map((res: UpdateJobResponse): ActionTypes.UpdateJobResponseAction =>
          new ActionTypes.UpdateJobResponseAction(res)
        );
    });
}
