import { Injectable } from '@angular/core';

@Injectable()
export class DatatableService {

  constructor() { }

  public joinParameters(dataTablesParameters, AndFilter = null) {
    let query = '';
    let and_filter = '';
    let and_filter_end = '';
    const search = dataTablesParameters['search']['value'];
    const columns = dataTablesParameters['columns'];
    const order = dataTablesParameters['order'];
    const length = dataTablesParameters['length'];
    const start = dataTablesParameters['start'];

    if (start > 0) {
      query += '?page=' + ((start / length) + 1);
    } else {
      query += '?page=1';
    }

    if (length > 0) {
      query += '&pagesize=' + length;
    } else {
      query += '&pagesize=10';
    }

    if (columns.length) {

      //order by columns
      if (order.length) {
        query += '&orderby=' + columns[order[0]['column']]['data'] + ' ' + order[0]['dir'];
      }

      if (AndFilter) {
        and_filter = '(' + AndFilter;
        and_filter_end = ')';
      }
      //search by columns
      if (AndFilter && !search.length) {
        query += '&filter=' + and_filter + ')';
      }

      if (AndFilter && search.length) {
        query += '&filter=' + and_filter + ' and (';
      }

      if (!AndFilter && search.length) {
        query += '&filter=(';
      }

      if (!AndFilter && !search.length) {
        query += '';
      }

      if (search.length) {
        let or = '';
        columns.forEach(function (element) {
          if (element['searchable']) {
            query += or + '(' + element['data'] + ' like %' + search.trim() + '%)';
            if (or === '') {
              or = ' or ';
            }
          }
        });
        query += ')' + and_filter_end;
      }
    }

    return query;
  }
}
