/**
 * Watertest Module
 *
 * TypeScript
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DatatableService} from './services/datatable.service';
import {DataTablesModule} from 'angular-datatables';
import {watertestRouting} from './watertest-routing.module';
import {ResultListAdminComponent} from './components/resultlistadmin/resultlistadmin.component';
import {CompanyAddMemberComponent} from './components/companyaddmember/companyaddmember.component';
import {CompanyMemberComponent} from './components/companymember/companymember.component';
import {CompanyInviteMemberComponent} from './components/companyinvitemember/companyinvitemember.component';
import {WatertestersComponent} from './components/watertesters/watertesters.component';
import {ResultCompanyListComponent} from './components/resultcompanylist/resultcompanylist.component';
import {CompanyListComponent} from './components/companylist/companylist.component';
import {TableResultsComponent} from './components/tableresults/tableresults.component';
import {ResultComponent} from './components/result/result.component';
import {CompanyComponent} from './components/company/company.component';
import {SendEmailComponent} from './components/sendemail/sendemail.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {CompanyListService} from './services/companylist.service';
import {ApiService} from '../services/api.service';
import {CompanyService} from './services/company.service';
import {CompanyMemberService} from './services/companymember.service';
import {SharedModule} from '../shared/shared.module';
import {ResultCompanyListService} from './services/resultcompanylist.service';
import {TableResultsService} from './services/tableresults.service';
import {CheckBoxListService} from './services/checkboxlist.service';
import {ReactiveFormsModule} from '@angular/forms';
import {DashboardService} from './services/dashboard.service';
import {CompanyAddMemberService} from './services/companyaddmember.service';
import {CompanyInviteMemberService} from './services/companyinvitemember.service';
import {ResultService} from './services/result.service';
import {AgmCoreModule} from '@agm/core';
import {ResultListAdminService} from './services/resultlistadmin.service';
import {WatertestersService} from './services/watertesters.service';
import { InvitationAcceptComponent } from './components/invitation-accept/invitation-accept.component';
import {MatCheckboxModule} from '@angular/material';
import {RemoveTesterModalComponent} from './components/remove-tester-modal/remove-tester-modal.component';
import { AdminTesterModalComponent } from './components/admin-tester-modal/admin-tester-modal.component';
import { ResultEmailComponent } from './components/result-email/result-email.component';
import { ProcheckRedirectComponent } from './components/procheck-redirect/procheck-redirect.component';

@NgModule({
  imports: [
    AgmCoreModule,

    CommonModule,
    SharedModule,
    DataTablesModule,
    watertestRouting,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  providers: [
    CheckBoxListService,
    CompanyAddMemberService,
    CompanyInviteMemberService,
    CompanyListService,
    CompanyMemberService,
    CompanyService,
    DashboardService,
    ResultCompanyListService,
    ResultListAdminService,
    ResultService,
    TableResultsService,
    WatertestersService,
    DatatableService,
    ApiService,
  ],
  declarations: [
    CompanyComponent,
    CompanyMemberComponent,
    CompanyListComponent,
    CompanyAddMemberComponent,
    ResultCompanyListComponent,
    ResultComponent,
    CompanyInviteMemberComponent,
    DashboardComponent,
    ResultListAdminComponent,
    SendEmailComponent,
    TableResultsComponent,
    WatertestersComponent,
    InvitationAcceptComponent,
    RemoveTesterModalComponent,
    AdminTesterModalComponent,
    ResultEmailComponent,
    ProcheckRedirectComponent,
  ],

  entryComponents: [

    // All modal components
    RemoveTesterModalComponent,
    AdminTesterModalComponent,
    ResultEmailComponent,
  ],
})
export class WatertestModule { }
