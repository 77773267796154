import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import * as moment from 'moment';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ServiceRecord, UpdateServiceRecordRequest } from '../../../models/service-record';
import { JobEditModalComponent } from '../../job/job-edit-modal/job-edit-modal.component';
import { WarrantyEditModalComponent } from '../../warranty/warranty-edit-modal/warranty-edit-modal.component';

/**
 * Summary
 *    Displays the user's upcoming services within a widget.
 *    
 *
 * Description
 *    Displays a list of upcoming services. The user can add, edit and view existing services from this widget.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-service-widget',
  templateUrl: './service-widget.component.html',
  styleUrls: ['./service-widget.component.scss'],
})
export class ServiceWidgetComponent {

  // List of events from the service dashboard
  @Input() records: ServiceRecord[] = [];

  // Flag to show if records are currently being loaded
  @Input() pending: boolean = false;

  // Additional classes to apply to component
  @Input() additionalClasses: string = '';

  // Fired when a new ServiceRequest is added via the
  // AddServiceRequestModalComponent
  @Output() onRequestAdded = new EventEmitter<UpdateServiceRecordRequest>();


  // Reference to "Add service request" dialog
  private dialogRef_details: any;


  /**
   * Constructor for component
   *
   * @param {MatDialog} dialog  Initialises a MatDialog component so that a new modal can be created
   * @param {Router} router     Initialises Router object providing navigation between pages
   */
  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) { }

  /**
   * Navigate the user to the submission selection page
   */
  goToJob() {
    this.router.navigate(['/submission-selection']).then(() => {
      window.scroll(0, 0);
    });
  }

  /**
   * Get the date of the given record
   *
   * @param {ServiceRecord} record The service record data
   *
   * @return {string} return record date
   */
  getRecordDate(record: ServiceRecord): string {
    return moment(record.lastServiceDate).format('DD MMM');
  }

  /**
   * Get the time of the given record
   *
   * @param {ServiceRecord} record The service record data
   *
   * @return {string} return record time
   */
  getRecordTime(record: ServiceRecord): string {
    return moment(record.lastServiceDate).format('HH:mm');
  }

  /**
   * Get the description of the given record
   *
   * @param {ServiceRecord} record The service record data
   *
   * @return {string} return record description
   */
  getRecordDescription(record: ServiceRecord): string {
    return record.customer.customerTitle + ' ' + record.customer.customerSurname;
  }

  /**
   * Get the location of the given record
   *
   * @param {ServiceRecord} record The service record data
   *
   * @return {string} return record location
   */
  getRecordLocation(record: ServiceRecord): string {
    return record.customer.customerAddress1 + ', ' + record.customer.customerAddress2;
  }

  /**
   * Called when a record is clicked, Opens a job or warranty modal dependant on the record type
   *
   * @param {ServiceRecord} sr The service record data
   */
  handleClickRecord(sr: ServiceRecord) {
    if (sr.type === 'JOB') {
      this.openJobRecord(sr.jobId);
    }
    else {
      this.openWarrantyRecord(sr.warrantyId);
    }
  }

  /**
   * Open a JobEditModalComponent popup to view and edit the record's data
   *
   * @param {string} id The job ID from the selected service record
   */
  openJobRecord(id: string) {
    this.dialogRef_details = this.dialog.open(JobEditModalComponent, {
      data: {
        jobId: id,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    // Close the modal and emit the record data to the parent component
    this.dialogRef_details.afterClosed().subscribe((result: any) => {
      if (result) {
        this.onRequestAdded.emit(result);
      }
    });
  }

  /**
   * Open a WarrantyEditModalComponent popup to view and edit the warranty's data
   *
   * @param {string} id The warranty ID from the selected service record
   */
  openWarrantyRecord(id: string) {
    this.dialogRef_details = this.dialog.open(WarrantyEditModalComponent, {
      data: {
        warrantyId: id,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    // Close the modal and emit the warranty data to the parent component
    this.dialogRef_details.afterClosed().subscribe((result: any) => {
      if (result)
        this.onRequestAdded.emit({ record: result });
    });
  }

  /*addNewTask() {
    this.dialogRef_add = this.dialog.open(AddServiceRequestModalComponent, {
      width: '75%',
    });

    this.dialogRef_add.afterClosed().subscribe(result => {
      if (result)
        this.onRequestAdded.emit(result);
    });
  }*/

  /**
   * Navigate the user to the service dashboard page
   */
  goToDashboard() {
    this.router.navigate(['/service-dashboard']);
  }

  /*selectEvent(e: DiaryEvent) {
    this.router.navigate(['/diary']);
  }*/
}
