import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { AdminUser } from '../../../models/admin-users';
import { UserRole } from '../../../models/registration';
import { AccreditationItem } from '../../../models/user-profile';
import {
  AddAdminUserRequestAction,
  DeleteAdminUserRequestAction,
  FetchAccreditationsRequestAction,
  FetchAdminUsersRequestAction,
  UpdateAdminUserRequestAction,
} from '../../../state-management/actions/admin-users';
import { FetchBusinessToolsRequestAction } from '../../../state-management/actions/business-tools';
import { FetchUserRolesRequestAction } from '../../../state-management/actions/registration';
import { State as AdminUsersState } from '../../../state-management/reducers/admin-users';
import { StoreState } from '../../../state-management/store';
import { AdminUserEditModalComponent } from './admin-user-edit-modal/admin-user-edit-modal.component';

/**
 * Summary
 *    Displays a paginated list of users (filterable) and allows users to be
 *    edited
 *
 * Description
 *    Users can be filtered by type and name/address. Results are displayed in
 *    a paginated list. Users can be edited and deleted and new users can be
 *    added.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnDestroy, OnInit {

  // "adminUsers" state from store
  public adminUsers$: Observable<AdminUsersState>;

  // Subscription to above Observable
  private adminUsersSub$: Subscription = null;

  // Search form
  public fgSearch: FormGroup;

  // Reference to user edit dialog
  private dialogRef_edit;

  // Local pagination variables (from store)
  public pageNum: number = 1;
  public totalPages: number = 0;
  // Paginated page numbers
  public pagination: number[] = [];

  // List of available UserRoles from API
  public roles: UserRole[] = [];
  public roleOptions: any[] = [];

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store<StoreState>,
  ) {
    this.adminUsers$ = this.store.select('adminUsers');

    this.fgSearch = this.fb.group({
      onlyShowDeleted: false,
      onlyShowRequestedDeletion: false,
      userType: null,
      userId: null,
      userName: null,
      userCompanyName: null,
      userAddress: null,
    });
  }

  ngOnInit() {
    // Update local state when store changes
    this.adminUsersSub$ = this.adminUsers$.subscribe((state: AdminUsersState) => {
      this.pageNum = state.pageNum;
      this.totalPages = state.totalPages;
      this.roles = state.roles;
      this.roleOptions = state.roles
        ? state.roles.map((v: UserRole): any => ({ value: v.id, label: v.name }))
        : [];

      this.paginate();
    });

    this.store.dispatch(new FetchAccreditationsRequestAction());
    this.store.dispatch(new FetchBusinessToolsRequestAction());
    this.store.dispatch(new FetchUserRolesRequestAction());

    this.performSearch();
  }

  ngOnDestroy() {
    if (this.adminUsersSub$)
      this.adminUsersSub$.unsubscribe();
  }

  /**
   * Sets the pagination variable based on the current and total pages
   */
  paginate() {
    const pagination = [];

    let maxNumbersToPaginate = 11;

    if (this.pageNum > 1) {
      let timesNumbersAdded = 0;

      for (let i = this.pageNum; i > 1; i--) {
        if (timesNumbersAdded >= 6) break;

        timesNumbersAdded++;
        maxNumbersToPaginate--;
        pagination.unshift(this.pageNum - timesNumbersAdded);
      }
    }

    pagination.push(this.pageNum);

    if (this.totalPages > this.pageNum) {
      for (let i = this.pageNum + 1; i < this.totalPages + 1; i++) {
        if (maxNumbersToPaginate === 0) break;

        maxNumbersToPaginate--;
        pagination.push(i);

      }
    }

    this.pagination = pagination;
  }

  /**
   * Opens AdminUserEditModalComponent to add a new user
   *
   * @param {AccreditationItem[]} accreditations List of accreditations for display in profile form
   */
  addUser(accreditations: AccreditationItem[]) {
    this.dialogRef_edit = this.dialog.open(AdminUserEditModalComponent, {
      data: {
        user: null,
        accreditations,
        roles: this.roles,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe((result) => {
      if (result)
        this.store.dispatch(new AddAdminUserRequestAction({ user: result as AdminUser }));
    });
  }

  /**
   * Asks for confirmation and then deletes the specified AdminUser
   *
   * @param {AdminUser} user AdminUser to delete
   */
  deleteUser(user: AdminUser) {
    if (
      confirm(
        `Are you sure you want to delete user ${user.id} (${user.profile.title} ${user.profile.firstName} ${user.profile.lastName}?`
      )
    )
      this.store.dispatch(new DeleteAdminUserRequestAction({ user }));
  }

  /**
   * Opens AdminUserEditModalComponent to edit the specified AdminUser
   *
   * @param {AdminUser}           user           AdminUser to edit
   * @param {AccreditationItem[]} accreditations List of accreditations for display in profile form
   */
  editUser(user: AdminUser, accreditations: AccreditationItem[]) {
    this.dialogRef_edit = this.dialog.open(AdminUserEditModalComponent, {
      data: {
        user,
        roles: this.roles,
        accreditations
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new UpdateAdminUserRequestAction({ user: result as AdminUser }));
      }
    });
  }

  /**
   * Fetches a specific page
   */
  goToPage(page: number) {
    this.pageNum = page;
    this.performSearch();
  }

  /**
   * Loads the next page of results or a set amount of pages forward
   */
  pageNext(pages: number = 1) {
    this.pageNum = Math.min(this.totalPages, this.pageNum + pages);
    this.performSearch();
  }

  /**
   * Loads the previous page of results or a set amount of pages backward
   */
  pagePrev(pages: number = 1) {
    this.pageNum = Math.max(1, this.pageNum - pages);
    this.performSearch();
  }

  /**
   * Performs a search for users by type, name and/or address
   */
  performSearch() {
    this.store.dispatch(
      new FetchAdminUsersRequestAction({
        onlyShowDeleted: this.fgSearch.value.onlyShowDeleted,
        onlyShowRequestedDeletion: this.fgSearch.value.onlyShowRequestedDeletion,
        userType: this.fgSearch.value.userType,
        userId: this.fgSearch.value.userId,
        userName: this.fgSearch.value.userName,
        userCompanyName: this.fgSearch.value.userCompanyName,
        userAddress: this.fgSearch.value.userAddress,
        perPage: 12,
        page: this.pageNum,
      })
    );
  }
}
