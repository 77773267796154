/**
 * Provides DateFormatPipe
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

//required classes
import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

/**
 * Pipe to format the given value as a date
 */
@Pipe({name: 'dateFormat', pure: true})
export class DateFormatPipe implements PipeTransform {
    /**
     * Execute the transformation
     *
     * @param date
     */
    transform(date: string): string {
        return moment(date).format('D MMMM YYYY');
    }
}
