/**
 * social models
 */

import * as moment from 'moment';

/**
 * Encapsulates a single social media item/post
 */
export class SocialItem {
  date: string;
  description: string;
  image: string;
  url: string;
}

/**
 * Encapsulates a response obtained from a request to retrieve the latest
 * social media items
 */
export class SocialFeedResponse {

  // Individual feeds
  facebookFeed: SocialItem[];
  instagramFeed: SocialItem[];
  twitterFeed: SocialItem[];

  // Error message
  error: string;

  /**
   * Creates a SocialItem instance from API data model (Facebook)
   *
   * @param {any} apiData API data
   * @return {SocialItem}
   */
  static getFacebookFeedDataFromApi(apiData: any): SocialItem {
    const sItem: SocialItem = new SocialItem();

    sItem.url = apiData.url;
    sItem.description = apiData.content_html;
    sItem.date = apiData.date_modified;

    return sItem;
  }

  /**
   * Creates a SocialItem instance from API data model (Twitter)
   *
   * @param {any} apiData API data
   * @return {SocialItem}
   */
  static getTwitterFeedDataFromApi(apiData: any): SocialItem {
    const sItem: SocialItem = new SocialItem();

    if (apiData.text)
      sItem.description = apiData.text;

    if (apiData.entities) {

      if (apiData.entities.media && apiData.entities.media[0]) {
        sItem.image = apiData.entities.media[0].media_url;

        sItem.url = apiData.entities.media[0].url;
      }

      if (apiData.entities.urls && apiData.entities.urls[0]) {
        sItem.url = apiData.entities.urls[0].expanded_url;
      } else if (apiData.retweeted_status && apiData.retweeted_status.entities && apiData.retweeted_status.entities.urls && apiData.retweeted_status.entities.urls[0]) {
        sItem.url = apiData.retweeted_status.entities.urls[0].expanded_url;
      }

    }

    if (apiData.created_at)
      sItem.date = apiData.created_at;

    return sItem;
  }

  /**
   * Creates a SocialItem instance from API data model (Instagram)
   *
   * @param {any} apiData API data
   * @return {SocialItem}
   */
  static getInstagramFeedDataFromApi(apiData: any): SocialItem {
    const sItem: SocialItem = new SocialItem();

    sItem.url = apiData.url;
    sItem.description = apiData.content_html;
    sItem.date = apiData.date_modified;

    return sItem;
  }
}
