import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable }      from '@angular/core';
import { Action }          from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { ResourceService } from '../../services/resource.service';

import * as ActionTypes from '../actions/resources';

import {
  FetchResourceItemsRequest,
  FetchResourceItemsResponse,
} from '../../models/resources';

@Injectable()
export class ResourceEffects {
  constructor(
    private service:  ResourceService,
    private actions$: Actions
  ) {}

  /**
   * For a FetchResourceItemsRequestAction, call
   * ResourceService::fetchResources() and dispatch a new
   * FetchResourceItemsResponseAction with the response.
   */
  @Effect() fetchResources$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_RESOURCE_ITEMS_REQUEST)
    .switchMap((req: ActionTypes.FetchResourceItemsRequestAction): Observable<ActionTypes.FetchResourceItemsResponseAction> =>
      this.service.fetchResources(req.payload)
        .map((res: FetchResourceItemsResponse): ActionTypes.FetchResourceItemsResponseAction =>
          new ActionTypes.FetchResourceItemsResponseAction(res)
        )
    );
}
