<div [class]="'container-fluid register-component'">
  <div class="row">
    <a href="/">
      <img src="/assets/i/common/logos/adey-pro-club-logo.png" alt="ADEY® ProClub Logo" class="top-right-logo">
    </a>
    <a href="/" class="nav-home-btn">Back to Login</a>

    <div class="col-xs-12 register-container">
      <h1>invitation</h1>

<!--      <app-page-content page="REGISTER" field="intro" class="intro" *ngIf="mode !== 'update' && mode !== 'simple'"></app-page-content>-->

      <ng-container>
        <br>
        <p>
          <!-- September Points Promotion -->
          <strong style="font-size: 1.1em;">*September Offer* For September only, experience the perks of being a Pro Partner+ and earn 5 points per <em>Pro2®</em> & <em>Micro2®</em> warranty registration. To get you started, we will reward you with 10 points for your first <em>Pro2®</em> or <em>Micro2®</em> registered in the month.
            <br/> <br/> What are you waiting for? Register your warranties now to avoid missing out on maximum points.</strong> <br/> <br/>
        </p>
        <p>ADEY ProClub&trade; has been designed specifically for installers to help manage your business!</p>
        <p>Not only does the ProClub offer quicker and easier warranty registrations for your filters, you can also easily access your customer records, receive a 20% discount on ADEY Spares, and earn points for your registrations.</p>
        <ul>
          <li>Register your warranties to see a job dashboard of all your customer properties</li>
          <li>Receive service reminders and prompt customer notifications to secure your business</li>
          <li>Earn points on your registrations and exchange for ADEY workwear, merchandise and Love2Shop vouchers</li>
        </ul>
        <br>
        <p>All you need to do is fill in the below information, accept the T&C's and you're in!</p>
      </ng-container>


    </div>
  </div>
<!--  <div class="row submit-body">-->
<!--    <div class="col-sm-7 col-xs-12">-->
<!--      <div [class]="formGroupClass('termsAndConditionsConfirm', 'col-xs-12 full-width-label standard-input')" *ngIf="showTerms">-->
<!--        <mat-checkbox md-no-ink class="md-primary" formControlName="termsAndConditionsConfirm">-->
<!--          Confirm that you agree to our <a href="https://www.adey.com/adeyproclub-terms-and-conditions" target="_blank">terms &amp; conditions</a> *-->
<!--        </mat-checkbox>-->
<!--      </div>-->
<!--      <div [class]="formGroupClass('dataProtectionConfirm', 'col-xs-12 full-width-label standard-input')" *ngIf="showTerms">-->
<!--        <mat-checkbox md-no-ink class="md-primary" formControlName="dataProtectionConfirm">-->
<!--          Confirm that you agree to our <a href="http://www.adey.com/privacy-policy" target="_blank">Privacy Policy</a> *-->
<!--        </mat-checkbox>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
