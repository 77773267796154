import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Action }          from '@ngrx/store';
import { Injectable }      from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable }      from 'rxjs/Observable';

import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/customer-suggestion';
import { FetchCustomerSuggestionResponse } from "../../models/customer-suggestion";

@Injectable()
export class CustomerSuggestionEffects {
    constructor(
        private service: AdminService,
        private actions$: Actions
    ){}

    /**
     * Fetch the customer suggestions from the API.
     *
     * @type {Observable<any>}
     */
    @Effect() fetchCustomerSuggestions$: Observable<Action> = this.actions$
        .ofType(ActionTypes.FETCH_CUSTOMER_SUGGESTION_REQUEST)
        .switchMap((req: ActionTypes.FetchCustomerSuggestionRequestAction): Observable<ActionTypes.FetchCustomerSuggestionResponseAction> =>
            this.service.fetchCustomerSuggestions(req.payload)
                .map((res: FetchCustomerSuggestionResponse): ActionTypes.FetchCustomerSuggestionResponseAction =>
                    new ActionTypes.FetchCustomerSuggestionResponseAction(res)
                )
        );
}
