import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { RewardsResponse } from '../../models/rewards';
import { RewardsService } from '../../services/rewards.service';
import * as ActionTypes from '../actions/rewards';

@Injectable()
export class RewardsEffects {
  constructor(
    private rewardsService: RewardsService,
    private actions$: Actions
  ) { }

  @Effect() installerHubVoucherRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.INSTALLER_HUB_VOUCHER_REQUEST)
    .switchMap((req: ActionTypes.InstallerHubVoucherRequestAction): Observable<ActionTypes.InstallerHubVoucherResponseAction> => {
      return this.rewardsService.getInstallerHubVoucher(req.payload)
        .map((res: RewardsResponse<string>): ActionTypes.InstallerHubVoucherResponseAction => {
          if (res && res.callback) {
            res.callback();
          }
          return new ActionTypes.InstallerHubVoucherResponseAction(res);
        });
    });

  @Effect() installerHubVoucherValuesRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.INSTALLER_HUB_VOUCHER_VALUES_REQUEST)
    .switchMap((): Observable<ActionTypes.InstallerHubVoucherValuesResponseAction> => {
      return this.rewardsService.getInstallerHubVoucherValues()
        .map((res: RewardsResponse): ActionTypes.InstallerHubVoucherValuesResponseAction => new ActionTypes.InstallerHubVoucherValuesResponseAction(res));
    });

  @Effect() sparesRewardsRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.SPARES_REWARDS_REQUEST)
    .switchMap((req: ActionTypes.SparesRewardsRequestAction): Observable<ActionTypes.SparesRewardsResponseAction> => {
      return this.rewardsService.redeemSparesRewards(req.payload)
        .map((res: RewardsResponse): ActionTypes.SparesRewardsResponseAction => {
          if (res && res.callback) {
            res.callback();
          }
          return new ActionTypes.SparesRewardsResponseAction(res);
        });
    });
}
