/**
 * Provides AdminPro3DeviceDetailModalComponent
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

//required classes
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {Subscription} from 'rxjs/Subscription';
import {State as AdminPro3ListState} from '../../../../state-management/reducers/admin-pro3';
import {State as LoginDetailsState} from '../../../../state-management/reducers/login-details';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Store} from '@ngrx/store';
import {StoreState} from '../../../../state-management/store';
import {
  AdminDevice,
  AdminDeviceDetailRequest,
  AdminDeviceNote,
  AdminDeviceNoteRequest
} from '../../../../models/admin-pro3';

import {
    AdminDeviceDetailRequestAction,
    AdminDeviceNoteRequestAction
} from '../../../../state-management/actions/admin-pro3';
import {AdminPro3RegisterWarrantyComponent} from './admin-pro3-register-warranty/admin-pro3-register-warranty.component';
import {
    AdminPro3DeviceDetailDialogData,
    AdminPro3RegisterWarrantyDialogData
} from '../../../../models/admin-pro3-dialog-data';
import {DFTableRequest} from '../../../../models/df-proclub';
import {AdminPro3Service} from '../../../../services/admin-pro3.service';
import {catchError, take} from 'rxjs/operators';

/**
 * Component providing the modal device detail view
 */
@Component({
    selector: 'app-admin-pro3-device-detail',
    templateUrl: './admin-pro3-device-detail-modal.component.html',
    styleUrls: ['./admin-pro3-device-detail-modal.component.scss']
})
export class AdminPro3DeviceDetailModalComponent implements OnInit {

    // Store state
    private store: Store<StoreState>;
    public filterList$: Observable<AdminPro3ListState>;
    public loginDetails$: Observable<LoginDetailsState>;

    // Subscription to above Observable
    private loginDetailsSub$: Subscription = null;

    //The device to display
    public device: AdminDevice;

    //Modal(s) for registering warranty (etc)
    private dialog: MatDialog;
    private dialogRef_warranty: MatDialogRef<AdminPro3RegisterWarrantyComponent>;

    //For the device note
    @ViewChild('note') note: HTMLTextAreaElement;
    noteDirty = false;
  noteId: number;
  private adminPro3Service: AdminPro3Service;
  private snackBar: MatSnackBar;

    /**
     * Get incoming data and inject the store connection
     *
     * @param data Data from call made by host component
     * @param injector Angular Injector
     */
    constructor(
        injector: Injector
    ) {
        //Set up the store
        this.store = <Store<StoreState>>injector.get(Store);
        this.loginDetails$ = this.store.select('loginDetails');
        this.filterList$ = this.store.select('adminPro3');

        //Get the device out of the incoming data
        const data = <AdminPro3DeviceDetailDialogData>injector.get(MAT_DIALOG_DATA);
        this.device = data.device;

        //Register dialog handler:
        this.dialog = injector.get(MatDialog);

      //For saving the note:
      this.adminPro3Service = injector.get(AdminPro3Service);
      this.snackBar = injector.get(MatSnackBar);
    }

    /**
     * When everything's ready, get the details from the store
     */
    ngOnInit() {
        //Check we are logged in
        this.loginDetailsSub$ = this.loginDetails$.subscribe((state: LoginDetailsState) => {
            if (state.user && state.currentProfile) {
                //Build the requests and add in the device ID
                //First device detail...
                // const deviceDetailReq = new AdminDeviceDetailRequest();
                // deviceDetailReq.filterId = this.device.id;

                //Make the requests
                this.store.dispatch(new AdminDeviceDetailRequestAction({filterId: this.device.id}));
                this.store.dispatch(new AdminDeviceNoteRequestAction({deviceId: this.device.id}));
            }
        });

        //Put a note in the console in case we need it (spec is to not show ID in frontend, but may be needed)
        //      first disable TSLint to allow console:
        // noinspection TsLint
        console.info('Showing detail for ' + this.device.id);
    }

    createOrEditWarranty() {
        this.dialogRef_warranty = this.dialog.open(AdminPro3RegisterWarrantyComponent, {
            data: <AdminPro3RegisterWarrantyDialogData>{
                device: this.device,
            },
            width: '75%',
            panelClass: 'feature-modal-dialog',
        });
    }

  /**
   * Check the filter list state for a note, and return it if one is available
   *
   * @param filterList
   */
  theNote(filterList: any) {
    if (!filterList.deviceNote) {
      return '';
    }

    this.noteId = filterList.deviceNote.id;
    return filterList.deviceNote.content;
  }

  /**
   * Store the note
   *
   * @param value
   */
  saveNote(value: string) {
    //Set up the note object
    const note = <Partial<AdminDeviceNote>>{
      content: value,
      device_id: this.device.id,
      id: this.noteId || undefined,
    };

    //Set up the call and subscribe to it
    this.adminPro3Service.saveDeviceNote(note).pipe(take(1))
      .subscribe(() => {
        //If it's successful, notify the user and hide the save button
        this.snackBar.open('Note saved', '', {duration: 5000});
        this.noteDirty = false;
      }, (err) => {
        //Otherwise notify the user of failure
        this.snackBar.open('Note failed to save: ' + err.error.summary.toString(), '', {duration: 5000});
      });
  }

  returnNote(noteElement: any) {
    const note = <Partial<AdminDeviceNote>>{
      content: (<ElementRef>noteElement) ? noteElement.nativeElement.value : '',
      device_id: this.device.id,
      id: this.noteId || undefined,
    };
    return note;
  }
}
