import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import * as ActionTypes from '../actions/feature';
import { FeatureService } from '../../services/feature.service';
var FeatureEffects = /** @class */ (function () {
    function FeatureEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        this.sparesRewardsRequest$ = this.actions$
            .ofType(ActionTypes.GET_FEATURES)
            .switchMap(function () { return _this.service.getUserFeatures()
            .then(function (payload) { return new ActionTypes.GetFeaturesSuccess(payload.resource); })
            .catch(function (error) { return new ActionTypes.GetFeaturesFailure(error); }); });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], FeatureEffects.prototype, "sparesRewardsRequest$", void 0);
    return FeatureEffects;
}());
export { FeatureEffects };
