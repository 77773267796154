/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-header.component";
import * as i4 from "@angular/router";
var styles_ProfileHeaderComponent = [i0.styles];
var RenderType_ProfileHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileHeaderComponent, data: {} });
export { RenderType_ProfileHeaderComponent as RenderType_ProfileHeaderComponent };
function View_ProfileHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ie-image-patch profile-photo transform-y-offset-50"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.userProfile.photoUrl) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.userProfile.photoUrl; _ck(_v, 2, 0, currVal_1); }); }
function View_ProfileHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "profile-photo-placeholder transform-y-offset-50 background-lighter-gray color-white align-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-user"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, null); }
function View_ProfileHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "color-gray"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n      Member for ", "\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getMembershipLength(_co.userProfile.createdAt); _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileHeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileHeaderComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 16, "div", [["class", "content col-sm-7 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "color-light-gray user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "medium membership-mobile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/rewards") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileHeaderComponent_4)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "h3", [["class", "color-adey-red medium no-margin"]], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", " Points"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-link no-pad color-adey-red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editProfile(_co.userProfile, _co.accreditations) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit\n      profile"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(26, 0, null, null, 9, "div", [["class", "profile-level-container pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/rewards") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(28, 0, null, null, 6, "div", [["class", "profile-level-badge background-light-gray color-adey-gray align-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(30, 0, null, null, 0, "i", [["class", "icon-parts"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(33, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userProfile.photoUrl; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.userProfile.photoUrl; _ck(_v, 6, 0, currVal_2); var currVal_5 = _co.userProfile.createdAt; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("profile-badge border-box border-light-gray " + (_co.isWidget ? "widget" : "page")); _ck(_v, 0, 0, currVal_0); var currVal_3 = ((_co.userProfile.firstName + " ") + _co.userProfile.lastName); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.userProfile.memberLevel; _ck(_v, 14, 0, currVal_4); var currVal_6 = _co.userProfile.pointsCurrent; _ck(_v, 20, 0, currVal_6); var currVal_7 = _co.userProfile.memberLevel; _ck(_v, 33, 0, currVal_7); }); }
export function View_ProfileHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userProfile; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfileHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-header", [], null, null, null, View_ProfileHeaderComponent_0, RenderType_ProfileHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProfileHeaderComponent, [i4.Router], null, null)], null, null); }
var ProfileHeaderComponentNgFactory = i1.ɵccf("app-profile-header", i3.ProfileHeaderComponent, View_ProfileHeaderComponent_Host_0, { userProfile: "userProfile", accreditations: "accreditations", isWidget: "isWidget" }, { editCallback: "editCallback" }, []);
export { ProfileHeaderComponentNgFactory as ProfileHeaderComponentNgFactory };
