import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import * as ActionTypes from '../actions/business-tools';
import { BusinessToolsService } from '../../services/business-tools.service';
import { BusinessToolResponse, BusinessToolVerificationTokenResponse } from '../../models/business-tools';

@Injectable()
export class BusinessToolsEffects {
  constructor(
    private service: BusinessToolsService,
    private actions$: Actions,
  ) { }

  @Effect() fetchBusinessToolsRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_BUSINESS_TOOLS_REQUEST)
    .switchMap((req: ActionTypes.FetchBusinessToolsRequestAction): Observable<ActionTypes.FetchBusinessToolsResponseAction> =>
      this.service.getBusinessTools()
        .map((res: BusinessToolResponse): ActionTypes.FetchBusinessToolsResponseAction =>
          new ActionTypes.FetchBusinessToolsResponseAction(res)
        )
    );

  @Effect() fetchBusinessToolsVerificationRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST)
    .switchMap((req: ActionTypes.FetchBusinessToolsVerificationTokenRequestAction): Observable<ActionTypes.FetchBusinessToolsVerificationTokenResponseAction> =>
      this.service.getBusinessToolVerificationToken(req.payload)
        .map((res: BusinessToolVerificationTokenResponse): ActionTypes.FetchBusinessToolsVerificationTokenResponseAction =>
          new ActionTypes.FetchBusinessToolsVerificationTokenResponseAction(res)
        )
    );
}
