import { EventEmitter, OnChanges, OnDestroy, OnInit, } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, } from 'angular-calendar';
import { DiaryEvent } from '../../../models/diary';
import { DiarySingleEventComponent } from '../diary-single-event/diary-single-event.component';
import { DiaryListEventsModalComponent } from '../diary-list-events-modal/diary-list-events-modal.component';
/**
 * Summary
 *    Display the diary's main body.
 *
 *
 * Description
 *    Display the diary interface and allow the user to cycle dates and switch the current view.
 *    events will filter into the correct view.
 *
 * @copyright 2017 ReallyB2B Limited
 */
var DiaryCalendarComponent = /** @class */ (function () {
    /**
     * Constructor for page
     *
     * @param {MatDialog} dialog Initialises a MatDialog component so that a new modal can be created
     */
    function DiaryCalendarComponent(dialog) {
        var _this = this;
        this.dialog = dialog;
        // Array of events to display
        this.events = [];
        // Array of available event types
        this.eventTypes = [];
        // Flag to indicate if events cannot be edited
        this.readOnly = false;
        // Default to viewing today's date in "month" mode
        this.view = 'month';
        this.viewDate = new Date();
        // Flag to indicate if ".small" CSS class should be applied
        this.small = false;
        // Fired after an event has been added via a modal pop-up
        this.onEventAdded = new EventEmitter();
        // Fired when the delete button is clicked
        this.onEventDeleted = new EventEmitter();
        // Fired after an event has been edited via a modal pop-up
        this.onEventEdited = new EventEmitter();
        // Input events mapped to DiaryCalendarEvent
        this._events = null;
        // Actions attached to each DiaryCalendarEvent (unless readOnly is set)
        this.actions = [
            {
                label: '<i class="fa fa-fw fa-pencil"></i>',
                onClick: function (_a) {
                    var event = _a.event;
                    _this.editEvent(event);
                }
            },
            {
                label: '<i class="fa fa-fw fa-times"></i>',
                onClick: function (_a) {
                    var event = _a.event;
                    _this.deleteEvent(event);
                }
            }
        ];
    }
    DiaryCalendarComponent.prototype.ngOnInit = function () {
    };
    DiaryCalendarComponent.prototype.ngOnDestroy = function () {
    };
    /**
     * Called when the onChange event is triggered internally, will remap the events based on the changes
     */
    DiaryCalendarComponent.prototype.ngOnChanges = function () {
        var _this = this;
        this._events = this.events && this.events.length > 0
            ? this.events.map(function (evt) { return _this.toDiaryCalendarEvent(evt, _this.readOnly ? null : _this.actions, !_this.readOnly); })
            : null;
    };
    /**
     * Called when the user selects a blank date on the calendar or the "Add event" button
     *
     * @param {Date} date The selected date will be passed through considering the user selected a blank day on the diary
     */
    DiaryCalendarComponent.prototype.addEvent = function (date) {
        var _this = this;
        if (this.readOnly)
            return;
        // open a new DiarySingleEventComponent within a popup 
        this.dialogRef_add = this.dialog.open(DiarySingleEventComponent, {
            data: {
                date: date ? date : new Date(),
                event: null,
                eventTypes: this.eventTypes,
            },
            width: '75%',
            panelClass: 'feature-modal-dialog',
        });
        // If the modal is closed with data, emit that data back to diary.component.ts
        // so that the new event can be added
        this.dialogRef_add.afterClosed().subscribe(function (result) {
            if (result)
                _this.onEventAdded.emit(result);
        });
    };
    /**
     * Called when the user selects a date on the calendar
     *
     * @param {Date} date The date of the selected day
     * @param {DiaryCalendarEvent[]} events The list of events linked to the given date
     */
    DiaryCalendarComponent.prototype.dayClicked = function (_a) {
        var _this = this;
        var date = _a.date, events = _a.events;
        // If the day contains no events, open the DiarySingleEventComponent as a
        // modal to add a new event
        if (!events || events.length === 0)
            this.addEvent(date);
        else if (events.length === 1)
            this.editEvent(events[0]);
        else {
            this.dialogRef_list = this.dialog.open(DiaryListEventsModalComponent, {
                data: {
                    date: date,
                    events: events.map(function (e) { return e.diaryEvent; })
                },
                panelClass: 'feature-modal-dialog',
            });
            // If the user has selected a date from the list, open it in a separate modal
            this.dialogRef_list.afterClosed().subscribe(function (result) {
                if (result)
                    _this.editEvent(_this.toDiaryCalendarEvent(result));
            });
        }
    };
    /**
     * Called if the user selects the delete event button from the DiarySingleEventComponent modal
     *
     * @param {DiaryCalendarEvent} e The event to be deleted
     */
    DiaryCalendarComponent.prototype.deleteEvent = function (e) {
        if (this.readOnly)
            return;
        // Emit the event to be deleted back to to diary.component.ts
        this.onEventDeleted.emit(e.diaryEvent);
    };
    /**
     * Called if the user selects an existing event from the diary or diary event list
     *
     * @param {DiaryCalendarEvent} e The event to be edited
     */
    DiaryCalendarComponent.prototype.editEvent = function (e) {
        var _this = this;
        // Open a new DiarySingleEventComponent modal with the selected event's data
        this.dialogRef_edit = this.dialog.open(DiarySingleEventComponent, {
            data: {
                event: e.diaryEvent,
                readOnly: this.readOnly,
                eventTypes: this.eventTypes,
            },
            width: '75%',
            panelClass: 'feature-modal-dialog',
        });
        // Determine which action to dispatch based on the data passed back when
        // the modal is closed.
        this.dialogRef_edit.afterClosed().subscribe(function (result) {
            if (result && !_this.readOnly && result.returnRef) {
                if (result.returnRef === 'SAVE')
                    _this.onEventEdited.emit(Object.assign({}, e.diaryEvent, result));
                else if (result.returnRef === 'DELETE')
                    _this.deleteEvent(_this.toDiaryCalendarEvent(result));
            }
        });
    };
    /**
     * Update the times of a given event
     *
     * @param {any} event     The event to be edited
     * @param {any} newStart The new start time for the event
     * @param {any} newEnd   The new end time for the event
     */
    DiaryCalendarComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        if (this.readOnly)
            return;
        // Update the times of a given event
        this.onEventEdited.emit(Object.assign({}, event.diaryEvent, DiaryEvent.updateTimes(event.diaryEvent, newStart, newEnd)));
    };
    /**
     * Called when the user clicks a blank space on the day view of the diary
     *
     * @param {Date} date The date of the event to add
     */
    DiaryCalendarComponent.prototype.hourClicked = function (_a) {
        var date = _a.date;
        if (this.readOnly)
            return;
        // Open a blank event modal with the given date
        this.addEvent(date);
    };
    /**
     * Create a new DiaryCalendarEvent object using event data
     *
     * @param {DiaryEvent} v                    The data for the given event
     * @param {CalendarEventAction[]} actions   Action buttons to be displayed in the modal
     * @param {boolean} draggable               Flag to determine if event can be dragged
     *
     * @return {DiaryCalendarEvent} Return the event data and actions in the form of a new DiaryCalendarEvent object
     */
    DiaryCalendarComponent.prototype.toDiaryCalendarEvent = function (v, actions, draggable) {
        if (actions === void 0) { actions = null; }
        if (draggable === void 0) { draggable = true; }
        // The start date of the event based on the value of the event's allDay flag
        var startDate = v.allDay ? moment.utc(v.date + ' 00:00:00') : moment.utc(v.date + ' ' + v.time);
        // The end date of the event calculated by adding the duration to the start date
        var endDate = moment(startDate).add(v.duration, 'm');
        // The list of available colours for the event
        var colours = {
            red: {
                primary: '#ad2121',
                secondary: '#FAE3E3'
            },
            blue: {
                primary: '#1e90ff',
                secondary: '#D1E8FF'
            },
            yellow: {
                primary: '#e3bc08',
                secondary: '#FDF1BA'
            },
            grey: {
                primary: '#333333',
                secondary: '#aaaaaa',
            },
        };
        // Set the colour based on the event state
        var colour;
        if (!v.userEvent)
            colour = colours.red;
        else if (!v.committed)
            colour = colours.grey;
        else if (v.deleted)
            colour = colours.red;
        else if (v.userEvent)
            colour = colours.blue;
        else
            colour = colours.yellow;
        // return the event data as a DiaryCalendarEvent object
        return {
            diaryEvent: v,
            start: startDate.toDate(),
            end: endDate.toDate(),
            title: v.details,
            color: colour,
            // Only add action buttons for user-created events
            actions: v.userEvent ? actions : null,
            // Event can only be moved/resized if it is a user-created event
            resizable: {
                beforeStart: draggable && v.userEvent,
                afterEnd: draggable && v.userEvent,
            },
            // TODO: allow dragging, see https://github.com/mattlewis92/angular-calendar/issues/373
            //draggable: draggable && v.userEvent,
            draggable: false,
        };
    };
    /**
     * Overwrite the month view within the angular-calendar component
     *
     * @param {CalendarMonthViewDay[]} body data passed in from angular-calendar
     */
    DiaryCalendarComponent.prototype.beforeMonthViewRender = function (_a) {
        var body = _a.body;
        // overwrite the css class of each day component to remove native unwanted styling
        body.forEach(function (day) {
            if (day.isFuture) {
                if (moment(day.date).isSame(moment(), 'week')) {
                    day.cssClass = 'same-week-event';
                }
            }
            day.events.forEach(function (event) {
                if (!event['diaryEvent'].userEvent) {
                    day.cssClass = 'adey-event';
                }
            });
        });
    };
    return DiaryCalendarComponent;
}());
export { DiaryCalendarComponent };
