/**
 * Shared Module
 *
 * TypeScript
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WaitingSpinnerComponent} from './waiting-spinner/waiting-spinner.component';
import {MatProgressBarModule, MatProgressSpinnerModule} from '@angular/material';
import {SecuredImageDirective} from '../directives/secured-image/secured-image.directive';

@NgModule({
  imports: [
    CommonModule,

    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    SecuredImageDirective,

    WaitingSpinnerComponent,
  ],
  exports: [
    SecuredImageDirective,

    WaitingSpinnerComponent,
  ]
})
export class SharedModule { }
