import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResultListAdminService} from '../../services/resultlistadmin.service';
import {DashboardService} from '../../services/dashboard.service';
import * as moment from 'moment';
import {
  TestExportQueryRequestAction,
  TestExportClearRequestAction
} from '../../state-management/actions/watertesters';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {State as WaterTesterState} from '../../state-management/reducers/watertester';
import {ResultEmailComponent} from '../result-email/result-email.component';
import {MatDialog} from '@angular/material';
import {TableTypes} from '../tableresults/TableTypes.enum';

import { ExportToCsv } from 'export-to-csv';
import {State as LoginDetailsState} from '../../../state-management/reducers/login-details';
import {CurrentUser} from '../../../models/login';
import {Subscription} from 'rxjs/Subscription';

@Component({
    selector: 'app-resultlistadmin',
    templateUrl: './resultlistadmin.component.html',
    styleUrls: ['./resultlistadmin.component.scss']
})
export class ResultListAdminComponent implements OnInit, OnDestroy {

    public filter: string = 'all';
    public title: string = '';
    public search: string = '';
    public searchFromTable: string = '';
    public month: number = 12;
    public tableType?: TableTypes;
    public lastTest: boolean = false;

    public backBtn: boolean = false;

    public resultIds: any[];

    public noSelected: string = 'Select result(s) please.';

    public noSubTitle: boolean = false;

    public maxRowsSelected: number = 5;

    // "watertester" from store
    public waterTester$: Observable<WaterTesterState>;
    private subWaterTester:    any;

    // Reference to login state
    public loginDetails$: Observable<LoginDetailsState>;
    private subLoginDetails:    any;

    // Subscription to the querystring parameters
    private paramSub: Subscription;

    public exportButtonDisabled: boolean = false;

    public normalHeader = [
      'Test Date',
      'Test ID',
      'Address',
      'Postcode',
      'Country',
      'Tester Name',
      'Tester Email Address',
      'Tester Contact Number',
      'Tester company Name',
      'Result Company Name',
      'Reason Test',
      'System Type',
      'Serial Boiler No',
      'Job Reference ID',
      'Filter Installed',
      'Overall Result',
      'Inhibitor',
      'Corrosion',
      'pH',
      'Notes',
      'App Version'
    ];

    public adminHeader = [
    'Test Date',
    'Test ID',
    'Address',
    'Postcode',
    'Country',
    'Tester Name',
    'Tester Email Address',
    'Tester Contact Number',
    'Tester company Name',
    'Result Company Name',
    'Reason Test',
    'System Type',
    'Boiler Serial No',
    'Job Reference ID',
    'Filter Installed',
    'Overall Result',
    'Inhibitor',
    'Inhibitor Value',
    'Corrosion',
    'Corrosion Value',
    'pH',
    'pH Value',
    'Notes',
    'App Version'
  ];

    public optionsExport = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Water test results',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: this.normalHeader
    };

    public csvExporter: any;

    public showExportButton: boolean = false;

    public isAdmin: boolean = false;

    constructor(
        private router: Router,
        private resultListAdminService: ResultListAdminService,
        private route: ActivatedRoute,
        private dashboardService: DashboardService,
        private _store: Store<any>,
        private dialog: MatDialog,
    ) {
      this.waterTester$ = this._store.select('WaterTester');

      // Set a reference to the login store
      this.loginDetails$ = this._store.select('loginDetails');
    }

    ngOnInit() {

        //waiting for any update on state details
        this.subLoginDetails = this.loginDetails$.subscribe((state: LoginDetailsState) => {

            if (state.currentProfile && state.currentProfile.userType === 1) {
                this.isAdmin = true;
            }
        });

        //waiting for any update on state exportData
        this.subWaterTester = this.waterTester$.subscribe((state: WaterTesterState) => {
          if (state.exportData && state.exportData.length > 0) {

              if (this.isAdmin) {
                this.optionsExport.headers = this.adminHeader;
              }

              this.csvExporter = new ExportToCsv(this.optionsExport);
              this.csvExporter.generateCsv(state.exportData);

              //clear data after export
              this._store.dispatch(new TestExportClearRequestAction());
          }

          this.exportButtonDisabled = false;
        });

        this.resultIds = [];

        this.paramSub = this.route.params.subscribe(params => {
            this.filter = params['filter'];
            if (this.filter === 'passed') {
                this.title = 'Properties OK';
            } else if (this.filter === 'failed') {
              this.title = 'Properties with Recommendations';
            }

            if (params['month'] > 0) {
                this.getResultsDue(params['month']);
            } else
              if (params['month1'] > 0 && params['month2'] > 0) {
                    this.getResultsDueNextMonth(params['month1'], params['month2']);
                } else
                  if (params['tester-id'] > 0) {
                        let allResults = false;
                        if (params['type'] === 'all') {
                          allResults = true;
                        }
                        this.getResultsByWaterTester(params['tester-id'], params['company-id'], allResults);
                        this.lastTest = true;
                        this.title = 'Water Tests';
                        this.noSubTitle = true;
                        this.showExportButton = true;
                  } else if (params['local-id'] > 0) {
                          this.backBtn = true;
                          this.getResultsByAddress(params['local-id'], params['company-id']);
                          this.lastTest = false;
                          this.title = 'Property';
                          this.noSubTitle = true;
                          this.showExportButton = true;
                        } else {
                          this.getResults(this.filter);
                          this.noSubTitle = false;
                        }
        });

    }

    ngOnDestroy() {
      if (this.subWaterTester) {
        this.subWaterTester.unsubscribe();
      }

      if (this.paramSub) {
        this.paramSub.unsubscribe();
      }
    }
    /**
     * Get the list of results
     * @param {string} filter
     */
    getResults(filter: string) {

      if (filter === 'passed') {
        this.tableType = TableTypes.normal;
        this.lastTest = true;
        //this.search = '((last_test_pass=1) AND (last_test<=' + dateTimeNow + '))';
        this.search = '(last_test_pass=1)';
      } else
        if (filter === 'failed') {
          this.tableType = TableTypes.normal;
          this.lastTest = true;
          //this.search = '((last_test_pass=0) AND (last_test<=' + dateTimeNow + '))';
          this.search = '(last_test_pass=0)';
        } else {
          this.showExportButton = true;
          this.tableType = TableTypes.all;
          this.lastTest = false;
          this.search = '';
        }
    }

    /**
     * Get the list of results by water tester
     * @param {number} testerId
     * @param companyId
     * @param allResults
     */
    getResultsByWaterTester(testerId: number, companyId: number = 0, allResults: boolean = false) {

      let company_filter = '';
      if ( companyId > 0 ) {
         company_filter = '(hosted_company_id=' + companyId + ') AND ';
      }
      if (allResults) {
        this.tableType = TableTypes.all;
      } else {
        this.tableType = TableTypes.waterTester;
      }

      if (company_filter !== '') {
        this.search = '(' + company_filter + '(user_id=' + testerId + '))';
      } else {
        this.search = '(user_id=' + testerId + ')';
      }

    }

    /**
     * Get the list of results by address
     * @param {number} localId
     * @param companyId
     */
    getResultsByAddress(localId: number, companyId: number) {

      this.tableType = TableTypes.byAddress;

      let company_filter = '';

      if ( companyId > 0 ) {
        company_filter = '(hosted_company_id=' + companyId + ') AND ';
      }

      this.search = '(' + company_filter + '(watertest_location_id=' + localId + '))';
    }

    /**
     * Get the list of results Due in next month
     * @param {number} month1
     * @param {number} month2
     */
    getResultsDueNextMonth(month1: number, month2: number) {
        const startDate = this.dashboardService.getDate(month1);
        const endDate = this.dashboardService.getDate(month2);
        this.tableType = TableTypes.due;
        this.title = 'Properties due in next 30 days';
        this.search = '(((last_pass IS NOT NULL) AND (last_pass > ' + startDate + ') AND (last_pass <= ' + endDate + '))';
        this.search += ' OR ((last_pass IS NULL) AND (last_test > ' + startDate + ') AND (last_test <= ' + endDate + ')))';
    }

    /**
     * Get the list of results Due X months
     * @param {number} month
     */
    getResultsDue(month: number) {
        const date = this.dashboardService.getDate(month);
        this.tableType = TableTypes.due;
        this.title = 'Properties with tests overdue';
        this.search = '((last_pass IS NOT NULL) AND (last_pass <= ' + date + ')';
        this.search += ' OR ((last_pass IS NULL) AND (last_test <= ' + date + ')))';
    }

    /**
     * Navigate the user to a given page
     *
     * @param {string} pageRef The page chosen to navigate to
     */
    goToPage(pageRef: string)
    {
        this.router.navigate([pageRef]);
    }


  /**
   * Check data selected to send
   */
  sendResultByEmail()
  {
    //console.log('this.resultIds.length : ', this.resultIds);
    if (this.resultIds.length > 0) {
      if ( this.resultIds.length > this.maxRowsSelected) {
        this.openResultEmail('You cannot select more than ' + this.maxRowsSelected + ' results.');
      } else {
        this.openResultEmail();
      }
    } else {
      this.openResultEmail(this.noSelected);
    }
  }

  /**
   * Export Result
   */
  exportResult()
  {
    this.exportButtonDisabled = true;
    //check if i will export from the table or not
    let exportSearch = this.search;
    const n = this.searchFromTable.indexOf('filter');
    if (n > -1) {
      exportSearch = this.searchFromTable;
    }
    this._store.dispatch(new TestExportQueryRequestAction({filter: exportSearch, isAdmin: this.isAdmin }));
  }

  /**
   * return date now
   */
  public dateNow() {
    return moment().format('Y-M-D');
  }


  /**
   * Navigates back
   */
  navigatesBack() {
    window.history.back();
  }

  /**
   * Events from  checkbox
   */
  checkResultsIds($event: any) {
    this.resultIds = $event;
  }

  /**
   * Events from  table search
   */
  checkSearchTable($event: any) {
    //console.log('event from table : ', $event);
    this.searchFromTable = $event;
  }

  /**
   * Opens ResultEmailComponent to send the results by email
   *
   * @param msg {string}
   *
   */
  openResultEmail(msg: string = null) {
    const dialogRef = this.dialog.open(ResultEmailComponent, {
      data: {
        resultIds: this.resultIds,
        noSelected: msg,
      }
    });
  }
}
