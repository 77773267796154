/**
 * Provides models for the Pro3 Admin Portal
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

/**
 * Class to contain the filter options for device status
 */
export class AdminDeviceStatusRequest {
    hosted_company_id?: number;
    installUID?: number;
}

/**
 * Class to contain the current status of all devices
 */
export class AdminDeviceStatus {
    totalCount: number;
    amberMagnetite: number;
    redMagnetite: number;
    redContact: number;
    battLow: number;
    battCritical: number;
    serviceOverdue: number;
    statusGreen: number;
    warrantyReplacements: number;
    orphanedDevices: number;
    excludeFieldTrial: boolean;
    excludeAdeyTesters: boolean;
    excludeFactoryReset: boolean;
}

/**
 * Class for a particular device
 */
export class AdminDevice {
    id: number;
    serialNo: string;
    filterNo: string;
    rawSerial?: number;
    isWarrantyReplacement: boolean;
    name: string;
    firmwareVer: string;
    pcbVersion: number;
    systemType: string;
    productID: number;
    installUID: number;
    currentUID: number;
    hosted_company_id?: number;
    installer?: string;
    customer?: string;
    homeowner?: number;
    warranty_customer?;
    threshold: number;
    warrantyID: number;
    productionFlag: boolean;
    fieldTrialFlag: boolean;
    lastCleaned: string;
    enableJsonLog: boolean;
    redirectToUrl: string;
    installDate?: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    quickFilter?: string;
    amberMagnetite?: boolean;
    redMagnetite?: boolean;
    redContact?: boolean;
    battLow?: boolean;
    battCritical?: boolean;
    serviceOverdue?: boolean;
    statusGreen?: boolean;
    isOrphaned?: boolean;
    boilerbrand?: string;
}

/**
 * Class for a particular alert
 */
export class AdminDeviceAlert {
    id: number;
    alertDefinitionID: number;
    deviceID: number;
    installerID: number;
    customerID: number;
    code: number;
    description: string;
    customerText: string;
    installerText: string;
    showCustomer: boolean;
    showInstaller: boolean;
    showAdmin: boolean;
    customerCanClose: boolean;
    installerCanClose: boolean;
    alertLevel: number;
    transient: boolean;
    source: string;
    created_at: string;
    updated_at: string;
    customer: string;

    //Whether the alert would usually be visible
    definitionShowCustomer: boolean;
    definitionShowInstaller: boolean;
    definitionShowAdmin: boolean;

    //Allow searching:
    quickFilter: string;

    // Device info:
    filterNo: string;
    serialNo: string;
    warranty_customer: number;
    homeowner: number;
    homeowner_name: string;
    warranty_customer_name: string;
    installer: string;

    boilerbrand?: string;
}

/**
 * Class for detailed device view
 */
export class AdminDeviceDetailItem {
    actionText: string;
    informationText: string;
    lastReadingTimestamp: number;
    lastReadingDateTime: string;
    currentReading: number;
    lastContactDateTime: string;
    lastContactTimestamp: number;
    batteryLevel: number;
    deviceId: number;
    deviceSerial: string;
    deviceName: string;
    deviceLastCleaned: string;
    nextServiceDate: string;
    deviceInstallDate: string;
    warrantyExpiryDate: string;
    deviceLastUpdated: string;
    customerTitle: string;
    customerFirstname: string;
    customerSurname: string;
    customerPhone: string;
    customerEmail: string;
    customerAddress1: string;
    customerAddress2: string;
    customerAddress3: string;
    customerTown_city: string;
    customerCountyState: string;
    customerPostcode: string;
}

/**
 * Structure for requests to get device details
 */
export class AdminDeviceDetailRequest {
    filterId: number;
}

/**
 * Structure for requests to dismiss alerts
 */
export class AdminDismissAlertRequest {
    alertId: number;
    dismissFor: string;
}

/**
 * Structure of response to request to dismiss alerts
 */
export class AdminDismissAlertResultItem {
    found: number;
    modified: number;
    deleted: number;
}

/**
 * Structure for requests to get device notes
 */
export class AdminDeviceNoteRequest {
    deviceId: number;
}

/**
 * Structure of device note records
 */
export class AdminDeviceNote {
    id: number;
    device_id: number;
    content: string;
    user_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    deleted_user_id: string;
}

export class AdminPro3RegisterWarrantyRequest {
    user_id?: any;

    //Warranty ID if available:
    id?: number;

    //Device, if available:
    device_id?: number;

    //Installer
    installer_id: number; //                         => ['required', 'integer'],

    //Customer (for whom device is installed)
    customer_title?: string; //                  => ['nullable', 'string'],
    customer_first_name: string; //             => ['required', 'string'],
    customer_surname: string; //                => ['required', 'string'],
    customer_phone?: string; //                  => ['nullable', 'string'],
    customer_email?: string; //                  => ['nullable', 'string'],

    //Property info (where device is installed)
    customer_address_1: string; //              => ['required', 'string'],
    customer_address_2?: string; //              => ['nullable', 'string'],
    customer_town_city: string; //              => ['required', 'string'],
    customer_county_state: string; //           => ['required', 'string'],
    customer_postcode: string; //               => ['required', 'string'],

    //Job details
    job_boilerbrand?: string; //                 => ['nullable', 'string'],
    job_cleaned: boolean; //                     => ['required', 'boolean'],
    job_cleaner_serial_no?: string; //           => ['required_if:job_cleaned,true', 'string'],
    job_magnacleanse?: boolean; //                => ['nullable', 'boolean'],
    job_filter_expiry_date?: Date; //          => ['nullable', 'date'],
    job_new_system?: boolean; //                  => ['nullable', 'boolean'],
    job_radiator_count?: boolean; //              => ['nullable', 'integer'],
    job_radiators_type?: string; //              => ['nullable', 'string'],
    job_pipework_type?: string; //               => ['nullable', 'string'],
    job_smart_thermostat_installed?: boolean; //  => ['nullable', 'boolean'],
    job_smart_thermostat_model?: string; //      => ['required_if:job_smart_thermostat_installed,true', 'string'],
    job_new_filter?: boolean; //                  => ['nullable', 'boolean'],
    job_protect?: boolean; //                     => ['nullable', 'boolean'],
    job_protect_serial_no?: string; //           => ['required_if:job_protect,true', 'string'],
    job_test?: boolean; //                        => ['nullable', 'boolean'],
    job_water_serial_no?: string; //             => ['required_if:job_test,true', 'string'],

    //Warranty details
    warranty_install_date: Date; //           => ['required', 'date'],
    warranty_new_boiler: boolean; //             => ['required', 'boolean'],
    warranty_old_filter_brand?: string; //       => ['nullable', 'string'],

    //Service record
    servicerecord_service_date: Date; //      => ['required', 'date'],
    servicerecord_last_service_date?: Date; // => ['nullable', 'date'],

    //Device
    device_filterNo: string; //                 => ['required', 'string'],
    device_serialNo: string; //                 => ['required', 'string'],
    device_name?: string; //                     => ['nullable', 'string'],
    device_systemType?: 'C' | 'O'; //               => ['nullable', 'in:C,O'],
    device_isWarrantyReplacement?: boolean; //    => ['nullable', 'boolean'],
    device_isTestDevice?: boolean;
}
