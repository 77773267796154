import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import * as moment from 'moment';


import { ServiceRecord } from '../../../models/service-record';


/**
 * Summary
 *    Form allowing a service record to be viewed and edited
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-service-record',
  templateUrl: './service-record.component.html',
  styleUrls: ['./service-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceRecordComponent implements OnChanges, OnInit {

  // ServiceRecord that is to be viewed/edited
  @Input() serviceRecord: ServiceRecord;

  // Flag to control view/edit mode
  @Input() readOnly: boolean = false;

  // Flag to indicate if the submit button is displayed
  @Input() showSubmit: boolean = true;

  // Label for submit button (if visible)
  @Input() submitLabel: string = 'Save changes';

  // Fired when the form is valid and submitted
  @Output() onSave = new EventEmitter<ServiceRecord>();


  // Page errors for display
  public errors: string = null;

  // Edit form
  public fg: FormGroup;


  constructor(
    private fb: FormBuilder
  ) { }

  ngOnChanges() {
    if (!this.fg)
      return;
    if (this.readOnly)
      this.fg.disable();
    else
      this.fg.enable();
  }

  ngOnInit() {
    this.buildForm();
  }


  /**
   * Builds the main service record edit form
   */
  buildForm() {
    const disabled = this.readOnly;

    this.fg = this.fb.group({
      date: [{value: this.getFieldDate('date'), disabled}, Validators.required],

      customer: this.fb.group({
        customerTitle:    [{value: this.getCustomerStr('customerTitle'),    disabled}],
        customerForename: [{value: this.getCustomerStr('customerForename'), disabled}, Validators.required],
        customerSurname:  [{value: this.getCustomerStr('customerSurname'),  disabled}, Validators.required],
        customerPostcode: [{value: this.getCustomerStr('customerPostcode'), disabled}, Validators.required],
      }),
      lastServiceDate:   [{value: this.getFieldDate('lastServiceDate'),  disabled}, Validators.required],
      lastServiceStatus: [{value: this.getFieldStr('lastServiceStatus'), disabled}, Validators.required],

      flags: this.fb.group({
        mc1Used:           [{value: this.getFlag('mc1Used'),           disabled}],
        mc3Used:           [{value: this.getFlag('mc3Used'),           disabled}],
        magnaCleanseFlush: [{value: this.getFlag('magnaCleanseFlush'), disabled}],
        magnaCleanFitted:  [{value: this.getFlag('magnaCleanFitted'),  disabled}],
        waterTestComplete: [{value: this.getFlag('waterTestComplete'), disabled}],
        serviceComplete:   [{value: this.getFlag('serviceComplete'),   disabled}],
      }),
    });
  }

  /**
   * Returns a field value as a JavaScript Date, or defaults to the current
   * date
   *
   * @param {string} field Name of the field
   * @return {Date} Field value as a Date or current date
   */
  getFieldDate(field: string): Date {
    return this.serviceRecord
      ? moment(this.serviceRecord[field]).toDate()
      : moment().toDate();
  }

  /**
   * Returns a field value as a string, or defaultValue if undefined
   *
   * @param {string} field        Name of the field
   * @param {string} defaultValue Value to return if field is undefined in model
   * @return {string}
   */
  getFieldStr(field: string, defaultValue: string = ''): string {
    return this.serviceRecord
      ? this.serviceRecord[field]
      : defaultValue;
  }

  /**
   * Returns a customer field value as a string, or defaultValue if undefined
   *
   * @param {string} field        Name of the field in Customer model
   * @param {string} defaultValue Value to return if field is undefined in model
   * @return {string}
   */
  getCustomerStr(field: string, defaultValue: string = ''): string {
    return this.serviceRecord
      ? this.serviceRecord.customer[field]
      : defaultValue;
  }

  /**
   * Returns a specified flag from the service record
   *
   * @param {string} field Name of the flag field
   * @return {boolean}
   */
  getFlag(field: string): boolean {
    return this.serviceRecord
      ? this.serviceRecord.flags[field]
      : false;
  }

  /**
   * Emits an updated ServiceRecord model to onSave() if the form is valid,
   * otherwise sets page errors
   */
  onSubmitForm() {
    if (this.fg.valid)
    {
      this.errors = null;
      this.onSave.emit(
        ServiceRecord.fromFormData(
          Object.assign({}, this.fg.value, {id: this.serviceRecord ? this.serviceRecord.id : null})
        )
      );
    }
    else
      this.errors = 'The form contains errors. Please correct them and try again';
  }
}
