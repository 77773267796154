import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface GenericModalProperties {
  title: string;
  content: string;
  confirmLabel: string;
  dismissLabel: string;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
})
export class GenericModalComponent {

  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericModalProperties,
  ) { }

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public dismiss(): void {
    this.dialogRef.close(false);
  }

}
