import { Injectable } from '@angular/core';

import {DatatableService} from './datatable.service';
import {ApiService} from '../../services/api.service';

@Injectable()
export class CompanyAddMemberService {

  constructor(
      private apiService: ApiService,
      private dataTable: DatatableService
  ) {

  }

}
