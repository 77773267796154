<div class="container-fluid">
  <div class="row content-page no-pad">

    <div class="col-sm-12 padded-container overflow">
      <div class="container-title">
        <h1 class="white">Social hub</h1>
      </div>

      <div class="social-container background-white col-xs-12" *ngIf="state$ | async; let socialFeed">
        <app-waiting-spinner kind="bar" *ngIf="socialFeed.socialPending">Loading social feed...</app-waiting-spinner>

        <!-- <div class="social-list" *ngIf="socialFeed.facebookFeed">
          <div [class]="'social-item-container'  + (fbItem.url ? ' pointer': '')"
            *ngFor="let fbItem of socialFeed.facebookFeed" (click)="openLink(fbItem.url)">
            <div class="social-item border-box border-lighter-gray" *ngIf="fbItem.description || fbItem.image">
              <div class="icon">
                <i aria-hidden="true" class="fa fa-facebook-official"></i> {{ fbItem.date | fromNow }}
              </div>
              <div class="ie-image-patch" *ngIf="fbItem.image" [style.backgroundImage]="'url(' + fbItem.image + ')'">
                <img [src]="fbItem.image">
              </div>
              <div *ngIf="fbItem.description" [innerHTML]="fbItem.description"></div>
            </div>
          </div>
        </div> -->

        <div class="social-list" *ngIf="socialFeed.twitterFeed">
          <div [class]="'social-item-container'  + (twitterItem.url ? ' pointer': '')"
            *ngFor="let twitterItem of socialFeed.twitterFeed" (click)="openLink(twitterItem.url)">
            <div class="social-item border-box border-lighter-gray"
              *ngIf="twitterItem.description || twitterItem.image">
              <div class="icon">
                <i aria-hidden="true" class="fa fa-twitter-square"></i> {{ twitterItem.date | fromNow }}
              </div>
              <div class="ie-image-patch" *ngIf="twitterItem.image"
                [style.backgroundImage]="'url(' + twitterItem.image + ')'">
                <img [src]="twitterItem.image">
              </div>
              <p *ngIf="twitterItem.description">{{ twitterItem.description }}</p>
            </div>
          </div>
        </div>

        <!-- <div class="social-list" *ngIf="socialFeed.instagramFeed">
          <div [class]="'social-item-container'  + (instagramItem.url ? ' pointer': '')"
            *ngFor="let instagramItem of socialFeed.instagramFeed" (click)="openLink(instagramItem.url)">
            <div class="social-item border-box border-lighter-gray"
              *ngIf="instagramItem.description || instagramItem.image">
              <div class="icon">
                <i aria-hidden="true" class="fa fa-instagram"></i> {{ instagramItem.date | fromNow }}
              </div>
              <div class="ie-image-patch" *ngIf="instagramItem.image"
                [style.backgroundImage]="'url(' + instagramItem.image + ')'">
                <img [src]="instagramItem.image">
              </div>
              <div *ngIf="instagramItem.description" [innerHTML]="instagramItem.description"></div>
            </div>
          </div>
        </div> -->

        <div class="clearfix"></div>

      </div>
    </div>

  </div>
</div>