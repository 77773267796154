import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { AdminService } from '../../services/admin.service';
import { ContentService } from '../../services/content.service';
import * as ActionTypes from '../actions/content-pages';
import { FetchPageContentResponse, UpdatePageContentResponse, } from '../../models/content-pages';
var ContentPagesEffects = /** @class */ (function () {
    function ContentPagesEffects(adminService, contentService, actions$) {
        var _this = this;
        this.adminService = adminService;
        this.contentService = contentService;
        this.actions$ = actions$;
        /**
         * For a FetchPageContentRequestAction, call
         * ContentService::fetchPageContent() and dispatch a new
         * FetchPageContentResponseAction with the response.
         */
        this.fetchPageContent$ = this.actions$
            .ofType(ActionTypes.FETCH_PAGE_CONTENT_REQUEST)
            .switchMap(function (req) {
            return _this.contentService.fetchPageContent()
                .map(function (res) {
                return new ActionTypes.FetchPageContentResponseAction(res);
            });
        });
        /**
         * For an UpdatePageContentRequestAction, call
         * AdminService::updatePageContent() and dispatch a new
         * UpdatePageContentResponseAction with the response.
         */
        this.updatePageContent$ = this.actions$
            .ofType(ActionTypes.UPDATE_PAGE_CONTENT_REQUEST)
            .switchMap(function (req) {
            return _this.adminService.updatePageContent(req.payload)
                .map(function (res) {
                return new ActionTypes.UpdatePageContentResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ContentPagesEffects.prototype, "fetchPageContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ContentPagesEffects.prototype, "updatePageContent$", void 0);
    return ContentPagesEffects;
}());
export { ContentPagesEffects };
