<div class="container-fluid company-page">
    <div class="row content-page" *ngIf="this.dashboard$ | async;let stateTopDashboard">

        <div class="container-title has-sub">
          <h1 class="white">
            <span *ngIf="title">{{title}}</span>
            <span *ngIf="companyName">{{companyName}}</span>
          </h1>
        </div>
        <div class="panel-table col-xs-12">
            <div class="top-dashboard">
                <div *ngIf="isCustomerService" class="top-box-total"  (click)="waterTestListCompany()">
                    <h1>Companies <span class="numbers" *ngIf="!stateTopDashboard.pendingTotalCompany">{{stateTopDashboard.totalCompany}}</span></h1>
                    <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTotalCompany"></app-waiting-spinner>
                </div>
                <div *ngIf="(isWaterTesterAdmin || isCustomerService)" class="top-box-total"   (click)="testerListCompany()">
                    <h1>Water Testers <span class="numbers" *ngIf="!stateTopDashboard.pendingTotalTester">{{stateTopDashboard.totalTester}}</span></h1>
                    <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTotalTester"></app-waiting-spinner>
                </div>
            </div>


            <div class="box-total grey" (click)="waterTests('all')">
                <h1>Total Water<br>Tests</h1>
                <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTestTotal"></app-waiting-spinner>
                <label class="numbers" *ngIf="!stateTopDashboard.pendingTestTotal">{{stateTopDashboard.testTotal}}</label>
            </div>
            <div class="box-total green" (click)="waterTests('passed')">
                <h1>Properties<br>OK</h1>
                <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTestStatusPropertyTotalPass"></app-waiting-spinner>
                <label class="numbers" *ngIf="!stateTopDashboard.pendingTestStatusPropertyTotalPass">{{stateTopDashboard.testStatusPropertyTotalPass}}</label>
            </div>
            <div class="box-total red white-theme" (click)="waterTests('failed')">
                <h1>Properties with<br>Recommendations</h1>
              <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTestStatusPropertyTotalFail"></app-waiting-spinner>
              <label class="numbers" *ngIf="!stateTopDashboard.pendingTestStatusPropertyTotalFail">{{stateTopDashboard.testStatusPropertyTotalFail}}</label>
            </div>
            <div class="box-total-split transparent ">
                <div class="split-box blue white-theme" (click)="waterTestsDueNextMonth(12,11)" >
                    <p class="white" *ngIf="stateTopDashboard.pendingTestOverDueTotalNextMonth">Loading...</p>
                    <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTestOverDueTotalNextMonth"></app-waiting-spinner>
                  <h3 *ngIf="!stateTopDashboard.pendingTestOverDueTotalNextMonth">Properties due<br>test in next 30 days<br><strong>{{stateTopDashboard.totalTestOverDueNextMonth}}</strong></h3>
                </div>
                <div class="split-box blue white-theme"  (click)="waterTestsDue(12)" style="margin-top: 2.5%;">
                    <p class="white" *ngIf="stateTopDashboard.pendingTestOverDueTotal">Loading...</p>
                    <app-waiting-spinner kind="spinner" *ngIf="stateTopDashboard.pendingTestOverDueTotal"></app-waiting-spinner>
                  <h3 *ngIf="!stateTopDashboard.pendingTestOverDueTotal">Properties with<br/>tests overdue<br><strong>{{stateTopDashboard.totalTestOverDue}}</strong></h3>
                </div>
            </div>
            <div class="email-container">
                <div class="formInput">
                    <app-waiting-spinner *ngIf="saving" class="icon-bar text-center color-white" kind="bar">

                    </app-waiting-spinner>
                    <div *ngIf="(errors||success)">
                        <div *ngIf="errors" class="alert alert-warning text-center">
                            {{message}}
                        </div>
                        <div *ngIf="success" class="alert alert-success text-center">
                            {{message}}
                        </div>
                    </div>
<!--                    <form [formGroup]="emailForm" (ngSubmit)="onFormSubmit()">-->
<!--                        <div [class]="formGroupClass('name','col-xs-12')" style="padding-left: 0">-->
<!--                            <label class="notification" for="notification" ><input id="notification"  type="checkbox" class="form-control" formControlName="notification">Notify me</label>-->
<!--                            <input  type="email" placeholder="Email Address" class="form-control" formControlName="email" />-->
<!--                            <button class="btn background-green color-white pull-right no-radius submit-button btn-margin btn-email">Save</button>-->
<!--                        </div>-->
<!--                    </form>-->
                </div>
            </div>
            <h4 class="note">Note: Dashboard results update every 1 minute.</h4>
        </div>
    </div>
</div>
