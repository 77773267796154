import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { CompanyService } from '../../services/company.service';
import { Company } from '../../models/company';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  spinnerMessage = '';
  loadingData = false;
  regCompany = false;
  companyId = 0;
  title = '';
  companyForm: FormGroup;
  formSubmitted = false;
  errors = false;
  success = false;
  message = '';
  companyDetails = [];
  emailPattern = '^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$';

  constructor(
      private formBuilder: FormBuilder,
      private companyMngService: CompanyService,
      private route: ActivatedRoute,
      private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
         this.title = (params['id'] > 0) ? 'Edit' : 'Register';
         this.companyId = (params['id'] > 0) ? params['id'] : 0;
          } );
    this.createCompanyForm();
  }

  createCompanyForm() {
    if (this.title === 'Edit') {
      this.loadingData = true;
      this.spinnerMessage = 'Loading data...';
      this.companyMngService.getCompanyById(this.companyId).subscribe(
          resp => {
            this.companyDetails = resp['data'][0];
            this.buildForm(this.companyDetails);
            this.loadingData = false;
          },
          err => {
            this.errors = true;
            this.message = 'The system can\'t get the company details';
          }
      );
    }else {
      this.buildForm([]);
    }
  }

  onFormSubmit() {

    this.success = false;
    this.errors = false;

    if (window)
      window.scrollTo(0, 0);

    this.formSubmitted = true;
    if (this.companyForm.valid) {

      const company: Company = this.companyForm.value;

      if (this.companyId > 0) {

        this.loadingData = true;
        this.spinnerMessage = 'Updating company...';
        this.companyMngService.editCompany(company, this.companyId).subscribe(
            resp => {
              this.success = true;
              this.message = 'Company successfully updated.';
              this.loadingData = false;
            },
            err => {
              this.errors = true;
              this.message = err.message;
              this.loadingData = false;
            }
        );
      }else {
        this.regCompany = true;
        this.spinnerMessage = 'Registering company...';
        this.companyMngService.createCompany(company).subscribe(
            resp => {
              if ( resp['original']['data'][0]['id'] > 0)
              {
                this.message = 'Company successfully registered.';
                this.success = true;
                this.regCompany = false;
                this.formSubmitted = false;
              }else {
                this.errors = true;
                this.message = 'Something went wrong!';
                this.regCompany = false;
              }

            },
            err => {
              this.errors = true;
              this.message = err.message;
              this.regCompany = false;
            }
        );
        this.companyForm.reset();
      }

    }
    else
    {
      this.errors = true;
      this.message = 'The form contains errors. Please correct the highlighted fields and try again.';
    }
  }

  buildForm(details) {

    //console.log(details);
    this.companyForm = this.formBuilder.group({
      name: [details['name'], [Validators.required]],
      postcode: [details['postcode'], Validators.required ],
      address_line_1: [details['address_line_1'], Validators.required ],
      address_line_2: [details['address_line_2']],
      address_line_3: [details['address_line_3']],
      town: [details['town'], Validators.required ],
      county: [details['county']],
      country: [details['country'], Validators.required ],
      contact_email: [details['contact_email'], [Validators.pattern(this.emailPattern)] ],
      contact_tel: [details['contact_tel']],
      comments: [details['comments']]
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.companyForm.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.formSubmitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Navigates to the /watertest/company/:id route
   */
  watertestBackResultsCompany() {
    this.router.navigate(['/watertest/company/' + this.companyId]);
  }

  /**
   * Navigates to the /watertest/company route
   */
  watertestListCompany() {
    this.router.navigate(['/watertest/company']);
  }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }
}
