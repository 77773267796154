import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


/**
 * Summary
 *    Dialog used for editing a user's reward points (amount earned and spent)
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-rewards-edit-modal',
  templateUrl: './admin-rewards-edit-modal.component.html',
  styleUrls: ['./admin-rewards-edit-modal.component.scss']
})
export class AdminRewardsEditModalComponent implements OnInit {

  // Points edit form
  public fg: FormGroup;

  // Errors for display
  public errors: string = null;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminRewardsEditModalComponent>,
    private fb:        FormBuilder
  ) {
    this.fg = this.fb.group({
      pointsEarned: [this.data.user ? this.data.user.points.earned : 0, Validators.required],
      pointsSpent:  [this.data.user ? this.data.user.points.spent  : 0, Validators.required],
    });
  }

  ngOnInit() { }


  /**
   * Closes the dialog and sends the updated values if the form is valid
   */
  onSave() {
    if (this.fg.valid)
    {
      const earned: number = parseInt(this.fg.value.pointsEarned, 10);
      const spent:  number = parseInt(this.fg.value.pointsSpent,  10);

      if (earned < 0)
        this.errors = 'Points earned must be >= 0';
      else if (spent < 0)
        this.errors = 'Points spent must be >= 0';
      else if (spent > earned)
        this.errors = 'Points spent must be <= points earned';
      else
        this.dialogRef.close({earned, spent});
    }
    else
      this.errors = 'The form is invalid. Please correct any errors and try again.';
  }
}
