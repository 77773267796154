import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { CountryService } from '../../services/country.service';
import * as ActionTypes from '../actions/country';
var CountryEffects = /** @class */ (function () {
    function CountryEffects(countryService, actions$) {
        var _this = this;
        this.countryService = countryService;
        this.actions$ = actions$;
        this.countryRequest$ = this.actions$
            .ofType(ActionTypes.COUNTRY_REQUEST)
            .switchMap(function () {
            return _this.countryService.getCountries()
                .map(function (res) { return new ActionTypes.CountryResponseAction(res); });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CountryEffects.prototype, "countryRequest$", void 0);
    return CountryEffects;
}());
export { CountryEffects };
