import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { WarrantyService } from '../../services/warranty.service';

import * as ActionTypes from '../actions/register-warranty';

import {
  CheckPromoCodeResponse,
  FetchProductTypesResponse,
  RegisterWarrantyRequest,
  RegisterWarrantyResponse,
} from '../../models/warranty';

@Injectable()
export class RegisterWarrantyEffects {
  constructor(
    private service:  WarrantyService,
    private actions$: Actions
  ) {}

  /**
   * For a CheckPromoCodeRequestAction, call WarrantyService::checkPromoCode()
   * and dispatch a new CheckPromoCodeResponseAction with the response.
   */
  @Effect() checkPromoCode$: Observable<Action> = this.actions$
    .ofType(ActionTypes.CHECK_PROMO_CODE_REQUEST)
    .switchMap((req: ActionTypes.CheckPromoCodeRequestAction): Observable<ActionTypes.CheckPromoCodeResponseAction> =>
      this.service.checkPromoCode(req.payload)
        .map((res: CheckPromoCodeResponse): ActionTypes.CheckPromoCodeResponseAction =>
          new ActionTypes.CheckPromoCodeResponseAction(res)
        )
    );
  
  /**
   * For a FetchProductTypesRequestAction, call
   * WarrantyService::FetchProductTypes() and dispatch a new
   * FetchProductTypesResponseAction with the response.
   */
  @Effect() FetchProductTypesRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.FETCH_PRODUCT_TYPES_REQUEST)
    .switchMap((req: ActionTypes.FetchProductTypesRequestAction): Observable<Action> => {
      return this.service.FetchProductTypes(true)
        .map((res: FetchProductTypesResponse): ActionTypes.FetchProductTypesResponseAction =>
          new ActionTypes.FetchProductTypesResponseAction(res)
        );
    });

  /**
   * For a RegisterWarrantyRequestAction, call
   * WarrantyService::submit() and dispatch a new
   * RegisterWarrantyResponseAction with the response.
   */
  @Effect() registerWarrantyRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.REGISTER_WARRANTY_REQUEST)
    .switchMap((req: ActionTypes.RegisterWarrantyRequestAction): Observable<Action> => {
      return this.service.submit(req.payload)
        .map((res: RegisterWarrantyResponse): ActionTypes.RegisterWarrantyResponseAction =>
          new ActionTypes.RegisterWarrantyResponseAction(res)
        );
    });
}
