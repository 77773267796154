import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { SystemMessageService } from '../../services/system-message.service';
import * as ActionTypes from '../actions/system-message';
var SystemMessageEffects = /** @class */ (function () {
    function SystemMessageEffects(systemMessageService, actions$) {
        var _this = this;
        this.systemMessageService = systemMessageService;
        this.actions$ = actions$;
        /**
         * For a SystemMessageRequestAction, call SystemMessage::getSystemMessage() and
         * dispatch a new SystemMessageResponseAction with the response.
         */
        this.systemMessageRequest$ = this.actions$
            .ofType(ActionTypes.SYSTEM_MESSAGE_REQUEST)
            .switchMap(function () {
            //console.log('effects');
            return _this.systemMessageService.getSystemMessage()
                .map(function (res) { return new ActionTypes.SystemMessageResponseAction(res); });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], SystemMessageEffects.prototype, "systemMessageRequest$", void 0);
    return SystemMessageEffects;
}());
export { SystemMessageEffects };
