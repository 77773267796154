<mat-dialog-content style="font-size: 18px;">
  <div class="row">
    <div class="col-xs-2 col-sm-2" style="font-weight: 600;">
      Device:
    </div>
    <div class="col-xs-10 col-sm-10">
      {{ device.filterNo }} - {{ device.serialNo }}
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12">
      Did you intend to discard the following note?
    </div>
  </div>
  <div class="row">
    <div class="col-xs-2 col-sm-2" style="font-weight: 600;">
      Note:
    </div>
    <div class="col-xs-10 col-sm-10" style="max-width: 400px;">
      {{ noteContent }}
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="font-size: 18px;">
  <button mat-button class="mat-button mat-button-base dialog-action" [matDialogClose]="true"><div class="content">Save</div><i class="fa fa-save"></i></button>
  <button mat-button class="mat-button mat-button-base dialog-action" [matDialogClose]="false"><div class="content">Discard</div><i class="fa fa-times"></i></button>
</mat-dialog-actions>
