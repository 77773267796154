/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resultlistadmin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../tableresults/tableresults.component.ngfactory";
import * as i4 from "../tableresults/tableresults.component";
import * as i5 from "../../services/tableresults.service";
import * as i6 from "../../services/checkboxlist.service";
import * as i7 from "../../services/datatable.service";
import * as i8 from "@angular/router";
import * as i9 from "./resultlistadmin.component";
import * as i10 from "../../services/resultlistadmin.service";
import * as i11 from "../../services/dashboard.service";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/material/dialog";
var styles_ResultListAdminComponent = [i0.styles];
var RenderType_ResultListAdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResultListAdminComponent, data: {} });
export { RenderType_ResultListAdminComponent as RenderType_ResultListAdminComponent };
function View_ResultListAdminComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n              ", "\n          "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.titleFilter; _ck(_v, 1, 0, currVal_0); }); }
function View_ResultListAdminComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h2", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultListAdminComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.titleFilter; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ResultListAdminComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn background-green color-white btn-result"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportResult() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Export results"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exportButtonDisabled; _ck(_v, 0, 0, currVal_0); }); }
function View_ResultListAdminComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "div", [["class", "container-fluid company-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 34, "div", [["class", "row content-page"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 31, "div", [["class", "content-watertest"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "container-title has-sub water-results-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "white"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultListAdminComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(17, 0, null, null, 13, "div", [["class", "navigator-watertest-table"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn background-adey-red color-white btn-result btn-margin-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/watertest/dashboard") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back to dashboard"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn background-adey-red color-white btn-result btn-margin-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigatesBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back to previous page"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn background-green color-white btn-result btn-margin-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendResultByEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Email results"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultListAdminComponent_4)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "app-tableresults", [["admin", "true"]], null, [[null, "resultIds"], [null, "tableSearch"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resultIds" === en)) {
        var pd_0 = (_co.checkResultsIds($event) !== false);
        ad = (pd_0 && ad);
    } if (("tableSearch" === en)) {
        var pd_1 = (_co.checkSearchTable($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_TableResultsComponent_0, i3.RenderType_TableResultsComponent)), i1.ɵdid(33, 114688, null, 0, i4.TableResultsComponent, [i5.TableResultsService, i6.CheckBoxListService, i7.DatatableService, i8.Router], { search: [0, "search"], tableType: [1, "tableType"], admin: [2, "admin"], lastTest: [3, "lastTest"] }, { resultIds: "resultIds", tableSearch: "tableSearch" }), (_l()(), i1.ɵted(-1, null, ["\n        Loading table...\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_v.context.$implicit.pendingList && _co.noSubTitle); _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.showExportButton; _ck(_v, 29, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.search, ""); var currVal_4 = _co.tableType; var currVal_5 = "true"; var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.lastTest, ""); _ck(_v, 33, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 9, 0, currVal_0); }); }
export function View_ResultListAdminComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ResultListAdminComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.waterTester$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ResultListAdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resultlistadmin", [], null, null, null, View_ResultListAdminComponent_0, RenderType_ResultListAdminComponent)), i1.ɵdid(1, 245760, null, 0, i9.ResultListAdminComponent, [i8.Router, i10.ResultListAdminService, i8.ActivatedRoute, i11.DashboardService, i12.Store, i13.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResultListAdminComponentNgFactory = i1.ɵccf("app-resultlistadmin", i9.ResultListAdminComponent, View_ResultListAdminComponent_Host_0, {}, {}, []);
export { ResultListAdminComponentNgFactory as ResultListAdminComponentNgFactory };
