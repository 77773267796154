import { Component, OnInit, Inject } from '@angular/core';
import { Store }                     from '@ngrx/store';
import { Observable }                from 'rxjs/Observable';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


import { StoreState }                   from '../../../state-management/store';
import { State as PostcodeLookupState } from '../../../state-management/reducers/postcode-lookup';

import {
  AddressListItem,
  FullAddressItem,
} from '../../../models/postcode-lookup';

import {
  GetPostcodeAddressRequestAction,
  PostcodeLookupRequestAction,
} from '../../../state-management/actions/postcode-lookup';

/**
 * Summary
 *    Autofill an address by passing a postcode to the external PostcodeLookup api.
 *
 *
 * Description
 *    Display a list of addresses under a given postcode. On selection of an address, passes
 *    the information back to the parent component.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-postcode-lookup-modal',
  templateUrl: './postcode-lookup-modal.component.html',
  styleUrls: ['./postcode-lookup-modal.component.scss']
})
export class PostcodeLookupModalComponent implements OnInit {

  // Store a reference to the postcode lookup state
  public postcodeLookupState$: Observable<PostcodeLookupState> = null;

  /**
   * Constructor for page
   *
   * @param {MAT_DIALOG_DATA} data                                  Contains reference to data passed into the modal when created
   * @param {MatDialogRef<PostcodeLookupModalComponent>} dialogRef  Create a reference to this dialog
   * @param {Store<StoreState>} store                               Initialises reference to the store
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PostcodeLookupModalComponent>,
    private store:     Store<StoreState>,
  ) {
    // Reference the postcode lookup state
    this.postcodeLookupState$ = this.store.select('postcodeLookup');
  }

  /**
   *  Dispatch PostcodeLookupRequestAction to the store in order to retrieve a list of addresses
   *  within the given postcode
   */
  ngOnInit() {
    if (this.data && this.data.postcode)
      this.store.dispatch(new PostcodeLookupRequestAction({postcode: this.data.postcode}));
  }

  /**
   * Return data for the selected address and close the modal
   *
   * @param {AddressListItem} address Contains the id for the selected address
   */
  onSelectItem(address: AddressListItem) {
    // If the address doesn't have an ID close the modal
    if (!address.id)
      this.dialogRef.close(false);

    // Dispatch GetPostcodeAddressRequestAction with the selected Address ID and
    // set a callback to close the modal with the full address information
    this.store.dispatch(
      new GetPostcodeAddressRequestAction({
        id:       address.id,
        callback: (a: FullAddressItem) => this.dialogRef.close(a),
      })
    );
  }

}
