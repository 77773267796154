import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable }   from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Store }        from '@ngrx/store';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';


import { b64ToBlob } from '../../../utility';

import { StoreState }             from '../../../state-management/store';
import { State as WarrantyState } from '../../../state-management/reducers/warranty-list';

import { WarrantyService } from '../../../services/warranty.service';

import {
  InitWarrantyRequestAction,
  FetchWarrantyRequestAction,
  FetchWarrantyPdfRequestAction,
} from '../../../state-management/actions/warranty-list';

import {
  ProductType,
  Warranty,
  FetchProductTypesResponse,
} from '../../../models/warranty';

import { PrintableWarrantyComponent } from '../printable-warranty/printable-warranty.component';
import { WarrantyDetailsComponent }   from '../warranty-details/warranty-details.component';


/**
 * Summary
 *    Provides a modal dialog which can be used to view, edit, print and
 *    download a Warranty
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-warranty-edit-modal',
  templateUrl: './warranty-edit-modal.component.html',
  styleUrls: ['./warranty-edit-modal.component.scss'],
})
export class WarrantyEditModalComponent implements OnDestroy, OnInit {

  // References to child components
  @ViewChild(PrintableWarrantyComponent) compPrintable: PrintableWarrantyComponent;
  @ViewChild(WarrantyDetailsComponent)   compDetails:   WarrantyDetailsComponent;


  // Set when view is being printed
  public printMode: boolean = false;

  // Store states and Subscription
  public  state$:        Observable<WarrantyState>;
  public  productTypes$: Observable<FetchProductTypesResponse>;
  private stateSub:      Subscription = null;

  // When set, form is tailored for admin users
  public adminMode: boolean = false;

  // When set, for is read-only
  public viewMode: boolean = true;

  // Set automatically if an IE-based browser is detected in order to provide
  // IE-specific file download functionality
  public ieDownloadMode: boolean = false;

  // Data URL for a downloadable PDF version (generated via API)
  public pdfUrlStr: string  = null;
  public pdfUrl:    SafeUrl = null;


  public productTypes: Object = {};


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<WarrantyEditModalComponent>,
    private sanitizer: DomSanitizer,
    private service:   WarrantyService,
    private store:     Store<StoreState>
  ) {
    this.adminMode = !!data.adminMode;
    this.viewMode  = !data.edit;

    this.state$ = this.store.select('warrantyList');
    this.store.dispatch(new InitWarrantyRequestAction());

    if (data.warrantyId)
      this.getWarrantyData(data.warrantyId);
  }

  ngOnInit() {
    this.productTypes$ = this.service.FetchProductTypes(true);

    // Set ieDownloadMode based on IE browser detection
    this.ieDownloadMode = !!(navigator['msSaveBlob']) && !!(window['Blob']);

    this.stateSub = this.state$.subscribe((state: WarrantyState) => {

      // Update pdfUrlStr and pdfUrl when the state changes
      if (state.pdfData)
      {
        // pdfData is a base64 string so encode it and create a data URL
        this.pdfUrlStr = 'data:application/pdf;base64,' + encodeURIComponent(state.pdfData);

        // Sanitize the URL string so that it can be applied to the template
        // attribute
        this.pdfUrl = this.sanitizer.bypassSecurityTrustUrl(this.pdfUrlStr);
      }
      else
      {
        this.pdfUrlStr = null;
        this.pdfUrl    = null;
      }

    });
  }

  ngOnDestroy() {
    if (this.stateSub) {
      this.stateSub.unsubscribe();
    }
  }


  /**
   * Returns a suitable PDF filename for the specified Warranty
   *
   * @param {Warranty} w
   * @return {string}
   */
  getDownloadFilename(w: Warranty): string {
    let name: string = 'Warranty record.pdf';

    if (w && w.customer)
      name = `${w.customer.customerTitle} ${w.customer.customerSurname}.pdf`;
    else if (w)
      name = `Warranty record ${w.id}.pdf`;

    return name;
  }

  /**
   * Fetches the specified Warranty
   *
   * @param {string} warrantyId
   */
  getWarrantyData(warrantyId: string) {
    this.store.dispatch(new FetchWarrantyRequestAction({id: warrantyId}));
  }

  /**
   * Returns the view to normal view/edit mode
   */
  hidePrintVersion() {
    this.printMode = false;
  }

  /**
   * Handles downloading of the PDF data (in pdfUrlStr) for IE browsers.
   * Warranty is required in order to obtain the correct filename.
   *
   * @param {Warranty} w Warranty model used for generating filename
   */
  ieDownload(w: Warranty) {
    if (!this.ieDownloadMode)
      return;

    const dataParts: string[] = this.pdfUrlStr.split(',');
    if (dataParts.length !== 2)
      return;

    const data: string = decodeURIComponent(dataParts[1]);

    const mimeParts: string[] = dataParts[0].match(/:(.*?);/);
    if (mimeParts.length < 2)
      return;

    navigator['msSaveBlob'](b64ToBlob(mimeParts[1], data), this.getDownloadFilename(w));
  }

  /**
   * Calls WarrantyDetailsComponent::onSubmitForm() to save the Warranty
   */
  onSaveClick() {
    this.compDetails.onSubmitForm();
  }

  /**
   * Handler for WarrantyDetailsComponent::onSubmit() EventEmitter; closes the
   * dialog with the updated Job/Warranty model.
   *
   * @param {Job|Warranty} v Updated Job/Warranty model from WarrantyDetailsComponent
   */
  onSubmitForm(v: any) {
    this.dialogRef.close(v);
  }

  /**
   * Calls PrintableWarrantyComponent::printView() (child component) to cause
   * the browser to print the Warranty
   */
  printView() {
    this.compPrintable.printView();
  }

  /**
   * Switches the view to print mode and requests a PDF version of the
   * specified Warranty
   *
   * @param {Warranty} w
   */
  showPrintVersion(w: Warranty) {
    this.printMode = true;

    // Fetch PDF data from API
    this.store.dispatch(new FetchWarrantyPdfRequestAction({id: w.id}));
  }
}
