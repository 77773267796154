import { Injectable } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { DatatableService } from './datatable.service';

@Injectable()
export class CompanyListService {

  constructor(
      private apiService: ApiService,
      private dataTable: DatatableService
  ) {

  }

  public getCompanies(dataTablesParameters) {

    const param = this.dataTable.joinParameters(dataTablesParameters);
    return this.apiService.apiGet('/watertest/company' + param);

  }
}
