import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import * as ActionTypes from '../actions/feature';
import { FeatureService } from '../../services/feature.service';
import { FeatureErrorResponse, FeatureResponse } from '../../models/feature';

@Injectable()
export class FeatureEffects {
  constructor(
    private service: FeatureService,
    private actions$: Actions
  ) { }

  @Effect() sparesRewardsRequest$: Observable<Action> = this.actions$
    .ofType(ActionTypes.GET_FEATURES)
    .switchMap(() => this.service.getUserFeatures()
      .then((payload: FeatureResponse) => new ActionTypes.GetFeaturesSuccess(payload.resource))
      .catch((error: FeatureErrorResponse) => new ActionTypes.GetFeaturesFailure(error))
    );
}
