import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { PageField, SitePage } from '../../../../../models/content-pages';

/**
 * Summary
 *    Provides a modal dialog used for editing page fields
 *
 * Description
 *    Each field for the page is displayed and the actual edit component used
 *    is based on the field type ("text", "textarea" or "rich").
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-content-page-edit-modal',
  templateUrl: './admin-content-page-edit-modal.component.html',
  styleUrls: ['./admin-content-page-edit-modal.component.scss']
})
export class AdminContentPageEditModalComponent {

  // Errors for display
  public errors: string = null;

  // Edit form
  public fg: FormGroup;

  // SitePage being edited
  public page: SitePage = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminContentPageEditModalComponent>,
    private fb: FormBuilder
  ) {

    // Generate the edit form if a SitePage has been passed in the dialog data
    if (data.page && data.page.fields) {
      this.page = data.page as SitePage;

      this.fg = this.fb.group(
        this.page.fields.reduce((a: any, v: PageField): any => {
          a[v.fieldName] = v.current;
          return a;
        }, {})
      );
    }

  }

  /**
   * Handler for the RichEditorComponent::onChange() EventEmitter: updates the
   * form control with the new value.
   *
   * @param {PageField} field   The field that has been changed
   * @param {any}       content The updated content of the field
   */
  onRichEditorChange(field: PageField, content: any) {
    const fc: AbstractControl = this.fg.controls[field.fieldName];
    fc.setValue(content);
  }

  /**
   * Called when the SitePage is to be saved and the dialog closed
   */
  onSave() {
    if (this.fg.valid) {
      this.errors = null;
      this.dialogRef.close(
        Object.assign({}, this.page, {
          fields: this.page.fields.map((f: PageField): PageField =>
            Object.assign({}, f, { current: this.fg.value[f.fieldName] })
          )
        })
      );
    }
    else
      this.errors = 'The form contains errors. Please correct them and try again.';
  }
}
