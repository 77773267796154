import {Component, OnInit} from '@angular/core';
import {ResultService} from '../../services/result.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ResultEmailComponent} from '../result-email/result-email.component';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs/Observable';
import {State as LoginDetailsState} from '../../../state-management/reducers/login-details';
import {Store} from '@ngrx/store';
import {StoreState} from '../../../state-management/store';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
    public latitude = -28.68352;
    public longitude = -147.20785;

    public loadingData: boolean = true;
    public test: any[];
    public dateTest: string = '';
    public timeTest: string = '';
    public engineer: string = '';
    public email:    string = '';
    public reason:   string = '';
    public installationType: string = '';
    public serialBoiler: string = null;
    public jobNumber: string = null;
    public location:  string = '';
    public postcode:  string = '';
    public resultId: number = 0;
    public image: any = null;
    public recommendation: string = '';
    public notes_address: string = '';
    public notes_test: string = '';
    public usingFilter: string = 'No';

    public loginDetails$: Observable<LoginDetailsState>;

    constructor(
        private resultService: ResultService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private store: Store<StoreState>,
    ) {
      this.loginDetails$ = this.store.select('loginDetails');
    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            this.resultId = (params['id'] > 0) ? params['id'] : 0;
        });

        if (!(this.resultId > 0)) {
            this.router.navigate(['/watertest/dashboard']);
        } else {
            this.resultService.getResultById(this.resultId).subscribe(
                resp => {
                    //console.log(resp);
                    const data = resp['data'][0];

                    let datetime = data['test_timestamp'];
                    datetime = datetime.split(' ');
                    this.dateTest = this.formatDate(datetime[0]);
                    this.timeTest = this.formatAMPM(datetime[1]);

                    this.location = this.cleanAddress(
                      data['watertest_location_by_watertest_location_id']['house'],
                      data['watertest_location_by_watertest_location_id']['addressLine1'],
                      data['watertest_location_by_watertest_location_id']['addressLine2'],
                      data['watertest_location_by_watertest_location_id']['addressLine3'],
                      data['watertest_location_by_watertest_location_id']['city'],
                      data['watertest_location_by_watertest_location_id']['postcode'],
                      data['watertest_location_by_watertest_location_id']['country']
                      );

                    this.postcode = data['watertest_location_by_watertest_location_id']['postcode'];
                    this.engineer = data['user_by_user_id']['first_name'] + ' ' + data['user_by_user_id']['last_name'];
                    this.email = data['user_by_user_id']['email'];
                    this.recommendation = data['recommendations']['htmlList'];

                    //notes for address
                    this.notes_address = this.cleanString(data['watertest_location_by_watertest_location_id']['note']);

                    this.test = JSON.parse(data['results']);

                    //notes for the test
                    this.notes_test = this.test['notes'];
                    console.log('test notes: ', this.test);
                    this.test['Moly'] = null;
                    this.test['Iron'] = null;
                    this.test['pH1'] = null;
                    this.test['Turbidity'] = null;

                    if (this.test['waterSample']['appearance'] === 'clear') {
                      const resultsArray = this.test['colourTest']['results'];
                      this.test['Moly'] = this.iconResult(resultsArray['molybdate']['outcome'], 'moly');
                      //this.test['Copper'] = this.iconResult(resultsArray ['copper']['outcome']);
                      this.test['Iron'] = this.iconResult(resultsArray ['iron']['outcome'], 'iron');
                      this.test['pH1'] = this.iconResult(resultsArray ['pH1']['outcome'], 'pH1');
                    } else {
                      this.test['Turbidity'] = this.iconResult('fail', 'turbidity');
                    }

                    this.test['OverAll'] = this.iconResultOverAll(data['pass']);
                    this.test['image'] = '';
                    this.test['latitude'] = this.test['userLocation']['latitude'];
                    this.test['longitude'] = this.test['userLocation']['longitude'];
                    this.test['image'] = this.test['waterSample']['image'];
                    this.reason = this.test['systemInfo']['reason'];
                    this.installationType = this.test['systemInfo']['type'];
                    this.serialBoiler = this.test['systemInfo']['serialBoiler'];
                    this.jobNumber = this.test['job_reference_id'];
                    if (this.test['systemInfo']['filter']) {
                        this.usingFilter = 'Yes';
                    }
                    this.loadingData = false;
                }
            );
        }
    }

    /**
     * Print the water test result
     */
    printTest(printableArea) {
        document.body.innerHTML = document.getElementById(printableArea).innerHTML;
        if (document.getElementById('test_card')) {
          document.getElementById('test_card').style.display = 'none';
        }
        document.getElementById('water_sample').className = 'col-xs-12';
        window.print();
        window.location.reload();
    }

    /**
     * Returns icon
     *
     * @param {string} result
     * @param {string} feature
     * @return icon
     */
    iconResult(result: string, feature: string) {
        if (result === 'out of range') {
           if (feature === 'moly') {
             return '<img src="/assets/recommended.png"/>';
           } else if ( feature === 'iron' || feature === 'pH1') {
             return '<img src="/assets/tick.png"/>';
          }
        }
        if (result === 'pass') {
            return '<img src="/assets/tick.png"/>';
        }
        return '<img src="/assets/recommended.png"/>';
    }

    /**
     * Returns icon
     *
     * @param {boolean} result
     * @return icon
     */
    iconResultOverAll(result: boolean) {
      if (result) {
        return '<img src="/assets/tick.png"/>';
      }
      return '<img src="/assets/recommended.png"/>';
    }

    /**
     * Navigates back
     */
    watertestBackResults() {
        window.history.back();
    }

    /**
     * Change from date to 12 pm/am
     */
    formatAMPM(date) {
        const d = date.split(':');
        // tslint:disable-next-line:radix
        const h = parseInt(d[0]);
        // tslint:disable-next-line:radix
        const m = parseInt(d[1]);
        const ampm = h >= 12 ? 'PM' : 'AM';
        let hours = h % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutes = m < 10 ? '0' + m : m;
        return h + ':' + minutes + ' ' + ampm;
    }

    /**
     * format date dd/mm/yyyy
     */
    formatDate(date) {
        const d = date.split('-');
        return d[2] + '/' + d[1] + '/' + d[0];
    }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }

  /**
   * Navigates back
   */
  navigatesBack() {
    window.history.back();
  }

  /**
   * Opens ResultEmailComponent to send the results by email
   */
  openResultEmail() {
    const dialogRef = this.dialog.open(ResultEmailComponent, {
      data: {
        resultIds: [this.resultId],
        noSelected: null,
      }
    });
  }

  /**
   * clean address
   */
  cleanAddress(house, add1, add2, add3, city, postcode, country) {
    const array_address = [];

    if (this.cleanString(house) !== '' ) {
      array_address.push(house);
    }

    if (this.cleanString(add1) !== '' ) {
      array_address.push(add1);
    }

    if (this.cleanString(add2) !== '' ) {
      array_address.push(add2);
    }

    if (this.cleanString(add3) !== '' ) {
      array_address.push(add3);
    }

    if (this.cleanString(city) !== '' ) {
      array_address.push(city);
    }

    if (this.cleanString(postcode) !== '' ) {
      array_address.push(postcode);
    }

    if (this.cleanString(country) !== '' ) {
      array_address.push(country);
    }

    //console.log('address : ', array_address);
    if (array_address.length) {
       return array_address.join(', ');
    }

    return '';
  }

  /**
   * clean address
   */
  cleanString(str) {
    if ( str === null || str === 'null') {
      return '';
    }
    return str.trim();
  }
}


