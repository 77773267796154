/**
 * social reducer: maintains state for social page
 */
import { SocialItem } from '../../models/social';
import * as actions from '../actions/social';

// State interface definition: maintains all state related to social media
// feeds and football scores
export interface State {
  // Pending flags and error message
  socialPending:   boolean;
  error:           string;

  // Social media feed items
  facebookFeed:  SocialItem[];
  twitterFeed:   SocialItem[];
  instagramFeed: SocialItem[];
}


const defaultState: State = {
  error:           null,
  facebookFeed:    null,
  instagramFeed:   null,
  socialPending:   false,
  twitterFeed:     null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to fetch social media items
     */
    case actions.SOCIAL_FEED_REQUEST:
      return Object.assign({}, state, {
        socialPending: true,
        error:         null,
        facebookFeed:  null,
        twitterFeed:   null,
        instagramFeed: null,
      });

    /**
     * Response from fetching social media items
     */
    case actions.SOCIAL_FEED_RESPONSE:
      return Object.assign({}, state, {
        socialPending: false,
        error:         action.payload.error,
        facebookFeed:  action.payload.facebookFeed,
        twitterFeed:   action.payload.twitterFeed,
        instagramFeed: action.payload.instagramFeed,
      });

    default:
      return state;
  }
}
