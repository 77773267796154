import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { ProductSelectorService } from '../../services/product-selector.service';
import * as ActionTypes from '../actions/products';
import { ProductSelectQueryResponse, } from '../../models/product-selector';
import { ProductCheckSerialResponse, } from '../../models/product';
var ProductsEffects = /** @class */ (function () {
    function ProductsEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a ProductSelectQueryRequestAction, call
         * ProductSelectorService::query() and dispatch a new
         * ProductSelectQueryResponseAction with the response.
         */
        this.query$ = this.actions$
            .ofType(ActionTypes.PRODUCT_SELECT_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.query(req.payload)
                .map(function (res) {
                return new ActionTypes.ProductSelectQueryResponseAction(res);
            });
        });
        /**
         * For a ProductCheckSerialRequestAction, call
         * ProductSelectorService::checkSerial() and dispatch a new
         * ProductCheckSerialResponseAction with the response.
         */
        this.checkSerial$ = this.actions$
            .ofType(ActionTypes.PRODUCT_CHECK_SERIAL_REQUEST)
            .switchMap(function (req) {
            return _this.service.checkSerial(req.payload)
                .map(function (res) {
                return new ActionTypes.ProductCheckSerialResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsEffects.prototype, "query$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsEffects.prototype, "checkSerial$", void 0);
    return ProductsEffects;
}());
export { ProductsEffects };
