import * as tslib_1 from "tslib";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { WatertestersService } from '../../services/watertesters.service';
import * as ActionTypes from '../actions/watertesters';
var WatertesterEffects = /** @class */ (function () {
    function WatertesterEffects(service, actions$) {
        var _this = this;
        this.service = service;
        this.actions$ = actions$;
        /**
         * For a RemoveTesterQueryResponseAction, call service.removeTesterFromCompany() and
         * dispatch a new RemoveTesterQueryResponseAction with the response.
         */
        this.removeTester$ = this.actions$
            .ofType(ActionTypes.REMOVE_TESTER_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.removeTesterFromCompany(req.payload)
                .map(function (res) {
                return new ActionTypes.RemoveTesterQueryResponseAction(res);
            });
        });
        /**
         * For a AdminTesterQueryResponseAction, call service.adminTesterCompany() and
         * dispatch a new AdminTesterQueryResponseAction with the response.
         */
        this.adminTester$ = this.actions$
            .ofType(ActionTypes.ADMIN_TESTER_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.adminTesterCompany(req.payload)
                .map(function (res) {
                return new ActionTypes.AdminTesterQueryResponseAction(res);
            });
        });
        /**
         * For a GetTesterQueryResponseAction, call service.getTester() and
         * dispatch a new GetTesterQueryResponseAction with the response.
         */
        this.getTester$ = this.actions$
            .ofType(ActionTypes.GET_TESTER_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTester(req.payload)
                .map(function (res) {
                return new ActionTypes.GetTesterQueryResponseAction(res);
            });
        });
        /**
         * For a GetTesterLocalQueryResponseAction, call service.getTesterLocal() and
         * dispatch a new GetTesterLocalQueryResponseAction with the response.
         */
        this.getTesterLocal$ = this.actions$
            .ofType(ActionTypes.GET_TESTER_LOCAL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTesterLocal(req.payload)
                .map(function (res) {
                return new ActionTypes.GetTesterLocalQueryResponseAction(res);
            });
        });
        /**
         * For a ResultEmailQueryResponseAction, call service.sendResultEmail() and
         * dispatch a new ResultEmailQueryResponseAction with the response.
         */
        this.sendResultEmail$ = this.actions$
            .ofType(ActionTypes.RESULT_EMAIL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.sendResultEmail(req.payload)
                .map(function (res) {
                return new ActionTypes.ResultEmailQueryResponseAction(res);
            });
        });
        /**
         * For a TesterRoleQueryResponseAction, call service.getTesterRole() and
         * dispatch a new TesterRoleQueryResponseAction with the response.
         */
        this.getTesterRole$ = this.actions$
            .ofType(ActionTypes.TESTER_ROLE_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTesterRole(req.payload)
                .map(function (res) {
                return new ActionTypes.TesterRoleQueryResponseAction(res);
            });
        });
        /**
         * For a CompanyTotalQueryResponseAction, call service.getCompanyTotal() and
         * dispatch a new CompanyTotalQueryResponseAction with the response.
         */
        this.getCompanyTotal$ = this.actions$
            .ofType(ActionTypes.COMPANY_TOTAL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getCompanyTotal()
                .map(function (res) {
                return new ActionTypes.CompanyTotalQueryResponseAction(res);
            });
        });
        /**
         * For a TesterTotalQueryResponseAction, call service.getTesterTotal() and
         * dispatch a new TesterTotalQueryResponseAction with the response.
         */
        this.getTesterTotal$ = this.actions$
            .ofType(ActionTypes.TESTER_TOTAL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTesterTotal()
                .map(function (res) {
                return new ActionTypes.TesterTotalQueryResponseAction(res);
            });
        });
        /**
         * For a TestTotalQueryResponseAction, call service.getTestTotal() and
         * dispatch a new TestTotalQueryResponseAction with the response.
         */
        this.getTestTotal$ = this.actions$
            .ofType(ActionTypes.TEST_TOTAL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTestTotal()
                .map(function (res) {
                return new ActionTypes.TestTotalQueryResponseAction(res);
            });
        });
        /**
         * For a TestStatusPropertyTotalPassQueryResponseAction, call service.getTestStatusPropertyTotal(1) and
         * dispatch a new TestStatusPropertyTotalPassQueryResponseAction with the response.
         */
        this.getTestStatusPropertyPassTotal$ = this.actions$
            .ofType(ActionTypes.TEST_STATUS_PROPERTY_TOTAL_PASS_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTestStatusPropertyTotal(1)
                .map(function (res) {
                return new ActionTypes.TestStatusPropertyTotalPassQueryResponseAction(res);
            });
        });
        /**
         * For a TestStatusPropertyTotalFailQueryResponseAction, call service.getTestStatusPropertyTotal(0) and
         * dispatch a new TestStatusPropertyTotalFailQueryResponseAction with the response.
         */
        this.getTestStatusPropertyFailTotal$ = this.actions$
            .ofType(ActionTypes.TEST_STATUS_PROPERTY_TOTAL_FAIL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTestStatusPropertyTotal(0)
                .map(function (res) {
                return new ActionTypes.TestStatusPropertyTotalFailQueryResponseAction(res);
            });
        });
        /**
         * For a TestOverDueTotalQueryResponseAction, call service.getTestDueTotal(0) and
         * dispatch a new TestOverDueTotalQueryResponseAction with the response.
         */
        this.getTestOverDueTotal$ = this.actions$
            .ofType(ActionTypes.TEST_OVER_DUE_TOTAL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTestDueTotal(req.payload)
                .map(function (res) {
                return new ActionTypes.TestOverDueTotalQueryResponseAction(res);
            });
        });
        /**
         * For a TestOverDueNextMonthTotalQueryResponseAction, call service.getTestDueTotal(0) and
         * dispatch a new TestOverDueNextMonthTotalQueryResponseAction with the response.
         */
        this.getTestOverDueNextMonthTotal$ = this.actions$
            .ofType(ActionTypes.TEST_OVER_DUE_NEXT_MONTH_TOTAL_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getTestDueTotal(req.payload)
                .map(function (res) {
                return new ActionTypes.TestOverDueNextMonthTotalQueryResponseAction(res);
            });
        });
        /**
         * For a TestExportQueryRequestAction, call getExportData and
         * dispatch a new TestOverDueNextMonthTotalQueryResponseAction with the response.
         */
        this.getExportData$ = this.actions$
            .ofType(ActionTypes.TEST_EXPORT_QUERY_REQUEST)
            .switchMap(function (req) {
            return _this.service.getExportData(req.payload)
                .map(function (res) {
                return new ActionTypes.TestExportQueryResponseAction(res);
            });
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "removeTester$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "adminTester$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTester$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTesterLocal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "sendResultEmail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTesterRole$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getCompanyTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTesterTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTestTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTestStatusPropertyPassTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTestStatusPropertyFailTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTestOverDueTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getTestOverDueNextMonthTotal$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], WatertesterEffects.prototype, "getExportData$", void 0);
    return WatertesterEffects;
}());
export { WatertesterEffects };
