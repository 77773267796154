import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { PostVoucherRedeemForm } from '../../../models/email';
import { UserProfile } from '../../../models/user-profile';
import { InitEmailStateAction, PostVoucherRedeemRequestAction } from '../../../state-management/actions/email';
import { UpdateUserProfileRequestAction } from '../../../state-management/actions/login-details';
import { State as EmailState } from '../../../state-management/reducers/email';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { StoreState } from '../../../state-management/store';
import { GenericModalComponent } from '../../common/generic-modal/generic-modal.component';


/**
 * Summary
 *    Page allowing user to redeem points for vouchers
 *
 * Description
 *    Displays a form (some fields pre-populated) and a select to choose a
 *    voucher value. Upon submit, the request to redeem this voucher is sent to
 *    the API.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-voucher-redeem',
  templateUrl: './voucher-redeem.component.html',
  styleUrls: ['./voucher-redeem.component.scss']
})
export class VoucherRedeemComponent implements OnDestroy, OnInit {

  // Store states
  public loginDetails$: Observable<LoginDetailsState>;
  public emailDetails$: Observable<EmailState>;

  // Subscription to store state
  private loginSub: Subscription;
  private user: UserProfile;

  // Form
  public fg: FormGroup = null;
  public fgChange = false;
  private fgChangeSub: Subscription;

  // Flag to indicate if form has been submitted
  public submitted: boolean = false;
  public pointsSpent: boolean = false;

  // Maximum number of points available to spend
  public maxPoints: number;

  // List of available vouchers
  public availableVouchers: string[] = [];


  constructor(
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private store: Store<StoreState>,
    private translate: TranslateService
  ) {
    store.dispatch(new InitEmailStateAction());

    // Build list of available voucher amounts
    this.availableVouchers = ['20', '25', '30'];

    // Custom validator: ensures that the user has enough points to redeem a
    // selected voucher
    const maxValue = (control: AbstractControl): { [key: string]: boolean } => {
      if (!control.value) {
        return null;
      }

      return (parseInt(control.value, 10) > this.maxPoints ? { error: true } : null);
    };

    this.fg = this._fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      company: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      town: [''],
      county: [''],
      postcode: ['', [Validators.required]],
      pointsToConvert: ['', [Validators.required, maxValue]],
      currency_type: ['']
    });

    this.loginDetails$ = this.store.select('loginDetails');
    this.emailDetails$ = this.store.select('email');
  }

  ngOnInit() {
    this.loginSub = this.loginDetails$.subscribe((res) => {
      if (res && res.currentProfile) {
        this.user = res.currentProfile;
        this.translate.getTranslation(res.currentProfile.countries_by_country_id.country_code).subscribe((translation) => {
          this.fg.get('currency_type').setValue(translation.CURRENCY);
        });

        // Pre-populate form fields with user profile values
        this.fg.get('name').setValue(res.currentProfile.title + ' ' + res.currentProfile.firstName + ' ' + res.currentProfile.lastName);
        this.fg.get('email').setValue(res.currentProfile.email);
        this.fg.get('company').setValue(res.currentProfile.companyName);
        this.fg.get('addressLine1').setValue(res.currentProfile.address1);
        this.fg.get('addressLine2').setValue(res.currentProfile.address2);
        this.fg.get('town').setValue(res.currentProfile.town);
        this.fg.get('county').setValue(res.currentProfile.county);
        this.fg.get('postcode').setValue(res.currentProfile.postCode);

        // Watch for form changes
        this.onFormChange();

        // Set the maximum number of redeemable points to the number of points
        // that the user has available
        this.maxPoints = parseInt(res.currentProfile.pointsCurrent, 10);
      }
    });
  }

  ngOnDestroy() {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
    if (this.fgChangeSub) {
      this.fgChangeSub.unsubscribe();
    }
  }

  private onFormChange(): void {
    const initialValue = this.fg.value;
    this.fgChangeSub = this.fg.valueChanges.subscribe(() => {
      this.fgChange = Object.keys(initialValue).some((key) => {
        const ignore = [
          'name',
          'currency_type',
          'pointsToConvert',
        ];

        if (ignore.includes(key)) {
          return false;
        }
        return this.fg.value[key] !== initialValue[key];
      });
    });
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.fg.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  public updateProfile(): void {
    const dialog = this.dialog.open(GenericModalComponent, {
      data: {
        title: 'Update profile details',
        content: 'Would you like to update your ProClub profile to match the details provided for the voucher request?',
        confirmLabel: 'Update',
        dismissLabel: 'Cancel',
      },
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.user.email = this.fg.value.email;
        this.user.companyName = this.fg.value.company;
        this.user.address1 = this.fg.value.addressLine1;
        this.user.address2 = this.fg.value.addressLine2;
        this.user.town = this.fg.value.town;
        this.user.county = this.fg.value.county;
        this.user.postCode = this.fg.value.postcode;
        this.store.dispatch(new UpdateUserProfileRequestAction({ profile: this.user }));
      }
    });
  }

  /**
   * Dispatches the redeem request if the form is valid
   */
  submitForm() {
    this.submitted = true;

    if (this.fg.valid) {
      this.pointsSpent = true;
      this.store.dispatch(new PostVoucherRedeemRequestAction({
        form: PostVoucherRedeemForm.fromFormData(this.fg.value),
        callback: () => this.router.navigate(['/rewards']),
      }));
    }
  }
}
