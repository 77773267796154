/**
 * Provides AdminCompanyEffects
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

import {Injectable} from '@angular/core';
import * as ActionTypes from '../actions/admin-companies';
import {AdminCompanyService} from '../../services/admin-company.service';
import {Actions, Effect} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {DFResponse} from '../../models/df-proclub';
import {HostedCompany} from '../../models/hosted-companies';

/**
 * NGRX Actions for the Companies
 */
@Injectable()
export class AdminCompanyEffects {
    /**
     * Set up required injected thingies
     *
     * @param adminCompanyService
     * @param actions$
     */
    constructor(
        private adminCompanyService: AdminCompanyService,
        private actions$: Actions
    ) {
    }

    @Effect() getListOfCompanies: Observable<Action> = this.actions$
        .ofType(ActionTypes.LOAD_COMPANIES)
        .switchMap((action: ActionTypes.AdminCompaniesLoadCompanyAction): Observable<Action> => {
            return this.adminCompanyService.getCompanyList(action.payload)
                .map((result: DFResponse<HostedCompany>): ActionTypes.AdminCompaniesLoadCompanyActionSuccess =>
                    new ActionTypes.AdminCompaniesLoadCompanyActionSuccess(result)
                );
        });
}
