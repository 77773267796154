import 'rxjs/add/operator/map';
import 'rxjs/add/observable/empty';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


import { ApiService } from './api.service';

import {
  FilterDataItem,
  FilterDataRequest,
  FilterDataResponse,
  TimelineDataRequest,
  TimelineDataResponse,
  TimelineData,
  AlertData,
  AlertDataRequest,
  AlertDataResponse,
  DismissAlertRequest,
  DismissAlertResponse,
  DeleteFilterRequest,
  DeleteFilterResponse
} from '../models/pro3';


@Injectable()
export class Pro3Service {

  constructor(
    private apiService: ApiService,
  ) { }

    /**
     * Gets installers associated filters and their data/
     * @param {FetchMonthlyWarrantyStatsRequest} req
     * @return {Observable<any>}
     */
    getFilterData(req: FilterDataRequest): Observable<FilterDataResponse> {
        return this.apiService.apiGet('/get-filters-v2?uid=' + encodeURIComponent(req.uid))
            .map((res: any): FilterDataResponse => {
                const valid: boolean = res && Array.isArray(res) && res[0] && res[0].deviceId;
                return {
                    error: valid ? null : 'No MagnaClean Sense currently registered.',
                    filterData: valid ? res.map(FilterDataItem.getFromApi) : null,
                };
            })
            .catch((err: any): Observable<FilterDataResponse> =>
                Observable.of({
                    error: err && err.error && err.error.message
                        ? `Could not fetch filter data: ${err.error.message}`
                        : err,
                    filterData: null,
                })
            );
    }

  deleteFilter(req: DeleteFilterRequest): Observable<any> {
    return this.apiService.apiDelete('/device/' + req.id)
      .map((res: any) => {
        const valid: boolean = res && res.resource && res.resource[0] && res.resource[0].id;
        return {
          error: (valid) ? null : 'Invalid response from server',
          message:  valid ? res.resource : null,
        };
      })
      .catch((err: any): Observable<DeleteFilterResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to register filter: ${err.error.message}`
            : 'Unable to register filter',
          message: null,
        })
      );
  }

  getTimelineData(req: TimelineDataRequest): Observable<TimelineDataResponse> {
    return this.apiService.apiGet('/get-filter-timeline?deviceID=' +  encodeURIComponent(req.deviceID))
      .map((res: any): TimelineDataResponse => {
        const valid: boolean = res && Array.isArray(res) && res[0] && res[0].id;
        return {
          error:    valid ? null : 'Invalid response from server',
          timelineData:  valid ? res.map(TimelineData.getFromApi) : null,
        };
      })
      .catch((err: any): Observable<TimelineDataResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Could not fetch timeline data: ${err.error.message}`
            : err,
          timelineData: null,
        })
      );
  }

  getAlertData(req: AlertDataRequest): Observable<AlertDataResponse> {
    return this.apiService.apiGet('/get-filter-alerts')
      .map((res: any): AlertDataResponse => {
        const valid: boolean = res.resource && Array.isArray(res.resource) && res.resource[0] && res.resource[0].id;
        return {
          error:    valid ? null : 'Invalid response from server',
          alertData:  valid ? res.resource.map(AlertData.getFromApi) : null,
        };
      })
      .catch((err: any): Observable<AlertDataResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Could not fetch alert data: ${err.error.message}`
            : err,
          alertData: null,
        })
      );
  }

  dismissAlert(req: DismissAlertRequest): Observable<any> {
    return this.apiService.apiPatch('/dismiss-alert', req)
      .map((res: any): DismissAlertResponse => {
        const valid: boolean = res && res.resource && res.resource[0] && res.resource[0].id;
        return {
          error: (valid) ? null : 'Invalid response from server',
          message:  valid ? res.resource : null,
        };
      })
      .catch((err: any): Observable<DismissAlertResponse> =>
        Observable.of({
          error: err && err.error && err.error.message
            ? `Unable to dimiss alert: ${err.error.message}`
            : 'Unable to dismiss alert',
          message: null,
        })
      );
  }


}
