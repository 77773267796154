/**
 * ApiService: provides base API functionality: HTTP request handling to the
 * configured API endpoint with authorization as appropriate based on the
 * current user login status.
 */

import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';


import { environment } from '../../environments/environment';
import {Store} from '@ngrx/store';
import {StoreState} from '../state-management/store';


@Injectable()
export class ApiSenseService {

  // Base URL for API access as configured in the current environment
  private baseUrl: string = environment.api.baseSenseUrl;

  // The current user's JWT string used to build a Authorization header (Bearer
  // token)
  private loginJwt: string = null;

  constructor(
    private _http: HttpClient,
    private _store: Store<StoreState>,
  ) {

  }

  /**
   * Builds a complete API URI from a passed suffix/endpoint
   *
   * @param {string} uriSuffix API endpoint
   * @return {string} Full API URI
   */
  private buildUri(uriSuffix: string): string {
    return this.baseUrl + uriSuffix;
  }

  /**
   * Builds a HttpHeaders instance with the "Authorization" header set with the
   * current JWT
   *
   * @return {HttpHeaders}
   */
  private getRequestHeaders(): HttpHeaders {
    return (new HttpHeaders()).set('Authorization', `Bearer ${this.loginJwt}`);
  }

  /**
   * Gets an options object to be passed to HttpClient request methods
   *
   * @return {object}
   */
  private getRequestOptions(): object {
    return this.loginJwt
      ? { headers: this.getRequestHeaders() }
      : {};
  }


  /**
   * Performs an API DELETE to a specified endpoint. Uses the current JWT if
   * available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiDelete<T>(uri: string): Observable<T> {
    return this._http.delete<T>(this.buildUri(uri), this.getRequestOptions());
  }

  /**
   * Performs an API GET to a specified endpoint. Uses the current JWT if
   * available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiGet<T>(uri: string): Observable<T> {
    return this._http.get<T>(this.buildUri(uri), this.getRequestOptions());
  }

  /**
   * Performs an API PATCH to a specified endpoint with specified data. Uses
   * the current JWT if available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @param {object} data Data to send in request
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiPatch<T>(uri: string, data: object): Observable<T> {
    return this._http.patch<T>(this.buildUri(uri), data, this.getRequestOptions());
  }

  /**
   * Performs an API POST to a specified endpoint with specified data. Uses the
   * current JWT if available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @param {object} data Data to send in request
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiPost<T>(uri: string, data: object): Observable<T> {
    return this._http.post<T>(this.buildUri(uri), data, this.getRequestOptions());
  }

  /**
   * Performs an API PUT to a specified endpoint with specified data. Uses the
   * current JWT if available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @param {object} data Data to send in request
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiPut<T>(uri: string, data: object): Observable<T> {
    return this._http.put<T>(this.buildUri(uri), data, this.getRequestOptions());
  }


  /**
   * Performs an API GET to a specified endpoint and returns the response as a
   * Blob.
   *
   * @param {string} uri API route
   * @return {Observable<Blob>} Observable of the API response
   */
  apiGetBlob(uri: string): Observable<Blob> {
    return this._http.get(this.buildUri(uri), { ...this.getRequestOptions(), ...{ responseType: 'blob' } });
  }

  /**
   * Performs an API POST to a specified endpoint and returns the response as a Blob.
   *
   * @param {string} uri  API route
   * @param {object} data Data to send in request
   * @return {Observable<Blob>} Observable of the API response
   */
  apiPostBlob(uri: string, data: object): Observable<Blob> {
    return this._http.post(this.buildUri(uri), data, { ...this.getRequestOptions(), ...{ responseType: 'blob' } });
  }

  /**
   * Performs an API GET to a specified endpoint and returns the response as
   * plain text.
   *
   * @param {string} uri API route
   * @return {Observable<string>} Observable of the API response
   */
  apiGetText(uri: string): Observable<string> {
    return this._http.get(this.buildUri(uri), { ...this.getRequestOptions(), ...{ responseType: 'text' } });
  }

  /**
   * Performs an API POST to a specified endpoint and returns the response as
   * plain text.
   *
   * @param {string} uri  API route
   * @param {object} data Data to send in request
   * @return {Observable<string>} Observable of the API response
   */
  apiPostText(uri: string, data: object): Observable<string> {
    return this._http.post(this.buildUri(uri), data, { ...this.getRequestOptions(), ...{ responseType: 'text' } });
  }

  /**
   * Get's the current time from the API.
   * @returns {Observable<string>}
   */
  apiTime(): Observable<string> {
    return this._http.get(this.buildUri('/time'), { ...this.getRequestOptions(), ...{ responseType: 'text' } });
  }

}
