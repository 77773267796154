/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-prompt.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "./confirm-prompt.component";
var styles_ConfirmPromptComponent = [i0.styles];
var RenderType_ConfirmPromptComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmPromptComponent, data: {} });
export { RenderType_ConfirmPromptComponent as RenderType_ConfirmPromptComponent };
export function View_ConfirmPromptComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(6, null, ["\n  ", "\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 12, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["mat-button", ""], ["matDialogClose", ""], ["type", "button"]], [[8, "disabled", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).dialogRef.close(i1.ɵnov(_v, 18).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor], null, null), i1.ɵdid(18, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(19, 0, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_5 = ""; _ck(_v, 13, 0, currVal_5); var currVal_9 = true; _ck(_v, 18, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.prompt.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.prompt.description; _ck(_v, 6, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = i1.ɵnov(_v, 13).ariaLabel; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = _co.prompt.close; _ck(_v, 14, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 17).disabled || null); var currVal_8 = i1.ɵnov(_v, 18).ariaLabel; _ck(_v, 16, 0, currVal_7, currVal_8); var currVal_10 = _co.prompt.confirm; _ck(_v, 19, 0, currVal_10); }); }
export function View_ConfirmPromptComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-prompt", [], null, null, null, View_ConfirmPromptComponent_0, RenderType_ConfirmPromptComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmPromptComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmPromptComponentNgFactory = i1.ɵccf("app-confirm-prompt", i7.ConfirmPromptComponent, View_ConfirmPromptComponent_Host_0, {}, {}, []);
export { ConfirmPromptComponentNgFactory as ConfirmPromptComponentNgFactory };
